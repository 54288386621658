<template>
    <div>
        <!-- 监听测试网切换提示 -->
        <v-app-bar v-model="showAlert" fixed elevation="0" rounded="0" height="44">
            <v-alert color="primaryGrey" elevation="0" rounded="0" align="center">
                <v-icon icon="mdi:mdi-alert-circle-outline" aria-label="alert-circle-outline"></v-icon>
                <span class="ml-2">
                    <span>You're viewing data from the </span>
                    <span v-if="!blockchain">all chains</span>
                    <span v-if="blockchain">{{ blockchain }} network</span>
                    <span>, but your wallet is connected to the {{ walletSelectedBlockchainNetwork }} network.</span>
                </span>
                <a class="del-underline gradient-left-red-purple-137 ml-10">
                    <v-btn rounded="0" elevation="2" variant="flat" color="button01" class="text-none text-grey05 body-p-small-b" width="137" height="28" @click="changeBlockchain" aria-label="Switch to">Switch to {{ walletSelectedBlockchainNetwork }}</v-btn>
                </a>
                <v-btn icon size="28" variant="text" aria-label="close" class="ml-10" @click="showAlert = false">
                    <v-icon aria-label="close" icon="mdi:mdi-close"></v-icon>
                </v-btn>
            </v-alert>
        </v-app-bar>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 显示警告
            showAlert: false,
            // 钱包选中的区块链网络
            walletSelectedBlockchainNetwork: null,
        }
    },
    props: {
        
    },
    components: {  },
    created(){
        // 检查 MetaMask
        this.checkMetaMask();
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain']),
    },
    watch:{

    },
    methods: {
        // 检查 MetaMask
        async checkMetaMask() {
            if (typeof window.ethereum !== 'undefined') {
                if(ethereum.isMetaMask) {
                    // 监听账户改变
                    window.ethereum.on('chainChanged', (chainId) => {
                        let walletSelectedBlockchain = this.blockchains.filter(b => b.chainId == chainId)[0];
                        if(walletSelectedBlockchain) {
                            // 选中的不是当前的提示切换
                            if(this.blockchain != walletSelectedBlockchain.blockchain) {
                                this.walletSelectedBlockchainNetwork = walletSelectedBlockchain.blockchain;
                                this.showAlert = true;
                            }
                        } else {
                            this.$store.dispatch('blockchainHandler', null);
                        }
                    });
                }
            }
        },
        // 切换区块链
        changeBlockchain() {
            this.$store.dispatch('blockchainHandler', this.walletSelectedBlockchainNetwork);
            this.showAlert = false;
        },
    }
}
</script>
<style scoped>

</style>