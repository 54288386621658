<template>
	<div>
        <!---------------------------- 统计 ---------------------------->
        <v-row no-gutters>
            <v-col cols="12" lg="12" sm="6" class="r-p-x-4">
                <div class="c-container">
                    <div class="c-card c-grid d-flex flex-column">
                        <!-- 加载中骨架 -->
                        <SkeletonCard :length="12" :height="126" v-if="loading"></SkeletonCard>
                        <!-- 数据 -->
                        <div class="c-height totalCard" v-for="(stat, i) in dashboardStats" :key="i" v-if="!loading">
                            <v-card elevation="3" :rounded="0" class="pa-8" color="black">
                                <div class="body-p-small text-primaryGrey">
                                    <span>{{ stat.name }}</span>
                                    <span v-if="stat.name == 'TOTAL UNCLAIMED ORs' || stat.name == 'TOTAL UNCLAIMED FRs'"> (Click to claim now)</span>
                                </div>
                                <div class="body-h2 text-primaryGrey">
                                    {{ $tools.formatNumber(stat.value, stat.beInteger ? 0 : 2) }}{{ ' ' + stat.symbol }}
                                    <v-icon  v-if="stat.name == 'TOTAL UNCLAIMED ORs' || stat.name == 'TOTAL UNCLAIMED FRs'" icon="fa-solid fa-up-right-from-square" size="24" class="mt-2 float-right mr-3"></v-icon>
                                </div>
                            </v-card>
                            <!-- 领取 OR 弹窗 -->
                            <v-btn aria-label="Claim now" rounded="0" variant="text" class="overlay text-none text-white body-p-large" @click="claimORDialog = true" v-if="stat.name == 'TOTAL UNCLAIMED ORs'">
                                <span>Claim now</span>
                                <ClaimOR :blockchain="currentBlockchain" :amount="stat.value" :show="claimORDialog" @watchClaimORDialog="watchClaimORDialog" @emitGetDashboards="getDashboards"></ClaimOR>
                            </v-btn>
                            <!-- 领取 FR 弹窗 -->
                            <v-btn  aria-label="Claim now" rounded="0" variant="text" class="overlay text-none text-white body-p-large" @click="claimFRDialog = true" v-if="stat.name == 'TOTAL UNCLAIMED FRs'">
                                <span>Claim now</span>
                                <ClaimFR :blockchain="currentBlockchain" :amount="stat.value" :show="claimFRDialog" @watchClaimFRDialog="watchClaimFRDialog" @emitGetDashboards="getDashboards"></ClaimFR>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!---------------------------- NFR ---------------------------->
        <v-row no-gutters>
            <v-card class="w-100 px-0 pt-72" rounded="0" elevation="0" color="transparent">
                <!---------------------------- tab ---------------------------->
                <v-row no-gutters class="r-p-x-4">
                    <v-col cols="9">
                        <v-tabs slider-color="primary" v-model="tab" class="w-90" density="compact">
                            <v-tab value="active" rounded="0" class="px-0 mr-4" @click="$refs.active && $refs.active.changeCondition()">
                                <v-badge :content="nfrStat.active" inline>
                                    <h4>Active nFRs</h4>
                                </v-badge>
                            </v-tab>
                            <v-tab value="completed" rounded="0" class="px-0 mr-4" @click="$refs.completed && $refs.completed.changeCondition()">
                                <v-badge :content="nfrStat.completed" inline>
                                    <h4>Completed nFRs</h4>
                                </v-badge>
                            </v-tab>
                            <v-tab value="wrapped" rounded="0" class="px-0 mr-4" @click="$refs.wrapped && $refs.wrapped.changeCondition()">
                                <v-badge :content="nfrStat.wrapped" inline>
                                    <h4>Wrapped nFRs</h4>
                                </v-badge>
                            </v-tab>
                            <v-tab value="unwrapped" rounded="0" class="px-0" @click="$refs.unwrapped && $refs.unwrapped.changeCondition()">
                                <v-badge :content="nfrStat.unwrapped" inline>
                                    <h4>Unwrapped nFRs</h4>
                                </v-badge>
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <!---------------------------- 过滤条件 ---------------------------->
                    <v-col cols="2" offset="1">
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn aria-label="menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p float-right" height="100%" v-bind="props">
                                    {{ sortCondition }}
                                    <v-icon end icon="mdi:mdi-menu-down" />
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                    <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
                <v-card-text class="px-0 pt-3">
                    <v-window v-model="tab">
                        <!---------------------------- Active ---------------------------->
                        <v-window-item value="active">
                            <ActiveNFRs ref="active" :blockchain="currentBlockchain.blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></ActiveNFRs>
                        </v-window-item>
                        <!---------------------------- Completed ---------------------------->
                        <v-window-item value="completed">
                            <CompletedNFRs ref="completed" :blockchain="currentBlockchain.blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></CompletedNFRs>
                        </v-window-item>
                        <!---------------------------- Wrapped ---------------------------->
                        <v-window-item value="wrapped">
                            <WrappedNFRs ref="wrapped" :blockchain="currentBlockchain.blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></WrappedNFRs>
                        </v-window-item>
                        <!---------------------------- Unwrapped ---------------------------->
                        <v-window-item value="unwrapped">
                            <UnwrappedNFRs ref="unwrapped" :blockchain="currentBlockchain.blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></UnwrappedNFRs>
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UserAPI from '@/api/user.js';
import NFRAPI from '@/api/nfr.js';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard.vue';
import ActiveNFRs from '@/components/user/dashboard/nft/ActiveNFRs.vue';
import CompletedNFRs from '@/components/user/dashboard/nft/CompletedNFRs.vue';
import WrappedNFRs from '@/components/user/dashboard/nft/WrappedNFRs.vue';
import UnwrappedNFRs from '@/components/user/dashboard/nft/UnwrappedNFRs.vue';
import ClaimOR from '@/components/user/dashboard/nft/ClaimOR.vue';
import ClaimFR from '@/components/user/dashboard/nft/ClaimFR.vue';
export default {
    data(){
        return {
            // 标签
            tab: this.$route.query?.tab?.toLowerCase(),
            // 排序条件
            sortConditions: ['Newest', 'Oldest'],
            sortCondition: 'Newest',
            // NFR 统计
            nfrStat: {
                active: 0,
                completed: 0,
                wrapped: 0,
                unwrapped: 0
            },
            // 加载中
            loading: true,
            // 仪表盘统计集合
            dashboardStats: [],
            // 领取 OR
            claimORDialog: false,
            // 领取 FR
            claimFRDialog: false,
            // 当前的区块链
            currentBlockchain: {},
        }
    },
    components: { SkeletonCard, ActiveNFRs, CompletedNFRs, WrappedNFRs, UnwrappedNFRs, ClaimOR, ClaimFR },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain']),
    },
    watch:{
        // 监听区块链
        blockchain: {
            handler(n, o) {
                if(n) {
                    this.currentBlockchain = this.blockchains.filter(b => b.blockchain == n)[0];  
                } else {
                    this.currentBlockchain = this.blockchains[0];
                }
                // 刷新
                this.changeBlockchainCondition(this.currentBlockchain);
            },
            immediate: true
        }
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 查询用户仪表盘数据
            this.getDashboards();
            // 查询NFR统计数据
            this.getNfrStat();
        },
        // 改变条件
        changeSortCondition(sortCondition) {
            this.sortCondition = sortCondition;
        },
        // 查询用户仪表盘数据
        async getDashboards() {
            this.loading = true;
            let res = await UserAPI.getDashboards(this.currentBlockchain.blockchain);
            let data = res.data;
            if(data.success) {
                this.dashboardStats = data.data;
            }
            this.loading = false;
        },
        // 查询NFR统计数据
        async getNfrStat() {
            let res = await NFRAPI.getNfrStat(this.currentBlockchain.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfrStat = data.data;
            }
        },
        // 监听领取 OR 弹窗
        watchClaimORDialog(show) {
            this.claimORDialog = show;
        },
        // 监听领取 FR 弹窗
        watchClaimFRDialog(show) {
            this.claimFRDialog = show;
        },
    }
}
</script>
<style scoped>
.v-btn{
    text-transform: none!important;
}

.totalCard{
  position: relative;
  overflow: hidden;
}

.totalCard:hover .overlay{
  opacity: 1;
  transform: scale(1);
}

.overlay{
  position: absolute;
  background-image: -webkit-linear-gradient(left,#03DAC5,#03DAC5);
  background-image: linear-gradient(90deg,#03DAC5,#03DAC5);
  background-size: 100%;
  color: #fff!important;
  width: 100%;
  height: 100%;
  top: 0;
  transform: scale(1.3);
  opacity: 0;
  transition: transform .4s ease 0s;
}

.overlay .v-btn{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.totalCard v-card{
  transition: transform .8s ease;
}

.totalCard:hover v-card{
  transform: scale(1.1);
}
</style>