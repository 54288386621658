<template>
	<div class="bg-lightGray align-center h-100">
        <v-row justify="center" align="center" v-if="!loading && !isMyNFT">
            You are not the owner!
        </v-row>
        <v-container class="h-100" v-if="isMyNFT">
            <v-card color="transparent" max-width="1400" rounded="0" elevation="0" class="mx-auto pt-7">
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-16">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1" class="justify-center">
                                <!-- 返回按钮 -->
                                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-13 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <h1 class="sub-page-h1 ml-13 text-primaryGrey r-m-l-0">
                                    <span v-if="nft.contractFunction == 'UNWRAP'">Unwrapped unNFT</span>
                                    <span v-else>Transfer your unNFT</span>
                                </h1>
                                <v-card max-width="560" class="ml-13 pa-8 mb-16 mt-8 r-m-l-0" color="paleYellow" rounded="0" elevation="2">
                                    <!-- ------------------------- Transfer to ------------------------- -->
                                    <div class="body-h4 mb-6 text-uppercase text-primaryGrey">Transfer to<span class="ml-1 text-red">*</span></div>
                                    <v-row align="center">
                                        <v-col cols="12" class="pa-0">
                                            <v-text-field clearable v-model="transferNFT.toAddress" label="Enter public address (0x)" variant="outlined" hide-details density="comfortable" @update:modelValue="transferPermissionByAddress"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" v-if="transferNFT.toAddress != null && transferToUserErrorMessage != null" class="pa-0 mt-1 text-red body-p">
                                            {{ transferToUserErrorMessage }}
                                        </v-col>
                                        <v-col cols="12" v-if="transferToUser != null" class="pa-0 mt-2">
                                            <UserAvatar :avatar="transferToUser?.avatar" :username="transferToUser?.username" :name="transferToUser?.name" :size="36" class="mr-2"></UserAvatar>
                                        </v-col>
                                    </v-row>
                                    <!-- ------------------------- Token Id ------------------------- -->
                                    <div class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">Token ID<span class="ml-1 text-red">*</span></div>
                                    <v-row align="center">
                                        <v-col cols="12" class="pa-0">
                                            <v-text-field v-model="transferNFT.tokenId" type="number" min="0" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- ------------------------- Set ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">
                                        Sets
                                    </h3>
                                    <div class="mt-6">
                                        <v-text-field v-model="nft.set.name" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                    </div>
                                    <!-- ------------------------- Category ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">Category</h3>
                                    <div class="mt-6">
                                        <v-text-field v-model="nft.category" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                    </div>
                                </v-card>
                                <div class="ml-13 r-m-l-0" v-if="!loading">
                                    <div v-if="nft.contractFunction != 'UNWRAP'">
                                        <div class="d-flex justify-space-between">
                                            <router-link :to="'/unnft/list/' + id" class="del-underline gradient-left-red-purple-200">
                                                <v-btn :disabled="transferLoading" :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" aria-label="List">List</v-btn>
                                            </router-link>
                                            <span class="gradient-left-red-purple-200">
                                                <v-btn :rounded="0" :loading="transferLoading" :disabled="!isOwner || transferToUser == null || !hasTransferPermission" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" aria-label="Transfer" @click="transfer()">Transfer</v-btn>
                                            </span>
                                        </div>
                                        <div class="mt-10">
                                            <router-link :to="$tools.getUnNFTUrl(nft.blockchain, id)" class="del-underline gradient-left-red-purple-block">
                                                <v-btn :disabled="transferLoading" :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b block" height="52" aria-label="View">View</v-btn>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="5" sm="6" cols="12" class="ml-12 mt-2 r-m-l-0 r-m-t-16">
                                <UnNftMedia :nftId="id"></UnNftMedia>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import UserAPI from '@/api/user.js';
import NFTAPI from '@/api/nft.js';
import NFTBlacklistAPI from '@/api/nft-blacklist.js';
import UnNftMedia from '@/components/nft/UnNftMedia.vue';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 是我的 NFT 吗
            isMyNFT: false,
            // 加载中
            loading: false,
            // 转移加载中
            transferLoading: false,
            // 是否有转移权限
            hasTransferPermission: false,
            // 转移到的用户
            transferToUser: null,
            // 转移到的用户错误信息
            transferToUserErrorMessage: null,
            // nft 转移参数
            transferNFT: {
                nftId: null,
                toAddress: null,
                tokenId: 0,
                blockchain: null,
                transactionHash: null
            },
            // NFT 信息
            nft: {
                set: {},
                user: {},
                owner: {},
                permission: {}
            },
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 当前的区块链
            currentBlockchain: {},
            web3: {},
            // 签名消息
            signMessage: 'untrading.org uses this signature to verify that you are the owner of this wallet address.',
            // 我是拥有者吗
            isOwner: false,
        }
    },
    components: { UnNftMedia },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "NFT_TRANSFER") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'walletType', 'blockchains']),
    },
    watch:{
        id: {
            handler(n, o) {
                this.checkIsMyNFT();
            },
            immediate: true
        },
    },
    methods: {
        // 这是我的 NFT 吗
        async checkIsMyNFT() {
            this.loading = true;
            let res = await NFTAPI.isMyNFT(this.id);
            let data = res.data;
            if(data.success) {
                this.isMyNFT = data.data;
                this.getNFTById();
            }
            this.loading = false;
        },
        // 是否存在该 NFT
        async exists() {
            let res = await NFTAPI.exists(this.id);
            let data = res.data;
            if(data.success && data.data) {
                setTimeout(() => {
                    // 存在则去详情页面
                    this.$router.push(this.$tools.getUnNFTUrl(this.nft.blockchain, this.nft.id));
                }, 100);
            } else  {
                // 不存在则导航到404
                this.$router.push('/404');
            }
        },
        // 查询owner信息
        async ownerOf() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let owner = await NFTContract.methods.ownerOf(this.nft.tokenId).call();
            if(owner) {
                this.isOwner = this.$tools.equalsIgnoreCase(owner, this.user.wallet);
            }
        },
        // 根据 NFT 主键查询黑名单集合
        async getBlacklistByNftId() {
            if(this.id == null) {
                return;
            }
            let res = await NFTBlacklistAPI.getBlacklistByNftId(this.id);
            let data = res.data;
            if(data.success) {
                this.blacklists = data.data;
            }
        },
        // 根据主键查询 NFT
        async getNFTById() {
            this.loading = true;
            // 判断用户是否有转移权限
            this.transferPermission();
            let res = await NFTAPI.getNFTById(this.id);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                // 判断权限
                if(this.nft.contractFunction == 'UNWRAP' || !this.isMyNFT) {
                    // 判断是否存在
                    this.exists();
                    return;
                }
                this.transferNFT.nftId = this.nft.id;
                this.transferNFT.tokenId = this.nft.tokenId;
                this.transferNFT.blockchain = this.nft.blockchain;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0]
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 查询owner信息
                this.ownerOf();
            }
            this.loading = false;
        },
        // 判断用户是否有转移权限
        async transferPermission() {
            if(this.id == null) {
                return;
            }
            let res = await NFTAPI.transferPermission(this.id);
            let data = res.data;
            if(data.success) {
                this.hasTransferPermission = data.data;
                if (!this.hasTransferPermission) {
                    this.$store.dispatch('snackbarMessageHandler', data.message);;
                }
            }
        },
        // 判断地址是否有接收转移权限
        async transferPermissionByAddress() {
            if(this.id == null) {
                return;
            }
            this.transferToUserErrorMessage = null;
            let res = await NFTAPI.transferPermissionByAddress(this.id, this.transferNFT.toAddress);
            let data = res.data;
            if(data.success && data.data) {
                // 根据钱包地址获取简单用户信息
                this.getSimpleUserByWallet();
            } else {
                this.transferToUser = null;
                this.transferToUserErrorMessage = data.message;
            }
        },
        // 根据钱包地址获取简单用户信息
        async getSimpleUserByWallet() {
            let res = await UserAPI.getSimpleUserByWallet(this.transferNFT.toAddress);
            let data = res.data;
            if(data.success) {
                this.transferToUser = data.data;
                this.transferToUserErrorMessage = null;
            } else {
                this.transferToUser = null;
                this.transferToUserErrorMessage = "This address is not signed up!";
            }
        },
        // 转移
        async transfer() {
            // 加载中
            this.transferLoading = true;
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('safeTransferFrom(address,address,uint256)');
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameters(['address', 'address', 'uint256'], [this.user.wallet, this.transferNFT.toAddress, this.nft.tokenId]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.currentBlockchain.unNFTContract, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.transferLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.transferLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            // 交易成功
            if(success) {
                // 更新参数
                this.transferNFT.transactionHash = transactionHash;
                // 链下转移，更新数据库
                // 20230912: 服务端订阅合约，前端取消请求
                // this.transferOnUntrading();
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 加载完成
                this.transferLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt(){
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_TRANSFER",
                    blockchain: this.transferNFT.blockchain,
                    transactionHash: this.transferNFT.transactionHash
                }
                this.$bus.emit('emitGetTransactionReceipt', params);
            }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                // 加载完成
                this.transferLoading = false;
                // 跳转到详情页面
                this.$router.push(this.$tools.getUnNFTUrl(this.nft.blockchain, this.nft.id));
            }
        },
        // 链下转移，更新数据库
        async transferOnUntrading() {
            let res = await NFTAPI.transfer(this.transferNFT);
            let data = res.data;
            if(data.success) {
                if(data.data) {
                    this.$router.push(this.$tools.getUnNFTUrl(this.nft.blockchain, this.nft.id));
                }
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>