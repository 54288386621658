import axios from '@/config/api.request'

export default {
    // 查询全部加密货币基准资产
    getCryptoBases(blockchain) {
        return axios.request({
            url: '/crypto/base/list',
            method: 'get',
            params: { blockchain },
        })
    },
    // 根据区块链和地址查询加密货币基准资产详情
    getCryptoBaseDetail(blockchain, address) {
        return axios.request({
            url: '/crypto/base/detail',
            method: 'get',
            params: { blockchain, address }
        })
    }
}