<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-5 r-m-l-0 text-primaryGrey">
                    <h4>Market Stats</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 ml-5 r-m-l-0 mt-1 mb-5"></v-divider>
                <v-card class="ml-5 r-m-l-0 mt-3" rounded="0" elevation="0" color="transparent">
                    <div>
                        <span class="body-p-large text-primaryGrey">
                            <count-to :start-val="0" :end-val="totalNFRPaid" :duration="2000" :decimals="2" separator="," prefix="$"></count-to>
                        </span><br/>
                        <span class="body-p text-primaryGrey">Total nFR Paid</span>
                    </div>
                    <div class="mt-3">
                        <span class="body-p-large text-primaryGrey">
                            {{ totalUserRewardsPaid }}
                        </span><br/>
                        <span class="body-p text-primaryGrey">UN - Total User Rewards Paid</span>
                    </div>
                </v-card>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import MarketAPI from '@/api/market.js';
export default {
    data(){
        return {
            // 总 $ NFR 支付
            totalNFRPaid: 0,
            // 总UN用户奖励
            totalUserRewardsPaid: 0
        }
    },
    components: {  },
    created(){
        // 查询全站产生的总 $ nFR 支付
        this.getTotalNFRPaid();
        // 查询全站产生的总UN用户奖励
        this.getTotalUserRewardsPaid();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchain'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.getTotalNFRPaid();
        }
    },
    methods: {
        // 查询全站产生的总 $ nFR 支付
        async getTotalNFRPaid() {
            let res = await MarketAPI.getTotalNFRPaid(null);
            let data = res.data;
            if(data.success) {
                this.totalNFRPaid = data.data;
            }
        },
        // 查询全站产生的总UN用户奖励
        async getTotalUserRewardsPaid() {
            let res = await MarketAPI.getTotalUserRewardsPaid();
            let data = res.data;
            if(data.success) {
                this.totalUserRewardsPaid = data.data;
                // http://numeraljs.com/
                var numeral = require('numeral');
                this.totalUserRewardsPaid = numeral(this.totalUserRewardsPaid).format('0.00a');
            }
        }
    }
}
</script>
<style scoped>

</style>