import axios from '@/config/api.request'

export default {
    // 查询全部类别
    getCategories() {
        return axios.request({
            url: '/nft/category/categories',
            method: 'get'
        })
    },
    // 更新 NFT 的类别
    updateCategoryNFT(data) {
        return axios.request({
            url: '/nft/category/nft/update',
            method: 'post',
            data: data
        })
    },
}