<template>
	<div>
        <v-card max-width="650" class="pa-3" rounded="0" elevation="0" color="transparent" align="center">
            <div class="bg-black elevation-show pa-7 pt-0">
                <v-row no-gutters class="pa-6">
                    <v-col cols="12">
                        <h2 class="sidebar-h2 mt-5 text-primaryGrey">Random Fact {{ fact.id != null ? `#${fact.id}` : '' }}</h2>
                        <div class="mt-10">
                            <div v-if="loading">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c4752f74-0679-4c78-c898-4cce868fbb00/public" height="30"></v-img>
                            </div>
                            <div v-else>
                                <h5 class="body-h5 mb-10 text-primaryGrey">
                                    {{ fact.content }}
                                </h5>
                                <v-icon class="icon pointer" size="42" color="primaryGrey" @click="getRandomFact()">mdi mdi-refresh-circle</v-icon>
                                <a :href="`https://x.com/intent/tweet?text=${twitterText}`" target="_blank">
                                    <v-icon class="icon pointer ml-5" size="36" color="primaryGrey">icon-x</v-icon>
                                </a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>
	</div>
</template>
<script>
import FactAPI from '@/api/fact.js';
export default {
    data(){
        return {
            // 事实
            fact: {},
            // 加载中
            loading: true
        }
    },
    props: {
        // 加密货币链接
        unCryptoUrl: {
            type: String,
            default: null
        }
    },
    components: {  },
    created(){
        
    },
    mounted(){
        // 延时1s查询
        setTimeout(() => {
            // 随机查询一个事实
            this.getRandomFact();
        }, 1000);
    },
    computed: {
        twitterText() {
            return encodeURIComponent('While waiting for my #unCrypto transaction to be confirmed on-chain, I learned an interesting random fact!😃❤️👇 \n\n“' + this.fact.content + '”\n\n'+ this.unCryptoUrl);
        }
    },
    watch:{

    },
    methods: {
        // 随机查询一个事实
        async getRandomFact() {
            this.loading = true;
            this.fact = {};
            let res = await FactAPI.getRandomFact();
            let data = res.data;
            if(data.success) {
                this.fact = data.data;
            } else {
                this.fact = {
                    content: 'You have arrived at nothing.'
                };
            }
            this.loading = false;
        },
    }
}
</script>
<style scoped>
.elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15),
     5px -5px 10px rgba(0, 0, 0, 0.15),
     0px 0px 0px,
     -5px -5px 10px rgba(0, 0, 0, 0.15);
    z-index: 9;
}
</style>