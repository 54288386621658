<template>
	<div>
        <v-card max-width="600" rounded="0" color="black" :class="responsive == 'pc' ? 'elevation-12 ' : 'elevation-6'">
            <v-row no-gutters class="py-3" justify="space-between">
                <div class="ml-4 text-primaryGrey">
                    <v-icon icon="mdi:mdi-ethereum" v-if="unCrypto.blockchain == 'Ethereum' || unCrypto.blockchain == 'Goerli' || unCrypto.blockchain == 'Sepolia'"></v-icon>
                    <v-icon size="16" v-if="unCrypto.blockchain == 'Arbitrum One'">icon-arbitrum</v-icon>
                    <v-icon size="16" v-if="unCrypto.blockchain == 'Polygon' || unCrypto.blockchain == 'Mumbai'">icon-polygonscan</v-icon>
                </div>
                <div class="mr-6">
                    <v-menu open-on-hover>
                        <template v-slot:activator="{ props }">
                            <v-icon icon="mdi:mdi-share-variant" class="mr-12 text-primaryGrey gradient-text-hover pointer" size="18" v-bind="props"></v-icon>
                        </template>
                        <v-list>
                            <!-- 复制链接 -->
                            <v-list-item>
                                <v-list-item-title>
                                    <v-icon icon="icon-link" class="text-primaryGrey" size="20"></v-icon>
                                    <span class="ml-3 gradient-underline-hover">
                                        <Clipboard :text="currentUrl">
                                            <a class="text-primaryGrey gradient-text-hover">Copy link</a>
                                        </Clipboard>
                                    </span>
                                </v-list-item-title>
                            </v-list-item>
                            <!-- 分享媒体 -->
                            <v-list-item v-for="(menu, index) in shareMenus" :key="index" :value="index">
                                <v-list-item-title class="gradient-underline-hover">
                                    <v-icon :icon="menu.icon" class="text-primaryGrey" size="20"></v-icon>
                                    <a :href="share(menu.type)" class="ml-3 text-primaryGrey gradient-text-hover" target="_blank">{{ menu.title }}</a>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-icon icon="mdi mdi-eye-outline" class="text-primaryGrey" size="18"></v-icon>
                    <span class="body-p-small ml-2">
                        <count-to :start-val="0" :end-val="stat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                    </span>
                    <v-icon v-if="!liked" icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer text-primaryGrey gradient-text-hover" @click.stop="like()"></v-icon>
                    <v-icon v-if="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike()"></v-icon>
                    <span class="body-p-small ml-2">
                        <count-to :start-val="stat.likeCount" :end-val="stat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                    </span>
                </div>
            </v-row>
            <div class="elevation-show bg-white-666666">
                <v-row no-gutters class="px-6 pt-8">
                    <v-col cols="12" class="text-grey05 mt-10">
                        <SkeletonCard v-if="loading" :height="76"></SkeletonCard>
                        <h2 v-if="!loading">
                            <span :class="responsive == 'pc' ? 'hero-h3' : 'hero-h6'">{{ unCrypto.listedSize > 0 
                                ? $tools.formatNumber(unCrypto.listedSize, unCrypto?.cryptoWrapped?.sizeDecimals) 
                                : $tools.formatNumber(unCrypto.currentSize, unCrypto?.cryptoWrapped?.sizeDecimals) }}</span>
                            <span class="float-right" :class="responsive == 'pc' ? 'mt-5 hero-h6' : 'mt-3 hero-h7'">{{ unCrypto.wrappedCurrency }}</span>
                        </h2>
                        <h5 class="body-h5 mt-3">
                            Offered at
                        </h5>
                    </v-col>
                    <v-col cols="12" class="text-grey05 mt-10">
                        <h5 class="body-h5 mb-3">
                            Limit
                        </h5>
                        <SkeletonCard v-if="loading" :height="76"></SkeletonCard>
                        <h2 v-if="!loading">
                            <span :class="responsive == 'pc' ? 'hero-h3' : 'hero-h6'">{{ unCrypto.listedSize > 0 ? $tools.formatNumber(unCrypto.limitPrice, unCrypto?.cryptoQuote?.sizeDecimals) : '--' }}</span>
                            <span class="float-right" :class="responsive == 'pc' ? 'mt-5 hero-h6' : 'mt-3 hero-h7'">{{ unCrypto.quoteCurrency }}</span>
                        </h2>
                    </v-col>
                    <v-col cols="12" v-if="unCrypto.contractFunction == 'UNWRAP'" class="text-grey05 mt-8">
                        <h5 class="body-h5">
                            Unwrapped
                        </h5>
                    </v-col>
                    <v-col cols="12" v-else-if="unCrypto.listedSize == 0" class="text-grey05 mt-8">
                        <h5 class="body-h5">
                            Unlisted
                        </h5>
                    </v-col>
                    <v-col cols="12" v-if="currentUnwrapSignature?.status == 'REQUESTED'" class="text-grey05 mt-8">
                        <h5 class="body-h5">
                            <v-icon aria-label="mdi-account-lock-open-outline" icon="mdi:mdi-account-lock-open-outline" class="text-primaryGrey" size="30"></v-icon>
                            <span>Unwrapping Requested</span>
                        </h5>
                    </v-col>
                    <v-col cols="12" v-else-if="currentUnwrapSignature?.status == 'SIGNED'" class="text-grey05 mt-8">
                        <h5 class="body-h5">
                            <v-icon aria-label="mdi-account-lock-open-outline" icon="mdi:mdi-account-lock-open-outline" class="text-primaryGrey" size="30"></v-icon>
                            <span>Unwrapping Approved</span>
                        </h5>
                    </v-col>
                </v-row>
                <v-row no-gutters class="px-6 py-10" align="center">
                    <v-col cols="10" class="text-grey05 d-flex" align="start">
                        <div v-if="currentUnwrapSignature?.status == 'REQUESTED' && unCrypto?.permission?.signUnwrap" class="mr-5">
                            <router-link :to="`/uncrypto/unwrap/${unCrypto.id}`" class="gradient-left-red-purple-182 del-underline">
                                <v-btn rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="40" aria-label="Approve Unwrapping">Approve Unwrapping</v-btn>
                            </router-link>
                        </div>
                        <div v-if="currentUnwrapSignature?.status == 'SIGNED' && unCrypto?.permission?.confirmUnwrap" class="mr-5">
                            <router-link :to="`/uncrypto/unwrap/${unCrypto.id}`" class="gradient-left-red-purple-182 del-underline">
                                <v-btn rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="40" aria-label="Confirm Unwrapping">Confirm Unwrapping</v-btn>
                            </router-link>
                        </div>
                        <div v-if="isOwner && unCrypto.contractFunction != 'UNWRAP' && unCrypto.listedSize == 0">
                            <router-link :to="`/uncrypto/list/${unCrypto.id}`" class="gradient-left-red-purple-80 del-underline">
                                <v-btn rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="80" height="40" aria-label="Sell">Sell</v-btn>
                            </router-link>
                        </div>
                    </v-col>
                    <v-col cols="2" class="text-grey05" align="end">
                        <!-- 菜单 -->
                        <UnCryptoMenu color="grey05" :cryptoId="id" :blockchain="unCrypto.blockchain" :isOriginator="isOriginator" :isOwner="isOwner" 
                            :isListed="unCrypto.listedSize > 0" :isUnwrapped="unCrypto.contractFunction == 'UNWRAP'"
                            :hasRequestUnwrapPermission="unCrypto?.permission?.requestUnwrap" 
                            :hasSignUnwrapPermission="unCrypto?.permission?.signUnwrap"
                            :hasConfirmUnwrapPermission="unCrypto?.permission?.confirmUnwrap"
                        ></UnCryptoMenu>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <div v-if="unCrypto.listedSize > 0" class="mt-8">
            <router-link :to="`/uncrypto/buy/${id}`" class="gradient-left-red-purple-300 del-underline" :class="hasBuyPermission ? 'pointer' : 'pointer-none'">
                <v-btn rounded="0" elevation="2" :disabled="!hasBuyPermission" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" aria-label="Buy Limit">Buy - Limit</v-btn>
            </router-link>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SkeletonCard from '@/components/util/skeleton/SkeletonCard.vue';
import UnCryptoMenu from '@/components/crypto/UnCryptoMenu.vue';
import CryptoAPI from '@/api/crypto.js';
import CryptoStatAPI from '@/api/crypto-stat.js';
import CryptoLikeAPI from '@/api/crypto-like.js';
import CryptoUnwrapAPI from '@/api/crypto-unwrap.js';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 封装后的加密货币
            unCrypto: {},
            // 分享菜单
            shareMenus: [
                { type: 'X', title: 'Share on 𝕏', icon: 'icon-x' },
                { type: 'facebook', title: 'Share on Facebook', icon: 'icon-facebook' },
                { type: 'linkedin', title: 'Share on LinkedIn', icon: 'icon-linkedin' },
            ],
            // 当前网址
            currentUrl: window.location.href,
            // 统计数据
            stat: {
                viewCount: 0,
                likeCount: 0
            },
            // 是否喜欢
            liked: false,
            // 是否有购买权限
            hasBuyPermission: false,
            // 当前最新一条签名记录
            currentUnwrapSignature: {},
        }
    },
    components: { SkeletonCard, UnCryptoMenu },
    created(){

    },
    mounted(){
        // 延时一分钟后重新查询一次，可能会更新信息
        setTimeout(() => {
            // 根据主键查询加密货币
            this.getCryptoById();
            // 查询统计数据
            this.getStat();
            // 判断用户是否有购买权限
            this.buyPermission();
            // 查询最新一条签名记录
            this.getUnwrapSignature();
        }, 60000);
    },
    computed: {
        ...mapGetters(['user', 'responsive']),
        // 我是发起人吗
        isOriginator(){
            return this.user?.id == this.unCrypto.userId;
        },
        // 我是拥有者吗
        isOwner(){
            return this.user?.id == this.unCrypto.ownerUserId;
        }
    },
    watch:{
        $route(to, from) {
            // 要去的页面还是当前页面，则更新id，否则是去别的页面，不更新
            if(to.name == "UnCrypto") {
                this.id = this.$route.params.id;
            }
        },
        id: {
            handler(newVal, oldVal) {
                // 根据主键查询加密货币
                this.getCryptoById();
                // 查询统计数据
                this.getStat();
                // 判断用户是否有购买权限
                this.buyPermission();
                // 查询最新一条签名记录
                this.getUnwrapSignature();
            },
            immediate: true
        }
    },
    methods: {
        // 分享
        share(type) {
            let link = null;
            let text = "Check out this unCrypto on untrading.org by @untradingOrg ! 🌐 \n\n" + this.currentUrl;
            switch(type) {
                case 'X':
                    // API - https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
                    return "https://x.com/intent/tweet?text=" + encodeURIComponent(text);
                case 'facebook':
                    // API - https://developers.facebook.com/docs/sharing/reference/share-dialog
                    return "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(this.currentUrl);
                case 'linkedin':
                    // API - https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/plugins/share-plugin
                    return "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(this.currentUrl);
            }
            return link;
        },
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.id == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.id);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
            }
            this.loading = false;
        },
        // 查询统计数据
        async getStat() {
            let res = await CryptoStatAPI.getStat(this.id);
            let data = res.data;
            if(data.success) {
                this.stat = data.data;
            }
        },
        // 是否喜欢
        async checkLiked() {
            let res = await CryptoLikeAPI.liked(this.id);
            let data = res.data;
            if(data.success) {
                this.liked = data.data;
            }
        },
        // 喜欢
        async like() {
            // 前端更新数据
            this.stat.likeCount++;
            this.liked = true;
            // 后端无感刷新
            let res = await CryptoLikeAPI.like(this.id);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getStat();
                // 是否喜欢
                this.checkLiked();
            } else {
                // 若响应失败则返回状态
                this.stat.likeCount--;
                this.liked = false;
            }
        },
        // 取消喜欢
        async unlike() {
            // 前端更新数据
            this.stat.likeCount--;
            this.liked = false;
            // 后端无感刷新
            let res = await CryptoLikeAPI.unlike(this.id);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getStat();
                // 是否喜欢
                this.checkLiked();
            }
        },
        // 判断用户是否有购买权限
        async buyPermission() {
            if(this.id == null) {
                return;
            }
            let res = await CryptoAPI.buyPermission(this.id);
            let data = res.data;
            if(data.success) {
                this.hasBuyPermission = data.data;
            }
        },
        // 查询最新一条签名记录
        async getUnwrapSignature() {
            if (this.id == null) {
                return;
            }
            let res = await CryptoUnwrapAPI.getUnwrapSignature(this.id);
            let data = res.data;
            if(data.success) {
                this.currentUnwrapSignature = data.data;
            }
        },
    }
}
</script>
<style scoped>
.elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15),
    5px -5px 10px rgba(0, 0, 0, 0.15),
    0px 0px 0px,
    -5px -5px 10px rgba(0, 0, 0, 0.15);
    z-index: 9;
}
</style>