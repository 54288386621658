<template>
	<div>
        <!-- 保存草稿弹窗 -->
        <v-dialog v-model="saveDraftDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <div class="sidebar-h2 text-primaryGrey">
                            <v-card-title class="d-flex pa-0">
                                <span>Save Draft?</span>
                                <v-spacer></v-spacer>
                                <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="saveDraft(false)"></v-icon>
                            </v-card-title>
                        </div>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="pa-0">
                            <div class="body-p text-primaryGrey">Do you want to save a draft of your unNFT to continue later?</div>
                            <div class="mt-10 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" @click="saveDraft(false)" aria-label="Discard">Discard</v-btn>
                                    <v-btn rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b ml-5" @click="saveDraft(true)" aria-label="Save">Save</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
export default {
    data(){
        return {
            // 保存草稿弹窗
            saveDraftDialog: false,
        }
    },
    props: {
        // 是否展示
        show: { type: Boolean, default: false },
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{
        show: {
            handler(n, o) {
                if(n) {
                    this.saveDraftDialog = true;
                } else {
                    this.saveDraftDialog = false;
                }
            },
            immediate: true
        },
    },
    methods: {
        // 保存草稿
        saveDraft(save) {
            this.$emit('watchSaveDraft', save);
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>