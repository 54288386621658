<template>
	<span>
        <router-link to="/notifications" class="del-underline">
            <span v-if="count == 0">
                <v-menu open-on-hover location="top">
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" color="primary" icon="mdi mdi-bell-outline" class="pointer" size="28"></v-icon>
                    </template>
                    <v-card class="pa-3 text-primaryGrey" rounded="0">Notifications</v-card>
                </v-menu>
            </span>
            <span v-if="count > 0" @click="readAll">
                <v-badge :content="count" class="animated infinite wobble">
                    <v-menu open-on-hover location="top">
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props" color="primary" icon="mdi mdi-bell-ring-outline" class="pointer" size="28"></v-icon>
                        </template>
                        <v-card class="pa-3 text-primaryGrey" rounded="0">Notifications</v-card>
                    </v-menu>
                </v-badge>
            </span>
        </router-link>
	</span>
</template>
<script>
import NotificationAPI from '@/api/notification.js';
export default {
    data(){
        return {
            // 未读通知数量
            count: 0
        }
    },
    components: {  },
    created(){
        // 监听查询未读通知数量
        this.$bus.on('emitGetUnreadCount', data => {
            this.getUnreadCount();
        })
        // 监听计算未读通知数量
        this.$bus.on('emitCalcUnreadCount', data => {
            this.count -= data;
        })
        // 查询未读通知数量
        this.getUnreadCount();
    },
    mounted(){
        // 间隔10分钟查询未读通知数量
        setInterval(() => {
            this.getUnreadCount();
        }, 600000);
    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 查询未读通知数量
        async getUnreadCount() {
            let res = await NotificationAPI.getUnreadCount();
            let data = res.data;
            if(data.success) {
                this.count = data.data;
            }
        },
        // 阅读全部通知
        async readAll() {
            let res = await NotificationAPI.readAll();
            let data = res.data;
            if(data.success) {
                // 重新查询未读数量
                this.getUnreadCount();
            }
        },
    }
}
</script>
<style scoped>

</style>