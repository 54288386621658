<template>
	<div>
        <v-container class="px-0 r-p-x-2 pb-16 text-primaryGrey">
            <v-card color="transparent" elevation="0" rounded="0" max-width="1440" class="mx-auto">
                <!-- pc -->
                <v-row no-gutters align="center"  v-if="responsive == 'pc'">
                    <v-col cols="12" lg="12" sm="6">
                        <h1 class="sub-page-h1 text-grey04">
                            {{ unwrap ? 'Historical and Complete' : 'Current' }} Non-fungible Future Rewards (nFR) Payments
                        </h1>
                        <v-divider class="my-7"></v-divider>
                    </v-col>
                    <!-- pc -->
                    <v-col cols="12">
                        <v-row no-gutters align="center">
                            <v-col cols="3">
                                <h2 class="body-text-btn">Originators’ Rewards (ORs)</h2>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="9" class="text-right" v-if="user.id == originator.id">
                                <router-link :to="'/dashboard/asset/uncrypto/ors/received/' + cryptoId" class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="100" height="48" aria-label="My ORs">My ORs</v-btn>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="mt-8">
                        <!-- ----------------------- OR ----------------------- -->
                        <v-row no-gutters>
                            <v-col cols="2">

                            </v-col>
                            <v-col cols="1" class="text-right">
                                <div class="body-h7 mb-3">o-tokens</div>
                            </v-col>
                            <v-col cols="1" class="text-right">
                                <div class="body-h7 mb-3">ORs</div>
                            </v-col>
                        </v-row>
                        <SkeletonCard :height="50" :length="2" class="w-100" v-if="nfr.originatorsRewards.length == 0"></SkeletonCard>
                        <div v-if="nfr.originatorsRewards.length > 0">
                            <v-row no-gutters align="center" v-for="(or, i) in nfr.originatorsRewards" :key="'or-' + i">
                                <v-col cols="2">  
                                    <div class="body-h7 mt-5 d-flex flex-nowrap">
                                        <UserAvatar :avatar="or.beneficiary.avatar" :username="or.beneficiary.username" :size="28"></UserAvatar>
                                        <span class="ml-2 ellipsis-line">{{ or.beneficiary.name }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="1" class="text-right">
                                    <v-text-field  :model-value="$tools.formatNumber(or.otoken, 0)" variant="underlined" density="comfortable" reverse readonly single-line hide-details></v-text-field>
                                </v-col>
                                <v-col cols="1" class="ml-2 text-right">
                                    <v-text-field :model-value="$tools.formatNumber(or.originatorReward, nfr.cryptoQuote.sizeDecimals)" variant="underlined" density="comfortable" reverse readonly single-line hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- ----------------------- FR ----------------------- -->
                        <v-row no-gutters align="center" class="mt-16">
                            <v-col cols="12">
                                <v-row no-gutters align="center">
                                    <v-col cols="3">
                                        <h2 class="body-text-btn text-primaryGrey">Future Rewards (FRs)</h2>
                                     </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="9" class="text-right" v-if="hasFutureRewards">
                                        <router-link :to="'/dashboard/asset/uncrypto/frs/received/' + cryptoId" class="del-underline gradient-left-red-purple-100">
                                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="100" height="48" aria-label="My FRs">My FRs</v-btn>
                                        </router-link>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="mt-8" v-if="nfr.futureRewards.length == 0">
                                <SkeletonCard :height="50" :length="10" class="w-100"></SkeletonCard>
                            </v-col>
                            <v-col cols="12" class="mt-8" v-if="nfr.futureRewards.length > 0">
                                <v-row no-gutters class="mb-5 body-p-small">
                                    <div class="c-fr-container">
                                        <div>
                                            <v-row no-gutters>
                                                <v-col cols="5">
                                                    <v-card color="transparent" elevation="0" rounded="0">
                                                        <div class="d-flex">
                                                            <h6 style="width: 8vw;">Owner No</h6>
                                                            <h6 style="width: 8vw;">Epoch</h6>
                                                            <h6 style="width: 10vw;">Active Gen</h6>
                                                            <h6 style="width: 18vw;"></h6>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="7">
                                                    <div class="c-fr" v-if="nfr.numberOfGenerations > 1">
                                                        <div v-for="n in (nfr.numberOfGenerations - 1)" :key="n">
                                                        <h6 class="float-right">{{ $tools.toOrdinalSuffix(n) }}</h6>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <div class="mb-3">
                                                <div v-for="(fr, i) in nfr.futureRewards" :key="'futureReward-' + i">
                                                    <div v-if="!fr.hidden">
                                                        <v-row no-gutters  class="d-flex flex-nowrap">
                                                            <v-col cols="5">
                                                                <v-card color="transparent" elevation="0" rounded="0">
                                                                    <div class="d-flex mt-5 font-weight-bold">
                                                                        <div style="width: 8vw;">{{ fr.ownerNo }}</div>
                                                                        <div style="width: 8vw;">{{ $tools.convertToRoman(fr.epoch) }}</div>
                                                                        <div style="width: 10vw;">
                                                                            <span v-if="fr.ownerNo == 1">{{ fr.generationTitle }}</span>
                                                                            <span v-else>{{ fr.generation > 0 ? fr.generationWord : '' }}</span>
                                                                        </div>
                                                                        <div class="d-flex flex-nowrap">
                                                                            <UserAvatar :avatar="fr.user.avatar" :username="fr.user.username" :size="24"></UserAvatar>
                                                                            <span class="ml-2 ellipsis-line" style="width: 180px;">{{ fr.user.name }}</span>
                                                                        </div>
                                                                    </div>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="7">
                                                                <div class="c-fr">
                                                                    <div v-for="(reward, j) in fr.rewards" :key="'reward-' + j" >
                                                                        <v-text-field :model-value="reward == null ? null : ( reward > 0 ? $tools.formatNumber(reward, nfr.cryptoQuote.sizeDecimals) : '--')" variant="underlined" density="comfortable" reverse readonly single-line hide-details></v-text-field>
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                                <div class="my-3 font-weight-bold" v-if="nfr.hasHidden">
                                                    <span v-if="currentShow == 'SHOW_MORE'" class="gradient-underline-hover pointer" @click="showMore">
                                                        <a class="del-underline gradient-text-hover">Show more ...</a>
                                                    </span>
                                                    <span v-if="currentShow == 'SHOW_LESS'" class="gradient-underline-hover pointer" @click="showLess">
                                                        <a class="del-underline gradient-text-hover">Show less</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- mobile -->
                <v-row no-gutters align="center"  v-if="responsive == 'mobile'">
                    <v-col cols="12">
                        <h1 class="sub-page-h1 text-grey04">
                            {{ unwrap ? 'Historical and Complete' : 'Current' }} Non-fungible Future Rewards (nFR) Payments
                        </h1>
                        <v-divider class="my-7"></v-divider>
                    </v-col>
                    <!-- pc -->
                    <v-col cols="12">
                        <v-row no-gutters align="center">
                            <v-col cols="9">
                                <h2  class="body-text-btn">Originators’ Rewards (ORs)</h2>
                            </v-col>
                            <v-col cols="3" class="text-right" v-if="user.id == originator.id">
                                <router-link :to="'/dashboard/asset/uncrypto/ors/received/' + cryptoId" class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="100" height="48" aria-label="My ORs">My ORs</v-btn>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="mt-8">
                        <!-- ----------------------- OR ----------------------- -->
                        <v-row no-gutters>
                            <v-col cols="4">
                            </v-col>
                            <v-col cols="4" class="pl-8">
                                <h4 class="body-h7 mb-3">o-tokens</h4>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <h4 class="body-h7 mb-3 pl-4">ORs</h4>
                            </v-col>
                        </v-row>
                        <SkeletonCard :height="50" :length="2" class="w-100" v-if="nfr.originatorsRewards.length == 0"></SkeletonCard>
                        <div v-if="nfr.originatorsRewards.length > 0">
                            <v-row no-gutters align="center" v-for="(or, i) in nfr.originatorsRewards" :key="'or-' + i">
                                <v-col cols="4">  
                                    <div class="body-h7 mt-5 parent d-flex">
                                        <UserAvatar :avatar="or.beneficiary.avatar" :username="or.beneficiary.username" :size="28"></UserAvatar>
                                        <span class="ml-2 ellipsis-line ">{{ or.beneficiary.name }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="4" class="pl-6">
                                    <v-text-field  :model-value="$tools.formatNumber(or.otoken, 0)" variant="underlined" density="comfortable" reverse readonly single-line hide-details></v-text-field>
                                </v-col>
                                <v-col cols="4" class="text-right pl-4">
                                    <v-text-field :model-value="$tools.formatNumber(or.originatorReward, nfr.cryptoQuote.sizeDecimals)" variant="underlined" density="comfortable" reverse readonly single-line hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- ----------------------- FR ----------------------- -->
                        <v-row no-gutters align="center" class="mt-16">
                            <v-col cols="12">
                                <v-row no-gutters align="center">
                                    <v-col cols="9" class="body-text-btn">
                                        Future Rewards (FRs)
                                     </v-col>
                                    <v-col cols="3" class="text-right" v-if="hasFutureRewards">
                                        <router-link :to="'/dashboard/asset/uncrypto/frs/received/' + cryptoId" class="del-underline gradient-left-red-purple-100">
                                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="100" height="48" aria-label="My FRs">My FRs</v-btn>
                                        </router-link>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="mt-8" v-if="nfr.futureRewards.length == 0">
                                <SkeletonCard :height="50" :length="10" class="w-100"></SkeletonCard>
                            </v-col>
                            <v-col cols="12" class="mt-8" v-if="nfr.futureRewards.length > 0">
                                <v-row no-gutters class="mb-5 body-p-small">
                                    <div class="c-fr-container">
                                        <div>
                                            <v-row no-gutters>
                                                <v-col cols="10">
                                                    <v-card color="transparent" elevation="0" rounded="0">
                                                        <!-- <div class="d-flex">
                                                            <div style="width: 180vw;">Owner No</div>
                                                            <div style="width: 160vw;" class="text-left">Epoch</div>
                                                            <div style="width: 100vw;">Active Gen</div>
                                                            <div style="width: 180vw;" class="mr-5"></div>
                                                        </div> -->
                                                        <v-row no-gutters>
                                                            <v-sheet width="70" height="40" color="transparent">
                                                                <h6>Owner No</h6>
                                                            </v-sheet>
                                                            <v-sheet width="55" height="40" color="transparent">
                                                                <h6>Epoch</h6>
                                                            </v-sheet>
                                                            <v-sheet width="65" height="40" color="transparent">
                                                                <h6>Active Gen</h6>
                                                            </v-sheet>
                                                            <v-sheet width="70" height="40" color="transparent">
                                                                
                                                            </v-sheet>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="2">
                                                    <div class="c-fr" v-if="nfr.numberOfGenerations > 1">
                                                        <div v-for="n in (nfr.numberOfGenerations - 1)" :key="n">
                                                        <h6 class="float-right">{{ $tools.toOrdinalSuffix(n) }}</h6>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <div class="mb-3">
                                                <div v-for="(fr, i) in nfr.futureRewards" :key="'futureReward-' + i">
                                                    <div v-if="!fr.hidden">
                                                        <v-row no-gutters  class="d-flex flex-nowrap">
                                                            <v-col cols="10">
                                                                <v-card color="transparent" elevation="0" rounded="0">
                                                                    <v-row no-gutters>
                                                                        <v-sheet width="70" class="mt-5" color="transparent">
                                                                            {{ fr.ownerNo }}
                                                                        </v-sheet>
                                                                        <v-sheet width="55" class="mt-5" color="transparent">
                                                                             {{ $tools.convertToRoman(fr.epoch) }}
                                                                        </v-sheet>
                                                                        <v-sheet width="65" color="transparent">
                                                                            <div class="mt-5">
                                                                                <span v-if="fr.ownerNo == 1">{{ fr.generationTitle }}</span>
                                                                                <span v-else>{{ fr.generation > 0 ? fr.generationWord : '' }}</span>
                                                                            </div>
                                                                        </v-sheet>
                                                                        <v-sheet width="70" color="transparent">
                                                                            <div class="parent d-flex mt-5">
                                                                                <UserAvatar :avatar="fr.user.avatar" :username="fr.user.username" :size="24"></UserAvatar>
                                                                                <span class="ml-2 ellipsis-line ">{{ fr.user.name }}</span>
                                                                            </div>
                                                                        </v-sheet>
                                                                    </v-row>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <div class="c-fr">
                                                                    <div v-for="(reward, j) in fr.rewards" :key="'reward-' + j" >
                                                                        <v-text-field :model-value="reward == null ? null : ( reward > 0 ? $tools.formatNumber(reward, nfr.cryptoQuote.sizeDecimals) : '--')" variant="underlined" density="comfortable" reverse readonly single-line hide-details></v-text-field>
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                                <div class="my-3 font-weight-bold" v-if="nfr.hasHidden">
                                                    <span v-if="currentShow == 'SHOW_MORE'" class="gradient-underline-hover pointer" @click="showMore">
                                                        <a class="del-underline gradient-text-hover">Show more ...</a>
                                                    </span>
                                                    <span v-if="currentShow == 'SHOW_LESS'" class="gradient-underline-hover pointer" @click="showLess">
                                                        <a class="del-underline gradient-text-hover">Show less</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoAPI from '@/api/crypto.js';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard';
export default {
    data(){
        return {
            // 当前展示
            currentShow: 'SHOW_MORE',
        }
    },
    props: {
        // 主键
        cryptoId: {
            type: String,
            default: null
        },
        // NFR 数据
        nfr: {
            type: Object,
            default: {
                numberOfGenerations: 1,
                totalNFRPaid: 0,
                originatorsRewards: [],
                futureRewards: [],
                cryptoQuote: {},
                hasHidden: false,
            },
        },
        // 是否已经解封
        unwrap: {
            type: Boolean,
            default: false
        }
    },
    components: { SkeletonCard },
    created(){
        
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'responsive']),
        // 发起人
        originator() {
            return this.nfr?.originatorsRewards[0]?.beneficiary || {};
        },
        // 是否有 FRs
        hasFutureRewards() {
            return this.nfr.futureRewards.filter(fr => fr.user.id == this.user.id).length > 0;
        }
    },
    watch:{
        
    },
    methods: {
        // 展示 FR
        showMore() {
            this.nfr.futureRewards.forEach(fr => fr.hidden = false);
            this.currentShow = 'SHOW_LESS';
        },
        // 隐藏 FR
        showLess() {
            this.nfr.futureRewards.filter(fr => fr.generation <= 0).forEach(fr => fr.hidden = true);
            this.currentShow = 'SHOW_MORE';
        },
    }
}
</script>
<style scoped>
.v-text-field ::v-deep input {
    font-size: 0.8em;
    font-weight: 100;
    text-transform: capitalize;
}

.v-text-field ::v-deep label {
    font-size: 0.8em;
}

.v-text-field ::v-deep button {
    font-size: 0.8em;
}

</style>