<template>
	<div class="bg-darkGrey my-12 pl-16">
        <v-card color="darkGrey" max-width="1230" height="236" elevation="0" class="mx-0-auto align-items-center">
            <v-row no-gutters class="d-flex flex-nowrap ml-0">
                <div class="item ml-16">
                    <h2 class="body-h4 text-white">A Revolutionary NFT Marketplace Framework</h2>
                    <h6 class="mt-3 body-p-small text-white">Discover a groundbreaking NFT marketplace framework that transforms the way sellers, buyers, and platforms interact, revolutionizing the trading experience.</h6>
                </div>
                <div class="item ml-10">
                    <h2 class="body-h4 text-white">Upgrade your crypto assets and maximize your returns</h2>
                    <h6 class="mt-3 body-p-small text-white">Wrapping your ERC-20 tokens and experiencing the power of Future Rewards (FRs) even after selling. Unlock The Full Potential Of Provenance Value Amplification (PVA) DeFi.</h6>
                </div>
                <div class="item ml-10">
                    <h2 class="body-h4 text-white">On-chain NFT royalties & on-chain licenses</h2>
                    <h6 class="mt-3 body-p-small text-white">On-chain nFR discourages royalty circumvention, and the platform implements Can't Be Evil licenses released by a16z.</h6>
                </div>
                <div class="mt-16 pt-3 ml-10">
                    <a href="https://docs.untrading.org" class="del-underline gradient-left-black-137" target="_blank">
                        <v-btn :rounded="0" elevation="2" color="button02" class="text-none text-black02 body-p-small-b" width="137" height="48" aria-label="Learn more">Learn more</v-btn>
                    </a>
                </div>
            </v-row>
        </v-card>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* .hero {
    height: 236px; 
    background-color: black;
    margin-top: 50px;
    margin-bottom: 50px;
} */
.item{
    max-width: 320px;
    border-left: 2px solid rgb(255, 255, 255);
    padding-left: 18px;
    width: 100%;
}
</style>