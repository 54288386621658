<template>
	<span :class="textColor">
        <span class="fs-36">{{ value || 0 }}</span>
        <br/>
        <span class="form-text"># of generations
            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                <template v-slot:activator="{ props }" >
                    <v-icon icon="mdi:mdi-alert-circle-outline" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                </template>
                <v-card max-width="420" class="pa-3 body-p" rounded="0">
                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                </v-card>
            </v-menu>
        </span>
    </span>
    <!-- 
        <div class="form-text"># of generations
            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                <template v-slot:activator="{ props }" >
                    <v-icon icon="mdi:mdi-alert-circle-outline" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                </template>
                <v-card max-width="420" class="pa-3 body-p" rounded="0">
                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                </v-card>
            </v-menu>
        </div>
        <div class="my-2 body-p-small">{{ unCrypto.numberOfGenerations || '--' }}</div>
        <v-divider></v-divider>
     -->
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        value: {
            type: [Number, String],
            default: 0
        },
        textColor: {
            type: String,
            default: 'text-primaryGrey'
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>