import axios from '@/config/api.request'

export default {
  // 查询设置
  getSetting() {
    return axios.request({
      url: '/setting/setting',
      method: 'get'
    })
  },
  // 更新设置
  setting(data) {
    return axios.request({
        url: '/setting/setting',
        method: 'post',
        data: data
    })
  },
}