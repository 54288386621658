import axios from '@/config/api.request'
import qs from 'qs'

export default {
    // 创建套装
    create(data) {
        return axios.request({
            url: '/set/create',
            method: 'post',
            data: data
        })
    },
    // 删除套装
    delete(id) {
        return axios.request({
            url: '/set/delete',
            method: 'delete',
            params: { id: id }
        })
    },
    // 根据主键查询套装
    getSetById(id) {
        return axios.request({
            url: '/set/set',
            method: 'get',
            params: { id: id }
        })
    },
    // 根据用户名查询所有的套装
    sets(username, sortBy, blockchain) {
        return axios.request({
            url: '/set/sets',
            method: 'get',
            params: { 
                username: username,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 更新套装头像图片
    updateLogo(data) {
        return axios.request({
            url: '/set/logo',
            method: 'post',
            data: data
        })
    },
    // 更新套装封面图片
    updateCover(data) {
        return axios.request({
            url: '/set/cover',
            method: 'post',
            data: data
        })
    },
    // 分页查询套装列表
    getSetsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/set/sets/page',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 查询最佳的套装列表
    getTopSets(blockchain) {
        return axios.request({
            url: '/set/top/sets',
            method: 'get',
            params: { 
                blockchain: blockchain
            }
        })
    },
    // 根据主键分页查询套装中的拥有者列表
    getSetOwnersByPage(setId, page, size) {
        return axios.request({
            url: '/set/set/owners',
            method: 'get',
            params: { 
                setId: setId,
                page: page,
                size: size
            }
        })
    },
    // 更新套装名称
    editName(data) {
        return axios.request({
            url: '/set/name',
            method: 'post',
            data: data
        })
    },
    // 从套装中移除 NFTs
    removeNftsFromSet(nftIds) {
        return axios.request({
            url: '/set/nfts/remove',
            method: 'get',
            params: { 
                nftIds: nftIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, { indices: false })
            }
        })
    },
    // 移动 NFT 到套装
    moveNftToSet(nftId, setId) {
        return axios.request({
            url: '/set/nft/move',
            method: 'get',
            params: { 
                nftId: nftId,
                setId: setId
            }
        })
    },
    // 根据 NFT 主键查询套装
    getSetByNftId(nftId) {
        return axios.request({
            url: '/set/nft/set',
            method: 'get',
            params: { 
                nftId: nftId
            }
        })
    }
}