<template>
	<div class="h-100 bg-f2f">
        <v-container align="center" class="body-p-small">
            <v-card max-width="1400" rounded="0" elevation="0" color="transparent" class="pt-10">
                <v-row no-gutters>
                    <v-col cols="12" lg="6" align="start">
                        <!-- 返回按钮 -->
                        <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                        <!-- UN 价格图表 -->
                        <UnderlyingPriceLineChart :currency="currency"></UnderlyingPriceLineChart>
                        <h1 class="sub-page-h1 text-grey04">Claim Your UN Tokens</h1>
                        <div class="mt-10">This page allows you to claim your UN tokens from various sources, including:</div>
                        <ul type="disc" class="mt-5 ml-5">
                            <li>Investments</li>
                            <li>Dividend Distributions</li>
                            <li>Airdrops</li>
                            <li>Platform User Rewards</li>
                        </ul>
                        <div class="mt-10">
                            <VestingSchedule></VestingSchedule>
                        </div>
                        <div class="mt-10">
                            <FAQs></FAQs>
                        </div>
                        <div class="mt-10">

                        </div>
                    </v-col>
                    <v-col cols="12" lg="5" align="start" class="ml-12 r-m-l-0">
                        <AssetOverview class="mb-10"></AssetOverview>
                        <TokenOverview></TokenOverview>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import UnderlyingPriceLineChart from '@/components/charts/UnderlyingPriceLineChart.vue'
import VestingSchedule from '@/views/un/components/VestingSchedule.vue';
import FAQs from '@/views/un/components/FAQs.vue';
import AssetOverview from '@/views/un/components/AssetOverview.vue';
import TokenOverview from '@/views/un/components/TokenOverview.vue';
export default {
    data(){
        return {
            // 货币
            currency: null
        }
    },
    components: { UnderlyingPriceLineChart, VestingSchedule, FAQs, AssetOverview, TokenOverview },
    created(){

    },
    mounted(){
        // 因为组件内监听改变才会查询，因此在这里更新值
        this.currency = 'UN';
    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>