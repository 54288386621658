import axios from '@/config/api.request'

export default {
    // 随机查询一个事实
    getRandomFact(id) {
        return axios.request({
            url: '/fact/random',
            method: 'get'
        })
    },
    // 根据主键查询一个事实
    getFactById(id) {
        return axios.request({
            url: '/fact/fact',
            method: 'get',
            params: { id: id }
        })
    }
}