<template>
    <span class="d-flex flex-nowrap align-center">
        <router-link v-if="nftId" :to="$tools.getUnNFTUrl(blockchain, nftId)" class="del-underline text-black">
            <v-avatar rounded="0" :size="size" class="pointer">
                <v-icon v-if="!avatar" color="#666" icon="mdi:mdi-image-multiple-outline" :size="size * 0.7"></v-icon>
                <v-img v-if="avatar" :src="avatar" cover :height="size">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular indeterminate color="primaryGrey"></v-progress-circular>
                        </div>
                    </template>
                </v-img>
            </v-avatar>
        </router-link>
        <v-avatar v-if="!nftId" rounded="0" :size="size">
            <v-icon v-if="!avatar" color="#666" icon="mdi:mdi-image-multiple-outline" :size="size * 0.7"></v-icon>
            <v-img v-if="avatar" :src="avatar" cover :height="size">
                <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular indeterminate color="primaryGrey"></v-progress-circular>
                    </div>
                </template>
            </v-img>
        </v-avatar>
        <span class="gradient-underline-hover ml-2" v-if="nftId">
            <router-link @click.stop :to="$tools.getUnNFTUrl(blockchain, nftId)" class="del-underline gradient-text-hover parent">
                <span class="body-p text-primaryGrey ellipsis-line">{{ name }}</span>
            </router-link>
        </span>
        <span class="gradient-underline-hover ml-2" v-if="!nftId">
            <span class="body-p text-primaryGrey ellipsis-line">{{ name }}</span>
        </span>
    </span>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    props: {
        nftId: {
            type: String,
            default: null
        },
        blockchain: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        avatar: {
            type: String,
            default: null
        },
        size: {
            type: Number,
            default: 36
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>