<template>
	<div>
        <v-menu location="right" open-on-hover :offset="20" rounded="0">
            <template v-slot:activator="{ props }">
                <div v-bind="props" class="pointer">
                    <span>Set</span>
                    <v-icon icon="fa-solid fa-angle-right" color="primaryGrey" class="ml-2 float-right" size="18"></v-icon>
                </div>
            </template>
            <v-list>
                <v-list-item class="form-text text-primaryGrey">
                    <v-list-item-title>
                        <span class="ml-2">Add to</span>
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                
                <!-- --------------- 已有的套装 --------------- -->
                <div v-for="(set, i) in sets" :key="set.id">
                    <v-list-item class="form-text text-primaryGrey pointer" @click="moveNftToSet(set.id)">
                        <v-list-item-title  class="d-flex flex-nowrap">
                            <Blockchain :blockchain="set.blockchain" :size="28"></Blockchain>
                            <SetAvatar class="ml-2" :avatar="set.logoImage" :setId="set.id" :size="28"></SetAvatar>
                            <span class="ml-2">{{ set.name }}</span>
                            <v-icon icon="mdi:mdi-check" color="primaryGrey" class="ml-2 float-right" size="24" v-if="currentSet.id == set.id"></v-icon>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>

                <!-- --------------- 创建 --------------- -->
                <router-link to="/set/create" class="del-underline">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>
                            <v-icon class="ml-11" icon="mdi:mdi-plus" size="28"></v-icon>
                            <span class="ml-2">Create a Set</span>
                        </v-list-item-title>
                    </v-list-item>
                </router-link>

            </v-list>
        </v-menu>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SetAPI from '@/api/set.js';
export default {
    data(){
        return {
            // 当前 NFT 的套装
            currentSet: {}
        }
    },
    props: {
        // NFT 主键
        nftId: {
            type: String,
            default: null
        },
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['sets']),
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询套装
                this.getSetByNftId();
            },
            immediate: true
        }
    },
    methods: {
        // 移动 NFT 到套装
        async moveNftToSet(setId) {
            let res = await SetAPI.moveNftToSet(this.nftId, setId);
            let data = res.data;
            if(data.success) {
                // 通知刷新套装数据
                this.$bus.emit('emitRefreshSet', {});
            }
            this.$store.dispatch('snackbarMessageHandler', data.message);
        },
        // 根据 NFT 主键查询套装
        async getSetByNftId() {
            if(this.nftId == null) {
                return;
            }
            let res = await SetAPI.getSetByNftId(this.nftId);
            let data = res.data;
            if(data.success) {
                this.currentSet = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>