<template>
	<div class="h-100">
        <v-container class="h-100" align="center">
            <v-row no-gutters class="h-100" align="center">
                <v-col>
                    <div class="body-h5 text-primaryGrey">Looks like there's nothing here yet!</div>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>