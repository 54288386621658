<template>
	<div>
        <!-- 详情页面 -->
        <v-card max-width="600" class="pb-8 r-m-r-1" rounded="0" color="black" :class="responsive == 'pc' ? 'elevation-12 ' : 'elevation-6'">
            <div class="py-3 d-flex justify-space-between">
                <div class="ml-4 text-primaryGrey">
                    <v-icon icon="mdi:mdi-ethereum" v-if="nft.blockchain == 'Ethereum' || nft.blockchain == 'Goerli' || nft.blockchain == 'Sepolia'"></v-icon>
                    <v-icon size="16" v-if="nft.blockchain == 'Arbitrum One'">icon-arbitrum</v-icon>
                    <v-icon size="16" v-if="nft.blockchain == 'Polygon' || nft.blockchain == 'Mumbai'">icon-polygonscan</v-icon>
                </div>
                <div>
                    <v-menu open-on-hover>
                        <template v-slot:activator="{ props }">
                            <v-icon icon="mdi:mdi-share-variant" class="mr-12 text-primaryGrey gradient-text-hover pointer" size="18" v-bind="props"></v-icon>
                        </template>
                        <v-list>
                            <!-- 复制链接 -->
                            <v-list-item>
                                <v-list-item-title>
                                    <v-icon icon="icon-link" class="text-primaryGrey" size="20"></v-icon>
                                    <span class="ml-3 gradient-underline-hover">
                                        <Clipboard :text="$config.domain + $tools.getUnNFTUrl(nft.blockchain, nftId)">
                                            <a class="text-primaryGrey gradient-text-hover">Copy link</a>
                                        </Clipboard>
                                    </span>
                                </v-list-item-title>
                            </v-list-item>
                            <!-- 分享媒体 -->
                            <v-list-item v-for="(menu, index) in shareMenus" :key="index" :value="index">
                                <v-list-item-title class="gradient-underline-hover">
                                    <v-icon :icon="menu.icon" class="text-primaryGrey" size="20"></v-icon>
                                    <a :href="share(menu.type)" class="ml-3 text-primaryGrey gradient-text-hover" target="_blank">{{ menu.title }}</a>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-icon icon="mdi mdi-eye-outline" class="text-primaryGrey" size="18"></v-icon>
                    <span class="body-p-small ml-2">
                        <count-to :start-val="0" :end-val="nftStat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                    </span>
                    <v-icon v-if="!liked" icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer text-primaryGrey gradient-text-hover" @click.stop="like()"></v-icon>
                    <v-icon v-if="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike()"></v-icon>
                    <span class="body-p-small ml-2 mr-6">
                        <count-to :start-val="nftStat.likeCount" :end-val="nftStat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                    </span>
                </div>
            </div>
            <!-- <v-img id="fullScreenPreview" :src="nft.file" :cover="!isFullScreen" height="648" v-if="nft.file" :class="isFullScreen ? 'zoom-out' : 'zoom-in'" ></v-img>
            <el-skeleton animated v-if="!nft.file">
                <template #template>
                    <el-skeleton-item variant="image" class="w-100" style="height: 648px; width: 648px;" />
                </template>
            </el-skeleton> -->
            <MediaFile :nftId="nftId" allow-fullscreen></MediaFile>
            <v-row no-gutters class="px-8 pt-8" v-if="responsive == 'pc'">
                <v-col cols="12">
                    <v-row no-gutters align-content="center">
                        <v-col cols="6">
                            <div class="body-h5 mb-2 text-primaryGrey">Owned by</div>
                            <div>
                                <OwnerAvatar :avatar="nft.owner.avatar" :username="nft.owner.username" :name="nft.owner.name"></OwnerAvatar>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="body-h5 text-primaryGrey text-right mt-1">Minted on {{ $tools.formatEngLishDate(nft.timestamp) }}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <v-col cols="12" class="px-0">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="6">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Originator
                            </div>
                            <div v-if="nft.user">
                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Set
                            </div>
                            <div>
                                <SetAvatar :avatar="nft.set.logoImage" :setId="nft.setId" :name="nft.set.name ? nft.set.name : '--'"></SetAvatar>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <div class="body-text-btn pt-8 text-primaryGrey">
                    Non-fungible Future Rewards (nFR) Parameters
                </div>
                <v-divider class="my-7"></v-divider>
                <v-col cols="12" class="px-0">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="form-text text-primaryGrey">Total Reward
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.totalReward') }} -->
                                        The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.totalRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6" class="pl-6">
                            <div class="form-text text-primaryGrey">Future Rewards (FR)
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.futureRewards') }} -->
                                        Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ 100 - nft.originatorRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="6" >
                            <div class="form-text text-primaryGrey"># of generations
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.ofGenerations') }} -->
                                        The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.numberOfGenerations }}</div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6" class="pl-6">
                            <div class="form-text text-primaryGrey">Originator Reward (OR)
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.originatorReward') }} -->
                                        The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.originatorRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="px-0 mt-16 d-flex justify-space-between">
                    <div class="form-text text-primaryGrey">
                        Total nFR Paid: <span class="body-h2 ml-2">{{ $tools.formatNumber(totalNFRPaid, 8) }}</span> 
                        <span class="ml-2">{{ nft.paymentToken }}</span>
                    </div>
                    <div class="mt-3">
                        <!-- 菜单 -->
                        <UnNftMenu :nftId="nftId" :blockchain="nft.blockchain" :isOriginator="isOriginator" :isOwner="isOwner" :isListed="listInfo.isListed" 
                            :hidden="nft.hidden" :wrap="nft.contractFunction == 'WRAP'"
                            :hasRequestUnwrapPermission="nft.permission.requestUnwrap" 
                            :hasSignUnwrapPermission="nft.permission.signUnwrap"
                            :hasConfirmUnwrapPermission="nft.permission.confirmUnwrap"
                        ></UnNftMenu>
                    </div>
                </v-col>
            </v-row>
            <!-- mobile -->
            <v-row no-gutters class="px-4 pt-8" v-if="responsive == 'mobile'">
                <v-col cols="12">
                    <v-row no-gutters align-content="center">
                        <v-col cols="12" md="6" sm="6">
                            <div class="body-h5 text-primaryGrey text-right mt-1">Minted on {{ $tools.formatEngLishDate(nft.timestamp) }}</div>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" class="mt-4">
                            <div class="body-h5 mb-2 text-primaryGrey">Owned by</div>
                            <div>
                                <OwnerAvatar :avatar="nft.owner.avatar" :username="nft.owner.username" :name="nft.owner.name"></OwnerAvatar>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <v-col cols="12" class="px-0">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Set
                            </div>
                            <div>
                                <SetAvatar :avatar="nft.set.logoImage" :setId="nft.setId" :name="nft.set.name ? nft.set.name : '--'"></SetAvatar>
                            </div>
                        </v-col>
                        <v-col cols="6" class="pt-8 r-p-t-0 r-p-l-8">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Originator
                            </div>
                            <div v-if="nft.user">
                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name "></UserAvatar>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <div class="body-text-btn pt-8 text-primaryGrey">
                    Non-fungible Future Rewards (nFR) Parameters
                </div>
                <v-divider class="my-7"></v-divider>
                    <div class="d-flex justify-space-between">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6">
                            <div class="body-p-small text-primaryGrey">Total Reward
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        {{ $t('common.totalReward') }}
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.totalRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-4">
                            <div class="body-p-small text-primaryGrey">Future Rewards (FR)
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        {{ $t('common.futureRewards') }}
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ 100 - nft.originatorRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="ml-2">
                        <v-col cols="12" sm="6">
                            <div class="body-p-small text-primaryGrey"># of generations
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        {{ $t('common.ofGenerations') }}
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.numberOfGenerations }}</div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-4">
                            <div class="body-p-small text-primaryGrey">Originator Reward (OR)
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        {{ $t('common.originatorReward') }}
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.originatorRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </div>
                <v-col cols="12" class="px-0 mt-16 d-flex justify-space-between">
                    <div class="form-text text-primaryGrey">
                        Total nFR Paid: <span class="body-h2 ml-2">{{ $tools.formatNumber(totalNFRPaid, 8) }}</span> 
                        <span class="ml-2">{{ nft.paymentToken }}</span>
                    </div>
                    <div class="mt-3">
                        <!-- 菜单 -->
                        <UnNftMenu :nftId="nftId" :blockchain="nft.blockchain" :isOriginator="isOriginator" :isOwner="isOwner" :isListed="listInfo.isListed" 
                            :hidden="nft.hidden" :wrap="nft.contractFunction == 'WRAP'"
                            :hasRequestUnwrapPermission="nft.permission.requestUnwrap" 
                            :hasSignUnwrapPermission="nft.permission.signUnwrap"
                            :hasConfirmUnwrapPermission="nft.permission.confirmUnwrap"
                        ></UnNftMenu>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <div class="mt-16" v-if="nft.contractFunction != 'UNWRAP'">
            <!-- ----------------- 没有购买权限则禁用 ------------------ -->
            <div v-if="!hasBuyPermission">
                <v-btn rounded="0" elevation="2" disabled color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" aria-label="Buy now">Buy now</v-btn>
            </div>
            <div v-if="hasBuyPermission">
                <router-link :to="'/unnft/buy/' + nftId" class="del-underline gradient-left-red-purple-300">
                    <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" aria-label="Buy now">Buy now</v-btn>
                </router-link>
            </div>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import NFTAPI from '@/api/nft.js';
import NFTStatAPI from '@/api/nft-stat.js';
import NFTLikeAPI from '@/api/nft-like.js';
import MediaFile from '@/components/media/MediaFile.vue';
import UnNftMenu from '@/components/nft/UnNftMenu.vue';
export default {
    data(){
        return {
            // NFT 
            nft: {
                user: {},
                owner: {},
                set: {},
                permission: {
                    requestUnwrap: false,
                    signUnwrap: false,
                    confirmUnwrap: false
                }
            },
            // NFT 统计数据
            nftStat: {
                viewCount: 0,
                likeCount: 0
            },
            // 是否喜欢
            liked: false,
            // 我是拥有者吗
            isOwner: false,
            // 全屏
            isFullScreen: false,
            // 分享菜单
            shareMenus: [
                { type: 'X', title: 'Share on 𝕏', icon: 'icon-x' },
                { type: 'facebook', title: 'Share on Facebook', icon: 'icon-facebook' },
                { type: 'linkedin', title: 'Share on LinkedIn', icon: 'icon-linkedin' },
            ],
            // FR 信息
            FRInfo: {},
            // 是否有购买权限
            hasBuyPermission: false,
            // 当前的区块链
            currentBlockchain: {},
            web3: {},
        }
    },
    // NFT 主键，上市信息
    props: {
        nftId: {
            type: String,
            default: null
        },
        listInfo: {
            type: Object,
            default: {
                price: 0,
                isListed: false,
                lister: null
            }
        },
        // 总支付的奖励
        totalNFRPaid: {
            type: Number,
            default: 0
        }
    },
    components: { MediaFile, UnNftMenu },
    created(){
        // 监听刷新套装数据
        this.$bus.on('emitRefreshSet', data => {
            this.getNFTById();
        })
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'responsive']),
        // 我是发起人吗
        isOriginator(){
            return this.user && this.nft.userId == this.user.id;
        },
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询 NFT
                this.getNFTById();
                // 判断用户是否有购买权限
                this.buyPermission();
                // 查询统计数据
                this.getNftStat();
                // 是否喜欢 NFT
                this.checkLiked();
            },
            immediate: true
        }
    },
    methods: {
        // 根据 NFT 主键查询 NFT
        async getNFTById() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.getNFTById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 查询owner信息
                this.ownerOf();
                // 查询FR信息
                // this.getFRInfo();
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 判断用户是否有购买权限
        async buyPermission() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.buyPermission(this.nftId);
            let data = res.data;
            if(data.success) {
                this.hasBuyPermission = data.data;
            }
        },
        // 查询统计数据
        async getNftStat() {
            let res = await NFTStatAPI.nftStat(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nftStat = data.data;
            }
        },
        // 是否喜欢 NFT
        async checkLiked(nftId) {
            let res = await NFTLikeAPI.liked(this.nftId);
            let data = res.data;
            if(data.success) {
                this.liked = data.data;
            }
        },
        // 喜欢 NFT
        async like(nftId) {
            // 前端更新数据
            this.nftStat.likeCount++;
            this.liked = true;
            // 后端无感刷新
            let res = await NFTLikeAPI.like(this.nftId);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getNftStat();
                // 是否喜欢 NFT
                this.checkLiked();
            } else {
                // 若响应失败则返回状态
                this.nftStat.likeCount--;
                this.liked = false;
            }
        },
        // 取消喜欢 NFT
        async unlike(nftId) {
            // 前端更新数据
            this.nftStat.likeCount--;
            this.liked = false;
            // 后端无感刷新
            let res = await NFTLikeAPI.unlike(this.nftId);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getNftStat();
                // 是否喜欢 NFT
                this.checkLiked();
            }
        },
        // 预览
        preview(){
            this.$emit('preview', null)
        },
        // 查询owner信息
        async ownerOf() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let owner = await NFTContract.methods.ownerOf(this.nft.tokenId).call();
            if(owner) {
                this.isOwner = this.$tools.equalsIgnoreCase(owner, this.user.wallet);
            }
        },
        // 全屏预览
        handleFullScreen() {
            const isFullScreen = document.webkitIsFullScreen || document.mozFullScreen || false;
            const fullScreenPreview = document.getElementById('fullScreenPreview');
            if (isFullScreen) {
                document.exitFullscreen();
            } else {
                fullScreenPreview.requestFullscreen();
            }
            this.isFullScreen = !isFullScreen;
        },
        // 分享
        share(type) {
            let link = null;
            let currentUrl = window.location.href;
            let text = "Check out this unNFT on untrading.org by @untradingOrg ! 🌐 \n\n" + currentUrl;
            switch(type) {
                case 'X':
                    // API - https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
                    return "https://x.com/intent/tweet?text=" + encodeURIComponent(text);
                case 'facebook':
                    // API - https://developers.facebook.com/docs/sharing/reference/share-dialog
                    return "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(currentUrl);
                case 'linkedin':
                    // API - https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/plugins/share-plugin
                    return "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(currentUrl);
            }
            return link;
        },
        // 查询FR信息
        async getFRInfo() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let FRInfoArray = await NFTContract.methods.getFRInfo(this.nft.tokenId).call();
            let FRInfoObject = {
                numGenerations: Number(FRInfoArray[0]),
                percentOfProfit: this.web3.utils.fromWei(FRInfoArray[1]),
                successiveRatio: this.web3.utils.fromWei(FRInfoArray[2]),
                lastSoldPrice: this.web3.utils.fromWei(FRInfoArray[3]),
                ownerAmount: Number(FRInfoArray[4]),
                addressesInFR: FRInfoArray[5]
            }
            this.FRInfo = FRInfoObject;
        },
    }
}
</script>
<style scoped>

</style>