<template>
	<div>
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" sm="12" md="8" xl="8" class="bg-grey-darken-4 px-16 py-16 w-100">
                    <div class="home-26 montserrat">
                        Earnings Estimator from My Community
                    </div>
                    <v-row no-gutters justify="start" align="center" class="mt-8">
                        <v-col cols="2">
                            Kingdom Size: 
                        </v-col>
                        <v-col cols="2">
                            <v-text-field  density="comfortable" reverse readonly hide-details></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pl-4">
                            citizens
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="start" align="center" class="mt-8">
                        <v-col cols="6">
                            <div class="montserrat home-16 text-primaryGrey">Average Transaction Details </div>
                            <v-row no-gutters class="mt-9">
                                <v-col cols="4">
                                    <v-text-field density="comfortable" reverse readonly hide-details></v-text-field>
                                     <span class="h6">Size</span>
                                </v-col>
                                <v-col cols="4" class="px-2">
                                    <v-text-field density="comfortable" placeholder="unBTC/USDC" reverse readonly hide-details></v-text-field>
                                     <span class="h6">Asset</span>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field density="comfortable" reverse readonly hide-details></v-text-field>
                                     <span class="h6">my share of each OR</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="5">
                            <div class="montserrat home-16 text-primaryGrey">Average unCrypto Non-Fungible Future Rewards (unFR) Parameters </div>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="5" class="pr-2">
                                    <v-text-field density="comfortable" reverse readonly hide-details></v-text-field>
                                     <span class="h6">Tatal Reward Ratio (R)</span>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field density="comfortable" placeholder="" reverse readonly hide-details></v-text-field>
                                     <span class="h6">Originator's Rewards Ratio (OR)</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div class="h6 pt-16 text-primaryGrey">Adjusting any of the parameters will result in different extra earnings.</div>
            </v-col>
            <v-col cols="12" sm="12" md="8" xl="8" xxl="8" class="mt-100">
                <div class="home-26 montserrat font-weight-bold">
                    Average Transaction Breakdown
                </div>
                <div>For a 15-average transaction per asset, the subtotal would be:</div>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>