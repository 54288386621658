import axios from '@/config/api.request'

export default {
    // 获取所有的稀有度名称集合
    getRarityNames(nftId) {
        return axios.request({
            url: '/nft/rarity/names',
            method: 'get'
        })
    }
}