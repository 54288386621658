<template>
    <div class="mt-3 mb-5">
        <div v-infinite-scroll="getCollectedNFTsByPage" infinite-scroll-immediate :infinite-scroll-disabled="noMoreData">
            <v-row no-gutters class="pb-5 ml-4" v-if="responsive == 'pc'">
                <div class="c-container">
                    <div class="c-p-card c-grid d-flex flex-column">
                        <div class="c-height" v-for="(nft, i) in nfts" :key="i">
                            <UnNft :nftId="nft.id" :showCommonRatio="isMyProfile"></UnNft>
                        </div>
                        <!-- 加载中骨架 -->
                        <SkeletonUnNFT :length="3" v-if="loading"></SkeletonUnNFT>
                    </div>
                </div>
            </v-row>
            <v-row no-gutters class="pb-5 r-p-r-7 r-m-t-8" v-if="responsive == 'mobile'">
                <v-col cols="10" sm="6">
                    <div class="c-container">
                        <div class="c-p-card c-grid d-flex flex-column">
                            <div class="c-height" v-for="(nft, i) in nfts" :key="i">
                                <UnNft :nftId="nft.id" :showCommonRatio="isMyProfile"></UnNft>
                            </div>
                            <!-- 加载中骨架 -->
                            <SkeletonUnNFT :length="3" v-if="loading"></SkeletonUnNFT>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import NFTAPI from '@/api/nft.js';
import UnNft from '@/components/nft/UnNft';
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {
            show: false,
            // NFT 集合
            nfts: [],
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            // 页码
            page: 1,
        }
    },
    props: {
        username: {
            type: String,
            default: null
        },
        sortBy: {
            type: String,
            default: null
        },
        blockchain: {
            type: String,
            default: null
        }
    },
    components: { UnNft, SkeletonUnNFT },
    created(){

    },
    mounted(){
        this.$nextTick(() => {
            // 根据用户名分页查询收藏的 NFT 列表
            this.getCollectedNFTsByPage();
        });
    },
    computed: {
        ...mapGetters(['user','responsive']),
        // 是我的资料页面吗
        isMyProfile() {
            return this.user && this.username == this.user.username;
        }
    },
    watch:{
        sortBy(n, o) {
            this.changeCondition();
        },
        blockchain(n, o) {
            this.changeCondition();
        }
    },
    methods: {
        // 根据用户名分页查询收藏的 NFT 列表
        async getCollectedNFTsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.loading = true;
            this.noDataAvailable = false;
            this.noMoreData = false;
            let res = await NFTAPI.getCollectedNFTsByPage(this.username, this.page, 10, this.sortBy, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.$emit('receiveCollectedCount', data.data.total);
                this.nfts.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                this.$emit('receiveCollectedCount', 0);
                if(this.nfts.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.nfts.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 改变条件
        changeCondition() {
            // 恢复data定义的所有参数
            Object.assign(this.$data, this.$options.data.call(this));
            // 重新查询数据
            this.getCollectedNFTsByPage();
        },
    }
}
</script>
<style scoped>

</style>