<template>
	<div>
        <v-card class="pb-16 mt-16" max-width="648" rounded="0" elevation="0" color="transparent">
             <h2 class="body-text-btn text-primaryGrey">
                Provenance
            </h2>
            <v-divider class="mt-2 mb-7"></v-divider>
            <!-- pc -->
            <v-timeline side="end" density="compact" truncate-line="both"  v-if="responsive == 'pc'">
                <v-timeline-item size="24" v-for="(provenance, i) in provenances" :key="i" fill-dot :dot-color="i == 0 ? '7b7' : 'c9c'">
                    <div class="d-flex justify-space-between">
                        <div class="d-flex align-center">
                            <span v-if="provenance.user">
                                <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                            </span>
                            <span class="ml-5">
                                <span class="body-h4 text-primaryGrey" v-function="provenance.contractFunction"></span>
                                <span v-if="provenance.contractFunction != 'UNWRAP_EXPIRED'">
                                    <span class="body-h4 text-primaryGrey" v-if="provenance.user.username !== provenance.user.wallet">{{ '@' + provenance.user.username }}</span>
                                    <span class="body-h4 text-primaryGrey" v-else>{{ '@' + $tools.privacyField(provenance.user.username) }}</span>
                                </span>
                                <div class="body-p-small text-primaryGrey">
                                    <span v-time="provenance.timestamp"></span>
                                    <span>{{ ' (' + $tools.formatEngLishDatetime(provenance.timestamp) + ')'}}</span>
                                </div>
                            </span>
                        </div>
                        <div v-if="provenance.transactionHash != null" class="right">
                            <span class="body-h4 mr-4 text-primaryGrey">{{ (provenance.price && provenance.price != 0) ? $tools.formatNumber(provenance.price) : '' }} {{ provenance.paymentToken }}</span>
                            <a :href="$tools.getBlockchainExplorerUrl(provenance.blockchain) + '/tx/' + provenance.transactionHash" class="del-underline" target="_blank">
                                <v-icon class="text-gray gradient-text-hover mt-n1" size="16" icon="fa-solid fa-up-right-from-square"></v-icon>
                            </a>
                        </div>
                    </div>
                </v-timeline-item>
            </v-timeline>
            <!-- mobile -->
            <v-timeline side="end" density="compact" truncate-line="both"  v-if="responsive == 'mobile'">
                <v-timeline-item size="24" v-for="(provenance, i) in provenances" :key="i" fill-dot :dot-color="i == 0 ? '7b7' : 'c9c'">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6">
                            <div class="d-flex align-center">
                                <span v-if="provenance.user">
                                    <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                </span>
                                <span class="ml-5">
                                    <!-- 事件 -->
                                    <span class="body-h4 text-primaryGrey" v-function="provenance.contractFunction"></span>
                                    <!-- 用户 -->
                                    <div v-if="provenance.contractFunction != 'UNWRAP_EXPIRED'">
                                        <span class="body-h4 text-primaryGrey" v-if="provenance.user.username !== provenance.user.wallet">{{ '@' + provenance.user.username }}</span>
                                        <span class="body-h4 text-primaryGrey" v-else>{{ '@' + $tools.privacyField(provenance.user.username) }}</span>
                                        <!-- 价格和链接 -->
                                        <div v-if="provenance.transactionHash != null" >
                                            <span class="body-h4 text-primaryGrey" :class="[provenance.paymentToken ? 'mr-4' : 'mr-0']">{{ (provenance.price && provenance.price != 0) ? $tools.formatNumber(provenance.price) : '' }} {{ provenance.paymentToken }}</span>
                                            <a :href="$tools.getBlockchainExplorerUrl(provenance.blockchain) + '/tx/' + provenance.transactionHash" class="del-underline" target="_blank">
                                                <v-icon class="text-gray gradient-text-hover mt-n1" size="16" icon="fa-solid fa-up-right-from-square"></v-icon>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- 时间 -->
                                    <div class="body-p-small text-primaryGrey">
                                        <span v-time="provenance.timestamp"></span>
                                        <span>{{ ' (' + $tools.formatEngLishDatetime(provenance.timestamp) + ')'}}</span>
                                    </div>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-timeline>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTProvenanceAPI from '@/api/nft-provenance.js'
export default {
    data(){
        return {
            // 数据集合
            provenances: [],
        }
    },
    props: ['nftId'],
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    computed: {
        ...mapGetters(['responsive']),
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据NFT主键查询历史记录
                this.getProvenances();
            },
            immediate: true
        }
    },
    methods: {
        // 根据NFT主键查询历史记录
        async getProvenances() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTProvenanceAPI.provenances(this.nftId);
            let data = res.data;
            if(data.success) {
                this.provenances = data.data;
            }
        }
    }
}
</script>
<style scoped>
.right {
    position: absolute;
    right: 0px;
}
</style>