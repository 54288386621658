<template>
	<div class="pb-16">
        <v-container class="h-100">
            <v-card max-width="1440" elevation="0" class="mx-0-auto pt-7" color="transparent">
                <v-row no-gutters justify="center" align="center" class="px-6">
                    <v-col cols="12" class="sub-page-h1 text-center text-primaryGrey">
                        Search unNFTs
                    </v-col>
                    <v-col cols="12" class="mt-16 pr-7">
                        <v-text-field clearable v-model="text" class="body-p" label="Search for your favorite art, tag, name, department, category or rarity..." variant="underlined" hide-details @keyup.enter="search"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center" align="center" class="px-6">
                    <!-- <v-col cols="12" class="body-h4 text-center">
                        Trending Tags
                    </v-col> -->
                    <!-- <v-col cols="12" class="mt-8 body-p text-primaryGrey">
                        {{ text ? ('#' + text) : '' }}
                    </v-col> -->
                    <!-- <v-col cols="12" align="center" class="mt-100" v-show="searchLoading">
                        <v-progress-circular :size="100" color="primaryGrey" :width="10" indeterminate></v-progress-circular>
                    </v-col> -->
                    <v-col cols="12" class="py-8 text-primaryGrey" v-show="showResultsInfo && noDataAvailable">
                        0 results for "{{ text }}" - try a different search or go explore something!
                    </v-col>
                    <v-col cols="12" class="py-8 text-primaryGrey" v-show="showResultsInfo && total > 0">
                        {{ total }} results for "{{ text }}"
                    </v-col>
                    <v-col cols="12" class="mb-16" :class="(searchLoading || !showResultsInfo) ? 'mt-16' : ''">
                        <v-row no-gutters v-infinite-scroll="searchByPage" infinite-scroll-immediate :infinite-scroll-distance="100" :infinite-scroll-disabled="noMoreData">
                            <div class="c-width mr-7">
                                <div class="c-card c-grid d-flex flex-column">
                                    <div class="c-height" v-for="(nft, i) in nfts" :key="i">
                                        <UnNft :nftId="nft.id"></UnNft>
                                    </div>
                                    <!-- 加载中骨架 -->
                                    <SkeletonUnNFT :length="10" v-if="searchLoading"></SkeletonUnNFT>
                                </div>
                            </div>
                            <!-- <v-col cols="4" v-for="trendingTags in trendingTagsList" :key="trendingTags.id">
                                <img :src="trendingTags.img" alt="" width="414" height="414">
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import SearchAPI from '@/api/search.js';
import UnNft from '@/components/nft/UnNft';
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {
            // 搜索文本
            text: this.$route.query.text,
            // 搜索加载中
            searchLoading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            // 搜索结果列表
            nfts: [],
            // 展示结果信息
            showResultsInfo: false,
            page: 1,
            size: 10,
            total: 0
        }
    },
    components: { UnNft, SkeletonUnNFT },
    created(){
        // 监听刷新套装数据
        this.$bus.on('emitRefreshNFTs', data => {
            this.search();
        })
    },
    mounted(){

    },
    computed: {

    },
    watch:{
        $route: {
            handler(n, o) {
                if(n) {
                    this.text = this.$route.query.text;
                }
            },
            immediate: true
        },
        text: {
            handler(newVal, oldVal) {
                this.showResultsInfo = false;
            },
            immediate: true
        }
    },
    methods: {
        // 搜索
        async search() {
            this.searchLoading = false;
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.nfts = [];
            this.page = 1;
            this.total = 0;
            if(this.text) {
                this.$router.push('/unnfts/search?text=' + this.text);
            } else {
                this.$router.push('/unnfts/search');
                return;
            }
            this.searchByPage();
        },
        // 搜索
        async searchByPage() {
            if(this.noDataAvailable || this.noMoreData || this.searchLoading) {
                return;
            }
            this.searchLoading = true;
            let res = await SearchAPI.searchNFTs(this.text, this.page, this.size);
            let data = res.data;
            if(data.success) {
                this.nfts.push(...data.data.records);
                this.page++;
                this.total = data.data.total;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.nfts.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.nfts.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.showResultsInfo = true;
            this.searchLoading = false;
        }
    }
}
</script>
<style scoped>

</style>