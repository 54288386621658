import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 是否喜欢套装
    liked(setId) {
        if(!token) {
            return { data: { success: false } }
        }
        return axios.request({
            url: '/set/like/liked',
            method: 'get',
            params: { setId: setId }
        })
    },
    // 喜欢套装
    like(setId) {
        return axios.request({
            url: '/set/like/like',
            method: 'get',
            params: { setId: setId }
        })
    },
    // 取消喜欢套装
    unlike(setId) {
        return axios.request({
            url: '/set/like/unlike',
            method: 'get',
            params: { setId: setId }
        })
    },
    
}