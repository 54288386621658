<template>
    <div class="mb-16">
        <Alert v-if="$route.path == '/'"></Alert>
        <Banner></Banner>
        <Hero v-if="responsive == 'pc'"></Hero>
        <HeroMobile v-if="responsive == 'mobile'"></HeroMobile>
        <v-container v-if="responsive == 'pc'">
            <v-card max-width="1080" elevation="0" class="mx-0-auto" color="transparent">
                <v-row no-gutters justify="center">
                    <v-col cols="12" sm="6">
                        <HomeBlockchain></HomeBlockchain>
                        <TopFlows></TopFlows>
                        <TrendingOriginators></TrendingOriginators>
                        <TopCollectors></TopCollectors>
                        <Sets></Sets>
                        <FeaturedReleases></FeaturedReleases>
                        <MarketStats></MarketStats>
                        <!-- <FeaturedIn></FeaturedIn> -->
                        <!-- <Newsletter></Newsletter> -->
                        <!-- <Connect></Connect> -->
                        <el-affix position="top" offset="-500">
                            <HomeTag></HomeTag>
                            <HomeFooter></HomeFooter>
                        </el-affix>
                    </v-col>
                    <v-col cols="12" sm="6" class="ml-n10">
                        <ForYou></ForYou>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <v-container  v-if="responsive == 'mobile'">
            <v-card max-width="1080" elevation="0" class="mx-0-auto" color="transparent">
                <v-row no-gutters justify="center">
                    <v-col cols="12" sm="6">
                        <HomeBlockchain></HomeBlockchain>
                        <ForYouCarousel class="mt-16"></ForYouCarousel>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import Alert from '@/components/common/Alert.vue'
import Banner from '@/components/home/Banner.vue'
import Hero from '@/components/home/Hero.vue'
import HeroMobile from '@/components/home/mobile/Hero.vue'
import HomeBlockchain from '@/components/home/HomeBlockchain.vue'
import TopFlows from '@/components/home/TopFlows.vue'
import TrendingOriginators from '@/components/home/TrendingOriginators.vue'
import TopCollectors from '@/components/home/TopCollectors.vue'
import Sets from '@/components/home/Sets.vue'
import ForYou from '@/components/home/ForYou.vue'
import ForYouCarousel from '@/components/home/mobile/ForYouCarousel.vue'
import HomeTag from '@/components/home/HomeTag.vue'
import HomeFooter from '@/components/home/HomeFooter.vue'
import FeaturedReleases from '@/components/home/FeaturedReleases.vue'
import MarketStats from '@/components/home/MarketStats.vue'
import FeaturedIn from '@/components/home/FeaturedIn.vue'
import Newsletter from '@/components/home/Newsletter.vue'
import Connect from '@/components/home/Connect.vue'
import { mapGetters } from "vuex";
export default {
    components: { Alert, Banner, Hero, HeroMobile,HomeBlockchain, TopFlows, TrendingOriginators, TopCollectors, Sets, ForYou, ForYouCarousel, HomeTag, HomeFooter, FeaturedReleases, MarketStats, FeaturedIn, Newsletter, Connect },
    computed: {
        ...mapGetters(['responsive'])
    },
}
</script>
<style scoped>

</style>