<template>
	<div>
        <v-card :class="responsive == 'pc' ? 'w-280' :'w-100'" elevation="0" rounded="0" color="transparent" class="text-primaryGrey ma-1">
            <v-row no-gutters class="px-4 py-4 r-p-l-0">
                <v-col cols="12">
                    <h3 class="body-text-btn">
                        Holding history
                    </h3>
                </v-col>
                <v-divider class="my-1"></v-divider>
                <v-col cols="12" v-if="holdingLoading">
                    <SkeletonCard :length="2"></SkeletonCard>
                </v-col>
                <v-col cols="12" justify="between" class="body-p-small" v-if="!holdingLoading">
                    <v-row no-gutters class="mb-8" v-for="(epoch, i) in holdingHistoryEpoches" :key="'holding-epoch' + i">
                        <v-col cols="12" class="d-flex justify-space-between" v-for="(holding, i) in epoch.holdings" :key="'holding' + i">
                            <div class="form-text">{{ holding.action }}</div>
                            <div class="body-p-smal">{{ $tools.formatEngLishDatetime(holding.timestamp) }}</div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters class="px-4 r-p-l-0 py-4" v-if="pnLEpochSummary.epoches.length > 0">
                <v-col cols="12">
                    <div class="body-text-btn">
                        PnL
                    </div>
                </v-col>
                <v-divider class="my-1"></v-divider>
                <v-col cols="12" v-if="pnlLoading">
                    <SkeletonCard :length="6"></SkeletonCard>
                </v-col>
                <v-col cols="12" justify="between" class="body-p-small" v-if="!pnlLoading">
                    <v-row no-gutters class="mb-8" v-for="(epoch, i) in pnLEpochSummary.epoches" :key="'pnl-epoch-' + i">
                        <v-col cols="12" class="d-flex justify-space-between" v-for="(pnl, i) in epoch.pnls" :key="'pnl-' + i">
                            <div class="form-text">{{ pnl.action }}</div>
                            <div>
                                <span :class="pnl.value < 0 ? 'text-red': '' ">{{ (pnl.value && pnl.value != 0) ? $tools.formatNumber(pnl.value, 8) : '--' }}</span>
                                <span class="ml-1">{{ pnl.paymentToken }}</span>
                            </div>
                        </v-col>
                        <v-divider thickness="1"></v-divider>
                        <v-col cols="12" class="d-flex justify-space-between">
                            <div class="form-text">Sub Total</div>
                            <div>
                                <span :class="epoch.total.value < 0 ? 'text-red': '' ">{{ (epoch.total.value && epoch.total.value != 0) ? $tools.formatNumber(epoch.total.value, 8) : '--' }}</span>
                                <span class="ml-1">{{ epoch.total.paymentToken }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" justify="between" class="body-p-small" v-if="!pnlLoading">
                    <v-row no-gutters>
                        <v-divider thickness="2"></v-divider>
                        <v-col cols="12" class="d-flex justify-space-between">
                            <div class="form-text">Total</div>
                            <div class="font-weight-bold">
                                <span :class="pnLEpochSummary.total.value < 0 ? 'text-red': '' ">{{ (pnLEpochSummary.total.value && pnLEpochSummary.total.value != 0) ? $tools.formatNumber(pnLEpochSummary.total.value, 8) : '--' }}</span>
                                <span class="ml-1">{{ pnLEpochSummary.total.paymentToken }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoProvenanceAPI from '@/api/crypto-provenance.js';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard';
export default {
    data(){
        return {
            // 持有加载中
            holdingLoading: false,
            // 历史记录
            holdingHistoryEpoches: [],
            // 损益加载中
            pnlLoading: false,
            // 损益历史记录
            pnLEpochSummary: {
                epoches: [],
                total: {}
            }
        }
    },
    // 加密货币主键
    props: {
        cryptoId: {
            type: String,
            default: null
        }
    },
    components: { SkeletonCard },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive'])
    },
    watch:{
        cryptoId: {
            handler(newVal, oldVal) {
                // 根据加密货币主键查询持有历史
                this.getHoldingHistories();
                // 根据加密货币主键查询持有损益
                this.getPnlHistories();
            },
            immediate: true
        },
    },
    methods: {
        // 根据加密货币主键查询持有历史
        async getHoldingHistories() {
            if(this.cryptoId == null) {
                return;
            }
            this.holdingLoading = true;
            let res = await CryptoProvenanceAPI.getHoldingHistories(this.cryptoId);
            let data = res.data;
            if(data.success) {
                this.holdingHistoryEpoches = data.data;
            }
            this.holdingLoading = false;
        },
        // 根据加密货币主键查询持有损益
        async getPnlHistories() {
            if(this.cryptoId == null) {
                return;
            }
            this.pnlLoading = true;
            let res = await CryptoProvenanceAPI.getPnlHistories(this.cryptoId);
            let data = res.data;
            if(data.success) {
                this.pnLEpochSummary = data.data;
            }
            this.pnlLoading = false;
        },
    }
}
</script>
<style scoped>

</style>