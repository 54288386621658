<template>
	<div class="bg-f2f h-100">
        <div class="h-100" v-if="!loading">
            <v-container class="mb-9">
                <v-card max-width="1400" elevation="0" rounded="0" color="transparent" class="pt-7 pb-7 mx-auto">
                    <v-row no-gutters justify="center">
                        <!-- ----------------------------------- Back -------------------------------------- -->
                        <v-col cols="12" align="center" class="mb-3">
                            <v-card width="840" elevation="0" rounded="0" color="transparent" class="text-left">
                                <router-link to="/dashboard" class="del-underline">
                                    <v-icon size="28" class="text-primaryGrey" aria-label="chevron-left-circle-outline" icon="mdi:mdi-chevron-left-circle-outline"></v-icon>
                                </router-link>
                            </v-card>
                        </v-col>
                        <!-- ----------------------------------- Info -------------------------------------- -->
                        <v-card elevation="0" rounded="0" color="transparent" :class="responsive == 'pc' ? 'w-280 ml-n5' :'w-100'">
                            <v-card elevation="12" color="black" rounded="0" class="text-primaryGrey ma-4">
                                <v-row no-gutters class="px-4 py-4">
                                    <v-col cols="12">
                                        <div class="sidebar-h2">
                                            {{ unCrypto.wrappedCurrency}}/{{ unCrypto.quoteCurrency }}
                                        </div>
                                        <v-divider class="my-1"></v-divider>
                                        <div class="body-text-btn mt-15">
                                            nFR Parameters
                                        </div>
                                        <v-divider class="my-1"></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="12" justify="between" class="body-p-small">
                                        <v-row no-gutters class="text-primaryGrey">
                                            <v-col cols="12" class="d-flex justify-space-between">
                                                <div class="form-text">Total Reward (f)</div>
                                                <div>{{ unCrypto.totalRewardRatio }}%</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">OR Ratio (a)</div>
                                                <div>{{ unCrypto.originatorRewardRatio }}%</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">FR Ratio (r)</div>
                                                <div>{{ 100 - unCrypto.originatorRewardRatio }}%</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">OR Split</div>
                                                <div>100/0</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text"># of Gen</div>
                                                <div>{{ unCrypto.numberOfGenerations }}</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text"><em>g</em></div>
                                                <div>{{ $tools.calcCommonRatio(unCrypto.numberOfGenerations) }}</div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <!-- ----------------------------------- History -------------------------------------- -->
                            <UnCryptoHoldingHistory :cryptoId="cryptoId"></UnCryptoHoldingHistory>
                            <!-- ----------------------------------- Link -------------------------------------- -->
                            <div class="body-p font-weight-bold ml-5 mt-15 r-m-l-1">
                                <span class="gradient-underline-hover">
                                    <router-link :to="'/dashboard/asset/uncrypto/ors/received/' + cryptoId" class="gradient-underline gradient-text-hover text-primaryGrey">
                                        TOTAL ORs RECEIVED
                                    </router-link>
                                </span>
                            </div>
                            <div class="body-p font-weight-bold ml-5 mt-5 r-m-l-1" v-if="!invalidNFRPayout">
                                <span class="gradient-underline-hover">
                                    <router-link :to="'/dashboard/asset/uncrypto/nfrs/paid/' + cryptoId" class="gradient-underline gradient-text-hover text-primaryGrey">
                                        TOTAL nFR PAYOUTS
                                    </router-link>
                                </span>
                            </div>
                        </v-card>
                        <v-card elevation="0" rounded="0" color="transparent" class="ml-16 r-m-l-0 r-m-t-16 r-p-x-1">
                            <!-- ----------------------------------- Title -------------------------------------- -->
                            <h2 class="sidebar-h2 text-primaryGrey d-flex flex-nowrap">
                                <span class="mr-2">FRs Received by @{{ user.name }}</span>
                                <UserAvatar :avatar="user.avatar" :username="user.username" :size="24"></UserAvatar>
                            </h2>
                            <!-- ----------------------------------- Asset Details -------------------------------------- -->
                            <v-card max-width="500" elevation="0" rounded="0" color="transparent" class="text-primaryGrey">
                                <v-row no-gutters>
                                    <v-col cols="12" sm="6" class="pa-0 mt-10">
                                        <h3 class="body-text-btn">
                                            Asset Details
                                        </h3>
                                    </v-col>
                                    <v-divider class="my-4"></v-divider>
                                    <v-col cols="12" justify="between" class="body-p-small">
                                        <v-row no-gutters>
                                            <v-col cols="12" class="d-flex justify-space-between">
                                                <div class="form-text">Symbol</div>
                                                <div>
                                                    <span class="gradient-underline-hover">
                                                        <router-link :to="`/uncrypto/${unCrypto?.wrappedCurrency?.toLowerCase()}/${unCrypto?.id}`" class="del-underline gradient-underline gradient-text-hover text-primaryGrey">
                                                           {{ unCrypto.wrappedCurrency }}/{{ unCrypto.quoteCurrency }}
                                                        </router-link>
                                                    </span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Initial Supply</div>
                                                <div>
                                                    <span>{{ $tools.formatNumber(unCrypto.size, unCrypto?.cryptoWrapped?.sizeDecimals) || '--' }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Supply</div>
                                                <div>
                                                    <span>{{ $tools.formatNumber(unCrypto.currentSize, unCrypto?.cryptoWrapped?.sizeDecimals) }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Contract Address</div>
                                                <div>
                                                    <span class="gradient-underline-hover">
                                                        <a :href="$tools.getBlockchainExplorerUrl(unCrypto.blockchain) + '/address/' + unCrypto.contractAddress" class="gradient-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                            {{ $tools.privacyField(unCrypto.contractAddress) }}
                                                        </a>
                                                    </span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Token ID</div>
                                                <div>
                                                    <span class="gradient-underline-hover">
                                                        <a :href="$tools.getBlockchainExplorerUrl(unCrypto.blockchain) + '/token/' + unCrypto.contractAddress + '?a=' + unCrypto.tokenId" class="gradient-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                            {{ unCrypto.tokenId }}
                                                        </a>
                                                    </span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Token Standard</div>
                                                <div>ERC721, ERC-5173</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Blockchain</div>
                                                <div>{{ unCrypto.blockchain }}</div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Created by</div>
                                                <div class="d-flex flex-nowrap">
                                                    <span class="gradient-underline-hover mr-2">
                                                        <router-link @click.stop :to="'/profile/' + unCrypto?.user?.username" class="del-underline gradient-text-hover parent">
                                                            <span class="body-p-small text-primaryGrey ellipsis-line">{{ unCrypto?.user?.name  }}</span>
                                                        </router-link>
                                                    </span>
                                                    <UserAvatar :avatar="unCrypto?.user?.avatar" :username="unCrypto?.user?.username" :size="20"></UserAvatar>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex justify-space-between mt-2">
                                                <div class="form-text">Current owner</div>
                                                <div class="d-flex flex-nowrap">
                                                    <span class="gradient-underline-hover mr-2">
                                                        <router-link @click.stop :to="'/profile/' + unCrypto?.owner?.username" class="del-underline gradient-text-hover">
                                                            <span class="body-p-small text-primaryGrey">{{ unCrypto?.owner.name  }}</span>
                                                        </router-link>
                                                    </span>
                                                    <UserAvatar :avatar="unCrypto?.owner?.avatar" :username="unCrypto?.owner?.username" :size="20"></UserAvatar>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <!-- ----------------------------------- Payment -------------------------------------- -->
                            <FRPaymentsReceived class="mt-10" :cryptoId="cryptoId"></FRPaymentsReceived>
                        </v-card>
                    </v-row>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoAPI from '@/api/crypto.js'
import CryptoNFRAPI from '@/api/crypto-nfr.js'
import UnCryptoHoldingHistory from '@/components/crypto/UnCryptoHoldingHistory.vue'
import FRPaymentsReceived from '@/components/crypto/FRPaymentsReceived.vue'
export default {
    data(){
        return {
            // 加密货币主键
            cryptoId: this.$route.params.cryptoId,
            // 加载中
            loading: false,
            // 加密货币
            unCrypto: {},
            // 无效的支出
            invalidNFRPayout: false
        }
    },
    components: { UnCryptoHoldingHistory, FRPaymentsReceived },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user','responsive'])
    },
    watch:{
        cryptoId: {
            handler(newVal, oldVal) {
                // 根据主键查询加密货币
                this.getCryptoById();
                // 根据加密货币主键和用户主键查询nFR支付记录是否无效
                this.isInvalidNFRPayout();
            },
            immediate: true
        },
        'user.id': {
            handler(newVal, oldVal) {
                // 根据加密货币主键和用户主键查询nFR支付记录是否无效
                this.isInvalidNFRPayout();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.cryptoId == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.cryptoId);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
            }
            this.loading = false;
        },
        // 根据加密货币主键和用户主键查询nFR支付记录是否无效
        async isInvalidNFRPayout() {
            if (this.cryptoId == null || this.user.id == null) {
                return;
            }
            let res = await CryptoNFRAPI.isInvalidNFRPayout(this.cryptoId, this.user.id);
            let data = res.data;
            if(data.success) {
                this.invalidNFRPayout = data.data;
            }
        },
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
</style>