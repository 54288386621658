<template>
  <div id="home" class="h-100 w-100">
    <v-container class="h-100" v-if="responsive == 'pc'">
      <v-row no-gutters class="h-100">
        <v-col cols="8" class="pt-16 d-flex flex-column justify-space-between align-center">
            <div class="body-p-large mt-16 w-75">
              <span class="text-primary">Past. Future. Rewarded.</span>
              <span>™</span>
            </div>
          <div class="d-flex flex-column justify-end w-75">
            <div class="home-h2 mt-100">WELCOME TO THE</div>
            <div class="home-h1 mt-10">
              <span class="text-primary">UN</span>TRADING ERA
            </div>
          </div>
          <div class="mb-200 w-75">
            <div class="mt-100 home-h3">NO MORE PREDICTIONS NEEDED</div>
          </div>
        </v-col>
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-col
              cols="2"
              v-bind="props"
              class="bg-f2f pt-16 d-flex flex-column justify-end pb-210"
            >
              <div
                class="pl-4 pr-12 body-p-small show-marg"
                v-show="isHovering"
              >
                <div class="mb-2">
                  Mint, wrap, exchange NFTs on our decentralized platform.
                </div>
                <div class="mb-11">
                  Unleash the full potential of your NFTs and benefit from their
                  historical value and future price appreciation, even after
                  you've sold them.
                </div>
              </div>
              <div class="w-50">
                <router-link to="/unnfts" class="del-underline">
                  <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                      <div
                        v-bind="props"
                        class="bg-primary text-white d-flex justify-center align-center body-h4 w-100 hind-marg"
                        :class="isHovering ? 'btn-shadow' : ''"
                      >
                        NFTS
                      </div>
                    </template>
                  </v-hover>
                </router-link>
              </div>
            </v-col>
          </template>
        </v-hover>
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-col
              cols="2"
              v-bind="props"
              class="bg-e5e pt-16 d-flex flex-column justify-end pb-210"
            >
              <div class="pl-4 pr-12 mb-11 body-p-small" v-show="isHovering">
                <div class="mb-2">
                  Convert your BTC and ETH into enhanced tokens and enjoy their
                  rising value, even after you sell them. Every buyer gets the
                  same advantage. This innovative method is driven by our
                  Provenance Value Amplification (PVA) system, which generates a
                  positive network effect that rewards all users.
                </div>
                <div class="mb-2">With untrading, you can:</div>
                <li>
                  Retain the potential of your investments, even after selling.
                </li>
                <li>
                  Escape the cycle of losses for improved investment results.
                </li>
                <li class="mb-2">
                  Gain from the long-term asset growth without exposure to
                  market fluctuations.
                </li>
                <div>
                  untrading marks the future of finance. Join us today for
                  smarter investing.
                </div>
              </div>
              <div class="w-100">
                <router-link to="/uncryptos" class="del-underline">
                  <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                      <div
                        v-bind="props"
                        class="bg-primary text-white d-flex justify-space-around align-center w-100 hind-marg"
                        :class="isHovering ? 'btn-shadow' : ''"
                      >
                        <span class="body-h4 ml-12">CRYPTOS</span>
                        <v-icon icon="mdi:mdi-chevron-right" size="36"></v-icon>
                      </div>
                    </template>
                  </v-hover>
                </router-link>
              </div>
            </v-col>
          </template>
        </v-hover>
      </v-row>
    </v-container>
   
    <!-- <Footer class="footer-class"></Footer> -->
    <Footer v-if="responsive == 'pc'" class="footer-class"></Footer>
    <div
      v-if="responsive == 'mobile'"
      class="h-100 pl-11 pt-3 d-flex flex-column justify-space-between"
    >
      <div>
        <div class="d-flex align-center justify-space-between">
          <logo
            type="header"
            color="light"
            class="mt-2"
            responsive="mobile"
          ></logo>
          <Installation class="mr-6"></Installation>
        </div>
        <div class="mt-13">
          <span class="text-primary font-weight-bold">Past. Future. Rewarded.</span>
        </div>
        <div class="pb-16 body-center">
          <div class="home-mobile-h2">WELCOME TO THE</div>
          <div class="home-mobile-h1 mt-5">
            <span class="text-primary">UN</span>TRADING ERA
          </div>
        </div>
        <div class="pt-16 home-mobile-h3 font-weight-bold">
          <div>NO MORE PREDICTIONS NEEDED</div>
        </div>
      </div>
      <div class="d-flex justify-space-between btn-h align-center">
        <router-link to="/unnfts" class="del-underline">
          <div class="text-btnwhite body-mobile-h4 text-center btn-nfts font-weight-bold h-100 w-100 px-8 py-2">
            NFTS
          </div>
        </router-link>
        <router-link to="/uncryptos" class="del-underline">
          <div class="h-100 w-100 bg-primary text-white body-mobile-h4 font-weight-bold d-flex align-center justify-center text-center py-1" >
            <span class="ml-12 mr-7">CRYPTOS</span>
            <v-icon icon="mdi:mdi-chevron-right" size="28" class="mr-6 my-1"></v-icon>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from './Footer.vue'
import { mapGetters } from "vuex";
import Logo from '@/components/common/Logo';
import Installation from '@/components/common/Installation';
export default {
  computed: {
    ...mapGetters(['token', 'user', 'responsive'])
  },
  components: { Logo, Installation, Footer },
}
</script>
<style scoped lang="scss">
.footer-class {
  position: absolute;
}

.show-marg {
  margin-top: 138px;
}

.hind-marg {
  height: 42px;
}

.btn-shadow {
  box-shadow: 4px 6px 10px 0px rgba(0, 0, 0, 0.25);
}

.btn-nfts {
    border: 3px solid #03DAC5;
    background: rgba(255, 255, 255, 0.00);
}
.btn-h{
    height: 36px;
    margin-bottom: 89px;
}

.body-center{
  margin-top:86px;
}
ul {
  list-style: none; /* 去掉默认的列表标记符号 */
  padding-left: 0;
}

li {
  display: flex; /* 使用 flexbox 布局 */
  align-items: flex-start; /* 对齐文本 */
}

li::before {
  content: '•'; /* 自定义列表标记符号 */
  font-size: 24px; /* 调整符号的大小 */
  margin-right: 10px; /* 添加标记符号与文本的间距 */
  line-height: 1; /* 控制符号的垂直对齐 */
}
</style>