<template>
	<div class="bg-lightGray h-100">
        <!-- 加载中 -->
        <div class="h-100" v-if="userProfileLoading">
            <v-container class="h-100" align="center">
                <v-row no-gutters align="center">
                    <v-col>
                        <v-progress-circular :size="100" :width="10" color="primaryGrey" indeterminate></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- 用户资料 -->
        <div class="h-100" v-if="!userProfileLoading">
            <v-container class="mb-16">
                <v-card max-width="1920" elevation="0" rounded="0" color="transparent" align="center" class="pt-7">
                    <v-card max-width="740" elevation="0" rounded="0" color="transparent" class="mb-8">
                        <div class="text-left text-primaryGrey">
                            <div>
                                <v-icon size="28" icon="mdi:mdi-chevron-left-circle gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                            </div>
                            <h1 class="sub-page-h1">Edit Your Profile</h1>
                        </div>
                    </v-card>
                    <v-card max-width="740" elevation="12" rounded="0" align="start" class="pa-8" color="black">
                        <!----------- USERNAME ----------->
                        <v-row>
                            <v-col cols="9">
                                <h2 class="body-h4  text-primaryGrey">NAME</h2>
                                <div class="parent">
                                    <div class="body-p mt-3 text-primaryGrey ellipsis-line">{{ userProfile.user.name }}</div>
                                </div>
                                <div class="body-h5 text-primaryGrey" v-if="userProfile.user.username !== userProfile.user.wallet">
                                    {{ '@' + userProfile.user.username }}
                                </div>
                            </v-col>
                            <v-col cols="3">
                                 <v-avatar icon size="144" class="float-right">
                                    <v-icon v-if="!userProfile.user.avatar" icon="mdi:mdi-account-circle-outline" size="144"></v-icon>
                                    <v-img  v-if="userProfile.user.avatar" cover height="144" :src="$tools.bigAvatar(userProfile.user.avatar)"></v-img>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <div class="body-p montserrat mt-13 font-italic text-primaryGrey">
                            All fields below are optional. They are not required, but highly recommended. Your reputation score may improve as more information becomes available. For enhanced staking and trading benefits, a scoring system will be implemented shortly. 
                        </div>
                        <v-divider class="my-13"></v-divider>

                        <!----------- PROFILE ----------->
                        <v-row no-gutters v-if="user.verified">
                            <v-col cols="12">
                                <h2 class="body-h4 text-primaryGrey">PROFILE PICTURE</h2>
                                <div class="body-p text-primaryGrey mt-3">Square cropped jpg, png, or gif. Max file size 5mb</div>
                                <div class="body-p text-primaryGrey mt-3">Please save changes after uploading successfully.</div>
                                <div class="mt-5">
                                    <el-upload ref="upload" :action="$config.baseUrl+ '/file/upload/cloudflare/image'" :show-file-list="false" accept="image/jpg, image/jpeg, image/png, image/gif, image/avif" :before-upload="uploadBefore" :on-success="uploadSuccess">
                                        <a class="gradient-left-red-purple-182 del-underline">
                                            <v-btn :rounded="0" elevation="2" :loading="avatarUploading" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="48">Upload image</v-btn>
                                        </a>
                                    </el-upload>
                                </div>
                            </v-col>
                            <!-- <v-col cols="4">
                                <v-img :src="$tools.bigAvatar(userProfile.user.avatar)" cover width="120" height="120" class="float-right"></v-img>
                            </v-col> -->
                        </v-row>
                        <v-divider class="my-13" v-if="user.verified"></v-divider>

                        <!----------- NAME ----------->
                        <v-row no-gutters>
                           <v-col>
                            <div class="mt-2">
                                <v-text-field clearable v-model="userProfile.user.name" label="Name" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </div>
                            <div class="mt-8">
                                <v-text-field clearable v-model="userProfile.user.location" label="Location" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </div>
                           </v-col>
                        </v-row>
                        <v-divider class="my-13"></v-divider>

                        <!----------- Email ----------->
                        <h2 class="body-h4 text-primaryGrey">REICEIVING MAIL NOTIFICATIONS</h2>
                        <div class="body-p mt-6 text-primaryGrey">Add your email address to receive notifications about your activity on untrading.org. This will not be shown on your profile.</div>
                        <div class="mt-6">
                            <v-text-field clearable v-model="userProfile.user.email" label="Email" variant="outlined" hide-details density="comfortable"></v-text-field>
                        </div>
                        <v-divider class="my-13"></v-divider>
                        
                        <div v-if="userProfile.user.username !== userProfile.user.wallet">
                            <!----------- Verify via Twitter ----------->
                            <div class="body-h4 text-primaryGrey text-uppercase pb-6">Verify your profile</div>
                            <v-row no-gutters v-if="!user.verified">
                                <v-col cols="6">
                                    <div class="body-p text-primaryGrey">Before you can create or collect on untrading.org, you must verify your account first.</div>
                                </v-col>
                                <v-col cols="4" offset="2">
                                    <router-link :to="'/profile/' + user.username" class="del-underline gradient-left-red-purple-block">
                                        <v-btn size="x-large" :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b block" height="52" aria-label="Verify via X (Twitter)">Verify via 𝕏</v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                            
                            <!----------- Verify ----------->
                            <v-row no-gutters v-if="user.verified">
                                <v-col cols="6">
                                    <div class="body-p text-primaryGrey">Show the untrading community that your profile is authentic. You must be verified via 𝕏. Additional verification will enhance your reputation. </div>
                                </v-col>
                                <v-col cols="4" offset="2">
                                    <v-text-field :model-value="'@' + userProfile.user.username" variant="solo" density="comfortable" append-inner-icon="icon-x" hide-details readonly></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Instagram 先不写 -->
                            <v-divider class="my-13"></v-divider>

                        </div>
                        <!----------- Link ----------->
                        <h2 class="body-h4 text-primaryGrey pb-6">ADD LINKS TO YOUR SOCIAL MEDIA PROFILES</h2>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-text-field prefix="Website" suffix="https://" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.website" label="Website URL" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4" v-if="$config.env == 'testnet'">
                            <v-col cols="6">
                                <v-text-field prefix="𝕏" suffix="x.com/" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.twitterUsername" label="𝕏 username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Facebook" suffix="facebook.com/" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.facebook" label="Facebook username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Reddit" suffix="reddit.com/user/" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.reddit" label="Reddit username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="YouTube" suffix="" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.youtube" label="Channel URL" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Snapchat" suffix="snapchat.com/add/" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.snapchat" label="Snapchat username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="TikTok" suffix="tiktok.com/@" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.tiktok" label="Tiktok username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Telegram" suffix="t.me/" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.telegram" label="Telegram username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Discord" suffix="" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.discord" label="Discord username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Clubhouse" suffix="" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.clubhouse" label="Clubhouse username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-text-field prefix="Twitch" suffix="twitch.tv/" bg-color="eee" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field clearable v-model="userProfile.social.twitch" label="Twitch username" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="my-13"></v-divider>

                        <!----------- BIO ----------->
                        <h2 class="body-h4 text-primaryGrey">BIO</h2>
                        <div class="body-p mt-6 text-primaryGrey">A short bio on who you are as an originator or collector, or both!</div>
                        <div class="mt-6">
                            <v-textarea clearable v-model="userProfile.user.bio" label="BIO" variant="outlined" persistent-counter :counter="1000"></v-textarea>
                        </div>
                    </v-card>
                    <v-card max-width="740" elevation="0" rounded="0" color="transparent" align="start">
                        <a class="gradient-left-red-purple-300 del-underline">
                            <v-btn rounded="0" :loading="updateLoading" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b mt-16" @click="update" aria-label="Save Changes">Save Changes</v-btn>
                        </a>
                    </v-card>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UserAPI from '@/api/user.js';
import SocialAPI from '@/api/social.js';
export default {
    data(){
        return {
            // 头像文件
            file: null,
            // 头像上传中
            avatarUploading: false,
            // 加载用户信息
            userProfileLoading: true,
            // 用户资料
            userProfile: {
                user: {},
                social: {},
            },
            // 更新加载中
            updateLoading: false
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        this.getByUsername();
    },
    computed: {
        ...mapGetters(['user'])
    },
    watch:{
        'user.username': {
            handler(newVal, oldVal){
                this.getByUsername();
            },
            immediate: true
        }
    },
    methods: {
        // 加载文件
        loadFile(param){
            this.file = param.file;
        },
        // 头像上传之前
        uploadBefore(rawFile) {
            this.avatarUploading = true;
        },
        // 头像上传成功
        uploadSuccess(data, uploadFile) {
            if(data.success) {
                // 更新头像
                this.userProfile.user.avatar = data.data;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
            this.avatarUploading = false;
        },
        // 根据用户名获取用户信息
        async getByUsername() {
            this.userProfileLoading = true;
            if(!this.user.username){
                return;
            }
            let res = await UserAPI.getByUsername(this.user.username);
            let data = res.data;
            if(data.success) {
                this.userProfile = data.data;
            }
            this.userProfileLoading = false;
        },
        // 编辑登录用户信息
        async update() {
            this.updateLoading = true;
            let res = await UserAPI.update(this.userProfile.user);
            let data = res.data;
            if(data.success) {
                // 更新社交资料
                await this.updateSocial();
                // 更新用户数据
                this.$bus.emit('emitGetMe', {});
                window.open('/profile/' + this.user.username, '_self');
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
            this.updateLoading = false;
        },
        // 编辑登录用户信息
        async updateSocial() {
            await SocialAPI.update(this.userProfile.social);
        },
        
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
</style>