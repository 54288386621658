<template>
	<div class="bg-lightGray h-100">
        <!-- 加载中 -->
        <div class="h-100" v-if="userProfileLoading">
            <v-container class="h-100" align="center">
                <v-row no-gutters align="center">
                    <v-col>
                        <v-progress-circular :size="100" :width="10" indeterminate color="primaryGrey"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- 设置 -->
        <div class="h-100" v-if="!userProfileLoading">
            <v-container class="mb-9">
                <v-card max-width="1400" rounded="0" elevation="0" color="transparent" align="center" class="pt-7 pb-7 mx-auto">
                    <v-card max-width="740" rounded="0" elevation="0" color="transparent" class="mb-8">
                        <div class="text-left text-primaryGrey">
                            <div>
                                <v-icon size="28" icon="mdi:mdi-chevron-left-circle gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                            </div>
                            <h1 class="sub-page-h1 text-grey04">Settings</h1>
                        </div>
                    </v-card>
                    <v-card max-width="740" color="black" elevation="12" align="start" class="pa-8" :rounded="0">
                        <!----------- Email Notifications ----------->
                        <h2 class="body-h4 text-primaryGrey">Email Notifications</h2>
                        <v-divider class="my-7"></v-divider>

                        <!----------- Email ----------->
                        <div class="mt-2">
                            <div v-if="user.email">
                                <v-text-field v-model="user.email" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                            </div>
                            <div v-else>
                                <router-link to="/edit" class="del-underline text-primaryGrey">
                                    <span class="gradient-text-hover body-h4">
                                        Add your email address
                                    </span>
                                </router-link>
                            </div>
                        </div>

                        <!----------- Buy Now Notifications ----------->
                        <h3 class="body-h4 mt-8 mb-3 text-primaryGrey">Buy Now Notifications</h3>
                        <v-row no-gutters>
                            <v-col>
                                <h6 class="body-p text-primaryGrey">Receive email notifications when someone buys your unNFT and when you buy an unNFT via Buy Now.</h6>
                            </v-col>
                            <v-col cols="3">
                                <div class="float-right text-primaryGrey">
                                    <v-switch v-model="setting.buyNow" color="primary" hide-details inset density="compact"></v-switch>
                                </div>
                            </v-col>
                        </v-row>

                        <!----------- For Sale Notifications ----------->
                        <h3 class="body-h4 mt-8 mb-3 text-primaryGrey">For Sale Notifications</h3>
                        <v-row no-gutters>
                            <v-col>
                                <h6 class="body-p text-primaryGrey">Receive email notifications when profiles that you follow list a new unNFT and set a Buy Now price on untrading.org.</h6>
                            </v-col>
                            <v-col cols="3">
                                <div class="float-right text-primaryGrey">
                                    <v-switch v-model="setting.sale" color="primary" hide-details inset density="compact"></v-switch>
                                </div>
                            </v-col>
                        </v-row>

                        <!----------- nFR Notifications ----------->
                        <h3 class="body-h4 mt-8 mb-3 text-primaryGrey">nFR Notifications</h3>
                        <v-row no-gutters>
                            <v-col>
                                <h6 class="body-p text-primaryGrey">Receive email notifications when you receive Originators’ Rewards (ORs) or Future Rewards (FRs) as a former owner of an unNFT, and when you share your profits with a Flow for nFR distritutions.</h6>
                            </v-col>
                            <v-col cols="3">
                                <div class="float-right text-primaryGrey">
                                    <v-switch v-model="setting.nfr" color="primary" hide-details inset density="compact"></v-switch>
                                </div>
                            </v-col>
                        </v-row>

                        <!----------- App Notifications ----------->
                        <h2 class="pt-100 body-h4 text-primaryGrey">App Notifications</h2>
                        <v-divider class="my-7"></v-divider>

                        <!----------- New Follower Notifications ----------->
                        <h3 class="body-h4 mt-8 mb-3 text-primaryGrey">New Follower Notifications</h3>
                        <v-row no-gutters>
                            <v-col>
                                <h6 class="body-p text-primaryGrey">Receive email notifications when someone follows you.</h6>
                            </v-col>
                            <v-col cols="3">
                                <div class="float-right text-primaryGrey">
                                    <v-switch v-model="setting.newFollower" color="primary" hide-details inset density="compact"></v-switch>
                                </div>
                            </v-col>
                        </v-row>

                        <!----------- New Artwork Notifications ----------->
                        <h3 class="body-h4 mt-8 mb-3 text-primaryGrey">New Artwork Notifications</h3>
                        <v-row no-gutters>
                            <v-col>
                                <h6 class="body-p text-primaryGrey">Receive email notifications when profiles that you follow mint a new unNFT on untrading.org.</h6>
                            </v-col>
                            <v-col cols="3">
                                <div class="float-right text-primaryGrey">
                                    <v-switch v-model="setting.newArtwork" color="primary" hide-details inset density="compact"></v-switch>
                                </div>
                            </v-col>
                        </v-row>

                        <!----------- New Likes Notifications ----------->
                        <h3 class="body-h4 mt-8 mb-3 text-primaryGrey">New Likes Notifications</h3>
                        <v-row no-gutters>
                            <v-col>
                                <h6 class="body-p text-primaryGrey">Receive email notifications when you receive a new like on your profile, unNFTs and collections.</h6>
                            </v-col>
                            <v-col cols="3">
                                <div class="float-right text-primaryGrey">
                                    <v-switch v-model="setting.newLikes" color="primary" hide-details inset density="compact"></v-switch>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card max-width="740" elevation="0" color="transparent" align="start">
                        <a class="gradient-left-red-purple-300 del-underline">
                            <v-btn rounded="0" :loading="updateLoading" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b mt-16 mb-8" aria-label="Save Changes" @click="update">Save Changes</v-btn>
                        </a>
                    </v-card>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SettingAPI from '@/api/setting.js';
export default {
    data(){
        return {
            test: false,
            // 加载设置
            userProfileLoading: true,
            // 更新加载中
            updateLoading: false,
            // 设置
            setting: {
                buyNow: true,
                sale: true,
                nfr: true,
                newFollower: true,
                newArtwork: true,
                newLikes: true
            }
        }
    },
    components: {  },
    created(){
        this.getSetting();
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user'])
    },
    watch:{

    },
    methods: {
        // 查询设置
        async getSetting() {
            this.userProfileLoading = true;
            let res = await SettingAPI.getSetting();
            let data = res.data;
            if(data.success) {
                this.setting = data.data;
            }
            this.userProfileLoading = false;
        },
        // 更新设置
        async update() {
            this.updateLoading = true;
            let res = await SettingAPI.setting(this.setting);
            let data = res.data;
            if(data.success) {
                this.getSetting();
            }
            this.updateLoading = false;
        },
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
</style>