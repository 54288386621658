import axios from '@/config/api.request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
    // 判断用户是否有请求解封权限
    hasRequestUnwrapPermission(cryptoId) {
        return axios.request({
            url: '/crypto/unwrap/permission/request',
            method: 'get',
            params: { cryptoId: cryptoId }
        })
    },
    // 判断用户是否有签名解封权限
    hasSignUnwrapPermission(cryptoId) {
        return axios.request({
            url: '/crypto/unwrap/permission/sign',
            method: 'get',
            params: { cryptoId: cryptoId }
        })
    },
    // 判断用户是否有确认解封权限
    hasConfirmUnwrapPermission(cryptoId) {
        return axios.request({
            url: '/crypto/unwrap/permission/confirm',
            method: 'get',
            params: { cryptoId: cryptoId }
        })
    },
    // 请求解封加密货币
    requestUnwrap(data) {
        return axios.request({
            url: '/crypto/unwrap/request',
            method: 'post',
            data: data
        })
    },
    // 签名解封加密货币
    signUnwrap(data) {
        return axios.request({
            url: '/crypto/unwrap/sign',
            method: 'post',
            data: data
        })
    },
    // 解封加密货币
    unwrap(data) {
        return axios.request({
            url: '/crypto/unwrap/unwrap',
            method: 'post',
            data: data
        })
    },
    // 根据加密货币主键查询最新一条签名记录
    getUnwrapSignature(cryptoId) {
        return axios.request({
            url: '/crypto/unwrap/signature',
            method: 'get',
            params: { cryptoId: cryptoId }
        })
    }
}