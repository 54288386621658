<template>
	<div>
        <v-container>
            <!-- Built -->
            <v-row no-gutters justify="center" align="center">
                <v-col cols="12" md="12" sm="12" class="d-flex justify-center text-primaryGrey">
                    Built on
                </v-col>
                <v-col cols="12" md="6" sm="12" class="d-flex justify-center mt-16">
                    <v-img width="24" height="24"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/785aaf1b-77e4-4c87-643c-3cbe14796800/public" contain></v-img>
                    <v-img width="24" height="24"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/12ad746b-ad78-4223-8abb-678a4970b900/public" contain></v-img>
                    <v-img width="24" height="24"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2cf02df1-ea1f-4815-cb23-eb271c056b00/public" contain></v-img>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>