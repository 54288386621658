<template>
	<div v-if="nfts.length > 0">
            <v-col cols="12">
                <v-row no-gutters>
                    <div class="c-container">
                        <v-card color="transparent" max-width="1400" elevation="0" rounded="0">
                            <div class="c-set c-grid">
                                <div class="c-item" v-if="responsive != 'mobile'">
                                    <div class="c-p">
                                        <div class="d-flex c-h-p">
                                            <div class="c-m-b-auto">
                                                <v-row align="center" no-gutters>
                                                    <div class="c-m-b-auto">
                                                        <router-link :to="'/set/' + set.id" class="del-underline" v-if="set.logoImage">
                                                            <v-img :src="set.logoImage" width="144" :aspect-ratio="1" cover></v-img>
                                                        </router-link>
                                                    </div>
                                                    <div class="mt-6">
                                                        <div class="body-p-large font-weight-bold">
                                                            <v-card color="transparent" elevation="0" class="gradient-underline-hover">
                                                                <router-link :to="'/set/' + set.id" class="del-underline text-white gradient-text-hover">
                                                                    <span class="body-p-large font-weight-bold">{{ set.name }}</span>
                                                                </router-link>
                                                            </v-card>
                                                        </div>
                                                        <div class="c-b">
                                                            <v-card elevation="0" max-width="400" class="pa-3 pl-0" rounded="0" color="transparent">
                                                                <div class="d-flex flex-nowrap">
                                                                    <UserAvatar :avatar="set.user.avatar" :username="set.user.username" class="mr-2"></UserAvatar>
                                                                    <span class="gradient-underline-hover">
                                                                        <router-link @click.stop :to="'/profile/' + set.user.username" class="del-underline parent gradient-text-hover">
                                                                            <span class="body-text-btn text-white ellipsis-line">{{ set.user.name }}</span>
                                                                        </router-link>
                                                                    </span>
                                                                </div>
                                                            </v-card>
                                                        </div>
                                                        <div class="algincenter">
                                                            <v-icon icon="mdi mdi-eye-outline" size="18" color="white"></v-icon>
                                                            <span class="body-p-small ml-2 text-white">
                                                                <count-to :start-val="0" :end-val="setStat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                                                            </span>
                                                            <v-icon v-show="!liked" icon="mdi mdi-heart-outline" color="white" size="18" class="ml-10 pointer" @click.stop="like"></v-icon>
                                                            <v-icon v-show="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike"></v-icon>
                                                            <span class="body-p-small ml-2 mr-6 text-white">
                                                                <count-to :start-val="setStat.likeCount" :end-val="setStat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                                                            </span>
                                                            <v-icon icon="mdi mdi-format-list-bulleted-square" size="18" class="ml-2"></v-icon>
                                                            <span class="body-p ml-2 mr-6">
                                                                {{ summary.totalCount }} {{ summary.totalCount > 1 ? 'unNFTs' : 'unNFT' }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </v-row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="c-b c-label c-gap c-grid pr-16 r-p-r-0">
                                    <div class="c-height pb-8 r-p-b-0" v-for="(nft, i) in nfts" :key="i">
                                        <UnNft :nftId="nft.id" :show-details="false"></UnNft>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-row>
            </v-col>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UnNft from '@/components/nft/UnNft';
import NFTAPI from '@/api/nft.js';
import SetAPI from '@/api/set.js';
import SetStatAPI from '@/api/set-stat.js';
import SetLikeAPI from '@/api/set-like.js'
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 套装对象
            set: {
                user: {}
            },
            // 当前套装的NFT集合
            nfts: [],
            // 套装统计数据
            setStat: {
                viewCount: 0,
                likeCount: 0
            },
            // 概括数据
            summary: {},
            // 是否喜欢
            liked: false,
        }
    },
    props: ['setId', 'excludedNftId'],
    components: { UnNft },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive']),
    },
    watch:{
        // 套装主键
        setId: {
            handler(newVal, oldVal) {
                this.getSetById();
                this.getSetStat();
                this.getSummary();
                this.checkLiked();
                this.getSetMoreNFTs();
            },
            immediate: true
        },
        // 排除的 NFT 主键
        excludedNftId: {
            handler(newVal, oldVal) {
                this.getSetMoreNFTs();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询套装
        async getSetById() {
            if(this.loading) {
                return;
            }
            this.loading = true;
            let res = await SetAPI.getSetById(this.setId);
            let data = res.data;
            if(data.success) {
                this.set = data.data;
            }
            this.loading = false;
        },
        // 根据套装主键查询该套装推荐的更多NFT
        async getSetMoreNFTs() {
            let res = await NFTAPI.getSetMoreNFTs(this.setId, this.excludedNftId);
            let data = res.data;
            let nfts = [];
            if(data.success) {
                this.nfts = data.data;
                // 告诉父级组件，有更多推荐
                this.$emit("emitHasMoreNFTs");
            }
        },
        // 查询统计数据
        async getSetStat() {
            let res = await SetStatAPI.setStat(this.setId);
            let data = res.data;
            let stat = {};
            if(data.success) {
                stat = data.data;
            }
            this.setStat = stat;
        },
        // 查询概括数据
        async getSummary() {
            let res = await SetStatAPI.summary(this.setId);
            let data = res.data;
            let summary = {};
            if(data.success) {
                summary = data.data;
            }
            this.summary = summary;
        },
        // 是否喜欢套装
        async checkLiked() {
            let res = await SetLikeAPI.liked(this.setId);
            let data = res.data;
            let liked = false;
            if(data.success) {
                liked = data.data;
            }
            this.liked = liked;
        },
        // 喜欢套装
        async like() {
            // 前端更新数据
            this.setStat.likeCount++;
            this.liked = true;
            // 后端无感刷新
            let res = await SetLikeAPI.like(this.setId);
            let data = res.data;
            // 若响应失败则返回状态
            if(!data.success) {
                this.setStat.likeCount--;
                this.liked = false;
            }
        },
        // 取消喜欢套装
        async unlike() {
            // 前端更新数据
            this.setStat.likeCount--;
            this.liked = false;
            // 后端无感刷新
            await SetLikeAPI.unlike(this.setId);
        },
    }
}
</script>
<style scoped>

</style>