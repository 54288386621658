<template>
	<div>
        <div class="text-grey04">
            <h1 class="text-primary" :class="responsive == 'pc' ? 'crypto-number' : 'mobile-h1'">
                <count-to :start-val="0" :end-val="value" :duration="2000" :decimals="2" separator="," prefix="$"></count-to>
            </h1>
            <div class="mt-2" :class="responsive == 'pc' ? 'body-text-btn' : 'body-p'">Total nFRs Paid to Crypto Department Users</div>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import MarketAPI from '@/api/market.js';
export default {
    data(){
        return {
            // 总价值
            value: 0,
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain', 'responsive']),
    },
    watch:{
        // 监听区块链
        blockchain: {
            handler(n, o) {
                // 查询加密货币产生的总 $ nFR 支付
                this.getCryptoTotalNFRPaid();
            },
            immediate: true
        },
    },
    methods: {
        // 查询加密货币产生的总 $ nFR 支付
        async getCryptoTotalNFRPaid() {
            this.value = 0;
            let res = await MarketAPI.getCryptoTotalNFRPaid(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.value = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>