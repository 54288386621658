<template>
	<div class="h-100 bg-lightGray">
        <v-container class="h-100">
           <v-card color="transparent" max-width="1032" elevation="0" class="mx-auto pt-7">
                 <v-row class="h-100" justify="center">
                    <v-col cols="12" sm="6">
                        <v-icon size="28" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                        <div class="mb-8 text-grey04 d-flex">
                            <h1 class="mr-2 sub-page-h1">Create a Set</h1>
                            <v-menu transition="slide-y-transition" open-on-hover :offset="10">
                                <template v-slot:activator="{ props }" >
                                    <a href="https://docs.untrading.org/tutorials/creating-a-set" target="_blank" class="del-underline">
                                        <v-icon icon="mdi:mdi-help-circle-outline" size="36" class="text-primaryGrey gradient-text-hover ml-3 mt-2" v-bind="props"></v-icon>
                                    </a>
                                </template>
                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                    How to: Creating a SET
                                </v-card>
                            </v-menu>
                        </div>
                        <v-card max-width="560" color="paleCyan" class="pa-8" elevation="2" :rounded="0">
                            <h2 class="sidebar-h2 mt-3 text-primaryGrey">Set information</h2>
                            <v-divider class="my-7"></v-divider>
                            <h3 class="body-h4 text-uppercase text-primaryGrey">Add Name<span class="ml-1 text-red">*</span></h3>
                            <div class="mt-3 body-p mb-6 text-primaryGrey">Can be updated later. </div>
                            <div>
                                <v-text-field clearable v-model="set.name" label="Name *" variant="outlined" hide-details density="comfortable"></v-text-field>
                            </div>
                            <div class="mb-6 mt-10">
                                <h3 class="body-h4 text-uppercase text-primaryGrey">Description</h3>
                            </div>
                            <v-textarea clearable v-model="set.description" label="Description" variant="outlined" hint="" persistent-counter :counter="1000"></v-textarea>
                            <div class="mb-6 mt-10">
                                <h3 class="body-h4 text-uppercase text-primaryGrey">category</h3>
                            </div>
                            <v-select v-model="set.category" label="Category" density="comfortable" :items="categories" variant="outlined"></v-select>
                            <div class="mb-6 mt-10">
                                <h3 class="body-h4 text-uppercase text-primaryGrey">blockchain<span class="ml-1 text-red">*</span></h3>
                            </div>
                            <v-select v-model="set.blockchain" label="Blockchain *" :items="blockchains" item-title="blockchain" item-value="blockchain" variant="outlined" density="comfortable" hide-details>
                                <template v-slot:selection="{ item }" >
                                    <img class="mr-3" :src="item.raw.icon" width="24" />
                                    <span>{{ item?.raw?.blockchain }}</span>
                                </template>
                            </v-select>
                            <div class="mt-10 body-p text-primaryGrey">
                                <span class="text-red mr-2">*</span>Required fields
                            </div>
                        </v-card>
                        <div class="pt-16 pb-16">
                            <a class="gradient-left-red-purple-300 del-underline">
                                <v-btn :loading="createLoading" :rounded="0" elevation="2" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" @click="create" aria-label="Create">Create</v-btn>
                            </a>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" lg="5" class="ml-8 mt-12 r-m-l-0">
                        <v-card max-width="360" min-height="360" elevation="0" class="ma-8 pa-8 r-m-x-0" color="c9c" :rounded="0">
                            <div class="mb-10 d-flex flex-nowrap align-center">
                                <div>
                                    <v-card width="87" height="87" class="bg-grey03" elevation="0" :rounded="0"></v-card>
                                </div>
                                <div class="ml-4 nft-title ellipsis-line text-primaryGrey">
                                    {{ set.name }}
                                </div>
                            </div>
                            <div class="mt-8">
                                <v-card elevation="2" color="grey03" max-width="290" class="pa-3 absolute-bottom-30 ellipsis-line" :rounded="0">
                                    <UserAvatar :avatar="user.avatar" :username="user.username" :name="user.name"></UserAvatar>
                                </v-card>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
           </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SetAPI from '@/api/set.js';
import SetCategoryAPI from '@/api/set-category.js';
export default {
    data(){
        return {
            // 表单
            set: {
                name: null,
                description: null,
                category: null,
                blockchain: null
            },
            // 类别集合
            categories: [],
            // 创建中
            createLoading: false
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        // 查询全部类别
        this.getCategories();
    },
    computed: {
        ...mapGetters(['user', 'blockchains']),
    },
    watch:{

    },
    methods: {
        // 查询全部类别
        async getCategories() {
            let res = await SetCategoryAPI.getCategories();
            let data = res.data;
            if(data.success) {
                this.categories = data.data;
            }
        },
        // 创建套装
        async create() {
            this.createLoading = true;
            let res = await SetAPI.create(this.set);
            let data = res.data;
            if(data.success) {
                // 更新全局套装数据
                this.$bus.emit('emitSets', {});
                // this.$router.push("/set/" + data.data);
                // this.$router.push("/profile/" + this.user.username);
                window.open('/profile/' + this.user.username + '?type=nfts&tab=sets', '_self');
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
            this.createLoading = false;
        }
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>