<template>
    <div>
        <!-- 弹窗 -->
        <v-dialog v-model="dialog" content-class="v-echarts-dialog" width="560" persistent>
            <v-card class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="px-0" :class="responsive == 'mobile' ? '' : 'd-flex'">
                            <div class="sidebar-h2 text-primaryGrey">🎉 Welcome to Our Community! 🎉</div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="dialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="body-p text-primaryGrey my-2 px-0">
                            <div>Congratulations on joining our platform! To make the most of your experience and enjoy all the perks, we have some exciting steps for you to take:</div>
                            <div class="ml-10">
                                <ol>
                                    <li>🐦 Follow us on 
                                        <span class='gradient-underline-hover gradient-underline'>
                                            <a href="https://x.com/untradingOrg" target="_blank" class="text-primaryGrey">X</a>
                                        </span>
                                         for news and updates.
                                    </li>
                                    <li>💬 Join our 
                                        <span class='gradient-underline-hover gradient-underline'>
                                            <a href="https://t.me/untrading" target="_blank" class="text-primaryGrey">Telegram</a>
                                        </span>
                                         group for discussions.
                                    </li>
                                    <li>🎮 Dive into our 
                                        <span class='gradient-underline-hover gradient-underline'>
                                            <a href="https://discord.gg/ducj4H8QY8" target="_blank" class="text-primaryGrey">Discord</a>
                                        </span>
                                         server for vibrant interactions.
                                    </li>
                                </ol>
                            </div>
                            <br/>
                            <div class="font-weight-bold">Perks of joining:</div>
                            <div class="ml-10">
                                <ul>
                                    <li>🏆 Unlock Reward bonuses. </li>
                                    <li>📢 Stay updated on announcements and events.</li>
                                </ul>
                            </div>
                            <br/>
                            <div>Exciting times ahead!</div>
                            <br/>
                            <div>Best,</div>
                            <div>The untrading.org team</div>
                            
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 弹窗
            dialog: false,
        }
    },
    components: {  },
    created(){
        // 监听
        this.$bus.on('callWelcomeDialog', data => {
            this.dialog = data;
        })
    },
    mounted(){
        // 加载弹窗
        this.loadDialog();
    },
    computed: {
        ...mapGetters(['responsive'])
    },
    watch:{
        dialog: {
            handler(n, o) {
                if(!n) {
                    // 关闭弹窗时删除
                    localStorage.removeItem('untrading-welcome');
                }
            }
        },
    },
    methods: {
        // 加载弹窗
        loadDialog() {
            let welcome = localStorage.getItem('untrading-welcome');
            if(welcome == 1) {
                this.dialog = true;
            } else {
                // 删除错误数据
                localStorage.removeItem('untrading-welcome');
                this.dialog = false;
            }
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>