import axios from '@/config/api.request'

export default {
    // 查询货币价格
    getPrices(currency) {
        return axios.request({
            url: '/price/prices',
            method: 'get',
            params: { currency }
        })
    }
}