import axios from '@/config/api.request'

export default {
    // 获取接受的媒体类型
    getAcceptTypes() {
        return axios.request({
            url: '/media/type/accept/types',
            method: 'get'
        })
    },
    // 获取接受的媒体名称
    getAcceptNames() {
        return axios.request({
            url: '/media/type/accept/names',
            method: 'get'
        })
    },
    // 获取 URL 的文件类型
    getFileType(fileUrl) {
        return axios.request({
            url: '/media/type/file/type',
            method: 'get',
            params: { fileUrl: fileUrl }
        })  
    }
}