<template>
	<div>
        <v-container fluid class="px-0">
            <v-row no-gutters justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="3" xl="3" class="text-center px-0 r-m-t-16">
                    <h2 class="text-white03">{{ totalUserRewardsPaid }}{{ totalUserRewardsPaid != 0 ? '+' : '' }}</h2> 
                    <div class="h5 text-primaryGrey">Total User Rewards Paid (UN)</div> 
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3" xl="3" class="text-center px-0 r-m-t-16">
                    <h2 class="text-white03">{{ totalValueLocked }}{{ totalValueLocked != 0 ? '+' : '' }}</h2> 
                    <div class="h5 text-primaryGrey">Total Value Locked (TVL)</div> 
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3" xl="3" class="text-center px-0 r-m-t-16">
                    <h2 class="text-white03">{{ totalUsersCount }}{{ totalUsersCount != 0 ? '+' : '' }}</h2> 
                    <div class="h5 text-primaryGrey">Total Traders</div> 
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3" xl="3" class="text-center px-0 r-m-t-16">
                    <h2 class="text-white03">{{ totalCountriesCount }}{{ totalCountriesCount != 0 ? '+' : '' }}</h2> 
                    <div class="h5 text-primaryGrey">Countries Serviced</div> 
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import MarketAPI from '@/api/market.js';
import UserAPI from '@/api/user.js';
import VisitAPI from '@/api/visit.js';
export default {
    data(){
        return {
            // 总UN用户奖励
            totalUserRewardsPaid: 0,
            // 总锁定价值
            totalValueLocked: 0,
            // 总用户数量
            totalUsersCount: 0,
            // 总国家数量
            totalCountriesCount: 0
        }
    },
    components: {  },
    created(){
        // 查询全站产生的总UN用户奖励
        this.getTotalUserRewardsPaid();
        // 查询加密货币的 $ 总锁定价值
        this.getCryptoTotalValueLocked();
        // 查询全部用户数量
        this.getTotalUsersCount();
        // 查询全部国家数量
        this.getTotalCountriesCount();
    },
    mounted(){

    },
    computed: {
        ...mapGetters([])
    },
    watch:{

    },
    methods: {
        // 查询全站产生的总UN用户奖励
        async getTotalUserRewardsPaid() {
            let res = await MarketAPI.getTotalUserRewardsPaid();
            let data = res.data;
            if(data.success) {
                this.totalUserRewardsPaid = data.data;
                // http://numeraljs.com/
                var numeral = require('numeral');
                this.totalUserRewardsPaid = numeral(this.totalUserRewardsPaid).format('0a').toUpperCase();
            }
        },
        // 查询加密货币的 $ 总锁定价值
        async getCryptoTotalValueLocked() {
            let res = await MarketAPI.getCryptoTotalValueLocked(null);
            let data = res.data;
            if(data.success) {
                this.totalValueLocked = data.data;
                // http://numeraljs.com/
                var numeral = require('numeral');
                this.totalValueLocked = numeral(this.totalValueLocked).format('0a').toUpperCase();
            }
        },
        // 查询全部用户数量
        async getTotalUsersCount() {
            let res = await UserAPI.getTotalUsersCount();
            let data = res.data;
            if(data.success) {
                this.totalUsersCount = data.data;
                if (this.totalUsersCount < 10) {
                    // 小于10则显示原数字
                } else if (this.totalUsersCount < 1000) {
                    // 小于1000则显示整十位
                    this.totalUsersCount = Math.floor(this.totalUsersCount / 10) * 10;
                } else {
                    // http://numeraljs.com/
                    var numeral = require('numeral');
                    this.totalUsersCount = numeral(this.totalUsersCount).format('0a').toUpperCase();
                }
            }
        },
        // 查询全部国家数量
        async getTotalCountriesCount() {
            let res = await VisitAPI.getTotalCountriesCount();
            let data = res.data;
            if(data.success) {
                this.totalCountriesCount = data.data;
                if (this.totalCountriesCount < 10) {
                    // 小于10则显示原数字
                } else if (this.totalCountriesCount < 1000) {
                    // 小于1000则显示整十位
                    this.totalCountriesCount = Math.floor(this.totalCountriesCount / 10) * 10;
                } else {
                    // http://numeraljs.com/
                    var numeral = require('numeral');
                    this.totalCountriesCount = numeral(this.totalCountriesCount).format('0a').toUpperCase();
                }
            }
        }
    }
}
</script>
<style scoped>

</style>