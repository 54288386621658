import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 根据加密货币主键查询历史记录
    getProvenances(cryptoId) {
        return axios.request({
            url: '/crypto/provenance/provenances',
            method: 'get',
            params: { cryptoId }
        })
    },
    // 根据加密货币主键查询价格历史图表
    getPriceHistories(cryptoId) {
        return axios.request({
            url: '/crypto/provenance/history/price',
            method: 'get',
            params: { cryptoId }
        })
    },
    // 根据加密货币主键查询持有历史
    getHoldingHistories(cryptoId) {
        return axios.request({
            url: '/crypto/provenance/history/holding',
            method: 'get',
            params: { cryptoId }
        })
    },
    // 根据加密货币主键查询持有损益
    getPnlHistories(cryptoId) {
        return axios.request({
            url: '/crypto/provenance/history/pnl',
            method: 'get',
            params: { cryptoId }
        })
    },
}