<template>
	<div>
        <!-- 未检测到 MetaMask 钱包 -->
        <v-dialog v-model="metaMaskNotDetectedDialog" persistent content-class="v-echarts-dialog">
            <v-card width="462" class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <div class="sidebar-h2 text-primaryGrey">Web3 wallet not detected</div>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <div class="body-p text-primaryGrey">Please make sure your wallet is unlocked and available. If you do not currently have a Web3 wallet, we suggest <a href="https://metamask.io" aria-label="Metamask" class="gradient-text-hover text-primaryGrey" target="_black">MetaMask</a>.</div>
                                <div class="body-p mt-16">
                                <a href="https://docs.metamask.io/guide/" class="gradient-text-hover text-primaryGrey" target="_black">I need help</a>
                            </div>
                            <div class="mt-16 mb-5 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn :rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Dismiss" @click="metaMaskNotDetectedDialog = false">Dismiss</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 钱包网络错误 -->
        <v-dialog v-model="invaildNetworkDialog" persistent content-class="v-echarts-dialog">
            <v-card width="462" class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters align="center">
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Invaild blockchain network</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <div class="body-p text-primaryGrey">Please select {{ currentBlockchain.blockchain }} in your wallet.</div><br/>
                            <div class="body-p text-primaryGrey mb-16">To add {{ currentBlockchain.blockchain }} to your MetaMask, simply navigate to 
                                <span class='gradient-underline-hover gradient-underline'>
                                    <a :href="currentBlockchain.blockchainExplorerUrl" target="_blank" class="del-underline gradient-text-hover">{{ currentBlockchain.blockchainExplorer }}</a>
                                </span>
                                 and click the "Add {{ currentBlockchain.blockchain }} Network" button in the lower-right corner.</div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn :rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="invaildNetworkDialog = false">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 钱包地址不匹配弹窗 -->
        <v-dialog v-model="walletAddressMismatchDialog" persistent content-class="v-echarts-dialog">
            <v-card width="462" class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters align="center">
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Wallet Address Mismatch</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="px-0">
                            <div class="body-p text-primaryGrey">You sign in address ({{ $tools.privacyField(user.wallet) }}) does not match your wallet address ({{ $tools.privacyField(currentWalletAddress) }}).</div>
                            <div class="body-p mt-3 text-primaryGrey">Please change your wallet account to match the sign in. </div>
                            <div class="body-p mt-3 text-primaryGrey">Or, sign out and then sign in again with the current wallet.</div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn :rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="walletAddressMismatchDialog = false">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import * as Ethers from "ethers";
import UserAPI from '@/api/user.js'
export default {
    data(){
        return {
            // 未检测到 MetaMask 钱包
            metaMaskNotDetectedDialog: false,
            // 无效的网络弹窗
            invaildNetworkDialog: false,
            // 钱包地址不匹配弹窗
            walletAddressMismatchDialog: false,
            // 当前钱包地址
            currentWalletAddress: '',
            // 当前交易的区块链
            currentBlockchain: {}
        }
    },
    components: {  },
    created(){
        // 检查
        this.checkMetaMask();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['metaMask', 'user', 'walletAddress', 'blockchains'])
    },
    watch:{

    },
    methods: {
        // 检查 MetaMask
        async checkMetaMask() {
            if (typeof window.ethereum !== 'undefined') {
                if(ethereum.isMetaMask) {
                    // 监听账户改变
                    window.ethereum.on('accountsChanged', (accounts) => {
                        this.$store.dispatch('walletAddressHandler', accounts[0]);
                    });
                    this.$store.dispatch('metaMaskHandler', window.ethereum);
                }
            }
        },
        // 链接 MetaMask
        connect() {
            if(this.metaMask) {
                this.metaMask.request({
                    method: 'eth_requestAccounts'
                }).then(res => {
                    if (res.length > 0) {
                        this.$store.dispatch('walletAddressHandler', res[0]);
                        this.$emit('emitGetRegisterStatus', null)
                    }
                })
            } else {
                // 未检测到 MetaMask 钱包弹窗
                this.metaMaskNotDetectedDialog = true;
            }
        },
        // 解锁钱包，请求当前的钱包地址
        async requestCurrentWalletAddress() {
            if(this.metaMask) {
                try {
                    let res = await this.metaMask.request({
                        method: 'eth_requestAccounts'
                    });
                    if (res.length > 0) {
                        this.currentWalletAddress = res[0];
                        if(this.user.wallet.toLowerCase() != this.currentWalletAddress.toLowerCase()){
                            // 打开钱包不匹配弹窗
                            this.walletAddressMismatchDialog = true;
                            return new Promise((resolve, reject) => {
                                reject(new Error('Wallet Address Mismatch'));
                            });
                        }
                        return new Promise((resolve, reject) => {
                            resolve(this.currentWalletAddress);
                        });
                    }
                } catch (err) {
                    console.log(err);
                    this.$emit('signError', null)
                    this.$store.dispatch('snackbarMessageHandler', err.message);
                }
            } else {
                // 未检测到 MetaMask 钱包弹窗
                this.metaMaskNotDetectedDialog = true;
            }
        },
        // 签名 MetaMask
        sign(message) {
            // https://docs.metamask.io/guide/signing-data.html#personal-sign
            let params = [this.walletAddress, message];
            this.metaMask.request({
                method: 'personal_sign',
                params: params,
                from: this.walletAddress
            }).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature)
            }).catch(err => {
                // 签名失败
                console.log(err.message)
                this.$emit('signError', null)
                this.$store.dispatch('snackbarMessageHandler', err.message);
            });
        },
        // 签名 MetaMask
        async signTypedData(blockchain, tokenId) {
            // https://docs.metamask.io/guide/signing-data.html#sign-typed-data-v4
            // 解锁钱包，请求当前的钱包地址
            await this.requestCurrentWalletAddress().catch(err => {
                // 钱包地址不匹配时，需要抛出异常来终止继续执行
                this.$emit('signError', null)
                throw err;
                return;
            });
            // 当前交易的区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                this.$emit('signError', null)
                return;
            }
            // Ethers 签名
            let domain = {
                name: "untrading Shared Contract",
                version: "1",
                chainId: currentBlockchain.chainId,
                verifyingContract: currentBlockchain.unNFTContract,
                salt: "0x48cdc51538d5c18ef784aab525d9823a2119e55be5a1288a7a9c675f0f202bdd"
            };
            let types = { 
                Unwrap: [
                    { name: "tokenId", type: "uint256" }
                ]
            };
            let params = { tokenId: tokenId };
            // Connecting to Ethereum: MetaMask: https://docs.ethers.org/v5/getting-started/#getting-started--connecting
            let ethers = new Ethers.providers.Web3Provider(this.metaMask);
            ethers.getSigner()._signTypedData(domain, types, params).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature)
            }).catch(err => {
                // 签名失败
                console.error(err);
                this.$emit('signError', null)
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
            // 钱包签名
            // let msgParams = JSON.stringify({
            //     domain: {
            //         name: "untrading Shared NFT Smart Contract",
            //         version: "1",
            //         chainId: currentBlockchain.chainId,
            //         verifyingContract: currentBlockchain.unNFTContract,
            //         salt: "0x48cdc51538d5c18ef784aab525d9823a2119e55be5a1288a7a9c675f0f202bdd"
            //     },
            //     message: {
            //         tokenId: tokenId
            //     },
            //     primaryType: 'Unwrap',
            //     types: { 
            //         Unwrap: [ 
            //             { name: "tokenId", type: "uint256" }
            //         ]
            //     },
            // });
            // let params = [this.user.wallet, msgParams];
            // this.metaMask.request({
            //     method: 'eth_signTypedData_v4',
            //     params: params,toWrapped
            //     from: this.user.wallet
            // }).then(signature => {
            //     // 签名成功
            //     this.$emit('signSuccess', signature)
            // }).catch(err => {
            //     // 签名失败
            //     this.$emit('signError', null)
            //     this.$store.dispatch('snackbarMessageHandler', err.message);
            // });
        },
        // 签名 MetaMask
        async signUnCryptoTypedData(cryptoWrapped, blockchain, to, tokenId) {
            // https://docs.metamask.io/guide/signing-data.html#sign-typed-data-v4
            // 解锁钱包，请求当前的钱包地址
            await this.requestCurrentWalletAddress().catch(err => {
                // 钱包地址不匹配时，需要抛出异常来终止继续执行
                this.$emit('signError', null)
                throw err;
                return;
            });
            // 当前交易的区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                this.$emit('signError', null)
                return;
            }
            // 切换网络
            if(this.metaMask.chainId != currentBlockchain.chainId) {
                await this.switchNetwork(currentBlockchain.chainId).catch(err => {
                    this.$emit('transactionClose', false, null)
                    // 拒绝切换网络时，需要抛出异常来终止继续执行
                    throw err;
                    return;
                });
            }
            // Ethers 签名
            let domain = {
                name: cryptoWrapped.name,
                version: "1",
                chainId: currentBlockchain.chainId,
                verifyingContract: cryptoWrapped.address,
                salt: "0xc25ebea6dd97ec30f15ce845010d7e9fee0398194f29ee544b5062c074544590"
            };
            let types = { 
                Unwrap: [
                    { name: "to", type: "address" },
                    { name: "tokenId", type: "uint256" }
                ]
            };
            let params = { to: to, tokenId: tokenId };
            // Connecting to Ethereum: MetaMask: https://docs.ethers.org/v5/getting-started/#getting-started--connecting
            let ethers = new Ethers.providers.Web3Provider(this.metaMask);
            ethers.getSigner()._signTypedData(domain, types, params).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature)
            }).catch(err => {
                // 签名失败
                console.error(err);
                this.$emit('signError', null)
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
        },
        // 发送交易
        async sendTransaction(blockchain, from, to, data, value) {
            // 判断登录过期时间
            let res = await UserAPI.getExpiration();
            let expirationData = res.data;
            if(expirationData.success) {
                let expiration = expirationData.data;
                // 过期时间在1小时以内的，直接重新登录
                if(expiration - new Date().getTime() < 60 * 60 * 1000) {
                    this.$emit('transactionClose', false, null)
                    this.$store.commit("tokenHandler", null);
                    this.$router.push("/connectwallet?redirectUrl=" + window.location.href);
                    return;
                }
            }
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            if(this.metaMask) {
                // 解锁钱包，请求当前的钱包地址
                await this.requestCurrentWalletAddress().catch(err => {
                    // 钱包地址不匹配时，需要抛出异常来终止继续执行
                    this.$emit('transactionClose', false, null)
                    throw err;
                    return;
                });
                // Chainlist: https://chainlist.org/
                let web3 = new Web3(this.metaMask);
                // let chainId = web3.utils.hexToNumber(this.metaMask.chainId);
                // 切换网络
                if(this.metaMask.chainId != this.currentBlockchain.chainId) {
                    await this.switchNetwork(this.currentBlockchain.chainId).catch(err => {
                        this.$emit('transactionClose', false, null)
                        // 拒绝切换网络时，需要抛出异常来终止继续执行
                        throw err;
                        return;
                    });
                }
                // Gas 上限: Mumbai 设置上限 100万个gas
                let gasLimit = null;
                // if(this.currentBlockchain.blockchain == 'Mumbai') {
                //     gasLimit = web3.utils.toHex(1000000);
                // }
                // 发送交易
                this.metaMask.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        from: from,
                        to: to,
                        gas: gasLimit,
                        data: data,
                        value: value
                    }]
                }).then(res => {
                   // 交易成功
                   console.log(res);
                    // res是该交易哈希值
                    this.$emit('transactionClose', true, res)
                }).catch(err => {
                    console.error(err);
                    this.$emit('transactionClose', false, null)
                    // -32603: Gas 太小错误
                    if(err.code == -32603) {
                        this.$store.dispatch('snackbarMessageHandler', "Transaction underpriced");
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', err.message);
                    }
                });
            }
        },
        // 切换网络
        async switchNetwork(chainId){
            if(this.metaMask) {
                await this.metaMask.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }],
                }).catch(err => {
                    this.$store.dispatch('snackbarMessageHandler', err.message);
                    // 如果错误代码为 4902 ，则 MetaMask 尚未添加请求的链，您必须使用 wallet_addEthereumChain 请求添加它。
                    // https://docs.metamask.io/wallet/reference/rpc-api/#wallet_addethereumchain
                    if (err.code == 4902) {
                        this.invaildNetworkDialog = true;
                    }
                    throw err;
                });
            }
        },
        // 批准
        async approve(blockchain, from, to, data, value) {
            // 判断登录过期时间
            let res = await UserAPI.getExpiration();
            let expirationData = res.data;
            if(expirationData.success) {
                let expiration = expirationData.data;
                // 过期时间在1小时以内的，直接重新登录
                if(expiration - new Date().getTime() < 60 * 60 * 1000) {
                    this.$emit('approveTransactionClose', false, null)
                    this.$store.commit("tokenHandler", null);
                    this.$router.push("/connectwallet?redirectUrl=" + window.location.href);
                    return;
                }
            }
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            if(this.metaMask) {
                // 解锁钱包，请求当前的钱包地址
                await this.requestCurrentWalletAddress().catch(err => {
                    // 钱包地址不匹配时，需要抛出异常来终止继续执行
                    this.$emit('approveTransactionClose', false, null)
                    throw err;
                    return;
                });
                // Chainlist: https://chainlist.org/
                let web3 = new Web3(this.metaMask);
                // 切换网络
                if(this.metaMask.chainId != this.currentBlockchain.chainId) {
                    await this.switchNetwork(this.currentBlockchain.chainId).catch(err => {
                        this.$emit('approveTransactionClose', false, null)
                        // 拒绝切换网络时，需要抛出异常来终止继续执行
                        throw err;
                        return;
                    });
                }
                // 发送交易
                this.metaMask.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        from: from,
                        to: to,
                        data: data,
                        value: value
                    }]
                }).then(res => {
                   // 交易成功
                   console.log(res);
                    // res是该交易哈希值
                    this.$emit('approveTransactionClose', true, res)
                }).catch(err => {
                    console.error(err);
                    this.$emit('approveTransactionClose', false, null)
                    // -32603: Gas 太小错误
                    if(err.code == -32603) {
                        this.$store.dispatch('snackbarMessageHandler', "Transaction underpriced");
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', err.message);
                    }
                });
            }
        },
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>