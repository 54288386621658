<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-5 r-m-l-0 text-primaryGrey">
                    <h4>Sets</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 r-m-l-0 mt-1 mb-10"></v-divider>
                <v-carousel v-model="carouselIndex" :continuous="false" :show-arrows="false" interval="5000" height="546" hide-delimiter-background color="d7d" delimiter-icon="mdi:mdi-circle" class="carousel-item-active">
                    <v-carousel-item v-for="set in sets" :key="set.id">
                        <v-card class="ml-5 r-m-l-0" elevation="0" rounded="0" color="transparent">
                            <!-- <v-img v-if="set.logoImage" :src="set.logoImage" class="white--text align-end" width="245" cover></v-img>
                            <v-img v-if="set.coverImage" :src="set.coverImage" class="white--text align-end" width="245" cover></v-img>
                            <v-img v-else class="white--text align-end" width="245" cover></v-img> -->
                            <SetAvatar :avatar="set.logoImage" :setId="set.id" :size="380" :aspect-ratio="1"></SetAvatar>
                            <div class="mt-6 body-h4 text-primaryGrey">
                                <!-- {{ set.name }} -->
                                <span class="gradient-underline-hover ml-2">
                                    <router-link :to="'/set/' + set.id" class="del-underline gradient-text-hover">
                                        <span>{{ set.name }}</span>
                                    </router-link>
                                </span>
                            </div>
                            <div class="mt-6">
                                <UserAvatar :avatar="set.user.avatar" :username="set.user.username" :name="set.user.name"></UserAvatar>
                            </div>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SetAPI from '@/api/set.js';
export default {
    data(){
        return {
            sets: [],
            // 轮播图的下标
            carouselIndex: 0,
        }
    },
    components: {  },
    created(){
        this.getTopSets();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchain'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.getTopSets();
        }
    },
    methods: {
        // 查询最佳的套装列表
        async getTopSets() {
            let res = await SetAPI.getTopSets(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.sets = data.data;
            } 
        },
    }
}
</script>
<style scoped>

</style>