import axios from 'axios'
import store from '@/store'
import router from '@/router'

class HttpRequest {
  constructor (baseUrl = baseURL) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        //
      }
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // 添加全局的loading...
      if (!Object.keys(this.queue).length) {
      }
      this.queue[url] = true
      // 让每个请求携带自定义token 
      config.headers['Authorization'] = 'Bearer ' +  store.getters.token,
      // 平台：浏览器
      config.headers['Platform'] = 'BROWSER' 
      config.headers['Referrer-Policy'] = 'strict-origin' 
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data, status } = res
      if(data.code == 401 || data.code == 403){
        router.push({ 
            name: 'ConnectWallet',
            query: {
                redirectUrl: window.location.href
            }
        });
      }
      if(data.code == 407){
        router.push({ name: 'VerifyViaTwitter' });
      }
      return { data, status }
    }, error => {
      this.destroy(url)
      let errorInfo = error.response
      if (!errorInfo) {
        const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
        errorInfo = {
          statusText,
          status,
          request: { responseURL: config.url }
        }
      }
      store.dispatch('snackbarMessageHandler', 'You are offline!')
      return Promise.reject(error)
    })
  }
  request (options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
export default HttpRequest
