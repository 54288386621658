import axios from '@/config/api.request'

export default {
    // 根据 NFT 主键查询黑名单集合
    getBlacklistByNftId(nftId) {
        return axios.request({
            url: '/nft/blacklist/blacklists',
            method: 'get',
            params: { nftId: nftId }
        })
    }
}