<template>
    <div>
        <v-container class="px-0">
            <v-card color="transparent" elevation="0" max-width="1400" class="mx-auto pt-16"> 
                <v-row no-gutters>
                    <div class="c-container d-flex flex-nowrap">
                        <div class="c-content-p c-grid">
                            <div class="c-item-p">
                                <div class="c-p">
                                    <div class="d-flex c-h-p">
                                        <div class="c-m-b-auto">
                                            <!-- 头像 -->
                                            <v-avatar size="144" class="bg-lightGray"></v-avatar>
                                            <v-card width="320" elevation="0" class="mt-n88 pt-88 pb-9" :rounded="0" color="transparent">
                                                <div class="mt-6">
                                                    <!-- 钱包 -->
                                                    <div class="d-flex align-center">
                                                        <v-card class="bg-lightGray" width="300" height="30" rounded="0" elevation="0"></v-card>
                                                    </div>
                                                    <!-- 名称 -->
                                                    <div class="parent mt-6">
                                                        <v-card class="bg-lightGray" width="150" height="50" rounded="0" elevation="0"></v-card>
                                                    </div>
                                                    <!-- 用户名 -->
                                                    <div class="body-p mt-6 text-primaryGrey">
                                                        <v-card class="bg-lightGray" width="120" height="30" rounded="0" elevation="0"></v-card>
                                                    </div>
                                                    <v-divider class="my-7"></v-divider>
                                                    <!-- 关注 -->
                                                    <div class="d-flex justify-start mt-9">
                                                        <v-card class="bg-lightGray" width="300" height="80" rounded="0" elevation="0"></v-card>
                                                    </div>
                                                    <!-- Twitter -->
                                                    <div class="mt-16 d-flex align-center">
                                                        <v-card class="bg-lightGray" width="200" height="30" rounded="0" elevation="0"></v-card>
                                                    </div>
                                                    <!-- BIO -->
                                                    <div class="mt-16">
                                                        <v-card class="bg-lightGray" width="300" height="30" rounded="0" elevation="0"></v-card>
                                                        <v-card class="bg-lightGray mt-1" width="300" height="30" rounded="0" elevation="0"></v-card>
                                                        <v-card class="bg-lightGray mt-1" width="150" height="30" rounded="0" elevation="0"></v-card>
                                                    </div>
                                                    <!-- 社交链接 -->
                                                    <div class="my-16">
                                                        <v-avatar size="24" class="bg-lightGray"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                        <v-avatar size="24" class="bg-lightGray ml-1"></v-avatar>
                                                    </div>
                                                    <v-divider class="my-2"></v-divider>
                                                    <!-- 加入时间 -->
                                                    <v-card class="bg-lightGray" width="200" height="30" rounded="0" elevation="0"></v-card>
                                                </div>
                                            </v-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="c-b c-label-p c-gap c-grid">
                            <div class="c-height d-flex flex-column">
                                <v-row no-gutters class="pr-4">
                                    <v-card class="w-100 px-0 pt-72" elevation="0" color="transparent">
                                        <!---------------------------- type ---------------------------->
                                        <v-row no-gutters>
                                            <v-col cols="9">
                                                <div class="d-flex">
                                                    <v-card class="bg-lightGray" width="200" height="50" rounded="0" elevation="0"></v-card>
                                                    <v-card class="bg-lightGray ml-5" width="200" height="50" rounded="0" elevation="0"></v-card>
                                                </div>
                                            </v-col>
                                            <v-divider class="mr-6"></v-divider>
                                        </v-row>
                                        <!---------------------------- tab ---------------------------->
                                        <v-row no-gutters class="pt-10">
                                            <v-col cols="9">
                                                <div class="d-flex">
                                                    <v-card class="bg-lightGray" width="150" height="50" rounded="0" elevation="0"></v-card>
                                                    <v-card class="bg-lightGray ml-5" width="150" height="50" rounded="0" elevation="0"></v-card>
                                                    <v-card class="bg-lightGray ml-5" width="150" height="50" rounded="0" elevation="0"></v-card>
                                                    <v-card class="bg-lightGray ml-5" width="150" height="50" rounded="0" elevation="0"></v-card>
                                                </div>
                                            </v-col>
                                            <v-divider class="mr-6"></v-divider>
                                        </v-row>
                                        <v-card-text class="px-0 pt-3 mr-5">
                                            <div class="c-container">
                                                <div class="c-p-card c-grid d-flex flex-column">
                                                    <SkeletonUnNFT :length="6"></SkeletonUnNFT>
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {

        }
    },
    components: { SkeletonUnNFT },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>