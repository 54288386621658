import axios from '@/config/api.request'

export default {
    // 查询加密货币全部产品
    getProducts() {
        return axios.request({
            url: '/crypto/product/products',
            method: 'get'
        })
    },
}