import axios from '@/config/api.request'

export default {
    // 领取 UN
    claim(data) {
        return axios.request({
            url: '/un/claim/claim',
            method: 'post',
            data: data
        })
    },
    // 查询领取记录
    getClaimedRecords() {
        return axios.request({
            url: '/un/claim/records',
            method: 'get'
        })
    },
}