import axios from '@/config/api.request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
    // 查询NFR统计数据
    getNfrStat(blockchain) {
        return axios.request({
            url: '/nfr/stat',
            method: 'get',
            params: { 
                blockchain: blockchain
            }
        })
    },
    // 分页查询活跃的NFR列表
    getActiveNfrsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nfr/nfrs/active',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 分页查询完成的NFR列表
    getCompletedNfrsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nfr/nfrs/completed',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 根据NFT主键查询NFR信息
    getNfrByNftId(nftId) {
        return axios.request({
            url: '/nfr/nfr',
            method: 'get',
            params: {
                nftId: nftId
            }
        })
    },
    // 根据NFT主键和用户主键查询OR信息
    getORPayment(nftId) {
        return axios.request({
            url: '/nfr/payment/ors',
            method: 'get',
            params: {
                nftId: nftId
            }
        })
    },
    // 根据NFT主键和用户主键查询FR信息
    getFRPayment(nftId, userId) {
        return axios.request({
            url: '/nfr/payment/frs',
            method: 'get',
            params: {
                nftId: nftId,
                userId: userId
            }
        })
    },
    // 根据NFT主键和用户主键查询nFR支付记录
    getNFRPayout(nftId, userId) {
        return axios.request({
            url: '/nfr/payout/nfrs',
            method: 'get',
            params: {
                nftId: nftId,
                userId: userId
            }
        })
    },
    // 分页查询封装的NFR列表
    getWrappedNfrsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nfr/nfrs/wrapped',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 分页查询解封的NFR列表
    getUnwrappedNfrsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nfr/nfrs/unwrapped',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    }
}