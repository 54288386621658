import axios from '@/config/api.request'

export default {
    // 单个文件上传
    upload(formData) {
        return axios.request({
            url: '/file/upload',
            headers: {'Content-Type': 'multipart/form-data'},
            method: 'post',
            data: formData,
        })
    }
}