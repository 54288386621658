import axios from '@/config/api.request'

export default {
    // 根据收藏夹主键查询统计数据
    collectionStat(collectionId) {
        return axios.request({
            url: '/collection/stat/stat',
            method: 'get',
            params: { collectionId: collectionId }
        })
    },
    // 浏览收藏夹
    view(collectionId) {
        return axios.request({
            url: '/collection/stat/view',
            method: 'get',
            params: { collectionId: collectionId }
        })
    },
    // 根据收藏夹主键查询概括数据
    summary(collectionId) {
        return axios.request({
            url: '/collection/stat/summary',
            method: 'get',
            params: { collectionId: collectionId }
        })
    }
}