<template>
	<div>
       <v-row no-gutters> 
            <v-col v-for="(item, index) in cards" :key="index" cols="3" class="mx-n2">
                <v-card width="200"  v-show="this.darkTheme == 1"  color="white" class="my-4">
                    <a :href="item.link" target="_blank">
                        <v-img :src="item.image" height="80px" class="mx-4"></v-img>
                    </a>
                </v-card>
                <v-card width="200" v-show="this.darkTheme == 0"  class="py-4" variant="text">
                    <a :href="item.link" target="_blank">
                        <v-img :src="item.image" height="80px"></v-img>
                    </a>
                </v-card>
            </v-col>
       </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            cards: [
                {
                title: "benzinga",
                image: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/acfdd4bf-b8eb-4389-0ffc-238dba043f00/public',
                link:'https://www.benzinga.com/pressreleases/24/04/38366417/singapore-based-untrading-debuts-crypto-provenance-value-amplification-pva-system-on-testnet'
                },
                {
                title: "coinmarketcap",
                image: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c873fb49-4151-43d2-bc55-8be614b8e000/public',
                link:  'https://coinmarketcap.com/community/articles/66b52ab26023986fba122a58/'
                },
                {
                title: "cryptodaily",
                image: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/50cb023f-5a16-45ff-2e13-3ea043836f00/public',
                link:'https://cryptodaily.co.uk/2024/08/the-untrading-ecosystem-a-paradigm-shift-in-trading-and-asset-management'
                },
                {
                title: "crypto.news",
                image: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/cda695d7-ecab-4660-08b5-a984caa5c200/public',
                link:'https://crypto.news/teenage-crypto-executive-slams-lazy-nfts-forecasts-drastic-changes-ahead/'
                },
                {
                title: "hackernoon",
                image: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/34b74312-5db8-4059-36a6-76b6fa723a00/public',
                link:  'https://hackernoon.com/exploring-erc-5173-nfr-future-rewards-contract-with-untrading-cto-and-co-founder-yale-reisoleil-jr'
                },
                {
                title: "p2e.game",
                image: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/49821b75-300a-4fb9-d4c2-af826dbe7100/public',
                link:'https://www.p2e.game/dailyNews/4xo97w028bnw'
                }
            ]
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>