<template>
    <div>
        <span class="flex-nowrap algincenter" :class="responsive == 'pc' ? 'flex-nowrap algincenter ' : ''">
            <router-link @click.stop v-if="username" :to="'/profile/' + username" class="del-underline">
                <v-avatar icon :size="size" class="pointer">
                    <v-icon v-if="!avatar" color="primary" aria-label="account-circle-outline" icon="mdi:mdi-account-circle-outline" :size="size"></v-icon>
                    <v-img v-if="avatar" :src="avatar" cover :height="size">
                        <slot  />
                    </v-img>
                </v-avatar>
            </router-link>
            <v-avatar v-if="!username" icon :size="size" class="del-underline">
                <v-icon v-if="!avatar" color="primary"  aria-label="account-circle-outline" icon="mdi:mdi-account-circle-outline" :size="size"></v-icon>
                <v-img v-if="avatar" :src="avatar" cover :height="size">
                    <slot  />
                </v-img>
            </v-avatar>
            <span class="ml-2 mt-2" :class="[underlineColor == 'gradient-underline-hover' ? 'gradient-underline-hover' : underlineColor]" v-if="name">
                <router-link @click.stop :to="'/profile/' + username" class="del-underline parent" :class="textColor == 'text-primaryGrey' ? 'gradient-text-hover' : ''">
                    <span class="body-p ellipsis-line" :class="textColor">{{ name }}</span>
                </router-link>
            </span>
        </span>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            
        }
    },
    props: {
        username: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        avatar: {
            type: String,
            default: null
        },
        size: {
            type: Number,
            default: 36
        },
        textColor: {
            type: String,
            default: 'text-primaryGrey'
        },
        underlineColor: {
            type: String,
            default: 'gradient-underline-hover'
        }
    },
    components: {  },
    created(){
        
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['responsive']),
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>