<template>
    <!-- 剪贴板 -->
    <!-- 使用示例：<Clipboard :text="text">{{ text }}</Clipboard> -->
	<!-- <span :id="id" class="pointer" @click="copy" @mouseleave="restoreTooltip">
        <v-tooltip activator="parent" location="bottom">{{ tooltip }}</v-tooltip>
        <slot  />
    </span> -->
    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom">
        <template v-slot:activator="{ props }" >
            <span v-bind="props" :id="id" class="pointer" @click="copy" @mouseleave="restoreTooltip">
                <slot  />
            </span>
        </template>
        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
           {{ tooltip }}
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: 'Clipboard',
    data(){
        return {
            id: 'clipboard-' + Math.random(),
            tooltip: 'Click to copy!'
        }
    },
    props: ['text'],
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 恢复提示
        restoreTooltip() {
            setTimeout(() => {
                this.tooltip = 'Click to copy!'
            }, 500);
        },
        // 复制
        copy() {
            const input = document.createElement('input')
            document.body.appendChild(input)
            // input.setAttribute('value', document.getElementById(this.id).innerText)
            input.setAttribute('value', this.text)
            input.select()
            if (document.execCommand('copy')) {
                // 复制成功
                this.tooltip = 'Copied!';
            }
            document.body.removeChild(input)
        }
    }
}
</script>
<style scoped>

</style>