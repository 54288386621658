<template>
	<div>
        <v-container class="mb-9 text-primaryGrey">
            <v-card max-width="1400" elevation="0" color="transparent" align="center" class="pt-7 pb-7 mx-auto">
                <v-card max-width="1400" color="transparent" rounded="0" elevation="0" class="mb-8">
                    <div class="text-left">
                        <div>
                            <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                        </div>
                        <div class="d-flex justify-space-between">
                            <h1 class="sub-page-h1">
                                <span>Kingdom Rewards</span>
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom" v-if="$config.env != 'prod'">
                                    <template v-slot:activator="{ props }">
                                        <v-icon v-bind="props" icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover ml-3" size="36"></v-icon>
                                    </template>
                                    <v-card max-width="600" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <b>Please note: </b>The testnet rewards displayed here, including test USDC, USDT, and WBTC, are for practice, not real rewards. To view and claim your actual UN Token Rewards you have earned here on the testnet, in addition to any rewards you have earned on the mainnet, head over to 
                                        <span class="gradient-underline-hover gradient-underline">
                                            <a :href="`https://untrading.org/profile/${user?.username}`" target="_blank" class="text-primaryGrey gradient-text-hover">https://untrading.org/</a>
                                        </span>
                                        and log in with the same wallet you're using here.
                                    </v-card>
                                </v-menu>
                            </h1>
                            <router-link to="/un" class="del-underline gradient-left-red-purple-142 pr-1 pb-1">
                                <v-btn rounded="0" elevation="2" variant="flat" aria-label="Claim UN Tokens" color="button01" class="text-none text-grey05 body-p-small-b" width="142px" height="48px">Claim UN Tokens</v-btn>
                            </router-link>
                        </div>
                    </div>
                </v-card>
                <v-card max-width="1400" color="transparent" rounded="0" elevation="0">
                     <v-data-table-server :headers="headers" :items="rewards" :items-length="total" :page="page" :items-per-page="size" :loading="loading" item-value="name" class="body-p text-primaryGrey" color="transparent" density="compact" @update:page="updatePage" @update:itemsPerPage="updateSize">
                        <template v-slot:headers>
                            <tr class="body-h5 px-0">
                                <td style="width: 50px" class="pl-0">No.</td>
                                <td style="width: 280px">Time</td>
                                <td style="width: 120px">Sequence</td>
                                <td style="width: 100px" class="text-right">Amount</td>
                                <td style="width: 150px" class="text-right">Quantity</td>
                                <td style="width: 200px" class="text-right">Balance</td>
                                <td style="width: 250px">Action</td>
                                <td>Item</td>
                            </tr>
                            <tr>
                                <td colspan="8" class="px-0">
                                    <v-divider color="primaryGrey" :thickness="3"></v-divider>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:item="{ index, item }">
                            <tr>
                                <td class="pl-0">{{ (page - 1) * size + index + 1 }}</td>
                                <td>{{ $tools.formatEngLishDatetime(item.timestamp) }}</td>
                                <td>{{ `Kingdom #${item.sequence}` }}</td>
                                <td class="text-right" :class="item.amount < 0 ? 'text-red' : 'text-primaryGrey'">{{ $tools.formatAmount(item.amount, 2) }}</td>
                                <td class="text-right" :class="item.quantity < 0 ? 'text-red' : 'text-primaryGrey'">{{ $tools.formatQuantity(item.quantity, 4) }}</td>
                                <td class="text-right">{{ $tools.formatNumber(item.balance, 4) }}</td>
                                <td>{{ item.userRewardConfigName }}</td>
                                <td>
                                    <span v-if="item.modelType == 'USER'">
                                        <UserAvatar :avatar="item.user.avatar" :username="item.user.username" :name="item.user.name" :size="24"></UserAvatar>
                                    </span>
                                    <span v-else-if="item.modelType == 'NFT'">
                                        <UnNftAvatar :size="24" :avatar="item.nft.fileThumbnail" :nftId="item.nft.id" :blockchain="item.nft.blockchain" :name="item.nft.name ? item.nft.name : '--'"></UnNftAvatar>
                                    </span>
                                    <span v-else-if="item.modelType == 'SET'">
                                        <SetAvatar :size="24" :avatar="item.set.logoImageThumbnail" :setId="item.set.id" :name="item.set.name ? item.set.name : '--'"></SetAvatar>
                                    </span>
                                    <span v-else-if="item.modelType == 'COLLECTION'">
                                        <CollectionAvatar :size="24" :avatar="item.collection.logoImageThumbnail" :collectionId="item.collection.id" :name="item.collection.name ? item.collection.name : '--'"></CollectionAvatar>
                                    </span>
                                    <span v-else-if="item.modelType == 'CRYPTO'">
                                        <span v-if="item.crypto?.tokenId == null">Unknown item</span>
                                        <span v-else class="font-weight-bold gradient-underline-hover">
                                            <router-link :to="`/uncrypto/${item.crypto?.wrappedCurrency?.toLowerCase()}/${item.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                {{ item.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(item.crypto?.tokenId, 0) }}
                                            </router-link>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:loading>
                            <v-skeleton-loader :type="`table-row@${size}`" color="transparent"></v-skeleton-loader>
                        </template>
                        <template v-slot:no-data>
                            <NoDataAvailable></NoDataAvailable>
                        </template>
                        <template v-slot:tfoot>
                            <tr>
                                <td colspan="8">
                                    <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:bottom>
                            <v-data-table-footer key="title" :items-per-page-options="perPageOptions"></v-data-table-footer>
                        </template>
                    </v-data-table-server>
                </v-card>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UserRewardKingdomAPI from '@/api/user-reward-kingdom.js';
export default {
    data () {
      return {
            loading: false,
            headers: [
                { title: 'No.', align: 'start', sortable: false },
                { title: 'Time', align: 'start', key: 'timestamp', sortable: false },
                { title: 'Sequence', align: 'start', key: 'sequence', sortable: false },
                { title: 'Amount', align: 'end', key: 'amount', sortable: false },
                { title: 'Sequence', align: 'start', key: 'sequence', sortable: false },
                { title: 'Quantity', align: 'end', key: 'quantity', sortable: false },
                { title: 'Balance', align: 'end', key: 'balance', sortable: false },
                { title: 'Action', align: 'start', key: 'userRewardConfigName', sortable: false },
                { title: 'Item', align: 'start', key: 'modelType', sortable: false },
            ],
            rewards: [],
            perPageOptions: [
                { title: '10', value: 10 },
                { title: '20', value: 20 },
                { title: '50', value: 50 },
                { title: '100', value: 100 }
            ],
            page:1,
            size: 20,
            total: 0,
         }
    },
    watch: {

    },
    components: {  },
    mounted () {
        this.getRewardsByPage();
    },
    computed: {
        ...mapGetters(['responsive', 'user']),
    },
    methods: {
        async getRewardsByPage() {
            if(this.loading) {
                return;
            }
            this.rewards = [];
            this.loading = true;
            let res = await UserRewardKingdomAPI.getRewardsByPage(this.page, this.size, null);
            let data = res.data;
            if(data.success) {
                this.total = data.data.total;
                this.rewards = data.data.records;
            } 
            this.loading = false;
        },
        updatePage(page) {
            this.page = page;
            this.getRewardsByPage();
        },
        updateSize(size) {
            this.page = 1;
            this.size = size;
            this.getRewardsByPage();
        }
    }
}
</script>
<style scoped>

</style>