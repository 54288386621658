<template>
	<div>
        <v-menu location="right" open-on-hover :offset="20">
            <template v-slot:activator="{ props }">
                <div v-bind="props" class="pointer">
                    <span>Collection</span>
                    <v-icon icon="fa-solid fa-angle-right" class="ml-2 float-right" size="18"></v-icon>
                </div>
            </template>
            <v-list>
                <v-list-item class="form-text text-primaryGrey">
                    <v-list-item-title>
                        <span class="ml-2">Add to</span>
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                
                <!-- --------------- 已有的收藏夹 --------------- -->
                <div v-for="(collection, i) in collections" :key="collection.id">
                    <v-list-item class="form-text text-primaryGrey pointer" @click="moveNftToCollection(collection.id)">
                        <v-list-item-title class="d-flex flex-nowrap">
                            <Blockchain :blockchain="collection.blockchain" :size="28"></Blockchain>
                            <CollectionAvatar class="ml-2" :avatar="collection.logoImage" :collectionId="collection.id" :size="28"></CollectionAvatar>
                            <span class="ml-2">{{ collection.name }}</span>
                            <v-icon icon="mdi:mdi-check" class="ml-2 float-right" size="24" v-if="currentCollection.id == collection.id"></v-icon>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>

                <!-- --------------- 创建 --------------- -->
                <router-link to="/collection/create" class="del-underline">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>
                            <v-icon class="ml-11" icon="mdi:mdi-plus" size="28"></v-icon>
                            <span class="ml-2">Create a Collection</span>
                        </v-list-item-title>
                    </v-list-item>
                </router-link>

            </v-list>
        </v-menu>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CollectionAPI from '@/api/collection.js';
export default {
    data(){
        return {
            // 当前 NFT 的收藏夹
            currentCollection: {}
        }
    },
    props: {
        // NFT 主键
        nftId: {
            type: String,
            default: null
        },
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['collections']),
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询收藏夹
                this.getCollectionByNftId();
            },
            immediate: true
        }
    },
    methods: {
        // 移动 NFT 到收藏夹
        async moveNftToCollection(collectionId) {
            let res = await CollectionAPI.moveNftToCollection(this.nftId, collectionId);
            let data = res.data;
            if(data.success) {
                // 如果在收藏夹页面，则通知刷新数据
                this.$bus.emit('emitRefresh', {});
            }
            this.$store.dispatch('snackbarMessageHandler', data.message);
        },
        // 根据 NFT 主键查询收藏夹
        async getCollectionByNftId() {
            if(this.nftId == null) {
                return;
            }
            let res = await CollectionAPI.getCollectionByNftId(this.nftId);
            let data = res.data;
            if(data.success) {
                this.currentCollection = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>