<template>
	<div>
       <v-card max-width="500" elevation="0" rounded="0" color="transparent" class="text-primaryGrey">
        <v-row no-gutters>
            <v-col cols="12" class="pa-0">
                <div class="body-text-btn">
                   nFR Payouts
                </div>
            </v-col>
            <v-divider class="my-4"></v-divider>
        </v-row>
        <v-row no-gutters class="mt-8">
            <v-col cols="12" class="pa-0">
                <div class="body-text-btn">
                   OR paid to the Originators
                </div>
            </v-col>
            <v-divider class="my-4"></v-divider>
            <v-col cols="12">
                <SkeletonCard :height="50" :length="2" v-if="loading"></SkeletonCard>
                <table class="bg-f2f text-primaryGrey mx-0" v-if="!loading">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                            <td class="form-text text-right px-0">To</td>
                            <td class="text-right px-0 form-text">Amount</td>
                            <td class="text-right px-0 form-text">Currency</td>
                        </tr>
                    </thead>
                    <tbody v-if="nfrPayout.originatorRewardPayouts.length > 0">
                        <tr class="body-p-small" v-for="payout in nfrPayout.originatorRewardPayouts" :key="payout.index">
                            <td></td>
                            <td></td>
                            <td class="body-p-small text-right px-0">
                                <span v-if="payout.paid" class="d-flex flex-nowrap justify-end">
                                    <span class="mr-2">{{ payout.to.name }}</span>
                                    <UserAvatar :avatar="payout.to.avatar" :username="payout.to.username" :size="24"></UserAvatar>
                                </span>
                            </td>
                            <td class="text-right body-p-small px-0">
                                <span v-if="payout.paid">
                                    <span v-if="payout.amount > 0">
                                        <span>{{ $tools.formatNumber(payout.amount, 8) }}</span>
                                        <span v-if="user.id == payout.to.id"> *</span>
                                    </span>
                                    <span v-else>--</span>
                                    <!-- {{ payout.amount > 0 ? $tools.formatNumber(payout.amount, 8) : '--' }} -->
                                </span>
                            </td>
                            <td class="text-right body-p-small px-0">
                                <span v-if="payout.paid">
                                    {{ payout.paymentToken }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th class="form-text px-0">Sub Total</th>
                            <th></th>
                            <th></th>
                            <th class="text-right form-text px-0">
                                {{ $tools.formatNumber(nfrPayout.originatorRewardSubTotal.amount, 8) }}
                            </th>
                            <th class="text-right body-p-small px-0">
                                {{ nfrPayout.originatorRewardSubTotal.paymentToken }}
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center text-primaryGrey body-p-small my-3" v-if="!loading && nfrPayout.originatorRewardPayouts.length == 0">
                    No Data Available
                </div>
                <div class="text-primaryGrey body-p-small">
                    * The Seller is paying themself as the Originator
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-8">
            <v-col cols="12" class="pa-0">
                <div class="body-text-btn">
                   FR paid to the former owners in the Flow
                </div>
            </v-col>
            <v-divider class="my-4"></v-divider>
            <v-col cols="12">
                <SkeletonCard :height="50" :length="10" v-if="loading"></SkeletonCard>
                <table class="bg-f2f text-primaryGrey mx-0" v-if="!loading">
                    <thead class="px-0">
                        <tr>
                            <td class="text-right form-text px-0" style="width: 60px;">Epoch</td>
                            <td class="text-right form-text px-0">Gen</td>
                            <td class="text-right form-text px-0">To</td>
                            <td class="text-right form-text px-0">Amount</td>
                            <td class="text-right form-text px-0">Currency</td>
                        </tr>
                    </thead>
                    <tbody v-if="nfrPayout.futureRewardPayouts.length > 0">
                        <tr v-for="payout in nfrPayout.futureRewardPayouts" :key="payout.index" class="body-p-small">
                            <td class="text-right body-p-small px-0">{{ $tools.convertToRoman(payout.epoch) }}</td>
                            <td class="text-right body-p-small px-0">{{ payout.generation }}</td>
                            <td class="text-right body-p-small px-0">
                                <span v-if="payout.paid" class="d-flex flex-nowrap justify-end">
                                    <span class="mr-2">{{ payout.to.name }}</span>
                                    <UserAvatar :avatar="payout.to.avatar" :username="payout.to.username" :size="24"></UserAvatar>
                                </span>
                            </td>
                            <td class="text-right body-p-small px-0">
                                <span v-if="payout.paid">
                                    {{ payout.amount > 0 ? $tools.formatNumber(payout.amount, 8) : '--' }}
                                </span>
                            </td>
                            <td class="text-right body-p-small ellipsis-line px-0">
                                <span v-if="payout.paid">
                                    {{ payout.paymentToken }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th class="form-text px-0">Sub Total</th>
                            <th></th>
                            <th></th>
                            <th class="form-text text-right px-0">{{ $tools.formatNumber(nfrPayout.futureRewardSubTotal.amount, 8) }}</th>
                            <th class="body-p-small text-right px-0">{{ nfrPayout.futureRewardSubTotal.paymentToken }}</th>
                        </tr>
                        <tr>
                            <th class="form-text px-0">Total</th>
                            <th></th>
                            <th></th>
                            <th class="form-text text-right px-0">{{ $tools.formatNumber(nfrPayout.total.amount, 8) }}</th>
                            <th class="body-p-small text-right px-0">{{ nfrPayout.total.paymentToken }}</th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center text-primaryGrey body-p-small my-3" v-if="!loading && nfrPayout.futureRewardPayouts.length == 0">
                    No Data Available
                </div>
            </v-col>
        </v-row>
       </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFRAPI from '@/api/nfr.js';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // NFR 支付记录
            nfrPayout: {
                originatorRewardPayouts: [],
                originatorRewardSubTotal: {},
                futureRewardPayouts: [],
                futureRewardSubTotal: {},
                total: {}
            }
        }
    },
    // NFT 主键
    props: {
        nftId: {
            type: String,
            default: null
        }
    },
    components: { SkeletonCard },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user']),
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据NFT主键和用户主键查询nFR支付记录
                this.getNFRPayout();
            },
            immediate: true
        },
        'user.id': {
            handler(newVal, oldVal) {
                // 根据NFT主键和用户主键查询nFR支付记录
                this.getNFRPayout();
            },
            immediate: true
        }
    },
    methods: {
        // 根据NFT主键和用户主键查询nFR支付记录
        async getNFRPayout() {
            if(this.nftId == null || this.user.id == null) {
                return;
            }
            this.loading = true;
            let res = await NFRAPI.getNFRPayout(this.nftId, this.user.id);
            let data = res.data;
            if(data.success) {
                this.nfrPayout = data.data;
            }
            this.loading = false;
        },
    }
}
</script>
<style scoped>
table { 
	width: 100%; 
	border-collapse: collapse; 
	}

tr th {
	padding: 10px; 
	border-top: 2px solid #9e9e9e; 
	text-align: left; 
	}

td { 
	padding: 10px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
	}

[data-toggle="toggle"] {
	display: none;
}
</style>