<template>
	<div>
        <div v-for="item in items" :key="item?.userReferralCode?.user?.id">
            <v-list v-model:opened="openedGroups" bg-color="transparent">
                <v-list-item v-if="item.totalChildrenCount == 0" class="px-0"
                    :subtitle="item?.userReferralCode?.user?.wallet" >
                    <template v-slot:prepend="avatar">
                        <UserAvatar :avatar="item?.userReferralCode?.user?.avatar" :username="item?.userReferralCode?.user?.username" :size="40" class="mr-4"></UserAvatar>
                    </template>
                    <template v-slot:title>
                        <span class="text-green mr-2">#{{ item.level }}</span>
                        <span class="mr-2">{{ item?.userReferralCode?.user?.name }}</span>
                        <span class="text-blue mr-2">({{ item.totalChildrenCount }})</span>
                    </template>
                </v-list-item>
                <v-list-group v-if="item.totalChildrenCount > 0" :value="item?.userReferralCode?.user?.wallet"
                    expand-icon="mdi mdi-chevron-down" collapse-icon="mdi mdi-chevron-up">
                    <template v-slot:activator="{ props }">
                        <v-list-item class="px-0"
                            v-bind="props"
                            :subtitle="item?.userReferralCode?.user?.wallet" >
                            <template v-slot:prepend="avatar">
                                <UserAvatar :avatar="item?.userReferralCode?.user?.avatar" :username="item?.userReferralCode?.user?.username" :size="40" class="mr-4"></UserAvatar>
                            </template>
                            <template v-slot:title>
                                <span class="text-green mr-2">#{{ item.level }}</span>
                                <span class="mr-2">{{ item?.userReferralCode?.user?.name }}</span>
                                <span class="text-blue mr-2">({{ item.totalChildrenCount }})</span>
                            </template>
                        </v-list-item>
                    </template>
                    <user-referral-treeview :items="item.children" v-if="item.totalChildrenCount > 0" class="ml-10" />
                </v-list-group>
            </v-list>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: 'user-referral-treeview',
    data(){
        return {
            // 打开的组
            openedGroups: []
        }
    },
    props: {
        items: Array,
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user']),
    },
    watch:{
        'user': {
            handler (val, oldVal) {
                this.openedGroups = [this.user?.wallet];
            },
            deep: true
        }
    },
    methods: {

    }
}
</script>
<style scoped>

</style>