<template>
	<div>
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="8" class="d-flex flex-column fill-height text-primaryGrey">
                <div class="lexend-deca home-18 gradient-underline-hover">
                    Untrading is a revolutionary DeFi platform that allows you to profit from asset appreciation even after selling. Using the unique <a href="https://5173.foundation" target="_blank" class="del-underline text-homeHover pointer font-weight-bold"><span class="gradient-text-hover text-primaryGrey">ERC-5173</span></a> standard, we've created a system where past owners continue to benefit from future price increases. It's a commission-free protocol for investing in both crypto and NFTs, designed to create a win-win scenario for all participants.
                </div>
                <div class="mt-8 lexend-deca home-18">
                    Untrading provides the native economic infrastructure for <router-link to="/the-network-state-connection" class="del-underline text-homeHover pointer font-weight-bold"><span class="gradient-text-hover text-primaryGrey">Network States</span></router-link>, offering decentralized governance, shared economies, and scalable sovereignty. 
                </div>
                <router-link to="/the-network-state-connection" class="del-underline gradient-left-red-purple-245 mt-16">
                    <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white home-18 lexend-deca" width="245" height="48" aria-label="Learn more">Learn more</v-btn>
                </router-link>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>