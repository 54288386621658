<template>
	<div>
        <v-row no-gutters class="mt-10 r-m-l-4">
            <v-col cols="12" lg="12" sm="12" class="justify-center">
                <div class="d-flex">
                    <h2 class="text-h5 font-weight-bold text-left text-primaryGrey">
                        <count-to :start-val="0" :end-val="walletBalance" :duration="1500" :decimals="8" separator="," :suffix="currentBlockchain.symbol == 'MATIC' ? ' MATIC' : ' Ξ'"></count-to>
                    </h2>
                    <div class="body-p-small text-primaryGrey mt-2 pl-6">
                        <count-to :start-val="0" :end-val="walletBalanceValue" :duration="1500" :decimals="2" separator="," prefix="$"></count-to>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-3 r-m-l-4">
            <v-col cols="12" lg="6" sm="12">
                <div class="d-flex">
                    <h2 class="text-h5 font-weight-bold text-primaryGrey gradient-underline-hover">
                        <router-link to="/user/rewards" aria-label="User Rewards" class="del-underline text-primaryGrey gradient-text-hover">
                            <count-to :start-val="0" :end-val="unUserRewardsBalance" :duration="1500" :decimals="4" separator="," suffix=" UN User Rewards"></count-to>
                            <!-- <span class="fa-solid fa-up-right-from-square fs-28 ml-5"></span> -->
                        </router-link>
                        <v-icon size="20" class="text-primaryGrey ml-5 mb-2" icon="fa-solid fa-up-right-from-square"></v-icon>
                    </h2>
                </div>
            </v-col>
            <v-col cols="12" lg="6" sm="12">
                <div class="d-flex">
                    <h2 class="text-h5 font-weight-bold text-primaryGrey gradient-underline-hover">
                        <router-link to="/kingdom/rewards" aria-label="User Rewards" class="del-underline text-primaryGrey gradient-text-hover">
                            <count-to :start-val="0" :end-val="unKingdomRewardsBalance" :duration="1500" :decimals="4" separator="," suffix=" UN Kingdom Rewards"></count-to>
                            <!-- <span class="fa-solid fa-up-right-from-square fs-28 ml-5"></span> -->
                        </router-link>
                        <v-icon size="20" class="text-primaryGrey ml-5 mb-2" icon="fa-solid fa-up-right-from-square"></v-icon>
                    </h2>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-3 r-m-l-4">
            <v-col cols="12" lg="6" sm="12" v-for="quote in cryptoQuotes" :key="quote.address">
                <v-row no-gutters>
                    <h2 class="text-h5 font-weight-bold text-left text-primaryGrey">
                        <count-to :start-val="0" :end-val="quote?.balance" :duration="1500" :decimals="6" separator="," :suffix="` ${quote.currency}`"></count-to>
                    </h2>
                </v-row>
            </v-col>
        </v-row>
        <!-- 20240813 使用数据表格展示，但是有 bug，暂留 -->
        <v-row v-if="false">
            <v-col>
                <!-- 数据表格 -->
                <v-data-table-server width="100%" fluid :headers="headers" :items="tokenBalances" :loading="tokenBalanceLoading"
                class="body-p text-primaryGrey" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact">
                <template v-slot:headers>
                    <tr class="body-h5">
                        <td class="px-0">Symbol</td>
                        <td class="px-0">Name</td>
                        <td class="px-0 text-right">Balance</td>
                        <td class="px-0 text-right">Price</td>
                        <td class="px-0 text-right">Value</td>
                        <td class="px-0 pl-3 ">Action</td>
                    </tr>
                    <tr>
                        <td :colspan="headers.length" class="px-0 text-center">
                            <v-divider color="primaryGrey" :thickness="3"></v-divider>
                        </td>
                    </tr>
                </template>
                <template v-slot:item="{ index, item }">
                    <tr>
                        <td class="px-0">{{ item.symbol }}</td>
                        <td class="px-0">{{ item.name }}</td>
                        <td class="px-0 text-right">{{ $tools.formatNumber(item.balance, 4) }}</td>
                        <td class="px-0 text-right">{{ $tools.formatNumber(item.price, -1) }}</td>
                        <td class="px-0 text-right">{{ $tools.formatNumber(item.value, 2) }}</td>
                        <td class="px-0 pl-3">
                            <div class="gradient-left-red-purple-142">
                                <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="Claim FRs">
                                    View
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
                <template v-slot:loading>
                    <v-skeleton-loader type="table-row@10" color="transparent"></v-skeleton-loader>
                </template>
                <template v-slot:no-data>
                    <NoDataAvailable></NoDataAvailable>
                </template>
                <template v-slot:tfoot>
                    <tr>
                        <td :colspan="headers.length" class="px-0">
                            <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                        </td>
                    </tr>
                </template>
                <template v-slot:bottom>

                </template>
            </v-data-table-server>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import UserRewardAPI from '@/api/user-reward.js';
import UserRewardKingdomAPI from '@/api/user-reward-kingdom.js';
import CryptoQuoteAPI from '@/api/crypto-quote.js';
export default {
    data(){
        return {
            // 钱包余额
            walletBalance: 0,
            // UN币余额
            unBalance: 0,
            // 用户奖励余额
            unUserRewardsBalance: 0,
            // 王国奖励余额
            unKingdomRewardsBalance: 0,
            // USDC 余额
            usdcBalance: 0,
            // 当前的区块链
            currentBlockchain: {},
            // Web3
            web3: {},
            // 加密货币报价资产对象集合
            cryptoQuotes: [],
            // 表头
            headers: [{},{},{},{},{},{}],
            tokenBalances: [],
            tokenBalanceLoading: false,
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive', 'user', 'prices', 'blockchains', 'blockchain']),
        // 钱包余额价值
        walletBalanceValue() {
            return (this.prices && this.prices.filter(P => P.currency == this.currentBlockchain.symbol)[0]?.price || 0) * this.walletBalance;
        },
    },
    watch:{
        'user.wallet': {
            handler(n, o) {
                if(n && this.currentBlockchain.blockchain) {
                    // 查询钱包余额
                    this.getWalletBalance();
                    // 查询UN币余额
                    this.getUNBalance();
                    // 查询用户奖励余额
                    this.getUserRewardsBalance();
                    // 查询王国奖励余额
                    this.getKingdomRewardsBalance();
                    // 查询USDC币余额
                    this.getUSDCBalance();
                }
            },
            immediate: true
        },
        // 监听区块链
        blockchain: {
            handler(n, o) {
                if(n) {
                    this.currentBlockchain = this.blockchains.filter(b => b.blockchain == n)[0];  
                } else {
                    this.currentBlockchain = this.blockchains[0];
                }
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 刷新
                this.changeBlockchainCondition(this.currentBlockchain);
            },
            immediate: true
        }
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain.blockchain);
            this.currentBlockchain = blockchain;
            this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 查询钱包余额
            this.getWalletBalance();
            // 查询UN币余额
            this.getUNBalance();
            // 查询用户奖励余额
            this.getUserRewardsBalance();
            // 查询王国奖励余额
            this.getKingdomRewardsBalance();
            // 查询USDC币余额
            // this.getUSDCBalance();
            // 查询全部加密货币报价资产
            this.getCryptoQuotes();
        },
        // 查询钱包余额
        async getWalletBalance() {
            if(this.user?.wallet) {
                this.walletBalance = 0;
                this.web3.eth.getBalance(this.user.wallet).then(walletBalance => {
                    this.walletBalance = Number(this.web3.utils.fromWei(walletBalance));
                });
            }
        },
        // 查询UN币余额
        async getUNBalance() {
            this.unBalance = 0;
            // let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            // let unBalance = await NFTContract.methods.balanceOf(this.user.wallet).call();
            // this.unBalance = Number(unBalance);
        },
        // 查询用户奖励余额
        async getUserRewardsBalance() {
            this.unUserRewardsBalance = 0;
            let res = await UserRewardAPI.getBalance();
            let data = res.data;
            let token = {
                symbol: 'UN',
                name: 'UN User Rewards',
                balance: 0,
                price: 0,
                value: 0,
                sort: 2
            };
            if (data.success) {
                this.unUserRewardsBalance = data.data;
                token.balance = data.data;
            }
            this.tokenBalances.push(token);
            this.tokenBalances.sort((a, b) => a.sort - b.sort);
        },
        // 查询王国奖励余额
        async getKingdomRewardsBalance() {
            this.unKingdomRewardsBalance = 0;
            let res = await UserRewardKingdomAPI.getBalance();
            let data = res.data;
            let token = {
                symbol: 'UN',
                name: 'UN Kingdom Rewards',
                balance: 0,
                price: 0,
                value: 0,
                sort: 3
            };
            if (data.success) {
                this.unKingdomRewardsBalance = data.data;
                token.balance = data.data;
            }
            this.tokenBalances.push(token);
            this.tokenBalances.sort((a, b) => a.sort - b.sort);
        },
        // 查询USDC币余额
        async getUSDCBalance() {
            if(this.user?.wallet) {
                this.usdcBalance = 0;
                let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.mainnetRPCUrl));
                let USDCContract = new web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.currentBlockchain.USDCContract);
                let usdcBalance = await USDCContract.methods.balanceOf(this.user.wallet).call();
                this.usdcBalance = Number(web3.utils.fromWei(usdcBalance, 'mwei'));
            }
        },
        // 查询全部加密货币报价资产
        async getCryptoQuotes() {
            this.cryptoQuotes = [];
            let res = await CryptoQuoteAPI.getCryptoQuotes(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.cryptoQuotes = data.data;
                // // 查询ERC20代币余额
                this.cryptoQuotes.forEach(quote => this.getTokemBalance(quote));
            }
        },
        // 查询ERC20代币余额
        async getTokemBalance(quote) {
            // 设置默认值 0
            quote.balance = 0;
            if(this.user?.wallet) {
                // 创建合约
                let contract = new this.web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, quote.address);
                // 查询余额
                let balance = await contract.methods.balanceOf(this.user.wallet).call();
                // 转换单位
                quote.balance = this.$web3Utils.fromWei(balance, quote.tokenDecimals);
                let token = {
                    symbol: quote.currency,
                    name: quote.name,
                    balance: quote.balance,
                    price: 0,
                    value: 0,
                    sort: 4
                };
                this.tokenBalances.push(token);
                this.tokenBalances.sort((a, b) => a.sort - b.sort);
            }
        },
    }
}
</script>
<style scoped>

</style>