<template>
	<div class="align-center bg-f2f" >
        <v-container class="bg-f2f r-p-b-0">
            <v-card color="f2f" rounded="0" elevation="0" max-width="1440" class="mx-auto pt-4 pr-16 r-p-r-1">
                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3" :class="responsive != 'pc' ? 'ml-3' : ''" @click="$router.back(-1)"></v-icon>
                <v-row no-gutters justify="center">
                    <!-- PC端显示在左侧 -->
                    <v-col cols="12" lg="6" v-if="responsive == 'pc'">
                        <div class="pr-16 r-p-r-0">
                            <!-- ------------------- 原始信息 -------------------- -->
                            <UnCryptoOwner ref="unCryptoOwner" :show-total="false"></UnCryptoOwner>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <!-- 价格和信息 -->
                        <v-card max-width="650" class="pa-4 pt-0 pr-0 r-p-x-0" elevation="0" rounded="0" color="transparent">
                            <v-card max-width="650" class="pa-3" rounded="0" elevation="0" color="transparent">
                                <!-- --------------------- BUY ------------------------ -->
                                <div v-if="action == 'BUY'">
                                    <!-- 注释 -->
                                    <div v-if="false">
                                        <v-row no-gutters class="px-6">
                                            <v-col cols="12" class="text-primaryGrey mt-4">
                                                <SkeletonCard v-if="loading" :height="44"></SkeletonCard>
                                                <p v-if="!loading" class="d-flex align-end justify-space-between">
                                                    <span  class="bp-h3">
                                                        {{ $tools.formatNumber(unCrypto.listedSize, unCrypto?.cryptoWrapped?.sizeDecimals) }} {{ unCrypto.wrappedCurrency }}
                                                    </span>
                                                    <span class="float-right body-h5">
                                                        Offered at
                                                    </span>
                                                </p>
                                            </v-col>
                                            <v-col cols="12" class="text-primaryGrey mt-3">
                                                <h5 class="body-h5">
                                                    Limit
                                                </h5>
                                                <SkeletonCard v-if="loading" :height="44"></SkeletonCard>
                                                <h2 v-if="!loading" class="d-flex align-end justify-space-between">
                                                    <span class="bp-h3">{{ $tools.formatNumber(unCrypto.limitPrice, unCrypto?.cryptoQuote?.sizeDecimals) }} </span>
                                                    <span class="qc-h2">{{ unCrypto.quoteCurrency }}</span>
                                                </h2>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-5"></v-divider>
                                        <v-row no-gutters class="px-6">
                                            <v-col cols="12">
                                                <h2 class="qc-h2 text-primaryGrey">Buy - Limit</h2>
                                            </v-col>
                                            <!-- 花费 -->
                                            <v-col cols="12" class="text-primaryGrey my-2">
                                                <h5 class="text-primaryGrey body-p-medium mb-1">I want to spend</h5>
                                                <v-row no-gutters>
                                                    <v-col cols="8">
                                                        <v-text-field v-model="buyCrypto.price" type="number" hide-details="auto" placeholder="0" :step="$tools.getTickStep(unCrypto?.cryptoQuote?.sizeDecimals)" :min="0" density="comfortable" class="body-p-small text-primaryGrey" variant="outlined"></v-text-field>
                                                        <div class="body-p-small">
                                                            <span>Balance: {{ $tools.formatNumber(paymentTokenBalance, unCrypto?.cryptoQuote?.sizeDecimals) }}</span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-text-field :model-value="unCrypto.quoteCurrency" hide-details="auto" density="comfortable" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="px-6">
                                            <!-- 数量 -->
                                            <v-col cols="12" class="text-primaryGrey mb-2">
                                                <h5 class="text-primaryGrey body-p-medium mb-1">I want to buy</h5>
                                                <v-row no-gutters>
                                                    <v-col cols="8">
                                                        <v-text-field v-model="buyCrypto.size" type="number" hide-details="auto" placeholder="0" :step="$tools.getTickStep(unCrypto?.cryptoWrapped?.sizeDecimals)" :min="0" density="comfortable" class="body-p-small text-primaryGrey" variant="outlined"></v-text-field>
                                                        <div class="body-p-small">
                                                            <!-- <span>Supply: {{ $tools.formatNumber(unCrypto.listedSize || 0, unCrypto?.cryptoWrapped?.sizeDecimals) }}</span> -->
                                                            <span>
                                                                <span class="pointer" :class="(buyCrypto.size == (unCrypto.listedSize * 0.25).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize * 0.25">25%</span>
                                                                <span class="pointer ml-3" :class="(buyCrypto.size == (unCrypto.listedSize * 0.5).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize * 0.5">50%</span>
                                                                <span class="pointer ml-3" :class="(buyCrypto.size == (unCrypto.listedSize * 0.75).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize * 0.75">75%</span>
                                                                <span class="pointer ml-3" :class="(buyCrypto.size == (unCrypto.listedSize * 1).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize">Max</span>
                                                            </span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-text-field :model-value="unCrypto.wrappedCurrency" hide-details="auto" density="comfortable" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <!-- 比率 -->
                                            <v-col cols="12" class="mt-2">
                                                <h5 class="text-primaryGrey body-p-medium">Buy {{ unCrypto.wrappedCurrency }} at rate</h5>
                                                <v-text-field density="comfortable" hide-details="auto" :model-value="`1 ${unCrypto.wrappedCurrency} = ${$tools.formatNumber(unCrypto.limitPrice, unCrypto?.cryptoQuote?.sizeDecimals)} ${unCrypto.quoteCurrency}`" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field>
                                            </v-col>
                                            <!-- 摘要 -->
                                            <v-col cols="12" class="mt-2">
                                                <h5 class="text-primaryGrey body-p-medium">Summary</h5>
                                                <v-text-field density="comfortable" hide-details="auto" :model-value="`You get ${$tools.formatNumber(buyCrypto.size, unCrypto?.cryptoWrapped?.sizeDecimals)} ${unCrypto.wrappedCurrency} for ${$tools.formatNumber(buyCrypto.price, unCrypto?.cryptoQuote?.sizeDecimals)} ${unCrypto.quoteCurrency}`" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field>
                                                <!-- (~ $${$tools.formatNumber(buyCrypto.price, unCrypto?.cryptoQuote?.sizeDecimals)}) -->
                                            </v-col>
                                            <!-- 提示 -->
                                            <v-col cols="12" class="mt-4">
                                                <h6 class="text-primaryGrey body-p-small">
                                                    Once the transaction is confirmed, the unCrypto will be sent to your wallet instantly. 
                                                </h6>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <!-- --------------------- NEW BUY ------------------------ -->
                                    <v-row no-gutters class="py-2 px-6 bg-black title-elevation-show">
                                        <v-col cols="12" class="text-primaryGrey body-text-btn">
                                            <span>Buy - Limit</span>
                                        </v-col>
                                    </v-row>
                                    <div class="bg-f2f2f2-666666 elevation-show pa-7">
                                        <v-row no-gutters>
                                            <v-col cols="12" class="text-grey05 body-h4 mb-5">
                                                <span>I want to spend</span>
                                            </v-col>
                                            <!-- <v-col cols="12" class="text-grey05 buy-h5 text-right">
                                                <span>$36,859.78</span>
                                            </v-col> -->
                                            <v-col cols="12" class="text-grey05 d-flex justify-space-between align-center">
                                                <v-text-field v-model="buyCrypto.price" :hide-details="true" type="number" placeholder="0" :step="$tools.getTickStep(unCrypto?.cryptoQuote?.sizeDecimals)" :min="0" density="comfortable" class="text-grey05 buy-input-font" variant="plain"></v-text-field>
                                                <div class="ml-2">
                                                    <p :class="responsive == 'pc' ? 'buy-p' : 'buy-p-small'">{{ unCrypto.quoteCurrency }}</p>
                                                </div>
                                            </v-col>
                                            <!-- <v-col cols="4" class="text-right">
                                                <p class="buy-p text-grey05">{{ unCrypto.quoteCurrency }}</p>
                                            </v-col> -->
                                            <v-col cols="12" class="my-6">
                                                <span class="buy-h5 text-grey05">Balance: {{ $tools.formatNumber(paymentTokenBalance, unCrypto?.cryptoQuote?.sizeDecimals) }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="bg-black elevation-show pa-7">
                                        <v-row no-gutters>
                                            <v-col cols="12" class="text-grey05 body-h4 mb-5">
                                                <span>I want to buy</span>
                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                    <template v-slot:activator="{ props }" >
                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-grey05 pointer ml-6 gradient-text-hover" v-bind="props"></v-icon>
                                                    </template>
                                                    <v-card max-width="420" class="pa-3 text-grey05 body-p" rounded="0">
                                                        <span>{{ `You get ${$tools.formatNumber(buyCrypto.size, unCrypto?.cryptoWrapped?.sizeDecimals)} ${unCrypto.wrappedCurrency} for ${$tools.formatNumber(buyCrypto.price, unCrypto?.cryptoQuote?.sizeDecimals)} ${unCrypto.quoteCurrency}` }}</span>
                                                    </v-card>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" class="text-grey05 d-flex justify-space-between align-center">
                                                <v-text-field v-model="buyCrypto.size" :hide-details="true" type="number" placeholder="0" :step="$tools.getTickStep(unCrypto?.cryptoWrapped?.sizeDecimals)" :min="0" density="comfortable" class="text-grey05 buy-input-font" variant="plain"></v-text-field>
                                                <div class="ml-2">
                                                    <p class="text-grey05" :class="responsive == 'pc' ? 'buy-p' : 'buy-p-small'">{{ unCrypto.wrappedCurrency }}</p>
                                                </div>
                                            </v-col>
                                            <!-- <v-col cols="4" class="text-right">
                                                <p class="text-grey05" :class="responsive == 'pc' ? 'buy-p-large' : 'buy-p-small'">{{ unCrypto.wrappedCurrency }}</p>
                                            </v-col> -->
                                            <v-col cols="12" class="my-6">
                                                <div class="body-p-small text-grey05">
                                                    <!-- <span>Supply: {{ $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) }}</span> -->
                                                    <span>
                                                        <span class="pointer" :class="(buyCrypto.size != 0 && !buyCrypto.max && buyCrypto.size == (unCrypto.listedSize * 0.25).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize * 0.25">25%</span>
                                                        <span class="pointer ml-3" :class="(buyCrypto.size != 0 && !buyCrypto.max && buyCrypto.size == (unCrypto.listedSize * 0.5).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize * 0.5">50%</span>
                                                        <span class="pointer ml-3" :class="(buyCrypto.size != 0 && !buyCrypto.max && buyCrypto.size == (unCrypto.listedSize * 0.75).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize * 0.75">75%</span>
                                                        <span class="pointer ml-3" :class="(buyCrypto.size != 0 && (buyCrypto.max || buyCrypto.size == (unCrypto.listedSize * 1).toFixed(unCrypto?.cryptoWrapped?.sizeDecimals))) ? 'text-primary font-weight-bold': ''" @click="buyCrypto.size = unCrypto.listedSize">Max</span>
                                                    </span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="mb-5">
                                                <span class="buy-h5 text-grey05">{{ `1 ${unCrypto.wrappedCurrency} = ${$tools.formatNumber(unCrypto.limitPrice, unCrypto?.cryptoQuote?.sizeDecimals)} ${unCrypto.quoteCurrency}` }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <!-- --------------------- CONFIRM ------------------------ -->
                                <div v-if="action == 'CONFIRM'" class="bg-black elevation-show pa-7">
                                    <v-row no-gutters class="pa-6">
                                        <v-col cols="12">
                                            <i class="iconfont icon-refresh text-primaryGrey"></i>
                                            <h2 class="sidebar-h2 mt-5 text-primaryGrey">Waiting for Confirmation...</h2>
                                            <v-divider class="my-7"></v-divider>
                                            <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                                Confirm this transaction in your wallet.
                                            </h5>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- --------------------- BEING_CONFIRMED ------------------------ -->
                                <div v-if="action == 'BEING_CONFIRMED'" class="bg-black elevation-show pa-7">
                                    <v-row no-gutters class="pa-6">
                                        <v-col cols="12">
                                            <i class="iconfont icon-refresh text-primaryGrey"></i>
                                            <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your purchase is being confirmed...</h2>
                                            <v-divider class="my-7"></v-divider>
                                            <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                                The purchase is being confirmed on the blockchain.
                                            </h5>
                                            <div class="gradient-underline-hover">
                                                <a :href="$tools.getBlockchainExplorerUrl(unCrypto.blockchain) + '/tx/' + transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p" target="_blank">
                                                    <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ currentBlockchain.blockchainExplorer }}
                                                </a>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- --------------------- COMPLETION ------------------------ -->
                                <div v-if="action == 'COMPLETION'" class="bg-black elevation-show pa-7">
                                    <v-row no-gutters class="pa-6">
                                        <v-col cols="12">
                                            <i class="mb-5 iconfont icon-complete text-primaryGrey"></i>
                                            <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your purchase has been confirmed!</h2>
                                            <v-divider class="my-7"></v-divider>
                                            <h5 class="mt-3 body-p text-primaryGrey">
                                                Congratulations! 
                                            </h5>
                                            <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                                You can view your {{ unCrypto.wrappedCurrency }} in the wallet and on the Dashboard!
                                            </h5>
                                            <div class="gradient-underline-hover">
                                                <a :href="$tools.getBlockchainExplorerUrl(unCrypto.blockchain) + '/tx/' + transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p" target="_blank">
                                                    <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ currentBlockchain.blockchainExplorer }}
                                                </a>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- --------------------- FAIL ------------------------ -->
                                <div v-if="action == 'FAIL'" class="bg-black elevation-show pa-7">
                                    <v-row no-gutters class="pa-6">
                                        <v-col cols="12">
                                            <i class="mb-5 iconfont icon-close text-primaryGrey"></i>
                                            <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your purchase has failed!</h2>
                                            <v-divider class="my-7"></v-divider>
                                            <h5 class="mt-3 body-p text-primaryGrey">
                                                Warning!
                                            </h5>
                                            <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                                Error encountered during contract execution.
                                            </h5>
                                            <div class="gradient-underline-hover">
                                                <a :href="$tools.getBlockchainExplorerUrl(unCrypto.blockchain) + '/tx/' + transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p" target="_blank">
                                                    <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ currentBlockchain.blockchainExplorer }}
                                                </a>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                            <!-- 事实 -->
                            <div v-if="action == 'COMPLETION'" class="mt-6">
                                <Fact :unCryptoUrl="`${$config.domain}/uncrypto/${unCrypto?.wrappedCurrency?.toLowerCase()}/${unCrypto?.id}`"></Fact>
                            </div>
                            <div class="mt-6 ml-3 pr-3">
                                <!-- 购买 -->
                                <div v-if="action == 'BUY' || action == 'CONFIRM'">
                                    <!-- 资金不足 -->
                                    <v-btn v-if="isInsufficientFunds" rounded="0" elevation="2" color="button01" class="text-none block text-grey05 body-p-small-b" height="66" disabled aria-label="Insufficient funds">Insufficient funds</v-btn>
                                    <!-- 购买 -->
                                    <a class="gradient-left-red-purple-block del-underline">
                                        <v-btn v-if="!isInsufficientFunds" rounded="0" elevation="2" color="button01" :loading="buyLoading" :disabled="isDisabledBuy" class="text-none block text-grey05 body-p-small-b" height="66" @click="getAllowance()" aria-label="Buy Limit">Buy - Limit</v-btn>
                                    </a>
                                </div>
                                <div v-if="action == 'COMPLETION'">
                                    <!-- 个人资料 -->
                                    <router-link :to="`/profile/${user.username}`" class="gradient-left-red-purple-block del-underline">
                                        <v-btn rounded="0" elevation="2" aria-label="Go to my profile" color="button01" class="text-none block text-grey05 body-p-small-b" height="66">Go to my profile</v-btn>
                                    </router-link>
                                </div>
                            </div>
                            <div class="mt-16 ml-3">
                                <Step process-status="process-text" finish-status="finish-text" :steps="steps" :action="action"></Step>
                            </div>
                        </v-card>
                    </v-col>
                    <!-- 手机版显示在下方 -->
                    <v-col cols="12" lg="6" v-if="responsive != 'pc'">
                        <div class="pr-16 r-p-r-0">
                            <!-- ------------------- 原始信息 -------------------- -->
                            <UnCryptoOwner ref="unCryptoOwner" :show-total="false"></UnCryptoOwner>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose" @approveTransactionClose="approveTransactionClose"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose" @approveTransactionClose="approveTransactionClose"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import SkeletonCard from '@/components/util/skeleton/SkeletonCard.vue';
import UnCryptoOwner from '@/components/crypto/UnCryptoOwner.vue';
import Fact from '@/components/common/Fact.vue';
import CryptoAPI from '@/api/crypto.js';
import CryptoWrappedAPI from '@/api/crypto-wrapped.js';
import CryptoQuoteAPI from '@/api/crypto-quote.js';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 操作
            action: 'BUY',
            // 步骤
            steps: [
                { title: 'Buy now', codes: ['BUY'] },
                { title: 'Confirm in your wallet', codes: ['CONFIRM', 'BEING_CONFIRMED'] },
                { title: 'Completion', codes: ['COMPLETION'] }
            ],
            // 封装后的加密货币
            unCrypto: {},
            // 当前的区块链
            currentBlockchain: {},
            // 当前的Web3服务对象
            web3: {},
            // 当前的合约对象
            contract: {},
            // 支付代币资产余额
            paymentTokenBalance: 0,
            // 加密货币封装资产
            cryptoWrapped: {},
            // 加密货币报价资产
            cryptoQuote: {},
            // 购买参数
            buyCrypto: {
                price: 0,       // 总价
                size: 0,        // 数量
                max: false      // 是否为最大数量
            },
            // 上市信息
            listInfo: {
                price: 0,       // 上市价格/单价
                amount: 0,      // 上市数量
                lister: null,   // 上市人
                isListed: false // 是否已经上市
            },
            // 是否有购买权限
            hasBuyPermission: false,
            // 购买加载中
            buyLoading: false,
            // 批准交易哈希
            approveTransactionHash: null,
            // 交易哈希
            transactionHash: null,
            // 交易收据定时器
            transactionReceiptInterval: null,
        }
    },
    components: { SkeletonCard, UnCryptoOwner, Fact },
    created(){
        // 监听
        this.$bus.on('callGetApproveTransactionReceipt', data => {
            if (data.type == "CRYPTO_BUY") {
                this.callGetApproveTransactionReceipt(data.data);
            }
        })
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "CRYPTO_BUY") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        // 测试交易失败
        // setTimeout(() => {
        //     let fail = { title: 'Fail', codes: ['FAIL'] };
        //     this.steps.splice(this.steps.length - 1, 1, fail);
        //     this.action = 'FAIL';
        // }, 3000);
        // 测试交易完成
        // setTimeout(() => {
        //     this.action = 'COMPLETION';
        // }, 3000);
    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'walletType', 'responsive']),
        // 禁用购买按钮
        isDisabledBuy() {
            return !this.hasBuyPermission || this.buyCrypto.price <= 0 || this.buyCrypto.size <= 0 || (this.buyCrypto.size > this.unCrypto.listedSize && !this.buyCrypto.max);
        },
        // 是否资金不足
        isInsufficientFunds() {
            return this.buyCrypto.price && this.buyCrypto.price > this.paymentTokenBalance;
        },
    },
    watch:{
        $route(to, from) {
            // 要去的页面还是当前页面，则更新id，否则是去别的页面，不更新
            if(to.name == "CryptoBuy") {
                this.id = this.$route.params.id;
            }
        },
        id: {
            handler(newVal, oldVal) {
                // 根据主键查询加密货币
                this.getCryptoById();
            },
            immediate: true
        },
        // 监听数量改变
        'buyCrypto.size': {
            handler(n, o) {
                if(n != null && n != "") {
                    this.buyCrypto.size = Number(Number(n).toFixed(this.unCrypto?.cryptoWrapped?.sizeDecimals));
                    this.buyCrypto.price = Number((this.buyCrypto.size * this.unCrypto.limitPrice).toFixed(this.unCrypto?.cryptoQuote?.sizeDecimals));
                    this.buyCrypto.max = (this.buyCrypto.size == this.unCrypto.listedSize);
                } else {
                    this.buyCrypto.size = 0;
                    this.buyCrypto.price = 0;
                    this.buyCrypto.max = false;
                }
            }
        },
        // 监听价格改变
        'buyCrypto.price': {
            handler(n, o) {
                if(n != null && n != "") {
                    this.buyCrypto.price = Number(Number(n).toFixed(this.unCrypto?.cryptoQuote?.sizeDecimals));
                    this.buyCrypto.size = Number((this.buyCrypto.price / this.unCrypto.limitPrice).toFixed(this.unCrypto?.cryptoWrapped?.sizeDecimals));
                    this.buyCrypto.max = (this.buyCrypto.size == this.unCrypto.listedSize);
                } else {
                    this.buyCrypto.price = 0;
                    this.buyCrypto.size = 0;
                    this.buyCrypto.max = false;
                }
            }
        },
    },
    methods: {
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.id == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.id);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
                // 查询购买权限
                this.buyPermission();
                // 格式化数量
                this.unCrypto.listedSize = Number(this.unCrypto.listedSize.toFixed(this.unCrypto?.cryptoWrapped?.sizeDecimals));
                // 默认购买最大数量
                this.buyCrypto.size = this.unCrypto.listedSize;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.unCrypto.blockchain)[0];
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 创建合约
                this.contract = new this.web3.eth.Contract(this.currentBlockchain.unCryptoAbi, this.unCrypto.contractAddress);
                // 根据区块链和地址查询加密货币封装资产详情
                await this.getCryptoWrappedDetail();
                // 根据区块链和地址查询加密货币报价资产详情
                await this.getCryptoQuoteDetail();
                // 查询支付代币资产余额
                this.getPaymentTokenBalance();
                // console.log(this.web3.utils.hexToNumberString('0x000000000000000000000000000000000000000000000000000000006b49d200'))
                // console.log(this.web3.utils.hexToNumberString('0x0000000000000000000000000000000000000000000000000de0b6b3a7640000'))
            }
            this.loading = false;
        },
        // 根据区块链和地址查询加密货币封装资产详情
        async getCryptoWrappedDetail() {
            let res = await CryptoWrappedAPI.getCryptoWrappedDetail(this.unCrypto.blockchain, this.unCrypto.contractAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoWrapped = data.data;
            }
        },
        // 根据区块链和地址查询加密货币报价资产详情
        async getCryptoQuoteDetail() {
            let res = await CryptoQuoteAPI.getCryptoQuoteDetail(this.unCrypto.blockchain, this.unCrypto.paymentTokenAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoQuote = data.data;
            }
        },
        // 查询支付代币资产余额
        async getPaymentTokenBalance() {
            this.paymentTokenBalance = 0;
            let paymentTokenContract = new this.web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.unCrypto.paymentTokenAddress);
            let paymentTokenBalance = await paymentTokenContract.methods.balanceOf(this.user.wallet).call();
            this.paymentTokenBalance = this.$web3Utils.fromWei(paymentTokenBalance, this.cryptoQuote.tokenDecimals);
        },
        // 查询上市信息
        async getListInfo() {
            let result = await this.contract.methods.getListInfo(this.unCrypto.tokenId).call();
            this.listInfo.price = this.$web3Utils.fromWei(result[0], this.cryptoQuote.tokenDecimals);
            this.listInfo.amount = this.$web3Utils.fromWei(result[1], this.cryptoWrapped.tokenDecimals);
            this.listInfo.lister = result[2];
            this.listInfo.isListed = result[3];
            // console.log(this.listInfo);
            // console.log(this.web3.utils.hexToNumberString('0x00000000000000000000000000000000000000000000000000000000d693a400'))
        },
        // 查询资产信息
        async getAssetInfo() {
            let result = await this.contract.methods.getAssetInfo(this.unCrypto.tokenId).call();
            // console.log('AssetInfo: ', result);
            return result;
        },
        // 判断用户是否有购买权限
        async buyPermission() {
            if(this.id == null) {
                return;
            }
            let res = await CryptoAPI.buyPermission(this.id);
            let data = res.data;
            if(data.success) {
                this.hasBuyPermission = data.data;
                // 没有权限则跳转到详情页面
                // 20240407: 没有购买权限时留在本页面
                // if (!this.hasBuyPermission) {
                //     this.$router.push(`/uncrypto/${this.unCrypto?.wrappedCurrency?.toLowerCase()}/${this.id}`);
                // }
            }
        },
        // 查询授权的数量
        async getAllowance() {
            // 加载中
            this.buyLoading = true;
            let paymentTokenContract = new this.web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.unCrypto.paymentTokenAddress);
            let allowanceWei = await paymentTokenContract.methods.allowance(this.user.wallet, this.unCrypto.contractAddress).call();
            let allowance = this.$web3Utils.fromWei(allowanceWei, this.cryptoQuote.tokenDecimals);
            // 允许的资金足够则直接封装，不够的话则申请额度
            if (allowance >= this.buyCrypto.price) {
                // 链上购买
                this.buy();
            } else {
                // 批准
                this.approve();
            }
        },
        // 批准
        async approve() {
            // 滚动到页面顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 使用平滑滚动
            });
            // 加载中
            this.buyLoading = true;
            // 确认中
            this.action = 'CONFIRM';
            // 编码参数列表
            try {
                // 合约方法
                let method = this.web3.eth.abi.encodeFunctionSignature('approve(address,uint256)');
                // 将参数编码
                let priceWei = this.$web3Utils.toWei(this.buyCrypto.price, this.cryptoQuote.tokenDecimals);
                let param = this.web3.eth.abi.encodeParameters(['address', 'uint256'], [this.unCrypto.contractAddress, priceWei]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType]?.approve(this.currentBlockchain.blockchain, this.user.wallet, this.unCrypto.paymentTokenAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.buyLoading = false;
                    // 失败后回到上一步
                    this.action = 'BUY';
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.buyLoading = false;
                // 失败后回到上一步
                this.action = 'BUY';
            }
        },
        // 批准交易关闭：成功/失败
        async approveTransactionClose(success, transactionHash) {
            this.approveTransactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 查询批准交易收据
                this.getApproveTransactionReceipt();
            } else {
                // 加载完成
                this.buyLoading = false;
                // 失败后回到上一步
                this.action = 'BUY';
            }
        },
        // 查询批准交易收据
        getApproveTransactionReceipt() {
            let params = {
                type: "CRYPTO_BUY",
                blockchain: this.unCrypto.blockchain,
                transactionHash: this.approveTransactionHash
            }
            this.$bus.emit('emitGetApproveTransactionReceipt', params);
            // // 轮训查询交易
            // this.transactionReceiptInterval = setInterval(() => {
            //     let params = {
            //         type: "CRYPTO_BUY",
            //         blockchain: this.unCrypto.blockchain,
            //         transactionHash: this.approveTransactionHash
            //     }
            //     this.$bus.emit('emitGetApproveTransactionReceipt', params);
            // }, 3000)
        },
        // 回调批准交易收据
        callGetApproveTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            console.log(data);
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                if(data.status) {
                    // 链上购买
                    this.buy();
                } else {
                    // 交易失败
                    this.$store.dispatch('snackbarMessageHandler', 'Transaction Failed!');
                    // 加载完成
                    this.buyLoading = false;
                    // 失败后回到上一步
                    this.action = 'BUY';
                }
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询批准交易收据
                    this.getApproveTransactionReceipt();
                }, 3000);
            }
        },
        // 链上购买
        async buy() {
            // 滚动到页面顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 使用平滑滚动
            });
            // 加载中
            this.buyLoading = true;
            // 购买时候再次查询一次上市状态和价格
            await this.getListInfo();
            // 如果已下市则中断
            if(!this.listInfo.isListed) {
                // 查询购买权限
                this.buyPermission();
                // 加载完成
                this.buyLoading = false;
                return;
            }
            // 确认中
            this.action = 'CONFIRM';
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('buy(uint256,uint256)');
                // 将参数编码
                let sizeWei = this.$web3Utils.toWei(this.buyCrypto.size, this.cryptoWrapped.tokenDecimals);
                // 如果设置为购买最大数量，则查询资产信息直接使用剩余全部数量
                if (this.buyCrypto.max) {
                    // 查询资产信息
                    sizeWei = await this.getAssetInfo();
                }
                let param = this.web3.eth.abi.encodeParameters(['uint256', 'uint256'], [this.unCrypto.tokenId, sizeWei]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType]?.sendTransaction(this.unCrypto.blockchain, this.user.wallet, this.unCrypto.contractAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.buyLoading = false;
                    // 失败后回到上一步
                    this.action = 'BUY';
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.buyLoading = false;
                // 失败后回到上一步
                this.action = 'BUY';
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 成功后进入下一步
                this.action = 'BEING_CONFIRMED';
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 失败后回到上一步
                this.action = 'BUY';
            }
            // 加载完成
            this.buyLoading = false;
        },
        // 查询交易收据
        getTransactionReceipt(){
            let params = {
                type: "CRYPTO_BUY",
                blockchain: this.unCrypto.blockchain,
                transactionHash: this.transactionHash
            }
            this.$bus.emit('emitGetTransactionReceipt', params);
            // // 轮训查询交易
            // this.transactionReceiptInterval = setInterval(() => {
            //     let params = {
            //         type: "CRYPTO_BUY",
            //         blockchain: this.unCrypto.blockchain,
            //         transactionHash: this.transactionHash
            //     }
            //     this.$bus.emit('emitGetTransactionReceipt', params);
            // }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            console.log(data);
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                if (data.status) {
                    // 交易成功，显示完成
                    this.action = 'COMPLETION';
                } else {
                    // 交易失败，显示失败
                    let fail = { title: 'Fail', codes: ['FAIL'] };
                    this.steps.splice(this.steps.length - 1, 1, fail);
                    this.action = 'FAIL';
                }
                // 根据主键查询加密货币
                this.getCryptoById();
                // 刷新子组件数据
                this.$refs.unCryptoOwner?.getCryptoById();
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询交易收据
                    this.getTransactionReceipt();
                }, 3000);
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style lang="scss">
.buy-input-font .v-input__control .v-field__field .v-field__input {
    font-size: 42px !important;
    padding: 0 !important;
    line-height: 54px !important;
    font-family: "Montserrat Bold", sans-serif !important;
}
</style>
<style scoped lang="scss">
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
.elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15),
     5px -5px 10px rgba(0, 0, 0, 0.15),
     0px 0px 0px,
     -5px -5px 10px rgba(0, 0, 0, 0.15);
    z-index: 9;
}
.title-elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05),
     5px -5px 10px rgba(0, 0, 0, 0.05),
     0px 0px 0px,
     -5px -5px 10px rgba(0, 0, 0, 0.05);
    z-index: 9;
}
</style>