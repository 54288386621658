<template>
	<div class="bg-lightGray">
        <!-- 设置 -->
        <div :class="responsive == 'mobile' ? 'fill-height h-v' : 'h-v'">
            <v-container>
                <v-card max-width="1400" rounded="0" elevation="0" color="transparent" align="center" class="pt-7 pb-7 mx-auto">
                    <v-card max-width="740" rounded="0" elevation="0" color="transparent" class="mb-8">
                        <div class="text-left text-grey04">
                            <h1 class="sub-page-h1">404</h1>
                        </div>
                    </v-card>
                    <v-card max-width="740" color="black" elevation="12" align="start" class="pa-10" :rounded="0">
                        <!----------- Email Notifications ----------->
                        <h2 class="body-h4 text-primaryGrey">Oops! You've ventured into uncharted territory.</h2>
                        <v-divider class="my-7"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="12" class="text-primaryGrey body-p">
                                We apologize for the detour! It seems you've stumbled upon a page that doesn't exist. Our imaginary traders must be on vacation.
                            </v-col>
                            <v-col cols="12" class="mt-4 text-primaryGrey body-p">
                                <ul>Don't worry, we've got you covered! Here are a few options to get you back on track:
                                    <li class="ml-10 mt-4"><span class="font-weight-bold">Return to Safety :</span> Hit that shiny button below to safely navigate back to our homepage and explore our fascinating world of untrade.
                                        <div class="mt-4">
                                            <router-link to="/" class="del-underline gradient-left-red-purple-200">
                                                <v-btn rounded="0" elevation="2" variant="flat" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52"> Return to Homepage</v-btn>
                                        </router-link>
                                        </div>
                                    </li>
                                    <li class="ml-10 mt-4">
                                        <span class="font-weight-bold">Need Assistance? : </span>If you're feeling lost or need a guiding hand, our friendly team is here to help. Feel free to reach out to us by clicking on the Chat button at the lower-right corner  with any questions or concerns.
                                    </li>
                                </ul>
                            </v-col>
                            <v-col cols="12" class="mt-4 text-primaryGrey body-p">
                                Remember, life's too short for boring trades! We're sorry for any inconvenience caused, and we hope you enjoy your journey back to the heart of untrading!
                            </v-col>
                            <v-col cols="12" class="mt-4 text-primaryGrey body-p">
                                <div>Happy exploring,</div>
                                <div>The untrading Team</div>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- <v-card max-width="740" elevation="0" color="transparent" align="start">
                        <a class="gradient-left-red-purple-300 del-underline">
                            <v-btn rounded="0" width="300" height="66" class="text-none text-primaryGrey mt-16 mb-8 body-p" aria-label="Save Changes" @click="update">Save Changes</v-btn>
                        </a>
                    </v-card> -->
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            
        }
    },
    components: {  },
    created(){
   
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(["responsive"])
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>