<template>
	<div class="h-100 bg-homeBg">
        <v-container fluid class="h-100">
            <v-row no-gutters justify="center" align="end" class="h-100">
                <Hero></Hero>
                <v-col cols="12" sm="12" md="12" class="text-center" align="bottom">
                    <h5 class="body-p-small-b text-primaryGrey pb-4">Built to disrupt</h5>
                    <div class="arrow-scroll">
                        <div class="arrow-scroll__wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="18" viewbox="0 0 38 18">
                                <path fill="#7b7b7b" d="M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z"></path>
                            </svg>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container> 
        <v-container fluid class="footer-class fullscreen bg-black r-p-x-0 pa-0">
            <!-- Revolution -->
            <v-row no-gutters class="py-250">
                <v-col cols="12" class="r-p-x-4 px-0">
                    <Revolution></Revolution>
                </v-col>
            </v-row>

            <!-- Kingdom -->
            <v-row no-gutters justify="center" align="end" class="mb-100 pb-250">
                <v-col cols="12" sm="12" md="8" xl="8">
                    <div class="d-flex flex-column mb-16 r-p-x-4">
                        <div class="home-36 marcellus-sc">Build Your Kingdom on Untrading</div>
                        <div class="home-24 marcellus-sc text-primaryGrey font-weight-bold mt-4">Become a King, or Queen, serve your realm, bridge the on-chain Silk Road.</div>
                        <div class="home-16 lexend-deca text-primaryGrey mt-4">Kingdoms on Untrading allow you to utilize our technologies to build your collaborative decentralized hedge pools for your community.</div> 
                    </div>
                    <Kingdom></Kingdom>
                </v-col>
            </v-row>


            <v-row no-gutters class="bg-homeBg py-250" justify="center" align="center">
                <v-col cols="12" class="px-0">
                    <CryptoScreenshot class="mb-200"></CryptoScreenshot>
                    <v-row no-gutters justify="start" align="center">
                        <v-col cols="12" sm="12" lg="3" xl="3" xxl="4" class="position-relative marketTop">
                            <Market></Market>
                        </v-col>
                    </v-row>
                    <!-- <v-row no-gutters justify="center" align="center">
                        <v-col cols="12" sm="12" md="8" xl="8" xxl="12">
                            <Market></Market>
                        </v-col>
                    </v-row> -->
                </v-col>
            </v-row>

            <!-- CryptoScreenshot -->
            <v-row no-gutters class="py-250" justify="center" align="center">
                <v-col cols="12">
                    <NftScreenshot></NftScreenshot>
                </v-col>
            </v-row>

            <!-- Data -->
            <v-row no-gutters class="py-250 bg-homeBg" justify="center" align="center">
               <v-col cols="12" sm="12" md="8">
                    <Data></Data>
               </v-col>
            </v-row>

            <v-row no-gutters class="py-250" justify="center" align="center">
                <v-col cols="12" md="7" sm="12" class="px-0 r-p-l-4 home-20 text-primaryGrey lexend-deca">
                    Self-Funding and Control: With over $2 million dollars invested with skin in the game, we are committed to reduce the conflicts in strategic direction or pressure for short-term gains at the expense of long-term value creation
                </v-col>
            </v-row>

            <!-- FAQ -->
            <v-row no-gutters class="py-250 bg-homeBg" justify="center" align="center">
                <v-col cols="12" sm="12" md="8" class="px-0 r-p-x-4">
                    <div class="h3 marcellus-sc">FAQs</div>
                </v-col>
                <v-col cols="12" sm="12" md="8" class="px-0 r-p-x-4 mt-8">
                    <Faq></Faq>
                    <div class="del-underline gradient-underline-hover text-primary mt-5 r-p-l-0">
                        <a href="https://docs.untrading.org/faqs" target="_blank" class="h5 text-primary lexend-deca">
                            More <span class="mdi mdi-arrow-right-thin"></span>
                        </a>
                    </div>
                </v-col>
            </v-row>

            <!-- News -->
            <v-row no-gutters class="py-250 bg-homeBg" justify="center" align="center">
                <v-col cols="12" sm="12" md="8" class="px-0 r-p-x-4">
                    <div class="h3 marcellus-sc mb-16">In the News</div>
                    <Meida></Meida>
                </v-col>
            </v-row>
            
            <v-row no-gutters justify="center" class="bg-homeBg pb-250">
                <v-col cols="12" sm="12" md="10" class="px-0">
                    <BuiltOn></BuiltOn>
                </v-col>
            </v-row>

            <v-divider></v-divider>
            <!-- Footer -->
            <v-row no-gutters justify="center" class="bg-homeBg">
                <v-col cols="12" sm="12" md="8" class="px-0">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters justify="center" class="bg-homeBg" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" class="gradient-underline-hover h5 d-flex justify-end align-center my-2">
                    <Theme show-text size="22"></Theme>
                </v-col>
            </v-row>
        </v-container> 
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import BuiltOn from './components/BuiltOn.vue';
import Hero from './components/Hero.vue';
import CryptoScreenshot from './components/CryptoScreenshot.vue';
import Data from './components/Data.vue';
import Faq from './components/Faq.vue';
import Revolution from './components/Revolution.vue';
import Footer from './components/Footer.vue';
import Kingdom from './components/Kingdom.vue';
import NftScreenshot from './components//NftScreenshot.vue';
import Market from './components//Market.vue';
import Meida from './components//Meida.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            scroll: 0,
        }
    },
    components: {BuiltOn, Hero, CryptoScreenshot, Data, Faq, Kingdom, NftScreenshot, Market, Meida, Footer, Revolution ,Theme},
    created(){
      
    },
    mounted(){

    },
    computed: {
            ...mapGetters(["darkTheme",'responsive'])
        },
    watch:{

    },
    methods: {
    
    }
}
</script>
<style scoped>

</style>