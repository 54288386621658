const unNFT_ABI = require('@/abi/unNFT-abi-v2.json')
const unCrypto_ABI = require('@/abi/unCrypto-abi-v2.json')
const ERC20_STANDARD_ABI = require('@/abi/ERC20-standard-abi.json')
const ERC721_STANDARD_ABI = require('@/abi/ERC721-standard-abi.json')
const UN_KYC_REGISTRY_ABI = require('@/abi/UN-KYC-registry-abi.json')
const UN_SNAPSHOT_CLAIM_ABI = require('@/abi/UN-snapshot-claim-abi.json')


export default {
    "mainnet": [
        {
            "blockchain": "Arbitrum One",
            "symbol": "ETH",
            "chainId":"0xa4b1",
            "icon": "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/12c2c47d-43a9-4612-071b-f82a736fda00/public",
            "blockchainExplorer": "Arbiscan",
            "blockchainExplorerUrl": "https://arbiscan.io",
            "unNFTContract": "0x79ea5d780cd5c32489b557db5029ecd8f9041cf0",
            "unNFTAbi": unNFT_ABI,
            "unCryptoAbi": unCrypto_ABI,
            "ERC20StandardAbi": ERC20_STANDARD_ABI,
            "ERC721StandardAbi": ERC721_STANDARD_ABI,
            "UNKYCRegistryContract": "0xb49c774ffa9981cf4cae8d7284b8c1968a6e1bf1",
            "UNSnapshotClaimContract": "0x3de0b177555ff19b3e2076f22a6502cac58fed96",
            "UNTokenContract": "0xa4336ddfe88712e3211384df631f5afa38b65eaf",
            "UNKYCRegistryAbi": UN_KYC_REGISTRY_ABI,
            "UNSnapshotClaimAbi": UN_SNAPSHOT_CLAIM_ABI,
            "infuraId": "caba5c1064a142c6b7701facf313317c",
            "RPCUrl": "https://arb-mainnet.g.alchemy.com/v2/02oy3f1uLDhqYzGwzWiUOabS4KnboM3v",
            "mainnetRPCUrl": "https://arb-mainnet.g.alchemy.com/v2/02oy3f1uLDhqYzGwzWiUOabS4KnboM3v",
            "USDCContract": "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
            "isSupportCreateNFT": true,
            "isSupportCreateCrypto": true
        },
        {
            "blockchain": "Ethereum",
            "symbol": "ETH",
            "chainId":"0x1",
            "icon": "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/1628d151-d7d4-4b3e-636b-6ff2c234ef00/public",
            "blockchainExplorer": "Etherscan",
            "blockchainExplorerUrl": "https://etherscan.io",
            "unNFTContract": "0x28711e10070dcc0b073168b95eec0f222f187a4b",
            "unNFTAbi": unNFT_ABI,
            "unCryptoAbi": unCrypto_ABI,
            "ERC20StandardAbi": ERC20_STANDARD_ABI,
            "ERC721StandardAbi": ERC721_STANDARD_ABI,
            "UNKYCRegistryContract": null,
            "UNSnapshotClaimContract": null,
            "UNTokenContract": null,
            "UNKYCRegistryAbi": UN_KYC_REGISTRY_ABI,
            "UNSnapshotClaimAbi": UN_SNAPSHOT_CLAIM_ABI,
            "infuraId": "caba5c1064a142c6b7701facf313317c",
            "RPCUrl": "https://eth-mainnet.g.alchemy.com/v2/J5wWlR3kkoyGdMSMr6D2XFo0wDjA5JOw",
            "mainnetRPCUrl": "https://eth-mainnet.g.alchemy.com/v2/J5wWlR3kkoyGdMSMr6D2XFo0wDjA5JOw",
            "USDCContract": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "isSupportCreateNFT": true,
            "isSupportCreateCrypto": true
        },
        {
            "blockchain": "Polygon",
            "symbol": "MATIC",
            "chainId":"0x89",
            "icon": "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c613372d-83c8-4eb6-8bc8-212570732700/public",
            "blockchainExplorer": "Polygonscan",
            "blockchainExplorerUrl": "https://polygonscan.com",
            "unNFTContract": "0x33ce2cd509330586ad747d536a0d59a302c3053d",
            "unNFTAbi": unNFT_ABI,
            "unCryptoAbi": unCrypto_ABI,
            "ERC20StandardAbi": ERC20_STANDARD_ABI,
            "ERC721StandardAbi": ERC721_STANDARD_ABI,
            "UNKYCRegistryContract": null,
            "UNSnapshotClaimContract": null,
            "UNTokenContract": null,
            "UNKYCRegistryAbi": UN_KYC_REGISTRY_ABI,
            "UNSnapshotClaimAbi": UN_SNAPSHOT_CLAIM_ABI,
            "infuraId": "caba5c1064a142c6b7701facf313317c",
            "RPCUrl": "https://polygon-mainnet.g.alchemy.com/v2/AIhegydqn_kcbg28gY1MGfvVtbNx7ALA",
            "mainnetRPCUrl": "https://polygon-mainnet.g.alchemy.com/v2/AIhegydqn_kcbg28gY1MGfvVtbNx7ALA",
            "USDCContract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
            "isSupportCreateNFT": true,
            "isSupportCreateCrypto": true
        }
    ],
    "testnet": [
        {
            "blockchain": "Sepolia",
            "symbol": "ETH",
            "chainId": "0xaa36a7",
            "icon": "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/1628d151-d7d4-4b3e-636b-6ff2c234ef00/public",
            "blockchainExplorer": "Etherscan",
            "blockchainExplorerUrl": "https://sepolia.etherscan.io",
            "unNFTContract": "0x96cdd4fc1b7b3e20fee9f64ef6e2f167dd922d0a",
            "unNFTAbi": unNFT_ABI,
            "unCryptoAbi": unCrypto_ABI,
            "ERC20StandardAbi": ERC20_STANDARD_ABI,
            "ERC721StandardAbi": ERC721_STANDARD_ABI,
            "UNKYCRegistryContract": "0x2101a52e506be5b9d7cac2b3d498984dd7a5b1c7",
            "UNSnapshotClaimContract": "0x533c415c51e6c62d08c5215598d6cfe4c501a56c",
            "UNTokenContract": "0x8a1b547a2dd5631a00072713b19d4b8c3e9fcd7a",
            "UNKYCRegistryAbi": UN_KYC_REGISTRY_ABI,
            "UNSnapshotClaimAbi": UN_SNAPSHOT_CLAIM_ABI,
            "infuraId": "d2b104f3076d4e62aa79669c47bf8bfe",
            "RPCUrl": "https://eth-sepolia.g.alchemy.com/v2/PjBZqNbk8VXL6cPZ_EHj4zvg2-KExm6A",
            "mainnetRPCUrl": "https://eth-mainnet.g.alchemy.com/v2/J5wWlR3kkoyGdMSMr6D2XFo0wDjA5JOw",
            "USDCContract": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "isSupportCreateNFT": true,
            "isSupportCreateCrypto": true
        },
        {
            "blockchain": "Goerli",
            "symbol": "ETH",
            "chainId": "0x5",
            "icon": "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/1628d151-d7d4-4b3e-636b-6ff2c234ef00/public",
            "blockchainExplorer": "Etherscan",
            "blockchainExplorerUrl": "https://goerli.etherscan.io",
            "unNFTContract": "0x1918ad8c32074d56a4dd7c9349a63c810943adc0",
            "unNFTAbi": unNFT_ABI,
            "unCryptoAbi": unCrypto_ABI,
            "ERC20StandardAbi": ERC20_STANDARD_ABI,
            "ERC721StandardAbi": ERC721_STANDARD_ABI,
            "UNKYCRegistryContract": null,
            "UNSnapshotClaimContract": null,
            "UNTokenContract": null,
            "UNKYCRegistryAbi": UN_KYC_REGISTRY_ABI,
            "UNSnapshotClaimAbi": UN_SNAPSHOT_CLAIM_ABI,
            "infuraId": "d2b104f3076d4e62aa79669c47bf8bfe",
            "RPCUrl": "https://eth-goerli.g.alchemy.com/v2/wDtaukpHPDshVPO0-WBgx2PZaYI7459V",
            "mainnetRPCUrl": "https://eth-mainnet.g.alchemy.com/v2/J5wWlR3kkoyGdMSMr6D2XFo0wDjA5JOw",
            "USDCContract": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "isSupportCreateNFT": false,
            "isSupportCreateCrypto": false
        },
        {
            "blockchain": "Mumbai",
            "symbol": "MATIC",
            "chainId":"0x13881",
            "icon": "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c613372d-83c8-4eb6-8bc8-212570732700/public",
            "blockchainExplorer": "Polygonscan",
            "blockchainExplorerUrl": "https://mumbai.polygonscan.com",
            "unNFTContract": "0xe9a66f7c67878cfc79453f4e65b39e98de934d5a",
            "unNFTAbi": unNFT_ABI,
            "unCryptoAbi": unCrypto_ABI,
            "ERC20StandardAbi": ERC20_STANDARD_ABI,
            "ERC721StandardAbi": ERC721_STANDARD_ABI,
            "UNKYCRegistryContract": null,
            "UNSnapshotClaimContract": null,
            "UNTokenContract": null,
            "UNKYCRegistryAbi": UN_KYC_REGISTRY_ABI,
            "UNSnapshotClaimAbi": UN_SNAPSHOT_CLAIM_ABI,
            "infuraId": "d2b104f3076d4e62aa79669c47bf8bfe",
            "RPCUrl": "https://polygon-mumbai.g.alchemy.com/v2/gahqFaNplnukoh_fieCC76918NcJtLM0",
            "mainnetRPCUrl": "https://polygon-mainnet.g.alchemy.com/v2/AIhegydqn_kcbg28gY1MGfvVtbNx7ALA",
            "USDCContract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
            "isSupportCreateNFT": false,
            "isSupportCreateCrypto": false
        }
    ]
}