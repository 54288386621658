<template>
    <div class="h-100">
        <v-container align="center" class="h-100">
            <v-row no-gutters align="center" class="h-100">
                <v-col cols="12">
                    For the best experience, connect from a desktop browser.
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
</style>