<template>
	<div class="bg-lightGray h-100">
        <v-container class="h-100" align="center">
            <!-- ----------------------------- Back -------------------------------- -->
            <v-card max-width="582" rounded="0" elevation="0" color="transparent" class="pt-4" align="start">
                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3" :class="responsive != 'pc' ? 'ml-2' : ''" @click="$router.back(-1)"></v-icon>
            </v-card>
            <!-- ----------------------------- Unwrap -------------------------------- -->
            <v-card max-width="1400" rounded="0" elevation="0" color="transparent" class="mx-auto">
                <v-card max-width="604" elevation="0"  color="transparent" class="px-3 pb-3" :loading="loading" :rounded="0">
                    <template v-slot:loader="{ isActive }">
                        <v-progress-linear :active="isActive" color="primary" indeterminate></v-progress-linear>
                    </template>
                    <v-row no-gutters class="py-4 bg-black" :class="responsive == 'pc' ? 'px-8' : 'px-4'">
                        <v-col cols="8" class="text-left">
                            <h3 class="body-h4 text-primaryGrey">
                                <span v-if="unCrypto.contractFunction == 'UNWRAP'">Unwrapped</span>
                                <span v-else>Unwrap (Request Unwrap)</span>
                            </h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right text-primaryGrey body-p">
                            <!-- 区块链 -->
                            <Blockchain :blockchain="unCrypto.blockchain" size="24" text></Blockchain>
                        </v-col>
                    </v-row>
                    <!-- <v-divider class="my-4"></v-divider> -->
                    <div class="bg-f2f2f2-666666 pt-2 pb-4 text-left elevation-show" :class="responsive == 'pc' ? 'px-8' : 'px-4'">
                        <!-- <v-col cols="12" class="px-0 text-left">
                            <h1 class="sub-page-h1 text-grey05">
                                {{ assetInfo.currentAssetAmount > 0 ? $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) : '' }}
                                {{ unCrypto.wrappedCurrency }} #{{ $tools.formatNumber(unCrypto.tokenId, 0) }}
                            </h1>
                        </v-col> -->
                        <v-row no-gutters class="my-2">
                            <v-col cols="12" class="text-grey05 body-p-large mb-1">
                                <span class="gradient-underline-hover">
                                    <router-link :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" class="text-grey05 gradient-text-hover">
                                        {{ unCrypto.wrappedCurrency }} #{{ $tools.formatNumber(unCrypto.tokenId, 0) }}
                                    </router-link>
                                </span>
                            </v-col>
                            <v-col cols="12" class="text-grey05">
                                <div class="d-flex justify-space-between">
                                    <div class="home-h3">{{ assetInfo.currentAssetAmount > 0 ? $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) : '' }}</div>
                                    <div class="title-h3" v-if="responsive == 'pc'">{{ unCrypto.wrappedCurrency }}/{{ unCrypto.quoteCurrency }}</div>
                                </div>
                            </v-col>
                            <!-- <v-col cols="12" lg="4" class="text-grey05 list-p" v-if="responsive == 'pc'">
                                {{ unCrypto.wrappedCurrency }}/{{ unCrypto.quoteCurrency }}
                            </v-col> -->
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters justify="space-between">
                            <h3 class="text-grey05 body-h5">Non-fungible Future Rewards (nFR) Parameters</h3>
                            <v-col cols="12" class="px-0">
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <TotalRewardValue :value="unCrypto.totalRewardRatio" text-color="text-grey05"></TotalRewardValue>
                                    </v-col>
                                    <v-col cols="6">
                                        <FutureRewardValue :value="100 - unCrypto.originatorRewardRatio" text-color="text-grey05"></FutureRewardValue>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <OriginatorRewardValue :value="unCrypto.originatorRewardRatio" text-color="text-grey05"></OriginatorRewardValue>
                                    </v-col>
                                    <v-col cols="6">
                                        <GenerationValue :value="unCrypto.numberOfGenerations" text-color="text-grey05"></GenerationValue>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="unCrypto.contractFunction != 'UNWRAP'" class="bg-black text-primaryGrey py-15 text-left elevation-show" :class="responsive == 'pc' ? 'px-8' : 'px-4'">
                        <v-row no-gutters>
                            <!-- 数量 -->
                            <v-col cols="12">
                                <h5 class="text-primaryGrey body-h5 mb-8">You will unwrap</h5>
                            </v-col>
                            <v-col cols="9">
                                <!-- <v-text-field v-model="assetInfo.currentAssetAmount" type="number" placeholder="0" density="comfortable" class="body-p-small text-primaryGrey" variant="solo" hide-details readonly></v-text-field> -->
                                <span class="wrap-input text-primaryGrey">{{ $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) }}</span>
                            </v-col>
                            <v-col cols="3" class="text-right">
                                <!-- <v-text-field :model-value="unCrypto.wrappedCurrency" density="comfortable" class="body-p-small text-primaryGrey" variant="solo" hide-details readonly></v-text-field> -->
                                <span class="list-p text-primaryGrey">{{ unCrypto.wrappedCurrency }}</span>
                            </v-col>
                            <v-divider class="my-3"></v-divider>
                            <!-- 摘要 -->
                            <v-row no-gutters class="mt-2">
                                <!-- <v-col cols="12">
                                    <h5 class="text-primaryGrey body-p-medium mb-4">Summary</h5>
                                </v-col> -->
                                <v-col cols="12">
                                    <!-- <v-text-field density="comfortable" :model-value="`You will unwrap ${$tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals)} ${unCrypto.wrappedCurrency}`" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field> -->
                                    <span class="text-primaryGrey body-h5 mb-4">{{ `You will unwrap ${$tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals)} ${unCrypto.wrappedCurrency}` }}</span>
                                </v-col>
                            </v-row>
                        </v-row>
                    </div>
                </v-card>
                <!-- 操作 -->
                <v-card max-width="588" elevation="0" color="transparent" align="start" :class="responsive == 'pc' ? 'px-9' : 'px-7'">
                    <div v-if="unCrypto.contractFunction != 'UNWRAP'" class="mt-4">
                        <!-- 已上市显示修改价格 -->
                        <div>
                            <!-- 上市 -->
                            <!-- <router-link :to="`/uncrypto/list/${unCrypto.id}`" class="gradient-left-red-purple-245 del-underline" :class="unwrapLoading ? 'pointer-none' : 'pointer'">
                                <v-btn :rounded="0" :disabled="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b" width="245" height="52" aria-label="List for sale">List for sale</v-btn>
                            </router-link> -->
                            <!-- 解封 -->
                            <span class="gradient-left-red-purple-block" v-if="!getUnwrapSignatureLoading">
                                <v-btn v-if="isOwner && currentUnwrapSignature.status == 'REQUESTED'" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" disabled aria-label="Requested">Requested</v-btn>
                                <v-btn v-else-if="isOriginator && currentUnwrapSignature.status == 'SIGNED'" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" disabled aria-label="Approved">Approved</v-btn>
                                <v-btn v-else-if="unCrypto.permission?.confirmUnwrap && unCrypto.permission?.signUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" @click="unwrap()" aria-label="Unwrap">Unwrap</v-btn>
                                <v-btn v-else-if="unCrypto.permission?.requestUnwrap && !unCrypto.permission?.signUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" @click="requestUnwrap()" aria-label="Request Unwrapping">Request Unwrapping</v-btn>
                                <v-btn v-else-if="!unCrypto.permission?.requestUnwrap && unCrypto.permission?.signUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" @click="signUnwrap()" aria-label="Approve Unwrapping">Approve Unwrapping</v-btn>
                                <v-btn v-else-if="unCrypto.permission?.confirmUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" @click="unwrap()" aria-label="Confirm Unwrapping">Confirm Unwrapping</v-btn>
                                <v-btn v-else :rounded="0" :loading="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b r-m-t-4" height="52" disabled aria-label="Unwrap">Unwrap</v-btn>
                            </span>
                        </div>
                    </div>
                    <div class="mb-4 mt-4">
                        <!-- 浏览 -->
                        <router-link :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" class="gradient-left-red-purple-block del-underline" :class="unwrapLoading ? 'pointer-none' : 'pointer'">
                            <v-btn rounded="0" :disabled="unwrapLoading" color="button01" class="text-none block text-grey05 body-p-small-b" height="52" aria-label="View">View</v-btn>
                        </router-link>
                    </div>
                </v-card>
            </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose" @signSuccess="signSuccess" @signError="signError"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose" @signSuccess="signSuccess" @signError="signError"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import * as Ethers from "ethers";
import CryptoAPI from '@/api/crypto.js';
import CryptoWrappedAPI from '@/api/crypto-wrapped.js';
import CryptoUnwrapAPI from '@/api/crypto-unwrap.js';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 封装后的加密货币
            unCrypto: {},
            // 当前的区块链
            currentBlockchain: {},
            // 当前的Web3服务对象
            web3: {},
            // 当前的合约对象
            contract: {},
            // 资产信息
            assetInfo: {
                currentAssetAmount: 0
            },
            // 加密货币封装资产
            cryptoWrapped: {},
            // 解封加载中
            unwrapLoading: false,
            // 加密货币解封参数
            unwrapCrypto: {
                cryptoId: null,
                blockchain: null,
                transactionHash: null,
                signature: null
            },
            // 签名
            signature: null,
            // 当前的解封状态
            unwrapStatus: null,
            // 查询 unwrap 签名加载中
            getUnwrapSignatureLoading: false,
            // 当前最新一条签名记录
            currentUnwrapSignature: {},
        }
    },
    components: { },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "CRYPTO_UNWRAP") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        // 根据主键查询加密货币
        this.getCryptoById();
    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'walletType', 'responsive']),
        // 是否为拥有者
        isOwner() {
            return this.user?.id == this.unCrypto?.ownerUserId;
        },
        // 是否为发起人
        isOriginator() {
            return this.user?.id == this.unCrypto?.userId;
        }
    },
    watch:{
        
    },
    methods: {
         // 改变区块链条件
         changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.id == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.id);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
                // 不是拥有者 && 不是发起人，则跳转到详情页面
                if (!this.isOwner && !this.isOriginator) {
                    this.$router.push(`/uncrypto/${this.unCrypto?.wrappedCurrency?.toLowerCase()}/${this.id}`);
                    return;
                }
                // 查询最新一条签名记录
                this.getUnwrapSignature();
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.unCrypto.blockchain)[0]
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 创建合约
                this.contract = new this.web3.eth.Contract(this.currentBlockchain.unCryptoAbi, this.unCrypto.contractAddress);
                // 根据区块链和地址查询加密货币封装资产详情
                await this.getCryptoWrappedDetail();
                // 查询资产信息
                this.getAssetInfo();
            }
            this.loading = false;
        },
        // 查询资产信息
        async getAssetInfo() {
            let result = await this.contract.methods.getAssetInfo(this.unCrypto.tokenId).call();
            this.assetInfo.currentAssetAmount = this.$web3Utils.fromWei(result, this.cryptoWrapped.tokenDecimals);
            // console.log('AssetInfo: ', this.assetInfo);
        },
        // 根据区块链和地址查询加密货币封装资产详情
        async getCryptoWrappedDetail() {
            let res = await CryptoWrappedAPI.getCryptoWrappedDetail(this.unCrypto.blockchain, this.unCrypto.contractAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoWrapped = data.data;
            }
        },
        // 查询最新一条签名记录
        async getUnwrapSignature() {
            if (this.id == null) {
                return;
            }
            this.getUnwrapSignatureLoading = true;
            let res = await CryptoUnwrapAPI.getUnwrapSignature(this.id);
            let data = res.data;
            if(data.success) {
                this.currentUnwrapSignature = data.data;
                this.signature = this.currentUnwrapSignature.signature;
            }
            this.getUnwrapSignatureLoading = false;
        },
        // 请求解封加密货币
        async requestUnwrap() {
            this.unwrapLoading = true;
            this.unwrapStatus = 'REQUESTED';
            // 已签名则则请求，未签名则调用钱包签名
            if(this.signature) {
                // 已签名则调用 api
                let params = { cryptoId: this.id };
                let res = await CryptoUnwrapAPI.requestUnwrap(params);
                let data = res.data;
                this.unwrapLoading = false;
                if(data.success && data.data) {
                    this.$store.dispatch('snackbarMessageHandler', "Requested!");
                    // 监听查询未读通知数量
                    this.$bus.emit('emitGetUnreadCount', null);
                    // 根据主键查询加密货币
                    this.getCryptoById();
                } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                }
            } else {
                // 调起钱包签名
                if(this.walletType) {
                    this.$refs[this.walletType].signUnCryptoTypedData(this.cryptoWrapped, this.unCrypto.blockchain, this.unCrypto?.owner?.wallet, this.unCrypto.tokenId);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    this.unwrapLoading = false;
                }
            }
        },
        // 签名解封加密货币
        async signUnwrap() {
            this.unwrapLoading = true;
            this.unwrapStatus = 'SIGNED';
            // 已签名则则请求，未签名则调用钱包签名
            if(this.signature) {
                // 已签名则调用 api
                let params = { 
                    cryptoId: this.id,
                    signature: this.signature
                };
                let res = await CryptoUnwrapAPI.signUnwrap(params);
                let data = res.data;
                this.unwrapLoading = false;
                if(data.success && data.data) {
                    this.$store.dispatch('snackbarMessageHandler', "Approved!");
                    // 监听查询未读通知数量
                    this.$bus.emit('emitGetUnreadCount', null);
                    // 根据主键查询加密货币
                    this.getCryptoById();
                } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                }
            } else {
                // 调起钱包签名
                if(this.walletType) {
                    this.$refs[this.walletType].signUnCryptoTypedData(this.cryptoWrapped, this.unCrypto.blockchain, this.unCrypto?.owner?.wallet, this.unCrypto.tokenId);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    this.unwrapLoading = false;
                }
            }
        },
        // 直接解封加密货币
        async unwrap() {
            this.unwrapLoading = true;
            this.unwrapStatus = 'UNWRAPPED';
            // 已签名则调用 api
            if(this.signature) {
                // 交易成功
                if(this.unwrapTransactionSuccess) {
                    let res = await CryptoUnwrapAPI.unwrap(this.unwrapCrypto);
                    let data = res.data;
                    this.unwrapLoading = false;
                    if(data.success && data.data) {
                        // 监听查询未读通知数量
                        this.$bus.emit('emitGetUnreadCount', null);
                        // 跳转到 Profile
                        window.open('/profile/' + this.user.username, '_self');
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                } else {
                    // 已签名但还未发送合约交易，发送解封交易
                    this.sendUnwrapTransaction();
                }
            } else {
                // 调起钱包签名
                if(this.walletType) {
                    this.$refs[this.walletType].signUnCryptoTypedData(this.cryptoWrapped, this.unCrypto.blockchain, this.unCrypto?.owner?.wallet, this.unCrypto.tokenId);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    this.unwrapLoading = false;
                }
            }
        },
        // 签名成功
        signSuccess(signature) {
            console.log("Sign Success!");
            this.signature = signature;
            switch (this.unwrapStatus) {
                case 'REQUESTED':
                    // 请求解封加密货币
                    this.requestUnwrap();
                    break;
                case 'SIGNED':
                    // 签名解封加密货币
                    this.signUnwrap();
                    break;
                case 'UNWRAPPED':
                    // 发送解封交易
                    this.sendUnwrapTransaction();
                    break;
                default:
                    break;
            }
        },
        // 签名错误
        signError(signature){
            this.unwrapLoading = false;
        },
        // 发送解封交易
        async sendUnwrapTransaction() {
            this.unwrapLoading = true;
            // 编码参数列表
            try {
                // 分割签名
                let sig = Ethers.utils.splitSignature(this.signature);
                // 合约方法
                let method  = this.web3.eth.abi.encodeFunctionSignature('unwrap(address,uint256,uint8,bytes32,bytes32)');
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameters(['address', 'uint256', 'uint8', 'bytes32', 'bytes32'], [this.unCrypto?.owner?.wallet, this.unCrypto.tokenId, sig.v, sig.r, sig.s]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.unCrypto.blockchain, this.user.wallet, this.unCrypto.contractAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.unwrapLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.unwrapLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            // 交易成功
            if(success) {
                this.unwrapTransactionSuccess = true;
                // 更新参数
                this.unwrapCrypto.cryptoId = this.id;
                this.unwrapCrypto.blockchain = this.unCrypto.blockchain;
                this.unwrapCrypto.transactionHash = transactionHash;
                this.unwrapCrypto.signature = this.signature;
                // 查询交易收据
                this.getTransactionReceipt();
                // 直接解封加密货币
                this.unwrap();
            } else {
                this.unwrapTransactionSuccess = false;
                // 加载完成
                this.unwrapLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt(){
            let params = {
                type: "CRYPTO_UNWRAP",
                blockchain: this.unwrapCrypto.blockchain,
                transactionHash: this.unwrapCrypto.transactionHash
            }
            this.$bus.emit('emitGetTransactionReceipt', params);
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 加载完成
                this.unwrapLoading = false;
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询交易收据
                    this.getTransactionReceipt();
                }, 3000);
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        next();
    }
}
</script>
<style lang="scss">
.unwrap-input-font .v-input__control .v-field__field .v-field__input {
    font-size: 30px !important;
    padding: 0 !important;
    line-height: 40px !important;
    font-family: "Montserrat Bold", sans-serif !important;
}
</style>
<style scoped lang="scss">
.elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15),
     5px -5px 10px rgba(0, 0, 0, 0.15),
     0px 0px 0px,
     -5px -5px 10px rgba(0, 0, 0, 0.15),
}
</style>