import axios from '@/config/api.request'

export default {
    // 查询 UN 代币概览
    getUNTokenOverview() {
        return axios.request({
            url: '/un/token/overview',
            method: 'get'
        })
    },
    // 查询 UN 资产概览
    getUNAssetOverview() {
        return axios.request({
            url: '/un/asset/overview',
            method: 'get'
        })
    },
}