<template>
    <div>
        <v-snackbar v-model="snackbar" location="bottom right" :timeout="timeout" rounded="0" color="primary" style="z-index:3001">
            <v-icon aria-label="mdi mdi-information-outline" color="white" icon="mdi:mdi-information-outline" size="24"></v-icon>
            <span class="ml-2 body-p-small-b text-white">{{ snackbarMessage }}</span>
        </v-snackbar>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            snackbar: false,
            timeout: 5000
        }
    },
    props: {
        
    },
    components: {  },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['snackbarMessage']),
    },
    watch:{
        snackbarMessage(n, o) {
            if(n) {
                this.snackbar = true;
            }
        }
    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>