<template>
	<div>
        <v-expansion-panels class="mb-6">
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-white03 r-p-x-0 px-0 lexend-deca" expand-icon="mdi:mdi-plus"><h4>What is untrading?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="home-18 text-primaryGrey lexend-deca">
                    <div>Untrading is a revolutionary DeFi platform designed to power the economic foundations of Network States - digital-first communities that operate like nations in the cloud. Our unique ERC-5173 standard allows users to profit from asset appreciation even after selling, creating a new paradigm for collaborative wealth creation and community building.</div>
                    <div class="mt-8">
                        Key features: 
                    </div>
                    <ul class="mt-2">
                        <li>Continuous Rewards: Benefit from Future Rewards on assets you've sold.</li>
                        <li>Kingdom Building: Create and grow your own digital community or "kingdom."</li>
                        <li>Network State Infrastructure: Provide the economic bedrock for emerging digital nations.</li>
                        <li>Community-Centric: Foster long-lasting connections and shared prosperity among members.</li>
                        <li>Influencer-Friendly: KOLs can transform their following into thriving, reward-generating communities.</li>
                    </ul>
                    <div class="mt-8">
                        Untrading isn't just a trading platform; it's a tool for building and sustaining the decentralized societies of tomorrow. Whether you're an individual trader, a crypto influencer, or a visionary looking to create a Network State, Untrading offers the infrastructure to turn these ambitions into reality.
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-white03 r-p-x-0 px-0 lexend-deca" expand-icon="mdi:mdi-plus"><h4>What are kingdoms, how do they work?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="home-18 text-primaryGrey lexend-deca">
                    <div>Kingdoms are our gamified approach to community building and collaborative investing. Here's how they work:</div>
                    <ul class="mt-2">
                        <li>Start as a Knight: Begin your journey by creating or joining a small community.</li>
                        <li>Grow Your Influence: As your community grows and engages more with the platform, you climb the ranks.</li>
                        <li>Set Rules: As a leader, you can set custom rules for asset management and profit-sharing within your kingdom.</li>
                        <li>Collaborative Wealth: Work together with your community to maximize returns and share in the prosperity.</li>
                        <li>Rise to Royalty: Progress from Knight to Sheikh, Sultan, Jiangjun, and ultimately to King or Queen as your kingdom expands.</li>
                        <li>Outside Tournament: Join Untrading's annual tournaments and compete in exotic places around the world for prizes with other kingdoms.</li>
                    </ul>
                    <div class="mt-8">Kingdoms incentivize community growth, active participation, and collaborative strategies, aligning with Untrading's core principle of cooperative wealth creation.</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-white03 r-p-x-0 px-0 lexend-deca" expand-icon="mdi:mdi-plus"><h4>Why would I want to share my profit?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="home-18 text-primaryGrey lexend-deca">
                    <div>Sharing profits on Untrading isn't about giving away your gains - it's about unlocking new avenues for wealth creation:</div>
                    <ul class="mt-2">
                        <li>Ongoing Rewards: By sharing a small portion of your profits, you gain the right to receive future rewards from assets you've sold, allowing you to defer a portion of your current earnings in exchange for the potential to receive larger future payouts, similar to buying a variable annuity. </li>
                        <li>Larger Profit Potential: The collective growth of assets benefits all participants, potentially leading to larger overall gains.</li>
                        <li>Reduced Risk: Diversify your earnings across multiple assets, even ones you no longer own.</li>
                        <li>Community Benefits: Stronger communities lead to more stable markets and better trading opportunities.</li>
                        <li>Aligned Incentives: When everyone benefits from an asset's success, it encourages better market behavior and reduces manipulation.</li>
                        <li>Transparency: The nFR framework provides more transparency around the asset's provenance and the distribution of future rewards, as it is built on the blockchain.</li>
                    </ul>
                    <div class="mt-8">Remember, on Untrading, you're not just sharing profits - you're gaining access to a new paradigm of collaborative wealth creation.</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
export default {
    data(){
        return {
           
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
 ul {
    list-style: none; /* 去掉默认的列表标记符号 */
    padding-left: 0;
  }
  
  li {
    display: flex; /* 使用 flexbox 布局 */
    align-items: flex-start; /* 对齐文本 */
  }
  
  li::before {
    content: '•'; /* 自定义列表标记符号 */
    font-size: 24px; /* 调整符号的大小 */
  margin-right: 10px; /* 添加标记符号与文本的间距 */
    line-height: 1; /* 控制符号的垂直对齐 */
  }
  
</style>