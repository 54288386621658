<template>
    <div>
        <v-container fluid class="mt-8">
           <v-card max-width="1080" elevation="0" class="mx-0-auto" color="transparent">
                <v-row justify="center" no-gutters>
                    <v-col cols="12" sm="12">
                        <div class="pb-200" v-if="loading">
                            <SkeletonBanner></SkeletonBanner>
                        </div>
                        <v-carousel v-model="carouselIndex" v-show="!loading" :continuous="false" :show-arrows="false" interval="5000" :height="responsive == 'mobile' ? height : 860" cycle hide-delimiter-background color="d7d" delimiter-icon="mdi:mdi-circle" class="carousel-item-active">
                            <v-carousel-item v-for="(nft, i) in nfts" :key="i">
                                <!-- ------------------------------ NFT ------------------------------ -->
                                <v-row id="banner-card" justify="center" no-gutters>
                                    <v-col cols="12" sm="6" class="pl-10 r-p-l-0">
                                        <!-- <div class="text-right body-h4 mb-6 text-primaryGrey text-uppercase r-m-r-2">FEATURED {{ nft.department == 'Artwork' ? 'Art' : nft.department }}</div> -->
                                        <h1 class="text-right body-h4 mb-6 text-grey04 text-uppercase r-m-r-2">{{ getFeaturedTitle(nft, i) }}</h1>
                                        <NFTPriceLineChart class="text-center justify-center r-m-l-1 r-m-r-1" :nftId="nft.id" :tokenId="nft.tokenId" v-if="responsive == 'mobile'"></NFTPriceLineChart>
                                        <div>
                                            <!-- pc -->
                                            <v-card elevation="12" rounded="0"  color="transparent" max-width="648" class="mx-auto" v-if="responsive == 'pc'">
                                                <div class="ml-n3">
                                                    <MediaFile :nftId="nft.id" overlay class="white--text align-end w-100"></MediaFile>
                                                    <div class="px-8 py-3">
                                                        <v-row no-gutters>
                                                            <v-col cols="12" sm="6" class="pr-6">
                                                                <div class="body-p-small text-primaryGrey">Total Reward
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            <!-- {{ $t('common.totalReward') }} -->
                                                                            The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div class="my-2 body-p-small text-primaryGrey">{{ nft.totalRewardRatio }}%</div>
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <div class="body-p-small text-primaryGrey">Future Rewards (FR)
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            <!-- {{ $t('common.futureRewards') }} -->
                                                                            Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div class="my-2 body-p-small text-primaryGrey">{{ 100 - nft.originatorRewardRatio }}%</div>
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row no-gutters class="mt-4">
                                                            <v-col cols="12" sm="6" class="pr-6">
                                                                <div class="body-p-small text-primaryGrey"># of generations
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            <!-- {{ $t('common.ofGenerations') }} -->
                                                                            The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div class="my-2 body-p-small text-primaryGrey">{{ nft.numberOfGenerations }}</div>
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <div class="body-p-small text-primaryGrey">Originator Reward (OR)
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            <!-- {{ $t('common.originatorReward') }} -->
                                                                            The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div class="my-2 body-p-small text-primaryGrey">{{ nft.originatorRewardRatio }}%</div>
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <v-row justify="end" align="center" no-gutters class="pb-4 px-8">
                                                        <v-col cols="6" class="pr-6">
                                                            <div class="body-p-small-b text-right text-primaryGrey">
                                                                <span>{{ nft.price > 0 ? 'Current price' : 'Unlisted' }}</span>
                                                            </div>
                                                            <div class="sidebar-h2 text-right mt-1 text-primaryGrey">
                                                                <span>{{ nft.price > 0 ? $tools.formatNumber(nft.price) + ' ' + nft.paymentToken : '--' }}</span>
                                                            </div>
                                                        </v-col >
                                                        <v-col cols="6">
                                                            <div class="body-p-small-b text-right text-primaryGrey">Originator</div>
                                                            <div class="mt-1 d-flex flex-nowrap justify-end">
                                                                <!-- <span class="body-p text-primaryGrey mr-2">{{ nft.user.name }}</span> -->
                                                                <span class="gradient-underline-hover mr-2">
                                                                    <router-link @click.stop :to="'/profile/' + nft.user.username" class="del-underline gradient-text-hover">
                                                                        <span class="body-p text-primaryGrey ellipsis-line">{{ nft.user.name }}</span>
                                                                    </router-link>
                                                                </span>
                                                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :size="24"></UserAvatar>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card>
                                            <!-- mobile -->
                                            <v-card elevation="6" rounded="0"  color="transparent" max-width="648" class="r-m-l-0 r-m-r-2" v-if="responsive == 'mobile'">
                                                <MediaFile :nftId="nft.id" overlay class="white--text align-end w-100"></MediaFile>
                                                <div class="d-flex justify-space-between mt-5 mx-4 pb-8" v-if="responsive == 'mobile'">
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="6">
                                                            <div class="body-p-small">
                                                                <div>Total Reward
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            {{ $t('common.totalReward') }}
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div>{{ nft.totalRewardRatio }}%</div>
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" class="mt-4">
                                                            <div class="body-p-small">
                                                                <div>Future Rewards
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            {{ $t('common.futureRewards') }}
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div>{{ 100 - nft.originatorRewardRatio }}%</div>
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="ml-4">
                                                        <v-col cols="12" sm="6">
                                                            <div class="body-p-small">
                                                                <div>Originator Rewards
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            {{ $t('common.originatorReward') }}
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div>{{ nft.originatorRewardRatio }}%</div>
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" class="mt-4">
                                                            <div class="body-p-small">
                                                                <div># of generations
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            {{ $t('common.ofGenerations') }}
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                                <div>{{ nft.numberOfGenerations }}</div>
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <v-row justify="end" align="center" no-gutters class="pb-4 px-8">
                                                    <v-col cols="6" class="pr-6">
                                                        <div class="body-p-small-b text-right text-primaryGrey">
                                                            <span>{{ nft.price > 0 ? 'Current price' : 'Unlisted' }}</span>
                                                        </div>
                                                        <div class="sidebar-h2 text-right mt-1 text-primaryGrey">
                                                            <span>{{ nft.price > 0 ? $tools.formatNumber(nft.price) + ' ' + nft.paymentToken : '--' }}</span>
                                                        </div>
                                                    </v-col >
                                                    <v-col cols="6">
                                                        <div class="body-p-small-b text-primaryGrey text-right">Originator</div>
                                                        <div class="mt-1 d-flex flex-nowrap justify-end">
                                                            <!-- <span class="body-p text-primaryGrey mr-2">{{ nft.user.name }}</span> -->
                                                            <span class="gradient-underline-hover mr-2">
                                                                <router-link @click.stop :to="'/profile/' + username" class="del-underline gradient-text-hover parent">
                                                                    <span class="body-p text-primaryGrey ellipsis-line" style="width:80px;">{{ nft.user.name }}</span>
                                                                </router-link>
                                                            </span>
                                                            <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :size="24"></UserAvatar>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="5" offset-lg="1" sm="6">
                                        <v-card max-width="400" elevation="0" class="h-100 px-1 mt-10" color="transparent" v-if="responsive == 'pc'">
                                            <NFTPriceLineChart :nftId="nft.id" :tokenId="nft.tokenId"></NFTPriceLineChart>
                                            <div class="d-flex justify-space-between pb-3 pt-16">
                                                <router-link to="/market" class="del-underline gradient-left-red-purple-182">
                                                    <v-btn :rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="48" aria-label="Explore">Explore</v-btn>
                                                </router-link>
                                                <router-link to="/unnft/mint" class="del-underline gradient-left-red-purple-182">
                                                    <v-btn :rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="48" aria-label="Create">Create</v-btn>
                                                </router-link>
                                            </div>
                                            <div class="mt-9">
                                               <v-menu>
                                                    <template v-slot:activator="{ props: menu }">
                                                        <v-tooltip location="top" max-width="392">
                                                            <template v-slot:activator="{ props: tooltip }">
                                                                <router-link to="/unnft/wrap" class="del-underline gradient-left-red-purple-block">
                                                                    <v-btn v-bind="mergeProps(menu, tooltip)" block :rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Wrap/Upgrade NFTs" width="392" height="48">Wrap/Upgrade NFTs</v-btn>
                                                                </router-link>
                                                            </template>
                                                            <!-- <span>{{ $t('common.wrapToolip') }}</span> -->
                                                            <span>Wrap and upgrade the NFTs you bought from other platforms to become an Originator on the untrading Protocol to take advantage of nFRs</span>
                                                        </v-tooltip>
                                                    </template>
                                               </v-menu>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                            <!-- ------------------------------ unETH ------------------------------ -->
                            <v-carousel-item v-if="false">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" sm="6">
                                        <div class="text-right body-h4 mb-6 text-primaryGrey">FEATURED CRYPTO</div>
                                        <div class="sidebar-h2 mb-6 text-primaryGrey">
                                            <span class="ml-2">unETH</span>
                                            <span class="float-right">{{ $tools.formatNumber(currentETHPrice) }} USDT</span>
                                        </div>
                                        <PriceLineChart @receiveCurrentPrice="receiveCurrentPrice"></PriceLineChart>
                                        <v-row class="ml-7 mt-8">
                                            <div class="d-flex justify-space-between">
                                                <div class="mr-8 body-p-small">
                                                    <div>Total Reward</div>
                                                    <div>35%</div>
                                                    <v-divider></v-divider>
                                                </div>
                                                <div class="mr-8 body-p-small">
                                                    <div>Future Rewards</div>
                                                    <div>60%</div>
                                                    <v-divider></v-divider>
                                                </div>
                                                <div class="mr-8 body-p-small">
                                                    <div>Originator Rewards</div>
                                                    <div>40%</div>
                                                    <v-divider></v-divider>
                                                </div>
                                                <div class="body-p-small">
                                                    <div># of generations</div>
                                                    <div>10</div>
                                                    <v-divider></v-divider>
                                                </div>
                                            </div>
                                        </v-row>
                                        <v-row justify="end" align="center" no-gutters class="mt-8">
                                            <v-col cols="12" sm="7">
                                                <div class="body-p-small-b text-right text-primaryGrey">Buy Now</div>
                                                <div class="sidebar-h2 text-right mt-1 text-primaryGrey">0.21598 @{{ $tools.formatNumber(1408.54) }} USDT</div>
                                            </v-col>
                                            <v-col cols="12" sm="3" class="ml-16">
                                                <div class="body-p-small-b text-right pr-1 text-primaryGrey">Originator</div>
                                                <div class="text-right mt-1">
                                                    <span class="body-p text-primaryGrey">@LouisLiu</span>
                                                    <v-avatar icon size="24" class="pointer ml-2">
                                                        <v-img src="https://pbs.twimg.com/profile_images/1286654002810122240/jO6to70E_normal.jpg"></v-img>
                                                    </v-avatar>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="5" offset="1">
                                        <v-card max-width="400" elevation="0" class="h-100 px-1">
                                            <div class="hero-h1 mt-9 pb-16 text-primaryGrey">{{ banner.title }}</div>
                                            <div class="d-flex justify-space-between mt-200 pb-3 pt-16" v-if="responsive == 'pc'">
                                                <router-link to="/sets" class="del-underline gradient-left-red-purple-182">
                                                    <v-btn :rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="48" aria-label="Collect">Collect</v-btn>
                                                </router-link>
                                                <router-link to="/unnft/mint" class="del-underline gradient-left-red-purple-182">
                                                    <v-btn :rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="182" height="48" aria-label="Create">Create</v-btn>
                                                </router-link>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                        </v-carousel>
                        <div class="pb-6 pt-16 text-center" v-if="responsive == 'mobile'">
                            <v-row no-gutters justify="center">
                                <div class="flex-nowrap">
                                    <router-link to="/sets" class="del-underline gradient-left-red-purple-142 mr-10">
                                        <v-btn :rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small-b" width="142" height="48" aria-label="Collect">Collect</v-btn>
                                    </router-link>
                                    <router-link to="/unnft/mint" class="del-underline gradient-left-red-purple-142">
                                        <v-btn :rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small-b" width="142" height="48" aria-label="Create">Create</v-btn>
                                    </router-link>
                                </div>
                                <div class="mt-4">
                                    <v-menu>
                                        <template v-slot:activator="{ props: menu }">
                                            <v-tooltip location="top" max-width="312">
                                                <template v-slot:activator="{ props: tooltip }">
                                                    <router-link to="/unnft/wrap" class="del-underline gradient-left-red-purple-block">
                                                        <v-btn v-bind="mergeProps(menu, tooltip)" block :rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small-b" aria-label="Wrap/Upgrade NFTs" min-width="324" height="48">Wrap/Upgrade NFTs</v-btn>
                                                    </router-link>
                                                </template>
                                                <!-- <span>{{ $t('common.wrapToolip') }}</span> -->
                                                Wrap and upgrade the NFTs you bought from other platforms to become an Originator on the untrading Protocol to take advantage of nFRs
                                            </v-tooltip>
                                        </template>
                                    </v-menu>
                                </div>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
           </v-card>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mergeProps } from 'vue'
import NFTAPI from '@/api/nft.js';
import PriceLineChart from "@/components/charts/PriceLineChart.vue";
import NFTPriceLineChart from "@/components/charts/NFTPriceLineChart.vue";
import SkeletonBanner from '@/components/util/skeleton/SkeletonBanner.vue';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            banner: {
                title: 'Your assets, upgraded',
                content: 'Bringing originators and collectors together on-chain'
            },
            nfts: [],
            // ETH 当前价格
            currentETHPrice: 0,
            // 加载中
            loading: false,
            // 轮播图的下标
            carouselIndex: 0,
            // 默认高度，根据数据动态计算
            // 978 高 = 918 内容 + 10 间距 + 50 轮播
            height: 978
        }
    },
    components: { PriceLineChart, NFTPriceLineChart, SkeletonBanner, MediaFile },
    created(){
        this.getFeatured();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'blockchain','responsive'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.getFeatured();
        }
    },
    methods: {
        mergeProps,
        // 查询精选的 NFT 列表
        async getFeatured() {
            this.loading = true;
            let res = await NFTAPI.getFeatured(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data;
                this.loading = false;
                this.carouselIndex = 0;
            }
            setTimeout(() => {
                let bannerCard = document.getElementById('banner-card');
                if(bannerCard) {
                    let contentHeight = bannerCard.height || bannerCard.clientHeight || bannerCard.offsetHeight || bannerCard.scrollHeight || this.height;
                    this.height = contentHeight + 10 + 50;
                }
            }, 1)
        },
        // 接收当前价
        receiveCurrentPrice(currentPrice){
            this.currentETHPrice = currentPrice;
        },
        // 查询精选标题
        getFeaturedTitle(nft, index){
            if(index == 0) {
                return 'FEATURED ON UNTRADING APP';
            }
            
            // 使用slice()方法截取下标1到结尾的部分
            // 使用reduce方法将对象数组分成不同的集合
            const blockchainMap = this.nfts.slice(1).reduce((acc, obj) => {
                const { blockchain } = obj;
                // 如果acc中已经存在该分组，则将对象推入对应的集合
                if (acc[blockchain]) {
                    acc[blockchain].push(obj);
                } else { // 否则，创建一个新的集合，并以blockchain为键进行存储
                    acc[blockchain] = [obj];
                }
                    return acc;
            }, {});
            // 第一个的区块链
            let firstBlockchain = this.nfts[0].blockchain.toUpperCase();
            // 当前这个在所在区块链中的下标
            let i = blockchainMap[nft.blockchain].indexOf(nft);
            // 如果当前和第一个在同一区块链则下标加一
            i = (nft.blockchain != this.nfts[0].blockchain) ? i : (i + 1);
            let title = 'FEATURED ' + nft.blockchain.toUpperCase() + ' UNNFT';
            return i == 0 ? title : (title + ' ' + (i + 1));
        }
    }
}
</script>
<style scoped>

</style>