<template>
	<div class="mt-3 mb-5 ml-4 r-m-l-0">
        <v-row v-if="isMe && !loading" no-gutters>
            <v-col cols="10" md="12" sm="6" class="px-0 r-p-r-4">
                <div class="c-container">
                    <div class="c-p-card c-grid d-flex flex-column">
                        <div class="c-height" v-for="(set, index) in sets" :key="index" :value="index">
                            <v-card rounded="0" elevation="12" color="transparent">
                                <v-hover v-slot="{ isHovering, props }">
                                    <router-link :to="'/set/' + set.id" class="del-underline">
                                        <v-img :src="set.coverImage" aspect-ratio="1" cover v-bind="props">
                                            <v-expand-transition>
                                                <div class="pa-4 bg-darkgray v-card--reveal" v-if="isHovering">
                                                    <!-- 上传头像弹窗 -->
                                                    <div class="d-flex flex-nowrap align-center">
                                                        <div>
                                                            <v-img :src="set.logoImage" width="87" height="87" class="text-center align-center" cover>
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon v-bind="props" @click.prevent="currentSetId = set.id;editUploadLogoImageDialog = true" icon="fa-solid fa-pencil" class="text-white"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        Upload logo image
                                                                    </v-card>
                                                                </v-menu>
                                                            </v-img>
                                                        </div>
                                                        <div class="parent">
                                                            <div class="ml-4 nft-title ellipsis-line text-white">
                                                                {{ set.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="text-white">
                                                        <!-- 上传封面弹窗 -->
                                                        <div class="mt-10 text-center">
                                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom">
                                                                <template v-slot:activator="{ props }" >
                                                                    <v-icon v-bind="props" @click.prevent="currentSetId = set.id;editUploadFeaturedImageDialog = true" icon="fa-solid fa-pencil" size="32"></v-icon>
                                                                </template>
                                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                    Upload featured image
                                                                </v-card>
                                                            </v-menu>
                                                        </div>
                                                        <!-- 用户信息 -->
                                                        <v-card class="absolute-bottom-30 w-100" elevation="0" :rounded="0" color="transparent">
                                                            <div class="d-flex">
                                                                <UserAvatar :avatar="userProfile.user.avatar" :username="userProfile.user.username"></UserAvatar>
                                                                <div class="body-text-btn mx-2 ellipsis-line" style="width: 65%">{{ userProfile.user.name }}</div>
                                                                <div class="text-right">
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom">
                                                                        <template v-slot:activator="{ props }" >
                                                                        <v-icon v-bind="props" @click.prevent="currentSetId = set.id;deleteDialog = true" icon="mdi:mdi-trash-can-outline" size="32" class="text-white"></v-icon>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            Delete
                                                                        </v-card>
                                                                    </v-menu>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </div>
                                                </div>
                                            </v-expand-transition>
                                        </v-img>
                                    </router-link>
                                </v-hover>
                            </v-card>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>   
        <!-- <v-row no-gutters justify="center" align="center" class="text-center mt-176" v-if="loading">
            <v-col>
                <v-progress-circular :size="100" color="primaryGrey" :width="10" indeterminate></v-progress-circular>
            </v-col>
        </v-row> -->
        <!-- 加载中骨架 -->
        <div class="c-container" v-if="loading">
            <div class="c-p-card c-grid d-flex flex-column">
                <SkeletonProfileSet :length="3"></SkeletonProfileSet>
            </div>
        </div>
        <v-row v-if="!isMe" no-gutters>
            <v-col cols="10" md="12" sm="6" class="px-0 r-p-r-4">
                <div class="c-container">
                    <div class="c-p-card c-grid d-flex flex-column">
                        <div class="c-height"  v-for="(set, index) in sets" :key="index" :value="index">
                            <v-card rounded="0" elevation="2" color="transparent">
                                <v-hover v-slot="{ isHovering, props }">
                                    <router-link :to="'/set/' + set.id" class="del-underline">
                                        <v-img :src="set.coverImage" aspect-ratio="1" cover v-bind="props">
                                            <v-expand-transition>
                                                <div class="pa-4 bg-darkgray v-card--reveal" v-if="isHovering">
                                                    <div class="mb-10 d-flex flex-nowrap align-center">
                                                        <div>
                                                            <v-img :src="set.logoImage" width="87" height="87" cover></v-img>
                                                        </div>
                                                        <div class="parent">
                                                            <div class="ml-4 nft-title ellipsis-line text-white">
                                                                {{ set.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="text-white">
                                                        <v-card class="absolute-bottom-30 w-100" elevation="0" :rounded="0" color="transparent">
                                                            <div class="d-flex">
                                                                <UserAvatar :avatar="userProfile.user.avatar" :username="userProfile.user.username"></UserAvatar>
                                                                <div class="body-text-btn mx-2 ellipsis-line" style="width: 75%">{{ userProfile.user.name }}</div>
                                                            </div>
                                                        </v-card>
                                                    </div>
                                                </div>
                                            </v-expand-transition>
                                        </v-img>
                                    </router-link>
                                </v-hover>
                            </v-card>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!-- -------------------- 弹窗 -------------------- -->
        <!-- 编辑头像 -->
        <v-dialog v-model="editUploadLogoImageDialog" content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8 bg-paleCyan" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex px-0">
                            <div class="sidebar-h2 text-primaryGrey">Upload logo image</div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="editUploadLogoImageDialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="px-0">
                            <div class="body-p text-primaryGrey">Drag and drop here, or click below to upload a JPG, PNG GIF file. </div>
                            <div class="mt-3 body-p text-primaryGrey">350 x 350 recommended.</div>
                            <div class="mt-3 body-p text-primaryGrey">This image will also be used for natvigation.</div>
                            <div class="fs-18 pt-16 mt-16 text-center">
                                <el-upload class="gradient-left-red-purple-300 text-primaryGrey" ref="upload" :action="$config.baseUrl+ '/file/upload/cloudflare/image'" :show-file-list="false" accept="image/jpg, image/jpeg, image/png, image/gif, image/avif" :before-upload="uploadBefore" :on-success="uploadLogoImageSuccess">
                                    <v-btn :rounded="0" elevation="2" :loading="uploading" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Select a file on computer">Select a file on computer</v-btn>
                                </el-upload>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 编辑封面图 -->
        <v-dialog v-model="editUploadFeaturedImageDialog" content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8 bg-paleCyan" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex px-0">
                            <div class="sidebar-h2 text-primaryGrey">Upload featured image</div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="editUploadFeaturedImageDialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="px-0">
                            <div class="body-p text-primaryGrey">Drag and drop here, or click below to upload a JPG, PNG GIF file.  </div>
                            <div class="mt-3 body-p text-primaryGrey">500 x 500 recommended.</div>
                            <div class="mt-3 body-p text-primaryGrey">This image will also be used for the cover background. It will also be used for featuring your set on promotional areas of untrading.</div>
                            <div class="fs-18 pt-16 mt-16 text-center">
                                <el-upload class="gradient-left-red-purple-300 text-primaryGrey" ref="upload" :action="$config.baseUrl+ '/file/upload/cloudflare/image'" :show-file-list="false" accept="image/jpg, image/jpeg, image/png, image/gif, image/avif" :before-upload="uploadBefore" :on-success="uploadCoverImageSuccess">
                                    <v-btn :rounded="0" elevation="2" :loading="uploading" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Select a file on computer">Select a file on computer</v-btn>
                                </el-upload>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 删除套装弹窗 -->
        <v-dialog v-model="deleteDialog" content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8 bg-paleCyan" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex px-0">
                            <div class="sidebar-h2 text-primaryGrey">Delete Set</div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="deleteDialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="px-0">
                            <div class="body-p text-primaryGrey">Set "{{ sets.filter(set => set.id === currentSetId)[0].name }}" will be permanently deleted, and it cannot be restored. It does not delete the unNFTs, only removes them.</div>
                            <div class="fs-18 mt-16 text-center">
                                <a class="gradient-left-red-purple-100">
                                    <v-btn :rounded="0" elevation="2" :loading="deleteLoading" width="100" height="52" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Delete" @click="deleteSet">Delete</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SetAPI from '@/api/set.js';
import SkeletonProfileSet from '@/components/util/skeleton/SkeletonProfileSet.vue';
export default {
    data(){
        return {
            //编辑上传logo图片
            editUploadLogoImageDialog: false,
            //编辑封面图片
            editUploadFeaturedImageDialog: false,
            // 删除套装弹窗
            deleteDialog: false,
            // 套装
            sets: [],
            // 当前操作的套装
            currentSetId: null,
            // 文件
            file: null,
            // 上传中
            uploading: false,
            // 加载中
            loading: false,
            // 删除中
            deleteLoading: false
        }
    },
    props: {
        userProfile: {
            type: Object,
            default: null
        },
        sortBy: {
            type: String,
            default: null
        },
        blockchain: {
            type: String,
            default: null
        }
    },
    components: { SkeletonProfileSet },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user']),
        // 是我
        isMe(){
            return this.userProfile && this.userProfile.user && this.user && this.userProfile.user.id == this.user.id;
        }
    },
    watch:{
        username: {
            handler(n, o) {
                this.getSets();
            },
            immediate: true
        },
        sortBy(n, o) {
            this.changeCondition();
        },
        blockchain(n, o) {
            this.changeCondition();
        }
    },
    methods: {
        // 查询所有的套装
        async getSets() {
            this.loading = true;
            let res = await SetAPI.sets(this.userProfile.user.username, this.sortBy, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.sets = data.data;
                this.$emit('receiveSetsCount', data.data.length);
            } else {
                this.$emit('receiveSetsCount', 0);
            }
            this.loading = false;
        },
        // 加载文件
        loadFile(param){
            this.file = param.file;
        },
        // 头像上传之前
        uploadBefore(rawFile) {
            this.uploading = true;
        },
        // 头像上传成功
        async uploadLogoImageSuccess(data, uploadFile) {
            if(data.success) {
                // 更新
                let params = {
                    setId: this.currentSetId,
                    logoImage: data.data
                }
                let res = await SetAPI.updateLogo(params);
                let updateLogoData = res.data;
                if(updateLogoData.success) {
                    // 查询所有的套装
                    this.getSets();
                }
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
            this.uploading = false;
            this.editUploadLogoImageDialog = false;
        },
        // 封面上传成功
        async uploadCoverImageSuccess(data, uploadFile) {
            if(data.success) {
                // 更新
                let params = {
                    setId: this.currentSetId,
                    coverImage: data.data
                }
                let res = await SetAPI.updateCover(params);
                let updateCoverData = res.data;
                if(updateCoverData.success) {
                    // 查询所有的套装
                    this.getSets();
                }
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
            this.uploading = false;
            this.editUploadFeaturedImageDialog = false;
        },
        // 删除
        async deleteSet() {
            this.deleteLoading = true;
            let res = await SetAPI.delete(this.currentSetId);
            let data = res.data;
            if(data.success) {
                // 查询所有的套装
                this.getSets();
                // 刷新用户数据，重新统计数量
                this.$emit('refresh', null);
                // 更新全局套装数据
                this.$bus.emit('emitSets', {});
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
            this.deleteLoading = false;
            this.deleteDialog = false;
        },
        // 改变条件
        changeCondition() {
            // 恢复data定义的所有参数
            Object.assign(this.$data, this.$options.data.call(this));
            // 重新查询数据
            this.getSets();
        },
    }
}
</script>
<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}
:deep(.v-echarts-dialog){
    width: auto;
}
</style>