<template>
	<span>
        <a class="del-underline gradient-left-red-purple-100">
            <v-btn rounded="0" :loading="loading" variant="outlined" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Follow" width="100" height="33" @click="followOrUnfollow">{{ isFollowed ? 'Following' : 'Follow' }}</v-btn>
        </a>
    </span>
</template>
<script>
import FollowAPI from '@/api/follow.js'
export default {
    data(){
        return {
            // 查询当前用户是否已关注对方
            isFollowed: false,
            // 加载中
            loading: false
        }
    },
    props: ['followedUserId'],
    components: {  },
    created(){

    },
    mounted(){
        
    },
    computed: {

    },
    watch:{
        // 监听对方用户主键改变则查询
        followedUserId: {
            handler(n, o){
                if(n) {
                    // 查询当前用户是否已关注对方
                    this.followed();
                }
            },
            immediate: true
        }
    },
    methods: {
        // 关注或者取关
        followOrUnfollow(){
            if(this.isFollowed) {
                this.unfollow();
            } else {
                this.follow();
            }
        },
        // 关注
        async follow() {
            this.loading = true;
            let param = {
                followedUserId: this.followedUserId
            }
            let res = await FollowAPI.follow(param);
            let data = res.data;
            if(data.success) {
                this.followed();
            }
            this.loading = false;
            // 刷新数据
            this.refresh();
        },
        // 取关
        async unfollow() {
            this.loading = true;
            let param = {
                followedUserId: this.followedUserId
            }
            let res = await FollowAPI.unfollow(param);
            let data = res.data;
            if(data.success) {
                this.followed();
            }
            this.loading = false;
            // 刷新数据
            this.refresh();
        },
        // 查询当前用户是否已关注对方
        async followed() {
            let param = {
                followedUserId: this.followedUserId
            }
            let res = await FollowAPI.followed(param);
            let data = res.data;
            if(data.success) {
                this.isFollowed = data.data;
            }
        },
        // 刷新
        refresh(){
            this.$emit('refresh', null)
        }
    }
}
</script>
<style scoped>

</style>