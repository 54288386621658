<template>
	<div>
        <h2 class="body-text-btn text-grey04">UN Token Vesting Schedule</h2>
        <table class="bg-f2f mt-5 w-100">
            <thead>
                <tr>
                    <th class="text-left form-text" :style="{width: responsive == 'pc' ? '100px' : '65px'}"></th>
                    <th class="text-left form-text" style="width: 65px;">Cliff Period</th>
                    <th class="text-left form-text" style="width: 65px;">Vesting Duration</th>
                    <th class="text-left form-text">Vesting Schedule</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Early Investors & Strategic Partners</td>
                    <td>3 Months</td>
                    <td>4 Years</td>
                    <td>Unlocked quarterly subject to a 4-year vesting schedule with a 3-month cliff.</td>
                </tr>
                <tr>
                    <td>Strategic Investors</td>
                    <td>1 Year</td>
                    <td>2 Years</td>
                    <td>Unlocked quarterly subject to a 2-year vesting schedule with a 1-year cliff.</td>
                </tr>
                <tr>
                    <td>Ecosystem</td>
                    <td>1 Year</td>
                    <td>5 Years</td>
                    <td>10% unlocked at launch to kickstart development, remaining 90% subject to a 5-year vesting schedule with a 1-year cliff.</td>
                </tr>
                <tr>
                    <td>Development Fund</td>
                    <td>6 Months</td>
                    <td>2 Years</td>
                    <td>10% unlocked at launch to kickstart development, remaining 90% subject to a 2-year vesting schedule with a 6-month cliff.</td>
                </tr>
                <tr>
                    <td>Team</td>
                    <td>1 Year</td>
                    <td>4 Years</td>
                    <td>25% unlocked after the first year, followed by monthly vesting of the remaining 75%.</td>
                </tr>
                <tr>
                    <td>Marketing and Partnerships</td>
                    <td>6 Months</td>
                    <td>3 Years</td>
                    <td>50% unlocked at launch for immediate utilization, remaining 50% subject to a 3-year vesting schedule with a 6-month cliff.</td>
                </tr>
                <tr>
                    <td>Liquidity Provision</td>
                    <td>6 Months</td>
                    <td>2 Years</td>
                    <td>50% unlocked at launch for immediate utilization, remaining 50% subject to a 2-year vesting schedule with a 6-month cliff.</td>
                </tr>
                <tr>
                    <td>User Rewards</td>
                    <td>3 Months</td>
                    <td>2 Years</td>
                    <td>10% unlocked at launch, remaining 90% subject to a 2-year vesting schedule with a 3-month cliff.</td>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td>6 Months</td>
                    <td>2 Years</td>
                    <td>Unlocked quarterly subject to a 2-year vesting schedule with a 6-month cliff.</td>
                </tr>
            </tbody>
        </table>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
table { 
	width: 100%; 
	border-collapse: collapse; 
}

tr th {
	padding: 2px 0px; 
	border-bottom: 2px solid rgb(var(--v-theme-black01)); 
	text-align: left; 
}

td { 
	padding: 2px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
}

[data-toggle="toggle"] {
	display: none;
}
</style>