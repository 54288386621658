<template>
	<div v-if="token">
        <v-card rounded="0" color="black" class="pa-8" :class="responsive == 'pc' ? 'elevation-12' : 'elevation-6'">
            <!-- ******************************* Asset Overview ******************************* -->
            <div>
                <h2 class="body-text-btn text-grey04">UN Asset Overview</h2>
                <table class="mt-3 w-100">
                    <thead>
                        <tr>
                            <th class="text-left form-text">Blockchain</th>
                            <th class="text-right form-text">Price</th>
                            <th class="text-right form-text">Balance</th>
                            <th class="text-right form-text">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" v-if="loading">
                            <td colspan="4">Loading...</td>
                        </tr>
                        <tr v-if="!loading" v-for="(balance, i) in assetOverview?.assetBalances" :key="`balance-${i}`">
                            <td>{{ balance.blockchain }}</td>
                            <td class="text-right">{{ $tools.formatAmount(balance.price, -1) }}</td>
                            <td class="text-right">{{ $tools.formatNumber(balance.balance, 2) }}</td>
                            <td class="text-right">{{ $tools.formatAmount(balance.value, 2) }}</td>
                        </tr>
                        <tr class="text-center" v-if="!loading && assetOverview?.assetBalances?.length == 0">
                            <td colspan="4">No Data Available</td>
                        </tr>
                        <tr class="text-center" v-if="!loading && assetOverview?.totalAssetBalance != null">
                            <td colspan="4">
                                <v-divider></v-divider>
                            </td>
                        </tr>
                        <tr v-if="!loading && assetOverview?.totalAssetBalance != null">
                            <td class="text-left form-text">Total</td>
                            <td></td>
                            <td class="text-right">{{ $tools.formatNumber(assetOverview?.totalAssetBalance?.balance, 2) }}</td>
                            <td class="text-right">{{ $tools.formatAmount(assetOverview?.totalAssetBalance?.value, 2) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UNAPI from "@/api/un.js";
export default {
    data(){
        return {
            // 加载中
            loading: true,
            // 资产概览
            assetOverview: {
                assetBalances: [],
                totalAssetBalance: null
            }
        }
    },
    components: {  },
    created(){
        
    },
    mounted(){
        this.$nextTick(() => {
            // 初始化
            this.init();
        });
    },
    computed: {
        ...mapGetters(['responsive', 'user', 'token', 'blockchains', 'walletType']),
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
        user: {
            handler(newVal, oldVal) {
                if(newVal?.wallet) {
                    // 查询 UN 资产概览
                    this.getUNAssetOverview();
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        // 初始化
        init() {
            // 当前区块链
            // if (this.$config.env == 'prod') {
            //     this.currentBlockchainNetwork = 'Arbitrum One';
            // }
            // this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.currentBlockchainNetwork)[0];
            // // 创建 web3
            // this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
        },
        
        // 查询 UN 资产概览
        async getUNAssetOverview() {
            if (!this.token) {
                return;
            }
            this.loading = true;
            let res = await UNAPI.getUNAssetOverview();
            let data = res.data;
            if(data.success) {
                this.assetOverview = data.data;
            }
            this.loading = false;
        },
        
    }
}
</script>
<style scoped>
table { 
	width: 100%; 
	border-collapse: collapse; 
}

tr th {
	padding: 2px 0px; 
	border-bottom: 2px solid rgb(var(--v-theme-black01)); 
	text-align: left; 
}

/* td { 
	padding: 2px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
} */

[data-toggle="toggle"] {
	display: none;
}
.v-selection-control--density-compact {
    --v-selection-control-size: 12px;
}
</style>