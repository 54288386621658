<template>
	<div>
        <v-dialog v-model="disputeDialog" content-class="v-echarts-dialog">
            <v-card max-width="500" class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col cols="12" sm="12">
                        <div>
                             <v-card-title class="d-flex px-0">
                                <v-icon size="28" class="text-primaryGrey" icon="mdi:mdi-alert-circle"></v-icon>
                                <v-spacer></v-spacer>
                                <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="disputeDialog = false"></v-icon>
                            </v-card-title>
                            <div class="sidebar-h2 text-primaryGrey mt-3">Dispute</div>
                            <v-divider class="my-4"></v-divider>
                            <div class="body-p text-primaryGrey">
                                We will initiate a reinvestigation based on the information you provide here if you dispute the accuracy of being blacklisted.
                            </div>
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">Account/Wallet</div>
                            <v-text-field v-model="user.wallet" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">Please Describe the Nature of Your Dispute <span class="text-red">*</span></div>
                            <v-textarea clearable v-model="description" variant="outlined" density="comfortable" persistent-counter :counter="2000" class="mt-5"></v-textarea>
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">Upload Document</div>
                            <v-row no-gutters>
                                <v-col cols="12" >
                                    <el-upload ref="upload" action="" :http-request="loadFile" :on-remove="removeFile" multiple drag>
                                        <a class="del-underline gradient-left-red-purple-block">
                                            <v-btn rounded="0" :loading="uploadingLoading" color="button01" class="text-none text-grey05 body-p-small-b block" height="52" aria-label="Select multiple files">Select multiple files</v-btn>
                                        </a>
                                    </el-upload>
                                </v-col>
                                <!-- <v-col cols="3" class="ml-6">
                                    <a class="del-underline gradient-left-red-purple-block">
                                        <v-btn rounded="0" elevation="2" class="text-none text-primaryGrey body-p-small-b" width="100" height="52">Remove</v-btn>
                                    </a>
                                </v-col> -->
                            </v-row>
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">Terms and Conditions <span class="text-red">*</span></div>
                            <div class="body-p-small text-primaryGrey">
                                By checking the box below, you confirm that you have read the terms and conditions, that you understand them and that you are in compliance with them.
                            </div>
                            <ul class="text-primaryGrey body-p-small ml-5 mt-3">
                                <li>You may submit a dispute only for information contained in your own account or wallet. By selecting AGREE, you certify that you are the person whose report information is being disputed.</li>
                                <li>You understand that providing information under false pretenses for the purpose of cheating is a violation of our Term of Service and Community Guidlines punishable by being permanent banned on the protocol.</li>
                                <li class="font-weight-bold">You agree to provide your accurate identifying information.</li>
                                <li>You agree that you will not upload information that violates any laws or third-party rights, including but not limited to intellectual property.</li>
                                <li>You agree to not upload objectionable material.</li>
                                <li>You agree to not upload any material that is not pertinent to your dispute.</li>
                                <li>You understand that the documentation you provide may be shared with the source(s) of the disputed information for reinvestigation purposes.</li>
                                <li>You agree not to attempt to upload any document that contains a virus or could pose a security risk.</li>
                                <li>If untrading.org has reason to believe the information you uploaded is subject to the restrictions noted above, we will not use or consider the documentation in our processing of your request.</li>
                                <li>If untrading.org discovers a virus or other issues that could pose a potential security risk, your documents will not be uploaded.</li>
                            </ul>
                            <v-checkbox v-model="agree" label="I agree to the above Terms and Conditions *" class="mt-2 my-checkbox"></v-checkbox>
                            <div class="text-center mb-5">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" :loading="submitLoading" color="button01" class="text-none text-grey05 body-p-small-b mt-3" width="100" height="52" :disabled="!agree" @click="dispute" aria-label="Submit">Submit</v-btn>
                                 </a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import FileAPI from '@/api/file.js';
import DisputeAPI from '@/api/dispute.js';
export default {
    data(){
        return {
            // 争议弹窗
            disputeDialog: false,
            // 描述
            description: null,
            // 上传中
            uploadingLoading: false,
            // 提交中
            submitLoading: false,
            // 文件 uid 集合
            fileUids: [],
            // 文件链接集合
            fileUrls: [],
            // 同意
            agree: false
        }
    },
    props: {
        // 是否展示
        show: { type: Boolean, default: false },
        // NFT 主键
        nftId: { type: String, default: null },
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user']),
    },
    watch:{
        show: {
            handler(n, o) {
                if(n) {
                    this.disputeDialog = true;
                } else {
                    this.disputeDialog = false;
                }
            },
            immediate: true
        },
        disputeDialog: {
            handler(n, o) {
                if(!n) {
                    this.$emit('watchDisputeDialog', false);
                }
            },
            immediate: true
        }
    },
    methods: {
        // 加载文件
        loadFile(param) {
            // 添加文件
            this.fileUids.push(param.file.uid);
            // 上传文件
            this.upload(param.file);
        },
        // 移除文件
        removeFile(param) {
            let index = this.fileUids.indexOf(param.uid);
            this.fileUids.splice(index, 1);
            this.fileUrls.splice(index, 1);
            // console.log(this.fileUrls);
        },
        // 上传文件
        async upload(file) {
            // 上传中
            this.uploadingLoading = true;
            let formData = new FormData();
	        formData.append("isSaveOriginalName", true);
	        formData.append("file", file);
            let res = await FileAPI.upload(formData);
            let data = res.data;
            if(data.success) {
                let index = this.fileUids.indexOf(file.uid);
                this.fileUrls[index] = data.data;
                // console.log(this.fileUrls);
            }
            this.$store.dispatch('snackbarMessageHandler', data.message);
            this.uploadingLoading = false;
        },
        // 提交申诉
        async dispute() {
            // 提交中
            this.submitLoading = true;
            let param = {
                nftId: this.nftId,
                description: this.description,
                fileUrls: this.fileUrls
            };
            let res = await DisputeAPI.dispute(param);
            let data = res.data;
            if(data.success) {
                this.disputeDialog = false;
                // 恢复data定义的所有参数
                Object.assign(this.$data, this.$options.data.call(this));
            }
            this.$store.dispatch('snackbarMessageHandler', data.message);
            this.submitLoading = false;
            
        }
        
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
::v-deep .my-checkbox .v-label {
 font-size: 14px!important;
}
::v-deep .el-upload-dragger {
    padding: 5px !important;
}
</style>