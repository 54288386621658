import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 是否喜欢 NFT
    liked(nftId) {
        if(!token) {
            return { data: { success: false } }
        }
        return axios.request({
            url: '/nft/like/liked',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    // 喜欢 NFT
    like(nftId) {
        return axios.request({
            url: '/nft/like/like',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    // 取消喜欢 NFT
    unlike(nftId) {
        return axios.request({
            url: '/nft/like/unlike',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    
}