<template>
	<div>
       <v-row no-gutters justify="center" align="center"> 
            <v-col cols="12" class="d-flex r-flex-column align-center px-0">
                <v-card width="160" color="transparent" rounded="0" elevation="0" v-for="(item, index) in cards" :key="index" class="mr-4 px-0">
                    <div>
                        <a :href="item.link" target="_blank">
                            <v-img contain :src="item.darkImage"></v-img>
                        </a>
                    </div>
                </v-card>
            </v-col>       
       </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            cards: [
                {
                title: "benzinga",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b1b587cd-fb60-4780-33a2-dc26781cf300/public',
                link:'https://www.benzinga.com/pressreleases/24/04/38366417/singapore-based-untrading-debuts-crypto-provenance-value-amplification-pva-system-on-testnet'
                },
                {
                title: "coinmarketcap",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7de2a339-a980-4a99-f6cf-28452d241a00/public',
                link:  'https://coinmarketcap.com/community/articles/66b52ab26023986fba122a58/'
                },
                {
                title: "cryptodaily",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/39566f14-5acd-41a5-b3dc-c93ac79dbd00/public',
                link:'https://cryptodaily.co.uk/2024/08/the-untrading-ecosystem-a-paradigm-shift-in-trading-and-asset-management'
                },
                {
                title: "crypto.news",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/26058e86-b19f-4e25-b780-90a21e402e00/public',
                link:'https://crypto.news/teenage-crypto-executive-slams-lazy-nfts-forecasts-drastic-changes-ahead/'
                },
                {
                title: "hackernoon",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/63e35fa1-2113-467e-b938-bcad6fc8b800/public',
                link:  'https://hackernoon.com/exploring-erc-5173-nfr-future-rewards-contract-with-untrading-cto-and-co-founder-yale-reisoleil-jr'
                },
                {
                title: "p2e.game",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/268b1f13-13a6-40d1-9775-24e49e705200/public',
                link:'https://www.p2e.game/dailyNews/4xo97w028bnw'
                },
                {
                title: "u.today",
                darkImage: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/405ed15d-be60-4545-9510-1c4d68473400/public',
                link:'https://u.today/untrading-bringing-user-centric-approach-to-revolutionize-financial-landscape'
                }
            ]
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>