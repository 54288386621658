import axios from '@/config/api.request'

export default {
    // 添加领取奖励记录
    claimReward(data) {
        return axios.request({
            url: '/nft/reward/claim',
            method: 'post',
            data: data
        })
    }
}