import axios from '@/config/api.request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
    // 导入 NFT
    importNFT(data) {
        return axios.request({
            url: '/nft/import',
            method: 'post',
            data: data
        })
    },
    // 铸造 NFT
    mint(data) {
        return axios.request({
            url: '/nft/mint',
            method: 'post',
            data: data
        })
    },
    // 封装 NFT
    wrap(data) {
        return axios.request({
            url: '/nft/wrap',
            method: 'post',
            data: data
        })
    },
    // 判断用户是否有购买权限
    buyPermission(id) {
        return axios.request({
            url: '/nft/permission/buy',
            method: 'get',
            params: { id: id }
        })
    },
    // 购买 NFT
    buy(data) {
        return axios.request({
            url: '/nft/buy',
            method: 'post',
            data: data
        })
    },
    // 判断用户是否有转移权限
    transferPermission(id) {
        return axios.request({
            url: '/nft/permission/transfer',
            method: 'get',
            params: { id: id }
        })
    },
    // 判断地址是否有接收转移权限
    transferPermissionByAddress(id, address) {
        return axios.request({
            url: '/nft/permission/transfer/address',
            method: 'get',
            params: { id: id, address: address }
        })
    },
    // 转移 NFT
    transfer(data) {
        return axios.request({
            url: '/nft/transfer',
            method: 'post',
            data: data
        })
    },
    // 根据主键查询 NFT
    getNFTById(id) {
        return axios.request({
            url: '/nft/nft',
            method: 'get',
            params: { id: id }
        })
    },
    // 根据主键查询 NFT 媒体文件
    getNFTMediaById(id) {
        return axios.request({
            url: '/nft/media',
            method: 'get',
            params: { id: id }
        })
    },
    // 根据主键查询简单 NFT
    getSimpleNFTById(id) {
        return axios.request({
            url: '/nft/simple',
            method: 'get',
            params: { id: id }
        })
    },
    // 根据用户名查询所有的 NFT
    getNFTsByUsername(username) {
        return axios.request({
            url: '/nft/nfts',
            method: 'get',
            params: { username: username }
        })
    },
    // 这是我的 NFT 吗
    isMyNFT(id) {
        return axios.request({
            url: '/nft/mine',
            method: 'get',
            params: { id: id }
        })
    },
    // 更新 NFT 代币主键
    updateTokenId(data) {
        return axios.request({
            url: '/nft/token/update',
            method: 'put',
            data: data
        })
    },
    // 上市
    list(data) {
        return axios.request({
            url: '/nft/list',
            method: 'post',
            data: data
        })
    },
    // 下市
    unlist(data) {
        return axios.request({
            url: '/nft/unlist',
            method: 'post',
            data: data
        })
    },
    // 将当前NFT设置为当前所在套装的头像
    makeSetLogo(data) {
        return axios.request({
            url: '/nft/set/logo',
            method: 'post',
            data: data
        })
    },
    // 根据套装主键查询所有 NFT 列表
    getSetNFTs(setId) {
        return axios.request({
            url: '/nft/nfts/list/set',
            method: 'get',
            params: { setId: setId }
        })
    },
    // 根据套装主键分页查询 NFT 列表
    getSetNFTsByPage(setId, page, size, sortBy) {
        return axios.request({
            url: '/nft/nfts/page/set',
            method: 'get',
            params: { 
                setId: setId,
                page: page,
                size: size,
                sortBy: sortBy
            },
            paramsSerializer: params => {
                return qs.stringify(params, { indices: false })
            }
        })
    },
    // 分页查询 NFT 列表
    getNFTsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nft/nfts/page',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 查询精选的 NFT 列表
    getFeatured(blockchain) {
        return axios.request({
            url: '/nft/nfts/featured',
            method: 'get',
            // 暂时先不区分区块链
            // params: {
            //     blockchain: blockchain
            // }
        })
    },
    // 查询 Flows 最高的 NFT 列表
    getTopFlows(blockchain) {
        return axios.request({
            url: '/nft/nfts/top/flows',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
    // 根据用户名分页查询收藏的 NFT 列表
    getCollectedNFTsByPage(username, page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nft/nfts/page/collected',
            method: 'get',
            params: { 
                username: username,
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 根据用户名分页查询创建的 NFT 列表
    getCreatedNFTsByPage(username, page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nft/nfts/page/created',
            method: 'get',
            params: { 
                username: username,
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 根据套装主键查询该套装推荐的更多NFT
    getSetMoreNFTs(setId, excludedNftId) {
        return axios.request({
            url: '/nft/nfts/more/set',
            method: 'get',
            params: { 
                setId: setId,
                excludedNftId: excludedNftId
            }
        })
    },
    // 根据 NFT 主键查询艺术家
    getArtistById(id) {
        return axios.request({
            url: '/nft/artist',
            method: 'get',
            params: { 
                id: id
            }
        })
    },
    // 根据 NFT 主键查询拥有者
    getOwnerById(id) {
        return axios.request({
            url: '/nft/owner',
            method: 'get',
            params: { 
                id: id
            }
        })
    },
    // 根据收藏夹主键分页查询 NFT 列表
    getCollectionNFTsByPage(collectionId, page, size, sortBy) {
        return axios.request({
            url: '/nft/nfts/page/collection',
            method: 'get',
            params: { 
                collectionId: collectionId,
                page: page,
                size: size,
                sortBy: sortBy
            }
        })
    },
    // 是否存在该 NFT
    exists(id) {
        return axios.request({
            url: '/nft/exists',
            method: 'get',
            params: { id: id }
        })
    },
    // 隐藏 NFT
    hide(id) {
        return axios.request({
            url: '/nft/hide',
            method: 'put',
            params: { id: id }
        })
    },
    // 取消隐藏 NFT
    unhide(id) {
        return axios.request({
            url: '/nft/unhide',
            method: 'put',
            params: { id: id }
        })
    },
    // 分页查询隐藏的 NFT 列表
    getHiddenNFTsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/nft/nfts/page/hidden',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
}