<template>
    <span class="c-center">
        <UserAvatar :avatar="avatar" :username="username" :size="size" class="mr-2"></UserAvatar>
        <span class="gradient-underline-hover">
            <router-link @click.stop :to="'/profile/' + username" class="del-underline gradient-text-hover parent">
                <span class="body-p text-primaryGrey ellipsis-line">{{ name }}</span>
            </router-link>
        </span>
    </span>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        name: {
            type: String,
            default: null
        },
        username: {
            type: String,
            default: null
        },
        avatar: {
            type: String,
            default: null
        },
        size: {
            type: Number,
            default: 36
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{
        
    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>