<template>
	<div class="bg-darkGrey my-12">
        <v-row no-gutters class="py-10">
            <v-col cols="12" sm="6">
                <div class="item ml-5">
                    <h2 class="body-h4 text-white">A Revolutionary NFT Marketplace Framework</h2>
                    <h6 class="mt-3 body-p-small text-white">Discover a groundbreaking NFT marketplace framework that transforms the way sellers, buyers, and platforms interact, revolutionizing the trading experience.</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-10">
                <div class="item ml-5">
                    <h2 class="body-h4 text-white">Upgrade your crypto assets and maximize your returns</h2>
                    <h6 class="mt-3 body-p-small text-white">Wrapping your ERC-20 tokens and experiencing the power of Future Rewards (FRs) even after selling. Unlock The Full Potential Of Provenance Value Amplification (PVA) DeFi.</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-10">
                <div class="item ml-5">
                    <h2 class="body-h4 text-white">On-chain NFT royalties & on-chain licenses</h2>
                    <h6 class="mt-3 body-p-small text-white">On-chain nFR discourages royalty circumvention, and the platform implements Can't Be Evil licenses released by a16z.</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-10 text-center">
                <a href="https://docs.untrading.org" class="del-underline" target="_blank">
                    <v-btn :rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small-b" width="137" height="48" aria-label="Learn more">Learn more</v-btn>
                </a>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* .hero {
    height: 236px; 
    background-color: black;
    margin-top: 50px;
    margin-bottom: 50px;
} */
.item{
    max-width: 320px;
    border-left: 2px solid rgb(255, 255, 255);
    padding-left: 18px;
    width: 100%;
}
</style>