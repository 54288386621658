<template>
	<div>
        <v-snackbar style="z-index: 3000;" v-if="responsive == 'pc'" max-height="48" v-model="cookiesDialog" bottom :light="darkTheme==0" :dark="darkTheme==1" :rounded="0" timeout="-1" min-width="101%">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" id="cookie-notification">
                        <v-row no-gutters>
                            <v-col cols="10">
                                <div class="body-p text-primaryGrey">
                                    This website uses cookies to improve your experience. By continuing to use this website, you agree to our use of cookies.
                                    Learn more about our 
                                    <span class='gradient-underline-hover gradient-underline'>
                                        <a href="https://docs.untrading.org/legal/privacy-policy#_dc8xrq9milpt" target="_blank" class="del-underline pointer">cookie policy</a>
                                    </span>
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2" class="text-right">
                                <v-icon aria-label="close" data-dismiss="cookie-notification" color="primary" @click="allowCookies()" icon="mdi:mdi-close"></v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-snackbar>
        <v-snackbar z-index="3000" class="mr-12" v-if="responsive == 'mobile'" max-height="130" v-model="cookiesDialog" bottom :light="darkTheme==0" :dark="darkTheme==1" :rounded="0" timeout="-1">
            <div id="cookie-notification">
                <div class="fs-14 text-primaryGrey">This website uses cookies to improve your experience. By continuing to use this website, you agree to our use of cookies.
                    Learn more about our 
                    <span class='gradient-underline-hover gradient-underline'>
                        <a href="https://docs.untrading.org/privacy-policy#_dc8xrq9milpt" target="_blank" class="del-underline pointer">cookie policy</a>
                    </span>
                    <v-icon class="float-right mt-1" aria-label="close" data-dismiss="cookie-notification" color="primary" @click="allowCookies()" icon="mdi:mdi-close"></v-icon>
                </div>
            </div>
        </v-snackbar>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            cookiesDialog: false,
        }
    },
    components: {  },
    created(){
        // 加载缓存
        this.loadCache();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme", "locale","responsive"])
    },
    watch:{

    },
    methods: {
        // 加载缓存
        loadCache() {
            let cookies = localStorage.getItem('untrading-cookies');
            this.cookiesDialog = !cookies;
        },
        // 允许
        allowCookies() {
            localStorage.setItem('untrading-cookies', 1);
            this.cookiesDialog = false;
        },
    }
}
</script>
<style scoped>

</style>