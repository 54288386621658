import axios from '@/config/api.request'

export default {
    // 查询全站产生的总 $ nFR 支付
    getTotalNFRPaid(blockchain) {
        return axios.request({
            url: '/market/total/nfr/paid',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
    // 查询全站产生的总UN用户奖励
    getTotalUserRewardsPaid() {
        return axios.request({
            url: '/market/total/user/rewards/paid',
            method: 'get'
        })
    },
    // 查询加密货币产生的总 $ nFR 支付
    getCryptoTotalNFRPaid(blockchain) {
        return axios.request({
            url: '/market/crypto/total/nfr/paid',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
    // 查询加密货币的 $ 总锁定价值
    getCryptoTotalValueLocked(blockchain) {
        return axios.request({
            url: '/market/crypto/total/value/locked',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
}