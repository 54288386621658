<template>
	<div>
        <v-row no-gutters>
            <v-col cols="12">
                <v-window>
                    <v-window-item v-infinite-scroll="getProvenancesByPage" infinite-scroll-immediate :infinite-scroll-distance="100" :infinite-scroll-disabled="forYou.noMoreData">
                        <div class="pl-n6">
                            <div class="body-h4 ml-5 r-m-l-0 text-primaryGrey">
                                Happening now
                            </div>
                            <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 r-m-l-0 mt-1 mb-5"></v-divider>
                            <div class="py-8 px-6 r-p-x-0 r-p-r-2" v-for="provenance in forYou.provenances" :key="provenance.id">
                                <v-card rounded="0" color="transparent" max-width="648" class="mx-auto" elevation="6">
                                    <v-row align="center" no-gutters class="pa-8 r-p-4">
                                        <v-col cols="1">
                                            <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                        </v-col>
                                        <v-col cols="11" class="pl-6 r-p-l-8">
                                            <div class="body-p text-primaryGrey">
                                                <span class="gradient-underline-hover">
                                                    <router-link @click.stop :to="'/profile/' + provenance.user.username" class="del-underline gradient-text-hover">
                                                        <span class="font-weight-bold">{{ provenance.user.name }}</span>
                                                    </router-link>
                                                </span>
                                                <span v-function-action="provenance.contractFunction"></span>
                                                <span class="font-weight-bold gradient-underline-hover">
                                                    <router-link :to="$tools.getUnNFTUrl(provenance.nft.blockchain, provenance.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                        {{ provenance.nft.name }}
                                                    </router-link>
                                                </span>
                                                <span v-if="provenance.contractFunction == 'LIST' || provenance.contractFunction == 'BUY'">
                                                    at {{ $tools.formatNumber(provenance.price) }} {{ provenance.paymentToken }}
                                                </span>
                                            </div>
                                            <div class="mt-1 body-p text-primaryGrey">
                                                <span v-time="provenance.timestamp"></span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <MediaFile :nftId="provenance.nft.id" overlay></MediaFile>
                                    </div>
                                </v-card>
                            </div>
                            <!-- 加载中骨架 -->
                            <div v-show="forYou.loading" class="mt-8 mx-6">
                                <SkeletonMoment :length="1"></SkeletonMoment>
                            </div>
                            <!-- 无可用数据 -->
                            <div v-show="forYou.noDataAvailable" class="my-10">
                                <NoDataAvailable></NoDataAvailable>
                            </div>
                        </div>
                    </v-window-item>
                </v-window>
            </v-col>   
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTProvenanceAPI from '@/api/nft-provenance.js';
import SkeletonMoment from '@/components/util/skeleton/SkeletonMoment.vue';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            size: 5,
            forYou: {
                // 加载中
                loading: false,
                // 无可用数据
                noDataAvailable: false,
                // 无更多数据
                noMoreData: false,
                // 搜索结果列表
                provenances: [],
                page: 2,
            }
        }
    },
    components: { SkeletonMoment, MediaFile },
    created(){
        this.getProvenancesByPage();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['token', 'blockchain', 'responsive'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 分页查询所有NFT的历史记录
        async getProvenancesByPage() {
            if(this.forYou.noDataAvailable || this.forYou.noMoreData || this.forYou.loading) {
                return;
            }
            this.forYou.noDataAvailable = false;
            this.forYou.noMoreData = false;
            this.forYou.loading = true;
            let res = await NFTProvenanceAPI.getProvenancesByPage(this.forYou.page, this.size, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.forYou.provenances.push(...data.data.records);
                this.forYou.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.forYou.noMoreData = true;
                }
            } else {
                if(this.forYou.provenances.length == 0) {
                    this.forYou.noDataAvailable = true;
                    this.forYou.noMoreData = false;
                } else if(this.forYou.provenances.length > 0) {
                    this.forYou.noDataAvailable = false;
                    this.forYou.noMoreData = true;
                }
            }
            this.forYou.loading = false;
        },
        // 刷新
        refresh() {
            this.forYou.loading = false;
            this.forYou.noDataAvailable = false;
            this.forYou.noMoreData = false;
            this.forYou.provenances = [];
            this.forYou.page = 2;
            // 重新查询数据
            this.getProvenancesByPage();
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}
</style>