<template>
	<div class="align-center pb-16" :class="isMe ? 'bg-lightGray' : 'bg-f2f'">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1440" class="mx-auto pt-8">
                <v-row no-gutters>
                    <v-col cols="12" v-if="responsive == 'pc'" class="pl-4">
                        <v-card elevation="0" color="transparent">
                            <v-row no-gutters>
                                <v-col cols="10">
                                    <v-card elevation="0" color="transparent">
                                        <v-row no-gutters >
                                            <v-col cols="4" class="ml-4">
                                                <v-icon size="24" class="text-primaryGrey gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <div class="body-h4 text-primaryGrey">
                                                    <span>COLLECTION</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pt-8" v-if="collection.id != null">
                                            <!-- 基本信息 -->
                                            <v-col cols="12" class="ml-3 d-flex align-center">
                                                <h1 class="hero-h1 text-grey04">{{ collection.name }}</h1>
                                                <span v-if="isMe">
                                                    <v-icon v-if="!isEditName" icon="fa-solid fa-pencil" class="pointer ml-5" size="24" @click="startEditName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-check" class="pointer ml-5" size="24" @click="editName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-close" class="pointer ml-5" size="24" @click="cancelEditName"></v-icon>
                                                </span>
                                            </v-col>
                                            <v-col cols="10" class="ml-3 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-text-field v-model="collection.name" label="Name" variant="outlined" hide-details density="comfortable"></v-text-field>
                                            </v-col>
                                            <v-col cols="10" class="ml-3 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-textarea v-model="collection.description" label="Description" variant="outlined" hint="" persistent-counter clearable :counter="1000"></v-textarea>
                                            </v-col>
                                            <v-col cols="11" class="body-p ml-4 mt-5 text-primaryGrey" v-if="!isEditName">
                                                <p class="pre-wrap" v-html="collection.description"></p>
                                            </v-col>
                                            <v-col cols="12" class="mt-8 ml-4">
                                                <v-card max-width="350" min-height="130" elevation="0" color="transparent" :rounded="0" class="d-flex justify-space-between">
                                                    <div class="body-text-btn">
                                                        <div class="text-primaryGrey">Blockchain</div>
                                                        <div class="text-primaryGrey">
                                                            <Blockchain :blockchain="collection.blockchain" size="24" text></Blockchain>
                                                        </div>
                                                    </div>
                                                    <div class="body-text-btn" v-if="collection.category">
                                                        <div class="text-primaryGrey">Category</div>
                                                        <div class="text-primaryGrey">
                                                            {{ collection.category }}
                                                        </div>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="2" class="pr-4 mt-2">
                                    <div class="float-right">
                                        <v-img :src="collection.logoImage" width="176" height="176" cover></v-img>
                                        <div class="mt-9 float-right mr-n6">
                                            <v-icon icon="mdi mdi-eye-outline" class="text-primaryGrey" size="18"></v-icon>
                                            <span class="body-p-small ml-2">
                                                <count-to :start-val="0" :end-val="collectionStat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                                            </span>
                                            <v-icon v-if="!liked" icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer text-primaryGrey" @click.stop="like()"></v-icon>
                                            <v-icon v-if="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike()"></v-icon>
                                            <span class="body-p-small ml-2 mr-6">
                                                <count-to :start-val="collectionStat.likeCount" :end-val="collectionStat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                                            </span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- mobile -->
                    <v-col cols="12" v-if="responsive == 'mobile'">
                        <v-card elevation="0" color="transparent">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card elevation="0" color="transparent">
                                        <v-row no-gutters >
                                            <v-col cols="12" class="ml-2">
                                                <v-icon size="24" class="text-primaryGrey gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <div class="body-h4 text-primaryGrey">
                                                    <span>COLLECTION</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pt-8">
                                            <v-col cols="11" class="mt-2 ml-2">
                                                <div>
                                                    <v-img :src="collection.logoImage" width="176" height="176" cover></v-img>
                                                    <div class="mt-9">
                                                        <v-icon icon="mdi mdi-eye-outline" class="text-primaryGrey" size="18"></v-icon>
                                                        <span class="body-p-small ml-2">
                                                            <count-to :start-val="0" :end-val="collectionStat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                                                        </span>
                                                        <v-icon v-if="!liked" icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer text-primaryGrey" @click.stop="like()"></v-icon>
                                                        <v-icon v-if="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike()"></v-icon>
                                                        <span class="body-p-small ml-2 mr-6">
                                                            <count-to :start-val="collectionStat.likeCount" :end-val="collectionStat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                                                        </span>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <!-- 基本信息 -->
                                            <v-col cols="11" class="sub-page-h1 ml-2 text-primaryGrey mt-8">
                                                {{ collection.name }}
                                                <span v-if="isMe">
                                                    <v-icon v-if="!isEditName" icon="fa-solid fa-pencil" class="pointer ml-5" size="24" @click="startEditName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-check" class="pointer ml-5" size="24" @click="editName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-close" class="pointer ml-5" size="24" @click="cancelEditName"></v-icon>
                                                </span>
                                            </v-col>
                                            <v-col cols="11" class="pl-2 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-text-field v-model="collection.name" label="collection Name" variant="outlined" hide-details density="comfortable"></v-text-field>
                                            </v-col>
                                            <v-col cols="11" class="pl-3 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-textarea v-model="collection.description" label="Description" variant="outlined" hint="" persistent-counter clearable :counter="1000"></v-textarea>
                                            </v-col>
                                            <v-col cols="11" class="body-p pl-2 mt-5 text-primaryGrey" v-if="!isEditName">
                                                <p class="pre-wrap" v-html="collection.description"></p>
                                            </v-col>
                                            <v-col cols="11" class="mt-8 ml-2">
                                                <v-card max-width="350" min-height="130" elevation="0" color="transparent" :rounded="0" class="d-flex justify-space-between">
                                                    <div class="body-text-btn">
                                                        <div class="text-primaryGrey">Blockchain</div>
                                                        <div class="text-primaryGrey">
                                                            <Blockchain :blockchain="collection.blockchain" size="24" text></Blockchain>
                                                        </div>
                                                    </div>
                                                    <div class="body-text-btn" v-if="collection.category">
                                                        <div class="text-primaryGrey">Category</div>
                                                        <div class="text-primaryGrey">
                                                            {{ collection.category }}
                                                        </div>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-9 pl-4 r-p-l-0">
                    <v-card class="w-100" elevation="0" color="transparent">
                        <v-row no-gutters>
                            <v-col cols="9" class="pl-4 r-p-l-2">
                                <v-tabs slider-color="primary" v-model="tab" density="compact">
                                    <v-tab value="UnNFTs" class="px-0 text-primaryGrey">
                                        <v-badge :content="summary.totalCount" inline>
                                            <h4>UnNFTs</h4>
                                        </v-badge>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                            <v-col cols="2" offset="1" class="pr-2">
                                <v-menu>
                                    <template v-slot:activator="{ props }">
                                        <v-btn variant="text" rounded="0" aria-label="menu-down" color="primaryGrey" class="text-none body-p float-right" height="100%" v-bind="props">
                                            {{ sortCondition }}
                                            <v-icon end icon="mdi:mdi-menu-down" />
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                            <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-divider class="mr-7 ml-4"></v-divider>
                        <v-card-text class="px-0 pt-3">
                            <v-window v-model="tab">
                                <v-window-item value="UnNFTs">
                                    <v-row no-gutters>
                                        <v-col class="mr-5 mb-5" v-if="isMe">
                                            <a class="del-underline gradient-left-red-purple-300" v-if="isRemoveNfts">
                                                <v-btn rounded="0" elevation="2" :loading="removeLoading" color="button01" class="text-none text-grey05 body-p-small-b ml-4" width="300" height="66" aria-label="Remove from collection" @click="removeNftsFromCollection">Remove from collection</v-btn>
                                            </a>
                                            <div class="float-right">
                                                <!-- <v-btn :rounded="1" icon size="medium" variant="text" color="teal-darken-4">
                                                    <v-icon icon="mdi:mdi-plus" size="36" class="text-primaryGrey"></v-icon>
                                                    <v-tooltip activator="parent" location="bottom">Add</v-tooltip>
                                                </v-btn> -->
                                                <v-btn :rounded="1" icon aria-label="Remove" size="medium" variant="text" class="ml-2" @click="isRemoveNfts = !isRemoveNfts">
                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom">
                                                        <template v-slot:activator="{ props }" >
                                                            <v-icon v-bind="props" aria-label="close" :icon="isRemoveNfts ? 'mdi:mdi-close' : 'mdi:mdi-trash-can-outline'" size="32" class="text-primary"></v-icon>
                                                        </template>
                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                            Remove
                                                        </v-card>
                                                    </v-menu>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" class="mr-5 mb-5">
                                            <div class="text-primaryGrey ml-4 mt-5" v-if="!loading && nfts.length == 0">This Collection may already be sold out! Please come back and keep an eye on our social media platforms for any future releases.</div>
                                        </v-col>
                                        <v-col cols="12" class="mb-5" :class="isMe ? '' : 'mt-14'" v-infinite-scroll="getCollectionNFTsByPage" :infinite-scroll-disabled="noMoreData">
                                            <v-row no-gutters class="flex-grow-1">
                                                <div class="c-widths">
                                                    <div class="c-card c-grid d-flex flex-column">
                                                        <div class="c-height" v-for="(nft, i) in nfts" :key="i">
                                                            <v-checkbox v-if="isRemoveNfts"  v-model="selectedNftIds" :label="nft.name" :value="nft.id" density="compact" class="my-checkbox"></v-checkbox>
                                                            <UnNft :nftId="nft.id"></UnNft>
                                                        </div>
                                                        <!-- 加载中骨架 -->
                                                        <SkeletonUnNFT :length="4" v-if="loading"></SkeletonUnNFT>
                                                    </div>
                                                </div>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CollectionAPI from '@/api/collection.js';
import CollectionStatAPI from '@/api/collection-stat.js';
import CollectionLikeAPI from '@/api/collection-like.js';
import NFTAPI from '@/api/nft.js';
import UnNft from '@/components/nft/UnNft';
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {
            // 收藏夹主键
            collectionId: this.$route.params.id,
            // 收藏夹对象
            collection: {
                user: {}
            },
            // 标签
            tab: null,
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Price', 'Lowest Price', 'Highest Views', 'Lowest Views'],
            sortCondition: 'Newest',
            // 当前收藏夹的NFT集合
            nfts: [],
            // 收藏夹统计数据
            collectionStat: {
                viewCount: 0,
                likeCount: 0
            },
            // 收藏夹概括数据
            summary: {},
            // 是否喜欢
            liked: false,
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            page: 1,
            size: 10,
            // 收藏夹中NFT的拥有者用户弹窗
            ownersDialog: false,
            // 是否编辑名称
            isEditName: false,
            // 旧的收藏夹信息
            oldCollection: {
                name: null,
                description: null
            },
            // NFT 价格集合
            nftPrices: [],
            // 是否移除 NFTs
            isRemoveNfts: false,
            // 移除加载中
            removeLoading: false,
            // 选中的 NFT 主键集合
            selectedNftIds: [],
        }
    },
    components: { UnNft, SkeletonUnNFT },
    created(){
        // 根据主键查询收藏夹
        this.getCollectionById();
        // 监听更新资料事件
        this.$bus.on('emitRefresh', data => {
            this.refresh();
        })
    },
    mounted(){
        this.view();
    },
    computed: {
        ...mapGetters(['user', 'responsive']),
        // 当前用户是自己
        isMe(){
            return this.collection.user && this.collection && this.collection.user.id == this.user.id;
        }
    },
    watch:{
        collectionId: {
            handler(newVal, oldVal) {
                // 根据主键查询收藏夹
                this.getCollectionById();
                // 查询统计数据
                this.getCollectionStat();
                // 查询概括数据
                this.getSummary();
                // 是否喜欢收藏夹
                this.checkLiked();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询收藏夹
        async getCollectionById() {
            let res = await CollectionAPI.getCollectionById(this.collectionId);
            let data = res.data;
            if(data.success) {
                // 更新对象
                this.collection = data.data;
                // 更新标题
                this.$route.meta.name = this.collection.name
            } else {
                // this.$store.dispatch('snackbarMessageHandler', data.message);;
                // 错误则导航到404
                this.$router.push('/404');
            }
        },
        // 根据收藏夹主键查询所有 NFT 列表
        async getCollectionNFTs() {
            let res = await NFTAPI.getCollectionNFTs(this.collectionId);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data;
            }
        },
        // 根据收藏夹主键分页查询 NFT 列表
        async getCollectionNFTsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await NFTAPI.getCollectionNFTsByPage(this.collectionId, this.page, this.size, this.sortCondition);
            let data = res.data;
            if(data.success) {
                this.summary.totalCount = data.data.total;
                this.nfts.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.nfts.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.nfts.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 查询概括数据
        async getSummary() {
            let res = await CollectionStatAPI.summary(this.collectionId);
            let data = res.data;
            if(data.success) {
                this.summary = data.data;
            }
        },
        // 浏览
        async view() {
            await CollectionStatAPI.view(this.collectionId);
        },
        // 查询统计数据
        async getCollectionStat() {
            let res = await CollectionStatAPI.collectionStat(this.collectionId);
            let data = res.data;
            if(data.success) {
                this.collectionStat = data.data;
            }
        },
        // 是否喜欢收藏夹
        async checkLiked() {
            let res = await CollectionLikeAPI.liked(this.collectionId);
            let data = res.data;
            if(data.success) {
                this.liked = data.data;
            }
        },
        // 喜欢收藏夹
        async like() {
            // 前端更新数据
            this.collectionStat.likeCount++;
            this.liked = true;
            // 后端无感刷新
            let res = await CollectionLikeAPI.like(this.collectionId);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getCollectionStat();
                // 是否喜欢收藏夹
                this.checkLiked();
            } else {
                // 若响应失败则返回状态
                this.collectionStat.likeCount--;
                this.liked = false;
            }
        },
        // 取消喜欢收藏夹
        async unlike() {
            // 前端更新数据
            this.collectionStat.likeCount--;
            this.liked = false;
            // 后端无感刷新
            let res = await CollectionLikeAPI.unlike(this.collectionId);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getCollectionStat();
                // 是否喜欢收藏夹
                this.checkLiked();
            }
        },
        // 改变条件
        changeSortCondition(sortCondition) {
            // 改变条件
            this.sortCondition = sortCondition;
            // 重置数据
            this.page = 1;
            this.nfts = [];
            this.noDataAvailable = false;
            this.noMoreData = false;
            // 重新查询数据
            this.getCollectionNFTsByPage();
        },
        // 关闭关注用户弹窗
        closeOwnersDialog(show) {
            this.ownersDialog = show;
        },
        // 开始编辑名称
        startEditName() {
            this.oldCollection.name = this.collection.name;
            this.oldCollection.description = this.collection.description;
            this.isEditName = true;
        },
        // 更新收藏夹名称
        async editName() {
            let params = {
                collectionId: this.collection.id,
                name: this.collection.name,
                description: this.collection.description
            };
            let res = await CollectionAPI.editName(params);
            let data = res.data;
            if(data.success) {
                this.isEditName = false;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 取消编辑名称
        cancelEditName() {
            this.collection.name = this.oldCollection.name;
            this.collection.description = this.oldCollection.description;
            this.isEditName = false;
        },
        // 从收藏夹中移除 NFTs
        async removeNftsFromCollection() {
            this.removeLoading = true;
            let res = await CollectionAPI.removeNftsFromCollection(this.selectedNftIds);
            let data = res.data;
            if(data.success) {
                // 刷新
                this.refresh();
            }
            this.removeLoading = false;
            this.isRemoveNfts = false;
        },
        // 刷新
        refresh() {
            // 查询概括数据
            this.getSummary();
            // 刷新数据
            this.changeSortCondition(this.sortConditions[0]);
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
::v-deep .my-checkbox .v-label {
 font-size: 14px!important;
}
</style>