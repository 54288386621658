<template>
	<div>
        <v-card rounded="0" color="black" class="pa-8" :class="responsive == 'pc' ? 'elevation-12' : 'elevation-6'">
            <!-- ******************************* Connect your wallet ******************************* -->
            <div v-if="action == 'CONNECT_WALLET'">
                <h2 class="body-text-btn text-grey04">Connect your wallet</h2>
                <div class="mt-5">To get started, please follow these steps:</div>
                <ol class="mt-5 ml-5">
                    <li>Switch your Metamask wallet to the Arbitrum One blockchain. If you haven't added Arbitrum One to your Metamask, please refer to the instructions provided in the FAQ section below.</li>
                    <li>Ensure that you have a small amount of Arbitrum Ethereum in your wallet to cover the gas fees for the token claim transaction.</li>
                    <li>Click the "Connect Wallet" button below to connect your wallet.</li>
                </ol>
                <div class="mt-15">
                    <router-link :to="'/connectwallet?redirectUrl=' + currentUrl" class="del-underline gradient-left-red-purple-block">
                        <v-btn rounded="0" elevation="2" height="52" color="button01" class="block text-none text-grey05 body-p-small-b" aria-label="Connect Wallet">Connect Wallet</v-btn>
                    </router-link>
                </div>
                <div class="mt-5">Once your wallet is connected and if it is whitelisted for any tokens, a table will appear displaying your available UN tokens.</div>
            </div>
            <!-- ******************************* KYC Unverified ******************************* -->
            <div v-if="action == 'KYC_UNVERIFIED'">
                <h2 class="body-text-btn text-grey04">Token Overview (KYC Unverified)</h2>
                <table class="mt-3 w-100">
                    <thead>
                        <tr>
                            <th class="text-left form-text">Token Source</th>
                            <th class="text-right form-text">Total Available</th>
                            <th class="text-right form-text">Vested Amount</th>
                            <th class="text-right form-text">Locked Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" v-if="loading">
                            <td colspan="4">Loading...</td>
                        </tr>
                        <tr v-if="!loading && tokenOverview?.tokenSources?.length > 0" v-for="(source, i) in tokenOverview?.tokenSources" :key="`source-${i}`">
                            <td>{{ source.tokenSource }}</td>
                            <td class="text-right">{{ $tools.formatNumber(source.totalAvailable, 2) }}</td>
                            <td class="text-right">{{ $tools.formatNumber(source.vestedAmount, 2) }}</td>
                            <td class="text-right">{{ $tools.formatNumber(source.lockedAmount, 2) }}</td>
                        </tr>
                        <tr class="text-center" v-if="!loading && tokenOverview?.tokenSources?.length == 0">
                            <td colspan="4">No Data Available</td>
                        </tr>
                    </tbody>
                </table>
                <div class="mt-5">You must complete our KYC (Know Your Customer) verification process before making a withdrawal.</div>
                <div class="mt-15">
                    <a href="https://forms.gle/cZAek51Xog2pg3kNA" target="_blank" class="del-underline gradient-left-red-purple-block">
                        <v-btn rounded="0" elevation="2" height="52" color="button01" class="block text-none text-grey05 body-p-small-b" aria-label="Complete KYC">Complete KYC</v-btn>
                    </a>
                </div>
                <div class="mt-5">Once your KYC status is verified, you can come back to this page to claim your UN tokens.</div>
            </div>
            <!-- ******************************* Claim UN Tokens ******************************* -->
            <div v-if="action == 'CLAIM_UN_TOKENS'">
                <h2 class="body-text-btn text-grey04">Token Overview (KYC Verified)</h2>
                <table class="mt-3 w-100">
                    <thead>
                        <tr>
                            <th class="text-left form-text">Token Source</th>
                            <th class="text-right form-text">Total Available</th>
                            <th class="text-right form-text">Vested Amount</th>
                            <th class="text-right form-text">Locked Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" v-if="loading">
                            <td colspan="4">Loading...</td>
                        </tr>
                        <tr v-if="!loading && tokenOverview?.tokenSources?.length > 0" v-for="(source, i) in tokenOverview?.tokenSources" :key="`source-${i}`">
                            <td>{{ source.tokenSource }}</td>
                            <td class="text-right">{{ $tools.formatNumber(source.totalAvailable, 2) }}</td>
                            <td class="text-right">{{ $tools.formatNumber(source.vestedAmount, 2) }}</td>
                            <td class="text-right">{{ $tools.formatNumber(source.lockedAmount, 2) }}</td>
                        </tr>
                        <tr class="text-center" v-if="!loading && tokenOverview?.tokenSources?.length == 0">
                            <td colspan="4">No Data Available</td>
                        </tr>
                    </tbody>
                </table>
                <h2 class="body-text-btn text-grey04 mt-10">Total Claimable Amount</h2>
                <div class="mt-3">{{ $tools.formatNumber(tokenOverview?.totalClaimableAmount, 2) }} UN Tokens  you can choose the amount you wish to claim immediately.</div>
                <!-- 可领取的代币来源单选项 -->
                <div v-if="claimableTokenSources.length > 0">
                    <div class="mt-3">Choose one of the following options to claim your vested tokens:</div>
                    <v-radio-group v-model="currentTokenSourceId" :disabled="isClaimDisabled" inline hide-details density="compact" class="body-p-small">
                        <table class="mt-3 w-75">
                            <tbody>
                                <tr v-for="source in claimableTokenSources" :key="`Token-Source-${source.id}`">
                                    <td style="width: 50px;">
                                        <v-radio :value="source.id"></v-radio>
                                    </td>
                                    <td>{{ source.tokenSource }}</td>
                                    <td class="text-right">{{ $tools.formatNumber(source.vestedAmount, 2) }} UN Tokens</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-radio-group>
                </div>
                <h2 class="body-text-btn text-grey04 mt-10">Staking Options</h2>
                <div class="mt-3">Choose one of the following options to claim your vested tokens:</div>
                <v-radio-group v-model="stakingOption" :disabled="isClaimDisabled" inline density="compact" class="body-p-small">
                    <table class="mt-3 w-100">
                        <tbody>
                            <tr>
                                <td style="width: 50px;"></td>
                                <td style="width: 50px;" class="text-right"></td>
                                <td class="text-right">Months</td>
                                <td class="text-right"></td>
                                <td class="text-right"></td>
                            </tr>
                            <tr v-for="staking in stakingOptions" :key="`Staking-${staking.value}`">
                                <td style="width: 50px;">
                                    <v-radio :value="staking.value"></v-radio>
                                </td>
                                <td style="width: 50px;">Stake</td>
                                <td class="text-right">{{ staking.month }}</td>
                                <td class="text-right">{{ staking.bonus }}</td>
                                <td class="text-right">Bonus</td>
                            </tr>
                        </tbody>
                    </table>
                </v-radio-group>
                <div class="mt-3">By locking your tokens for various periods of time, you will be able to earn the additional bonus shown above.</div>
                <div class="mt-3">Once you have chosen your claim amount (and optionally, your staking preference), click the "Claim UN Tokens" button to proceed.</div>
                <div class="mt-10">
                    <a class="del-underline gradient-left-red-purple-block">
                        <v-btn rounded="0" elevation="2" :loading="claimLoading" :disabled="isClaimDisabled" height="52" color="button01" class="block text-none text-grey05 body-p-small-b" aria-label="Claim UN Tokens" @click="claim()">Claim UN Tokens</v-btn>
                    </a>
                </div>
                <!-- 领取记录 -->
                <div v-if="claimedRecords.length > 0">
                    <h2 class="body-text-btn text-grey04 mt-10">Claimed Records</h2>
                    <table class="mt-3 w-100">
                        <thead>
                            <tr>
                                <th class="text-left form-text">Time</th>
                                <th class="text-left form-text">Token Source</th>
                                <th class="text-left form-text">Staking</th>
                                <th class="text-right form-text">Amount</th>
                                <th class="text-right form-text">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="record in claimedRecords" :key="record.id">
                                <td>
                                    <v-menu open-on-hover location="bottom" offset="15">
                                        <template v-slot:activator="{ props }">
                                            <span v-bind="props">{{ $tools.formatEngLishDate(record.timestamp) }}</span>
                                        </template>
                                        <v-card class="pa-3 text-primaryGrey body-p" rounded="0">{{ $tools.formatEngLishDatetime(record.timestamp) }}</v-card>
                                    </v-menu>
                                </td>
                                <td>{{ tokenSources.filter(t => t.code == record.tokenSource)[0]?.name }}</td>
                                <td>{{ stakingOptions.filter(s => s.code == record.stakingOption)[0]?.month }} Months</td>
                                <td class="text-right">
                                    <v-menu open-on-hover location="bottom" offset="15">
                                        <template v-slot:activator="{ props }">
                                            <span v-bind="props">{{ $tools.formatNumber(record.totalQuantity, 2) }}</span>
                                        </template>
                                        <v-card class="pa-3 text-primaryGrey body-p-small" rounded="0">
                                            <table class="w-100">
                                                <tr>
                                                    <td style="width: 120px;">Claimed Amount: </td>
                                                    <td class="text-right">{{ $tools.formatNumber(record.quantity, 2) }} UN Tokens</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 120px;">Total Amount: </td>
                                                    <td class="text-right">{{ $tools.formatNumber(record.totalQuantity, 2) }} UN Tokens</td>
                                                </tr>
                                            </table>
                                        </v-card>
                                    </v-menu>
                                </td>
                                <td class="text-right">
                                    <span class="gradient-underline-hover gradient-underline">
                                        <a :href="$tools.getBlockchainExplorerUrl(record.blockchain) + '/tx/' + record.transactionHash" class="del-underline text-primary gradient-text-hover pointer" target="_blank">
                                            <v-menu open-on-hover location="bottom" offset="15">
                                                <template v-slot:activator="{ props }">
                                                    <span v-bind="props">View</span>
                                                </template>
                                                <v-card class="pa-3 text-primaryGrey body-p" rounded="0">View on Explorer</v-card>
                                            </v-menu>
                                        </a>
                                    </span>
                                    <span class="gradient-underline-hover gradient-underline ml-2">
                                        <a :href="`${getSablierUrl(record)}`" class="del-underline text-primary gradient-text-hover pointer" target="_blank">
                                            <v-menu open-on-hover location="bottom" offset="15">
                                                <template v-slot:activator="{ props }">
                                                    <span v-bind="props">Withdraw</span>
                                                </template>
                                                <v-card class="pa-3 text-primaryGrey body-p" rounded="0">Connect your wallet to Sablier and withdraw UN.</v-card>
                                            </v-menu>
                                        </a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-5 font-weight-bold">Legal Disclaimer: </div>
                <div class="mt-3">By claiming your tokens, you agree to the terms and conditions of the UN token distribution. Please ensure that you have read and understood the terms before proceeding with the claim. Trading cryptocurrencies involves significant risk and can result in the loss of your invested capital.</div>
                <div class="mt-3">If you have any further questions or need assistance with the token claiming process, please contact our support team at 
                    <span class='gradient-underline-hover gradient-underline'>
                        <a href="mailto:support@untrading.org" class="text-black01 gradient-text-hover" target="_blank">support@untrading.org</a>
                    </span>
                    <span>.</span>
                </div>
            </div>
        </v-card>
        <!-- 步骤组件 -->
        <Step process-status="process-text" finish-status="finish-text" :steps="steps" :action="action" class="mt-16"></Step>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs');
import UNAirdropTestProofsData from "@/views/un/data/un-airdrop-test-proofs.json";
import UNAirdropProdProofsData from "@/views/un/data/un-airdrop-prod-proofs.json";
import Web3 from "web3";
import UNAPI from "@/api/un.js";
import UNClaimAPI from "@/api/un-claim.js";
export default {
    data(){
        return {
            // 操作
            action: 'CONNECT_WALLET',
            // 步骤
            steps: [
                { title: 'Connect Wallet', codes: ['CONNECT_WALLET'] },
                { title: 'Complete KYC Verification', codes: ['KYC_UNVERIFIED'] },
                { title: 'Claim UN Tokens', codes: ['CLAIM_UN_TOKENS'] }
            ],
            // 当前 URL
            currentUrl: window.location.href,
            // 代币概览
            tokenOverview: {
                tokenSources: [],
                totalClaimableAmount: 0
            },
            // 可领取的代币来源
            claimableTokenSources: [],
            // 代币来源集合
            tokenSources: [
                { code: 'INVESTMENT', name: 'Investment' },
                { code: 'DIVIDEND_DISTRIBUTION', name: 'Dividend Distribution' },
                { code: 'AIRDROP', name: 'Airdrop' },
                { code: 'USER_REWARD', name: 'Platform Rewards (Total)' },
                { code: 'USER_REWARD_KINGDOM', name: 'Platform Rewards (Kingdom)' }
            ],
            // 质押选项集合
            stakingOptions: [
                { value: 0, code: 'NONE', name: 'None', month: 0, bonus: '0%' },
                { value: 1, code: 'TIER_ONE', name: 'Tier One', month: 3, bonus: '15%' },
                { value: 2, code: 'TIER_TWO', name: 'Tier Two', month: 6, bonus: '24%' },
                { value: 3, code: 'TIER_THREE', name: 'Tier Three', month: 9, bonus: '39%' },
                { value: 4, code: 'TIER_FOUR', name: 'Tier Four', month: 12, bonus: '64%' },
            ],
            // 质押选项
            stakingOption: null,
            // 加载中
            loading: false,
            // 加载中
            claimLoading: false,
            // 当前的区块链
            // currentBlockchainNetwork: 'Sepolia',
            // currentBlockchain: {},
            web3: {},
            // 交易哈希
            transactionHash: null,
            // 已领取
            unTokenClaimed: false,
            // 领取记录
            claimedRecords: [],
            // 当前正在领取的代币来源主键
            currentTokenSourceId: null,
            // 当前正在领取的代币来源
            currentTokenSource: null
        }
    },
    components: {  },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "UN_SNAPSHOT_CLAIM") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        this.$nextTick(() => {
            // 初始化
            this.init();
        });
    },
    computed: {
        ...mapGetters(['responsive', 'user', 'token', 'blockchains', 'walletType']),
        // 是否领取禁用
        isClaimDisabled() {
            return this.tokenOverview.totalClaimableAmount <= 0;
        }
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
        user: {
            handler(newVal, oldVal) {
                if(newVal?.wallet) {
                    // 更新操作
                    this.updateAction();
                    // 查询 UN 代币概览
                    this.getUNTokenOverview();
                    // 查询领取记录
                    this.getClaimedRecords();
                }
            },
            immediate: true,
            deep: true
        },
        'token': {
            handler(newVal, oldVal) {
                // 更新操作
                this.updateAction();
            },
            immediate: true
        }
    },
    methods: {
        // 初始化
        init() {
            // 当前区块链
            // if (this.$config.env == 'prod') {
            //     this.currentBlockchainNetwork = 'Arbitrum One';
            // }
            // this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.currentBlockchainNetwork)[0];
            // // 创建 web3
            // this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
        },
        // 更新操作
        updateAction() {
            if (this.token) {
                if (this.user.kyc) {
                    this.action = 'CLAIM_UN_TOKENS';
                } else {
                    this.action = 'KYC_UNVERIFIED';
                }
            } else {
                this.action = 'CONNECT_WALLET';
            }
        },
        // 查询 UN 代币概览
        async getUNTokenOverview() {
            if (!this.token) {
                return;
            }
            this.loading = true;
            let res = await UNAPI.getUNTokenOverview();
            let data = res.data;
            if(data.success) {
                this.tokenOverview = data.data;
                // 可领取的代币来源：过滤归属金额大于0的数据
                this.claimableTokenSources = this.tokenOverview.tokenSources.filter(element => element.vestedAmount > 0);
            }
            this.loading = false;
        },
        // 获取证明
        getProof() {
            let proof = [];
            if (this.$config.env == 'prod') {
                proof = UNAirdropProdProofsData.leaves.filter(data => data.input[0].toLowerCase() == this.user.wallet.toLowerCase())[0]?.proof;
            } else {
                proof = UNAirdropTestProofsData.filter(data => data.inputs[0].toLowerCase() == this.user.wallet.toLowerCase())[0]?.proof;
            }
            return proof;
        },
        // 查询是否已领取
        // async claimed() {
        //     // 查询是否已领取
        //     let UNSnapshotClaimContract = new this.web3.eth.Contract(this.currentBlockchain.UNSnapshotClaimAbi, this.currentBlockchain.UNSnapshotClaimContract);
        //     // bool
        //     let claimed = await UNSnapshotClaimContract.methods.claimed(this.user.wallet).call();
        //     this.unTokenClaimed = claimed;
        //     if (claimed) {
        //         // 查询领取记录
        //         this.getClaimedRecords()
        //     }
        // },
        // 领取
        async claim() {
            // 选择代币来源
            if (this.currentTokenSourceId == null) {
                this.$store.dispatch('snackbarMessageHandler', 'Please select a token source.');
                return;
            }
            // 设置代币来源
            this.currentTokenSource = this.claimableTokenSources.filter(element => element.id == this.currentTokenSourceId)[0];
            // 判断可以领取的代币来源
            if (this.currentTokenSource == null) {
                this.$store.dispatch('snackbarMessageHandler', 'No claimable amount.');
                return;
            }
            // 选择领取选项
            if (this.stakingOption == null) {
                this.$store.dispatch('snackbarMessageHandler', 'Please select a staking option.');
                return;
            }
            // 加载中
            this.claimLoading = true;
            // 当前领取的区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == this.currentTokenSource?.unTokenSource?.blockchain)[0];
            if(currentBlockchain == null) {
                this.$store.dispatch('snackbarMessageHandler', 'The blockchain is not supported.');
                // 加载完成
                this.claimLoading = false;
                return;
            }
            // 创建 web3
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            // 查询一次链上的KYC状态
            let UNKYCRegistryContract = new web3.eth.Contract(currentBlockchain.UNKYCRegistryAbi, this.currentTokenSource?.unTokenSource?.kycRegistryContractAddress);
            // bool
            let isKYCVerified = await UNKYCRegistryContract.methods.isKYCVerified(this.user.wallet).call();
            // 如果未验证则中断
            if(!isKYCVerified) {
                this.claimLoading = false;
                this.action = 'KYC_UNVERIFIED';
                this.$store.dispatch('snackbarMessageHandler', 'Please complete KYC verification first!');
                return;
            }
            // 编码参数列表
            try {
                // 获取证明
                // let proof = this.getProof();
                let proof = this.currentTokenSource?.unTokenSource?.proofs;
                if (proof == null || proof.length == 0) {
                    this.$store.dispatch('snackbarMessageHandler', 'The snapshot proofs is being prepared, please try again later.');
                    // 加载完成
                    this.claimLoading = false;
                    return;
                }
                let method = web3.eth.abi.encodeFunctionSignature('claim(uint128,uint8,bytes32[])');
                // 将参数编码
                let amountWei = this.$web3Utils.toWei(this.currentTokenSource?.unTokenSource?.quantity, 18);
                let param = web3.eth.abi.encodeParameters(['uint128', 'uint8', 'bytes32[]'], [amountWei, this.stakingOption, proof]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(currentBlockchain.blockchain, this.user.wallet, this.currentTokenSource?.unTokenSource?.snapshotContractAddress, data, null);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.claimLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.claimLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            // 保存交易历史
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 保存领取记录
                this.saveUNClaim();
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 加载完成
                this.claimLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt() {
            let params = {
                type: "UN_SNAPSHOT_CLAIM",
                blockchain: this.currentTokenSource?.unTokenSource?.blockchain,
                transactionHash: this.transactionHash
            }
            this.$bus.emit('emitGetTransactionReceipt', params);
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 加载完成
                this.claimLoading = false;
                // 查询 UN 代币概览
                this.getUNTokenOverview();
                // 查询领取记录
                this.getClaimedRecords();
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询交易收据
                    this.getTransactionReceipt();
                }, 3000);
            }
        },
        // 保存领取记录
        async saveUNClaim() {
            let param = {
                unTokenSourceId: this.currentTokenSource?.unTokenSource?.id,
                tokenSource: this.currentTokenSource?.unTokenSource?.tokenSource,
                stakingOption: this.stakingOptions.filter(s => s.value == this.stakingOption)[0].code,
                blockchain: this.currentTokenSource?.unTokenSource?.blockchain,
                transactionHash: this.transactionHash
            };
            let res = await UNClaimAPI.claim(param);
            let data = res.data;
            if(data.success && data.data) {
                this.$store.dispatch('snackbarMessageHandler', "Success");
            } else {
                this.$store.dispatch('snackbarMessageHandler', "Failed");
            }
        },
        // 查询领取记录
        async getClaimedRecords() {
            if (!this.token) {
                return;
            }
            let res = await UNClaimAPI.getClaimedRecords();
            let data = res.data;
            if(data.success) {
                this.claimedRecords = data.data;
            }
        },
        // 查询 Sablier 链接
        getSablierUrl(record) {
            // 当前领取的区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == record.blockchain)[0];
            if(currentBlockchain == null) {
                return;
            }
            // 创建 web3
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            let chainId = Number(web3.utils.toBN(currentBlockchain.chainId).toString());
            let url = 'https://app.sablier.com/stream/LL2-' + chainId + '-' + record.sablierStreamId;
            return url;
        }
    }
}
</script>
<style scoped>
table { 
	width: 100%; 
	border-collapse: collapse; 
}

tr th {
	padding: 2px 0px; 
	border-bottom: 2px solid rgb(var(--v-theme-black01)); 
	text-align: left; 
}

/* td { 
	padding: 2px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
} */

[data-toggle="toggle"] {
	display: none;
}
.v-selection-control--density-compact {
    --v-selection-control-size: 12px;
}
</style>