<template>
    <span v-if="blockchain">
        <v-avatar icon :size="size" color="primaryGrey" v-if="currentBlockchain.icon" class="mr-2">
            <v-img :src="currentBlockchain.icon" cover :height="size"></v-img>
        </v-avatar>
        <span v-if="text">{{ blockchain }}</span>
    </span>
    <span v-if="!blockchain && text && allChains">
        <span>All Chains</span>
    </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            
        }
    },
    props: {
        blockchain: {
            type: String,
            default: null
        },
        text: {
            type: Boolean,
            default: false
        },
        allChains: {
            type: Boolean,
            default: false
        },
        size: {
            type: [Number, String],
            default: 36
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchains']),
        // 当前区块链
        currentBlockchain() {
            let b = this.blockchains.filter(b => b.blockchain == this.blockchain)[0];
            return b ? b : {};
        }
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>