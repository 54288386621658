<template>
	<div class="bg-lightGray align-center">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1400" class="mx-auto pt-7">
                <v-row no-gutters justify="center">
                    <img v-if="fileType == 'image/avif'" id="image/avif" :src="nft.file" v-show="false" />
                    <!--------------------------- 同意规则 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'AGREE_TO_RULES'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <v-icon size="28" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-12 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <div class="mb-6 text-primaryGrey ml-13 r-m-l-0 d-flex">
                                    <h1 class="sub-page-h1 text-grey04">Mint an unNFT</h1>
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10">
                                        <template v-slot:activator="{ props }" >
                                            <a href="https://docs.untrading.org/tutorials/minting-an-unnft-and-list-it-for-sell-on-untrading" target="_blank" class="del-underline">
                                                <v-icon icon="mdi:mdi-help-circle-outline" class="text-primaryGrey gradient-text-hover ml-3 mt-2" v-bind="props" size="36"></v-icon>
                                            </a>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            How to: Minting an unNFT and List It for Sell on untrading
                                        </v-card>
                                    </v-menu>
                                </div>
                                <!--------------------------- unNFT info --------------------------->
                                <v-card max-width="462" color="transparent" class="ml-13 r-m-l-0" elevation="0" rounded="0">
                                    <h5 class="body-p text-primaryGrey">
                                        By uploading an item to this website, importing, minting or wrapping an unNFT, I represent and warrant that I am the owner of the item and have the right to upload, mint and wrap the unNFT to my wallet address. I also acknowledge that the untrading Protocol does not check or verify the copyright holders of the item. In the event that I do not own the copyright to the item or do not have the right to upload and mint the unNFT, I agree to indemnify and hold the Protocol and its affiliates harmless for any damages or losses resulting from my actions. I also acknowledge that any copyright disputes that may arise in the future are between myself and the third party, and the Protocol will not be held liable for any such disputes.
                                    </h5>
                                    <div class="mt-5">
                                        <v-checkbox v-model="agreeToRules" density="compact" class="my-checkbox font-weight-bold" color="primaryGrey">
                                            <template v-slot:label>
                                              <div>I have read and agree to the rules</div>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </v-card>
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <a class="gradient-left-red-purple-300 del-underline">
                                        <v-btn :disabled="!agreeToRules" :rounded="0" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" @click="action = 'SELECT_FILE'" aria-label="Next">Next</v-btn>
                                    </a>
                                </div>
                            </v-col>
                            <v-col md="5" cols="12" sm="6" class="mr-16 pr-73 mt-16 pt-12">
                                <Step :steps="steps" :action="action" class="ml-12 r-m-l-0"></Step>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--------------------------- 选择文件 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'SELECT_FILE'">
                        <v-row no-gutters class="">
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <v-icon size="28" class=" text-primaryGrey mb-3 ml-12 r-m-l-0 gradient-text-hover" @click="action = 'AGREE_TO_RULES'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <div class=" mb-6 ml-13 r-m-l-0 d-flex">
                                    <h1 class="sub-page-h1 text-grey04">Mint an unNFT</h1>
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10">
                                        <template v-slot:activator="{ props }" >
                                            <a href="https://docs.untrading.org/tutorials/minting-an-unnft-and-list-it-for-sell-on-untrading" target="_blank" class="del-underline">
                                                <v-icon icon="mdi:mdi-help-circle-outline" class="text-primaryGrey gradient-text-hover ml-3 mt-2" v-bind="props" size="36"></v-icon>
                                            </a>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            How to: Minting an unNFT and List It for Sell on untrading
                                        </v-card>
                                    </v-menu>
                                </div>
                                <!--------------------------- unNFT info --------------------------->
                                <v-card max-width="462" color="paleYellow" class="ml-13 pa-8 r-m-l-0" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Upload a media file</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="body-p text-primaryGrey">
                                        Drag and drop to the square on the right, or click below to upload a {{ acceptNames}} file.
                                    </h5>
                                    <h5 class="body-p mt-5 text-primaryGrey">Max image size: 10 MB</h5>
                                    <h5 class="body-p text-primaryGrey">Max video size: 100 MB</h5>
                                </v-card>
                                <div class="ml-13 r-m-l-0">
                                    <el-upload ref="upload" action="" :http-request="loadFile" :show-file-list="false" :accept="acceptTypes">
                                        <div class="mt-16">
                                            <a class="gradient-left-red-purple-300 del-underline">
                                                <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" aria-label="Select a file on computer">Select a file on computer</v-btn>
                                            </a>
                                        </div>
                                    </el-upload>
                                </div>
                            </v-col>
                            <v-col md="5" cols="12" sm="6" class="mr-16 mt-n7 r-m-r-0 r-p-r-0 r-m-t-8" :class="responsive == 'pc' ? 'pr-73 ' : 'pr-0'">
                                <el-upload ref="upload" action="" :http-request="loadFile" :show-file-list="false" :accept="acceptTypes" drag>
                                    <div :class="responsive == 'pc' ? 'upload ' : 'upload-m'"></div>
                                </el-upload>
                                <Step :steps="steps" :action="action" class="ml-12 r-m-l-0 r-m-t-8"></Step>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--------------------------- 填写信息 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'FILE_PREVIEW' || action == 'INFO' || action == 'REWARD_RATIOS' || action == 'MINT' || action == 'CONFIRM' || action == 'CREATED'">
                        <v-row no-gutters>

                            <!--------------------------- 文件预览 --------------------------->
                            <v-col cols="12" md="5" sm="6" offset-md="1" v-if="action == 'FILE_PREVIEW'">
                                <v-icon size="28" class="ml-12 text-primaryGrey gradient-text-hover r-m-l-0" @click="action = 'SELECT_FILE'; deleteFile(nft.ipfsCid)" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 mt-3 text-grey04">Mint an unNFT</h1>
                                <!--------------------------- unNFT info --------------------------->
                                <v-card max-width="560" color="paleYellow" class="ml-13 r-m-l-0 pa-8 mt-8" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">File preview</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="body-p text-primaryGrey">
                                        If you are unsatisfied with it, you can replace and reupload the file.
                                    </h5>
                                    <h5 class="body-p mt-3 text-primaryGrey">
                                        To proceed to Step Three, press "Next."
                                    </h5>
                                    <h5 class="body-p text-primaryGrey mt-3">
                                        While the uploaded video is being processed, it may display as "Video not found." You can ignore this and proceed.
                                    </h5>
                                </v-card>
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <a class="gradient-left-red-purple-300 del-underline">
                                        <v-btn :rounded="0" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="Next" @click="action = 'INFO'">Next</v-btn>
                                    </a>
                                </div>
                            </v-col>
                            
                            <!--------------------------- 基本信息 --------------------------->
                            <v-col cols="12" md="5" sm="6" offset-md="1" v-if="action == 'INFO'">
                                <v-icon size="28" class="ml-12 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'FILE_PREVIEW'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <!-- <v-btn :rounded="0" variant="outlined" class="text-none gradient-border text-black ml-13" width="300" height="66" @click="action = 'FILE_PREVIEW'">Back to the last step</v-btn> -->
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 mt-3 text-grey04">Mint an unNFT</h1>
                                <!--------------------------- unNFT info --------------------------->
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-8 bg-paleYellow" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">unNFT info</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="body-p text-primaryGrey">
                                        Once your NFT is minted to the Polygon (Ethereum, Arbitrum One) blockchain, you will not be able to edit or update any of its information.
                                    </h5>
                                    <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">Name<span class="ml-1 text-red">*</span></h3>
                                    <v-text-field clearable v-model="nft.name" variant="outlined" label="Name *" hide-details density="comfortable"></v-text-field>
                                    <div class="mb-6 mt-16">
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">External link</h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        untrading will include a link to this URL on this item’s detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more detailes.
                                    </h5>
                                    <v-text-field clearable v-model="nft.externalUrl" variant="outlined" label="External link" hide-details density="comfortable"></v-text-field>
                                    <div class="mb-6 mt-16">
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">Description</h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        This description will be included on the item’s detailed page next to the image.
                                    </h5>
                                    <v-textarea clearable v-model="nft.description" label="Description" variant="outlined" hint="" persistent-counter :counter="1000"></v-textarea>
                                </v-card>
                                <!--------------------------- Attributes --------------------------->
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-10 bg-paleYellow" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Attributes</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <div class="mb-6">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">department<span class="ml-1 text-red">*</span></h3>
                                    </div>
                                    <!-- ---------- Department ---------- -->
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Choose one of the following department
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                As an NFT-framework-based investment platform, untrading.org is organized in four departments: Artwork Dept, Membership Dept, Collectible Dept and Crypto Dept.
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <div>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none body-p" :class="nft.department == 'Artwork' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Artwork'" aria-label="Artwork">Artwork</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none ml-2 body-p" :class="nft.department == 'Membership' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Membership'" aria-label="Membership">Membership</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none ml-2 r-m-l-0 body-p" :class="nft.department == 'Collectible' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Collectible'" aria-label="Collectible">Collectible</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none mt-2 r-m-l-2 body-p" disabled :class="nft.department == 'Cryptos' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Cryptos'" aria-label="Cryptos">Cryptos</v-btn>
                                    </div>
                                    <!-- ---------- Rarity ---------- -->
                                    <div class="mb-6 mt-16">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">Rarity</h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        <span>The rarity rating of an unNFT is ultimately determined by the creator and the market. The criteria being used to evaluate it can vary. untrading allows you to assign the token's rarity.</span>
                                    </h5>
                                    <div class="mt-3">
                                        <v-select v-model="nft.rarity" label="Rarity" :items="rarityNames" variant="outlined" density="comfortable" hide-details clearable></v-select>
                                    </div>
                                    <!-- ---------- Sensitive ---------- -->
                                    <div class="mt-16">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">Explicit & Sensitive Content</h3>
                                    </div>
                                    <div class="mt-3 body-p mb-6 align-center d-flex">
                                        <span class="mr-10 text-primaryGrey">Set this item as explicit and sensitive content
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing untrading.org.
                                                </v-card>
                                            </v-menu>
                                        </span>
                                        <span class="font-weight-medium text-grey-darken-1">
                                            <v-switch v-model="nft.sensitiveContent" color="primary" density="comfortable" hide-details inset></v-switch>
                                        </span>
                                    </div>
                                </v-card>
                                <!--------------------------- License --------------------------->
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-10 bg-paleYellow" elevation="2" :rounded="0">
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">License<span class="ml-1 text-red">*</span></h3>
                                    <div class="mt-3 mb-6">
                                        <h6 class="body-p text-primaryGrey">
                                           Choosing from one of six NFT-specific licenses
                                           <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    We support the “Can’t Be Evil” NFT Licenses by a16z that are inspried by the guiding principle in Web3 and Creative Commons. You must choose one of the six licenses for your unNFT.
                                                </v-card>
                                            </v-menu>
                                        </h6>
                                    </div>
                                    <v-select v-model="nft.license" label="License *" :items="licenses" item-title="name" item-value="name" variant="outlined" density="comfortable" hide-details></v-select>
                                    <div class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey" v-for="license in licenses" :key="license.id">
                                           <span v-if="license.name == nft.license">{{ license.description }}</span>
                                        </span>
                                    </div>
                                </v-card>
                                <!--------------------------- NEXT --------------------------->
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <a class="del-underline gradient-left-red-purple-300">
                                        <v-btn :rounded="0" width="300" elevation="2" height="66" color="button01" class="text-none text-grey05 body-p-small-b" @click="gotoRewardRatios()" aria-label="Next">Next</v-btn>
                                    </a>
                                </div>
                            </v-col>

                            <!--------------------------- 奖励比例 --------------------------->
                            <v-col cols="12" md="5" sm="6" offset-md="1" v-if="action == 'REWARD_RATIOS'">
                                <v-icon size="28" class="ml-12 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'INFO'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <!-- <v-btn :rounded="0" variant="outlined" class="text-none gradient-border text-black ml-13" width="300" height="66" @click="action = 'INFO'">Back to the last step</v-btn> -->
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 mt-3 text-grey04">Mint an unNFT</h1>
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-8" color="paleYellow" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Reward Ratios</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">
                                        Total Reward Ratio <span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT. Recommended 35%.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey gradient-text-hover pointer" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    Once created, the Total Reward Ratio (f) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must mint a new unNFT.
                                                </div>
                                                <div class="mt-2">
                                                    You may find yourself unable to sell your unNFT if this value is too low or too high. 
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.totalRewardRatio" label="Total Reward Ratio" type="number" density="comfortable" min="5" max="50" placeholder="Between 5% to 50%" variant="outlined" hide-details :reverse="Number(nft.totalRewardRatio) > 0">
                                        <template v-slot:append-inner>%</template>
                                    </v-text-field>
                                    <h3 class="mt-16 mb-6 body-h4 text-uppercase text-primaryGrey">
                                        Originator Reward Ratio (OR)<span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split). Recommended 40%.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey gradient-text-hover pointer" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    The Originators' Token (o-token) may be viewed and managed on the Dashboard. Each unNFT consists of 100 o-tokens, 70 of which are sent to the originator(s) and 30 to untrading.org (For a limited time, all o-tokens designed for untrading.org are waived).
                                                </div>
                                                <div class="mt-2">
                                                    On your Dashboard, you can transfer o-tokens to other creators or originators.
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.originatorRewardRatio" label="Originator Reward Ratio (OR)" density="comfortable" type="number" min="5" max="50" placeholder="Between 5% to 50%" variant="outlined" hide-details :reverse="Number(nft.originatorRewardRatio) > 0">
                                        <template v-slot:append-inner>%</template>
                                    </v-text-field>
                                    <h3 class="mt-16 mb-6 body-h4 text-uppercase text-primaryGrey">
                                        Number of Generations <span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit. Recommended 10.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey gradient-text-hover pointer" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    Once created, the Number of Generation (w) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must mint a new unNFT.
                                                </div>
                                                <div class="mt-2">
                                                    You may find yourself unable to sell your unNFT if this value is too low or too high. 
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.numberOfGenerations" label="Number of Generations" density="comfortable" type="number" min="5" max="20" placeholder="Between 5 to 20" variant="outlined" hide-details :reverse="Number(nft.numberOfGenerations) > 0"></v-text-field>
                                </v-card>
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <a class="del-underline gradient-left-red-purple-300">
                                        <v-btn width="300" :rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" height="66" @click="gotoMint()" aria-label="Next">Next</v-btn>
                                    </a>
                                </div>
                            </v-col>

                            <!--------------------------- MINT --------------------------->
                            <v-col cols="12" md="5" sm="6" offset-md="1" v-if="action == 'MINT'">
                                <v-icon size="28" class="ml-12 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'REWARD_RATIOS'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <!-- <v-btn :rounded="0" variant="outlined" class="text-none gradient-border text-black ml-13" width="300" height="66" @click="action = 'REWARD_RATIOS'">Back to the last step</v-btn> -->
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 mt-3 text-grey04">Mint an unNFT</h1>
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-8" color="paleYellow" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Mint your unNFT</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">Blockchain<span class="ml-1 text-red">*</span></h3>
                                    <div class="mt-3 mb-6">
                                        <h5 class="body-p text-primaryGrey">
                                            Choosing from supported blockchains
                                        </h5>
                                    </div>
                                    <!-- 选择区块链 -->
                                    <v-select v-model="selectedBlockchain" :label="isDisabledBlockchain ? '' : 'Blockchain *'" :items="supportedBlockchains" item-title="blockchain" :variant="isDisabledBlockchain ? 'solo' : 'outlined'" density="comfortable" :disabled="isDisabledBlockchain" hide-details return-object @update:modelValue="updateSelectedBlockchain">
                                        <template v-slot:selection="{ item }" >
                                            <img class="mr-3" :src="item.raw.icon" width="24" />
                                            <span>{{ item?.raw?.blockchain }}</span>
                                        </template>
                                    </v-select>
                                    <h5 class="mt-16 body-p mb-16 text-primaryGrey">
                                        Finallze your unNFT by minting it on the blockchain. Once you mint your unNFT, it cannot be updated or changed.
                                    </h5>
                                </v-card>
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <a class="del-underline gradient-left-red-purple-300">
                                        <v-btn width="300" :rounded="0" elevation="2" :loading="mintLoading" color="button01" class="text-none text-grey05 body-p-small-b" height="66" @click="confirmUnNftInfo" aria-label="Mint">Mint!</v-btn>
                                    </a>
                                </div>
                            </v-col>

                            <!--------------------------- Waiting for Confirmation --------------------------->
                            <v-col cols="12" md="5" sm="6" offset-md="1" v-if="action == 'CONFIRM'">
                                <v-icon size="28" class="ml-12 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'MINT'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 mt-3 text-grey04">Mint an unNFT</h1>
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-8" color="paleYellow" elevation="2" :rounded="0">
                                    <i class="iconfont icon-refresh text-primaryGrey"></i>
                                    <h2 class="sidebar-h2 mt-5 text-primaryGrey">Waiting for Confirmation...</h2>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                        Confirm this transaction in your wallet. Gas will also need to be approved afterwards. The gas fee is the cost of interacting with the blockchain. In no way are they created or collected by untrading.org.
                                    </h5>
                                </v-card>
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <a class="del-underline gradient-left-red-purple-300">
                                        <v-btn width="300" :rounded="0" elevation="2" :loading="mintLoading" color="button01" class="text-none text-grey05 body-p-small-b" height="66" @click="confirmUnNftInfo" aria-label="Mint">Mint!</v-btn>
                                    </a>
                                </div>
                            </v-col>

                            <!--------------------------- Created --------------------------->
                            <v-col cols="12" md="5" sm="6" offset-md="1" v-if="action == 'CREATED'">
                                <v-icon size="28" class="ml-12 r-m-l-0 text-primaryGrey" icon="iconfont icon-refresh"></v-icon>
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 mt-3 text-primaryGrey">Mint an unNFT</h1>
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mt-8" color="paleYellow" elevation="2" :rounded="0">
                                    <i class="iconfont icon-refresh text-primaryGrey"></i>
                                    <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your unNFT is being created...</h2>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="body-p mb-16 text-primaryGrey">
                                        The unNFT is being minted on the blockchain.
                                    </h5>
                                    <div class="gradient-underline-hover">
                                        <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/tx/' + nft.transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p" target="_blank">
                                            <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ selectedBlockchain != null ? selectedBlockchain.blockchainExplorer : '' }}
                                        </a>
                                    </div>
                                </v-card>
                            </v-col>

                            <!--------------------------- 文件预览 和 步骤条 --------------------------->
                            <v-col md="5" sm="6" cols="12" class="ml-12 r-m-l-0 mt-100 pt-5">
                                <MintMedia :src="nft.ipfsFileUrl"></MintMedia>
                                <Step :steps="steps" :action="action" class="mt-16"></Step>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    <!--------------------------- Completion --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'COMPLETION'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 text-grey04">Mint an unNFT</h1>
                                <v-card max-width="560" class="ml-13 r-m-l-0 pa-10 mt-8 bg-paleYellow" elevation="2" :rounded="0">
                                    <i class="mb-5 iconfont icon-complete text-primaryGrey"></i>
                                    <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your unNFT has been minted !</h2>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="body-p text-primaryGrey">
                                        Congratulations! Your artwork has offically been minted as an unNFT on the Polygon (Ethereum, Arbitrum One) Network to your set.
                                    </h5>
                                    <h5 class="mt-5 body-p text-primaryGrey">
                                        Now it’s time to list it for sale on untrading!
                                    </h5>
                                </v-card>
                                <div class="ml-13 mt-16 r-m-l-0">
                                    <div class="d-flex justify-space-between" :class="responsive == 'mobile' ? ' flex-column' : ''">
                                        <router-link :to="'/unnft/list/' + nftId" class="del-underline gradient-left-red-purple-200">
                                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="66" aria-label="Sell">Sell</v-btn>
                                        </router-link>
                                        <router-link :to="$tools.getUnNFTUrl(selectedBlockchain != null ? selectedBlockchain.blockchain : null, nftId)" class="del-underline gradient-left-red-purple-200 r-m-t-4">
                                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="66" aria-label="View">View</v-btn>
                                        </router-link>
                                    </div>
                                    <Step :steps="steps" :action="action" class="mt-16"></Step>
                                </div>
                            </v-col>
                            <v-col md="5" sm="6" cols="12" class="ml-12 r-m-l-0 mt-16 pt-4">
                                <UnNftMedia :nftId="nftId"></UnNftMedia>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 弹窗组件 -->
        <!-- Uploading to IPFS... -->
        <v-dialog v-model="uploadingDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-progress-linear indeterminate bg-opacity="1" bg-color="paleYellow" color="primary"></v-progress-linear>
            <v-card class="pa-8 bg-paleYellow" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h2 class="sidebar-h2 text-primaryGrey">Uploading to IPFS...</h2>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <h5 class="body-p mb-16 text-primaryGrey">The InterPlanetary File System, known as IPFS, is a peer-to-peer network for sharing data in a distributed file system.</h5>
                            <div class="body-p gradient-underline-hover">
                                <a href="https://ipfs.io" aria-label="Ipfs" target="_black" class="gradient-text-hover text-primaryGrey del-underline">Learn about IPFS</a><v-icon class="ml-2 text-primaryGrey" icon="fa-solid fa-arrow-right-long" size="14"></v-icon>
                            </div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" width="200" height="48" color="button01" class="text-none text-grey05 body-p-small-b" @click="reload" aria-label="Interrupt upload">Interrupt upload</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 文件太大弹窗 -->
        <v-dialog v-model="fileTooPowerfulDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h2 class="sidebar-h2 text-primaryGrey">Your file is too powerful</h2>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <h5 class="body-p mb-16 text-primaryGrey">The max file size is 100MB.</h5>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="fileTooPowerfulDialog = false">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 无效的媒体文件弹窗组件 -->
        <v-dialog v-model="invalidMediaFileDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h2 class="sidebar-h2 text-primaryGrey">Invalid media file</h2>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <h5 class="body-p text-primaryGrey">{{ uploadErrorMessage }}</h5>
                            <h5 class="body-p mb-16 text-primaryGrey">Please select a supported file to upload!</h5>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="invalidMediaFileDialog = false">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 保存草稿弹窗 -->
        <SaveDraft :show="saveDraftDialog" @watchSaveDraft="watchSaveDraft"></SaveDraft>
        <!-- 确认信息弹窗 -->
        <ConfirmUnNftInfoDialog :show="confirmUnNftInfoDialog" :nft="nft" @watchConfirmUnNftInfoDialog="watchConfirmUnNftInfoDialog"></ConfirmUnNftInfoDialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import IPFSAPI from '@/api/ipfs.js'
import SetAPI from '@/api/set.js'
import NFTAPI from '@/api/nft.js'
import NFTRarityAPI from '@/api/nft-rarity.js'
import MediaAPI from '@/api/media.js'
import MediaTypeAPI from '@/api/media-type.js'
import MintMedia from '@/components/nft/MintMedia.vue'
import UnNftMedia from '@/components/nft/UnNftMedia.vue'
import SaveDraft from '@/components/nft/SaveDraft.vue'
import ConfirmUnNftInfoDialog from '@/components/nft/ConfirmUnNftInfoDialog.vue'
import Web3 from "web3";
export default {
    data(){
        return {
            // 操作
            action: 'AGREE_TO_RULES',
            // 文件太大弹窗
            fileTooPowerfulDialog: false,
            // 文件上传中
            uploadingDialog: false,
            // 无效的媒体文件弹窗
            invalidMediaFileDialog: false,
            // 上传失败信息
            uploadErrorMessage: null,
            // 步骤
            steps: [
                { title: 'Agree to rules', codes: ['AGREE_TO_RULES'] },
                { title: 'Upload a file', codes: ['SELECT_FILE'] },
                { title: 'Preview uploaded file', codes: ['FILE_PREVIEW'] },
                { title: 'Enter unNFT info', codes: ['INFO'] },
                { title: 'Set reward ratios', codes: ['REWARD_RATIOS'] },
                { title: 'Mint your unNFT', codes: ['MINT'] },
                { title: 'Confirm in your wallet', codes: ['CONFIRM', 'CREATED'] },
                { title: 'Completion', codes: ['COMPLETION'] }
            ],
            // 接受的媒体类型
            acceptTypes: "",
            // 接受的媒体名称
            acceptNames: "",
            // 稀有度名称集合
            rarityNames: [],
            // 同意规则
            agreeToRules: false,
            // 文件
            file: null,
            // 文件类型
            fileType: null,
            // 文件 Base64 编码，用于预览
            fileBase64: null,
            // 选中的区块链
            selectedBlockchain: {},
            // 创建对象
            nft: {
                setId: this.$route.query.setId,
                name: null,
                externalUrl: null,
                description: null,
                license: null,
                image: null,
                file: null,
                ipfsFileUrl: null,
                ipfsCid: null,  // ipfsCid 用于删除时使用
                totalRewardRatio: null,
                originatorRewardRatio: null,
                numberOfGenerations: null,
                blockchain: null,
                contractAddress: null,
                transactionHash: null,
                department: null,
                rarity: null,
                sensitiveContent: false,
                metadata: null
            },
            // 元数据的 IPFS 链接
            metadataIpfsUrl: null,
            // 铸造加载中
            mintLoading: false,
            // 创建成功后的平台主键
            nftId: null,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 禁用区块链
            isDisabledBlockchain: false,
            // 保存草稿弹窗
            saveDraftDialog: false,
            // 确认信息弹窗
            confirmUnNftInfoDialog: false,
            // 路由要去的地方
            routeTo: null
        }
    },
    components: { MintMedia, UnNftMedia, SaveDraft, ConfirmUnNftInfoDialog },
    created(){
        // 加载缓存
        this.loadCache();
        // 获取接受的媒体类型
        this.getAcceptTypes()
        // 获取接受的媒体名称
        this.getAcceptNames();
        // 获取所有的稀有度名称集合
        this.getRarityNames();
        // 查询套装
        this.getSetById(this.nft.setId);
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "NFT_MINT") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user', 'walletType', 'metaMask', 'licenses', 'blockchains', 'responsive']),
        // NFT 支持的区块链
        supportedBlockchains() {
            return this.blockchains.filter(b => b.isSupportCreateNFT);
        }
    },
    watch:{
        // 监听文件
        file(newVal, oldVal) {
            if(newVal) {
                // 上传文件
                this.upload();
                // 文件类型
                this.fileType = this.file.type;
                const _this = this
                const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = function (e) {
                    // 文件base64
                    _this.uploadFileBase64= e.currentTarget.result
                }
            } else {
                this.file = null;
                this.fileType = null;
                this.fileBase64 = null;
            }
        },
        // 监听步骤做缓存
        action(newVal, oldVal) {
            // 不是最后一步时候缓存，最后一步不缓存
            if(this.steps[this.steps.length - 1].codes.indexOf(newVal) == -1) {
                localStorage.setItem('untrading-mint-step', newVal)
            }
        },
        // 监听参数变化做缓存
        nft: {
            handler(newVal, oldVal) {
                localStorage.setItem('untrading-mint', JSON.stringify(newVal))
            },
            deep: true
        }
    },
    methods: {
        // 加载缓存
        loadCache() {
            let action = localStorage.getItem('untrading-mint-step');
            if(action) {
                this.action = action;
            }
            let nft = localStorage.getItem('untrading-mint');
            if(nft) {
                // 更新数据，同时更新为新的套装主键
                let cacheNft = JSON.parse(nft);
                cacheNft.setId = this.nft.setId;
                this.nft = cacheNft;
                // 查询套装
                this.getSetById(this.nft.setId);
                // 选中的区块链
                this.selectedBlockchain = this.supportedBlockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
            } else {
                localStorage.removeItem('untrading-mint-step');
                this.action = 'AGREE_TO_RULES';
            }
            // 如果当前正在 CREATED 则查询交易收据
            if(this.action == 'CREATED') {
                this.getTransactionReceipt();
            }
        },
        // 加载文件
        loadFile(param){
            let size =  param.file.size / 1024 / 1024;
            // 文件不能超过100MB
            if(size >= 100) {
                this.fileTooPowerfulDialog = true;
                return;
            }
            this.file = param.file;
        },
        // 刷新页面
        reload() {
            location.reload();
        },
        // 获取接受的媒体类型
        async getAcceptTypes() {
            let res = await MediaTypeAPI.getAcceptTypes();
            let data = res.data;
            if(data.success) {
                this.acceptTypes = data.data;
            }
        },
        // 获取接受的媒体名称
        async getAcceptNames() {
            let res = await MediaTypeAPI.getAcceptNames();
            let data = res.data;
            if(data.success) {
                this.acceptNames = data.data;
            }
        },
        // 获取所有的稀有度名称集合
        async getRarityNames() {
            let res = await NFTRarityAPI.getRarityNames();
            let data = res.data;
            if(data.success) {
                this.rarityNames = data.data;
            }
        },
        // 根据主键查询套装
        async getSetById(setId) {
            let res = await SetAPI.getSetById(setId);
            let data = res.data;
            if(data.success) {
                this.isDisabledBlockchain = true;
                // 套装的区块链就是 NFT 的区块链
                this.nft.blockchain = data.data.blockchain;
                // 选中的区块链
                this.selectedBlockchain = this.supportedBlockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 合约地址
                this.nft.contractAddress = this.selectedBlockchain.unNFTContract;
            } else {
                this.isDisabledBlockchain = false;
                this.nft.setId = null;
                this.nft.blockchain = null;
                this.nft.contractAddress = null;
                this.selectedBlockchain = {};
            }
        },
        // 下一步填写奖励比例
        gotoRewardRatios() {
            if(this.nft.name == null || this.nft.name.length == 0) {
                this.$store.dispatch('snackbarMessageHandler', "Name is required");
                return;
            }
            if(this.nft.description != null && this.nft.description.length > 1000) {
                this.$store.dispatch('snackbarMessageHandler', "Description size must be between 0 and 1000");
                return;
            }
            if(this.nft.department == null || this.nft.department.length == 0) {
                this.$store.dispatch('snackbarMessageHandler', "Department is required");
                return;
            }
            if(this.nft.license == null || this.nft.license.length == 0) {
                this.$store.dispatch('snackbarMessageHandler', "License is required");
                return;
            }
            this.action = 'REWARD_RATIOS';
        },
        // 下一步
        gotoMint() {
            let totalRewardRatio = Number(this.nft.totalRewardRatio);
            if(totalRewardRatio < 5 || totalRewardRatio > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Total Reward Ratio");
                return;
            }
            let originatorRewardRatio = Number(this.nft.originatorRewardRatio);
            if(originatorRewardRatio < 5 || originatorRewardRatio > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Originator Reward Ratio");
                return;
            }
            let numberOfGenerations = Number(this.nft.numberOfGenerations);
            if(numberOfGenerations < 5 || numberOfGenerations > 20) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Number Of Generations");
                return;
            }
            this.action = 'MINT';
        },
        // 上传文件
        async upload(){
            // 上传中
            this.uploadingDialog = true;
            let formData = new FormData();
	        formData.append("file", this.file)
            let res = await IPFSAPI.uploadFile(formData);
            let data = res.data;
            if(data.success) {
                this.nft.file = data.data.fileUrl;
                this.nft.ipfsFileUrl = data.data.httpUrl;
                this.nft.image = data.data.ipfsUrl;
                this.nft.ipfsCid = data.data.cid;
                // 文件预览
                this.action = 'FILE_PREVIEW';
                // 如果是avif更新一次尺寸
                if(this.fileType == 'image/avif') {
                    // 更新图片尺寸
                    this.updateDimensions();
                }
            } else {
                // this.$store.dispatch('snackbarMessageHandler', data.message);
                // 上传失败
                this.uploadErrorMessage = data.message;
                this.invalidMediaFileDialog = true;
            }
            // 关闭弹窗
            this.uploadingDialog = false;
        },
        // 更新图片尺寸
        async updateDimensions() {
            let image = document.getElementById('image/avif');
            let param = {
                fileUrl: this.nft.file,
                width: image.width,
                height: image.height
            };
            await MediaAPI.updateDimension(param);
        },
        // 更新选择的区块链
        updateSelectedBlockchain(item){
            this.selectedBlockchain = item;
            this.nft.blockchain = item.blockchain;
            this.nft.contractAddress = item.unNFTContract;
        },
        // 确认信息
        confirmUnNftInfo() {
            if(!this.nft.blockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            if(!this.nft.contractAddress) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild contract address");
                return;
            }
            this.confirmUnNftInfoDialog = true;
        },
        // 铸造NFT
        async mintNFT(){
            // 加载中
            this.action = 'CONFIRM';
            this.mintLoading = true;
            // 创建元数据结构
            let metadata = {
                originator: this.user.wallet,
                name: this.nft.name,
                externalUrl: this.nft.externalUrl,
                description: this.nft.description,
                license: this.nft.license,
                image: this.nft.image,
                totalRewardRatio: this.nft.totalRewardRatio,
                originatorRewardRatio: this.nft.originatorRewardRatio,
                numberOfGenerations: this.nft.numberOfGenerations,
                blockchain: this.nft.blockchain,
                department: this.nft.department,
                sensitiveContent: this.nft.sensitiveContent
            };
            // 上传元数据到 IPFS
            let res = await IPFSAPI.uploadJson(metadata);
            let data = res.data;
            if(data.success) {
                // 更新 metadata 链接
                this.nft.metadata = data.data.httpUrl;
                this.metadataIpfsUrl = data.data.ipfsUrl;
                let web3 = new Web3(new Web3.providers.HttpProvider(this.selectedBlockchain.RPCUrl));
                // 编码参数列表
                try {
                    // 合约方法
                    let method = web3.eth.abi.encodeFunctionSignature('mint(address,uint8,uint256,uint256,uint8,string)');
                    let totalRewardRatioWei = web3.utils.toWei((Number(this.nft.totalRewardRatio) / 100) + '');
                    let originatorRewardRatioWei = web3.utils.toWei((Number(this.nft.originatorRewardRatio) / 100) + '');
                    // 执照的下标
                    let license = this.licenses.filter(license => license.name == this.nft.license)[0].id;
                    // 将参数编码
                    let param = web3.eth.abi.encodeParameters(['address', 'uint8', 'uint256', 'uint256', 'uint8', 'string'], [this.user.wallet, this.nft.numberOfGenerations, totalRewardRatioWei, originatorRewardRatioWei, license, this.metadataIpfsUrl]).substring(2);
                    // 组装数据
                    let data = method + param;
                    // 调起钱包发送交易
                    if(this.walletType){
                        this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.selectedBlockchain.unNFTContract, data);
                    } else {
                        // 错误通知
                        this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                        // 加载完成
                        this.mintLoading = false;
                    }
                } catch (error) {
                    console.error(error);
                    this.$store.dispatch('snackbarMessageHandler', error);
                    // 加载完成
                    this.mintLoading = false;
                    // 失败后回到上一步
                    this.action = 'MINT';
                }
            } else {
                // 错误通知
                this.$store.dispatch('snackbarMessageHandler', data.message);
                // 加载完成
                this.mintLoading = false;
                // 失败后回到上一步
                this.action = 'MINT';
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.nft.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                await this.mint();
                // 成功后进入下一步
                this.action = 'CREATED';
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 失败后回到上一步
                this.action = 'MINT';
            }
            // 加载完成
            this.mintLoading = false;
        },
        // 铸造
        async mint() {
            let res = await NFTAPI.mint(this.nft);
            let data = res.data;
            // 清除缓存
            localStorage.removeItem('untrading-mint-step');
            localStorage.removeItem('untrading-mint');
            if(data.success) {
                this.nftId = data.data;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 查询交易收据
        getTransactionReceipt(){
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_MINT",
                    blockchain: this.nft.blockchain,
                    transactionHash: this.nft.transactionHash
                }
                this.$bus.emit('emitGetTransactionReceipt', params);
            }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                // 无论交易成功还是失败，都转到下一步
                this.action = 'COMPLETION';
                // 清除缓存
                localStorage.removeItem('untrading-mint-step');
                localStorage.removeItem('untrading-mint');
            }
        },
        // 监听保存草稿
        watchSaveDraft(save) {
            this.saveDraftDialog = false;
            // 不保存
            if(!save) {
                // 删除文件
                this.deleteFile(this.nft.ipfsCid);
                // 清除缓存
                localStorage.removeItem('untrading-mint-step');
                localStorage.removeItem('untrading-mint');
            }
            if(this.routeTo) {
                this.$router.push(this.routeTo.fullPath);
            }
        },
        // 监听确认信息弹窗
        watchConfirmUnNftInfoDialog(confirmed) {
            this.confirmUnNftInfoDialog = false;
            if(confirmed) {
                // 铸造NFT
                this.mintNFT();
            }
        },
        // 删除文件
        async deleteFile(cid) {
            // 发出删除请求
            await IPFSAPI.delete(cid);
        }
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        // 如果有没有步骤数据，则本身无缓存，不提示
        if(!localStorage.getItem('untrading-mint-step')) {
            next();
        }
        if(this.routeTo) {
            next();
        } else {
            this.routeTo = to;
            this.saveDraftDialog = true;
            // 不加 next(); 就会阻止离开页面的
        }
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
.upload{
    width: 414px;
    height: 414px;
    border: 3px dashed #7b7b7b;
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
}
:deep(.v-echarts-dialog){
    width: auto;
}
::v-deep .my-checkbox .v-label {
 font-size: 14px!important;
}
</style>