import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 根据 NFT 主键查询历史记录
    provenances(nftId) {
        return axios.request({
            url: '/nft/provenance/provenances',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    // 分页查询所有 NFT 的历史记录
    getProvenancesByPage(page, size, blockchain) {
        return axios.request({
            url: '/nft/provenance/provenances/page',
            method: 'get',
            params: { 
                page: page,
                size: size,
                blockchain: blockchain
            }
        })
    },
    // 分页查询关注用户的 NFT 的历史记录
    getFollowingProvenancesByPage(page, size, blockchain) {
        if(!token) {
            return { data: { success: false } }
        }
        return axios.request({
            url: '/nft/provenance/provenances/following/page',
            method: 'get',
            params: { 
                page: page,
                size: size,
                blockchain: blockchain
            }
        })
    },
    // 根据 NFT 主键查询价格历史图表
    getPriceHistories(nftId) {
        return axios.request({
            url: '/nft/provenance/history/price',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    // 根据 NFT 主键查询持有历史
    getHoldingHistories(nftId) {
        return axios.request({
            url: '/nft/provenance/history/holding',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    // 根据 NFT 主键查询持有损益
    getPnlHistories(nftId) {
        return axios.request({
            url: '/nft/provenance/history/pnl',
            method: 'get',
            params: { nftId: nftId }
        })
    },
}